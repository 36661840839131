import React from 'react'
import {listToTree} from '../utils/treeUtils'
import TreeRoot from "./tree/treeRoot";

const ItemTypesList = (props) => {
  return (
    <div>
      {/* props.noHeading !== true &&
        <h3 className="mb-3">Catalog Definitions</h3>
      */}
      <TreeRoot treeName={props.treeName}
                children={listToTree(props.itemTypes)}
                actions={props.actions}
                onSelect={props.onSelect}
                selectedId={props.selectedId}
                additionalClassNames={props.additionalClassNames}
      />
    </div>
  )
}

export default ItemTypesList
