import React, { useState, useEffect, useRef } from 'react'
import limsApi from '../utils/limsApi'
import { Modal, Card, Container, Tabs, Tab, ToggleButton, ToggleButtonGroup, Form, Row, Col, OverlayTrigger, Tooltip as BSTooltip, Spinner } from 'react-bootstrap'
import moment from 'moment'
import { GraphCanvas, recommendLayout, GraphCanvasRef, useSelection } from 'reagraph'

const NetworkViewer = ({entry, ...props}) => {

  const graphRef = useRef(null)
  const [entries, setEntries] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const fromEntry = entry

  const nodes = entries.map(entry => {
        return ({ id: `${entry.id}`, label: entry.name, fill: entry.id == fromEntry.id ? 'red' : null })
      })

  const edges = []
  entries.map(entry => {
    const target = entry.id
    let sources = []
    Object.keys(entry.inputData).map(key => {
      const value = entry.inputData[key]
      entry.itemType.allTypeFields.map(field => {
        if (field.id == key) {
          if (field.fieldTypeId == 7) {
            sources.push(value)
          }
        }
      })
    })

    sources.map(source=>{
      edges.push(
        {
          source: `${source}`,
          target: `${target}`,
          id: `${source}-${target}`,
          label: "Edge"
        }
      )
    })

  })


  const layout = recommendLayout(nodes, edges)

  const fetchNetwork = async () => {
    await limsApi.get(`entries/network/${entry.id}`, (response)=>{
      const networkData = response.data.entries
      setEntries(networkData)
      console.log(networkData);
    },
    'Error fetching entry', undefined, setIsLoading)
  }

  useEffect(() => {
    fetchNetwork()
  }, [])

  const { onNodePointerOver } = useSelection({
    ref: graphRef,
    nodes,
    edges,
    pathSelectionType: 'out'
  });

  return (
    <Modal show={true} size="xl" onHide={() => props.closeModal()} enforceFocus={false}>
      <Modal.Header closeButton>
        <Modal.Title>Network Viewer</Modal.Title>
      </Modal.Header>
      <Modal.Body>
          <div style={{height: "80vh", width: "100%"}}>
            { !isLoading ?
                <GraphCanvas
                  ref={graphRef}
                  draggable
                  //cameraMode="rotate"
                  //layoutType="forceDirected3d"
                  layoutType="treeTd2d" // THIS IS GOOD
                  onNodePointerOver={onNodePointerOver}
                  nodes={nodes}
                  edges={edges}
                />
              :
              <div style={{position: "absolute", top: "48%", left: "48%"}}><Spinner animation="border" variant="primary" /></div>
            }
          </div>
      </Modal.Body>
    </Modal>
  )
}

export default NetworkViewer
