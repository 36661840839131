import React, { useState, useEffect } from 'react'
import { withRouter, Link } from 'react-router-dom'
import axios from 'axios'
import { Carousel, Modal, Spinner, InputGroup, Form } from 'react-bootstrap'
import { Worker } from '@react-pdf-viewer/core'
import { Viewer, OpenFile } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout'
import { getFilePlugin } from '@react-pdf-viewer/get-file'
import { SeqViz } from "seqviz"

const FileAttachmentFieldDisplay = ({ field, inputData, reload, edit, editFunc, fileAttachments=[] }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [files, setFiles] = useState(fileAttachments)
  const [showImages, setShowImages] = useState(-1)
  const [imageFiles, setImageFiles] = useState([])
  const [showPdf, setShowPdf] = useState(-1)
  const [showSequence, setShowSequence] = useState(-1)

  const fileType = field.fieldOptionsAttributes[0] ? field.fieldOptionsAttributes[0].value : null

  const pdfExtenstions = ["pdf"]
  const imageExtensions = ["png", "jpeg", "jpg"]
  const sequenceExtensions = ["gb", "fa"]

  const fetchFile = () => {
    setFiles([])
    const token = localStorage.getItem('gf-token')
    if (Array.isArray(inputData[field.id])) {
      inputData[field.id].map(attachmentId => {
        axios.get(`/api/file_attachments/${attachmentId}`, { headers: {"Authorization" : `Token ${token}`} })
          .then(response => {
            setFiles(prev => [...prev, response.data])
            setIsLoading(false)
          })
          .catch(error => {
            console.log(error)
            setIsLoading(false)
          })
      })
      inputData[field.id].length == 0 ? setIsLoading(false) : null
    } else {
      axios.get(`/api/file_attachments/${inputData[field.id]}`, { headers: {"Authorization" : `Token ${token}`} })
        .then(response => {
          setFiles([response.data])
          setIsLoading(false)
        })
        .catch(error => {
          console.log(error)
          setIsLoading(false)
        })
    }
  }

  const downloadFile = (file) => {
    const token = localStorage.getItem('gf-token')
    axios({
      url: `/api/file_attachments/${file.id}/download`, //your url
      method: 'GET',
      responseType: 'blob', // important
      headers: {"Authorization" : `Token ${token}`}
    })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', file.name)
        document.body.appendChild(link)
        link.click()
      })
      .catch(error => {
        console.log(error)
      })
  }

  const getExt = (filename) => {
    const re = /(?:\.([^.]+))?$/
    return (re.exec(filename)[1]) ? (re.exec(filename)[1]).toLowerCase() : ""
  }

  const getDisplayValue = (filename) => {
    const ext = getExt(filename)
    if (imageExtensions.includes(ext))
      return (<><i className="far fa-file-image mr-1"></i> {filename}</>)
    else if (sequenceExtensions.includes(ext))
      return (<><i className="fas fa-dna mr-1"></i>{filename}</>)
    else if (pdfExtenstions.includes(ext))
      return (<><i className="far fa-file-pdf mr-1"></i> {filename}</>)
    else
      return (<><i className="far fa-file-alt mr-1"></i> {filename}</>)
  }

  useEffect(() => {
    edit ? (null) : (inputData[field.id] != null ? fetchFile() : (setIsLoading(false), setFiles([])))
  }, [reload, inputData[field.id]])

  useEffect(()=> {
    edit ? setFiles(fileAttachments) : null
  }, [fileAttachments])


  return (
    <>
      {!isLoading || edit  ? (
        files != null && files.length > 0 ? (
          files.map((file, index) => {
            if (file && file.name != null) {

              const ext = getExt(file.name)
              const display = getDisplayValue(file.name)

              return (
                <div key={file.id} id={file.id} style={{marginTop: (index == 0) ? "" : "0.25rem"}}>
                  { imageExtensions.includes(ext) &&
                    <a
                      onClick={() => {
                        setShowImages(index)
                        const imgFiles = files.filter(f => imageExtensions.includes(getExt(f.name)))
                        setImageFiles(imgFiles)
                      }}
                      href="#"
                      >
                      {display}
                    </a>
                  }
                  { pdfExtenstions.includes(ext) &&
                    <a onClick={() => setShowPdf(index)} href="#">
                      {display}
                    </a>
                  }
                  { sequenceExtensions.includes(ext) &&
                    <a onClick={() => setShowSequence(index)} href="#">
                      {display}
                    </a>
                  }
                  { (!pdfExtenstions.includes(ext) && !imageExtensions.includes(ext) && !sequenceExtensions.includes(ext)) &&
                    <a href={file.file.url} target="_blank">
                      {display}
                    </a>
                  }
                  { edit &&
                    <a
                      href='#'
                      className='ml-2'
                      onClick={(e) => {e.preventDefault(); editFunc(file.id)}}
                    >
                      <i className="fas fa-times-circle text-muted"></i>
                    </a>
                  }
                </div>
              )
            }
          })
        ) : (null)
      ) : (
        <i className="fas fa-spinner fa-spin"></i>
      )}
      { showImages >= 0 &&
        <ImageCarouselModal files={imageFiles} downloadFile={(file)=>downloadFile(file)} activeIndex={showImages} closeModal={()=>setShowImages(-1)} />
      }
      { showPdf >= 0 &&
        <PdfFileModal file={files[showPdf]} downloadFile={(file)=>downloadFile(file)} closeModal={()=>setShowPdf(-1)} />
      }
      { showSequence >= 0 &&
        <SequenceFileModal file={files[showSequence]} downloadFile={(file)=>downloadFile(file)} closeModal={()=>setShowSequence(-1)} />
      }
    </>
  )
}

export default FileAttachmentFieldDisplay


const ImageCarouselModal = ({files, closeModal, activeIndex, downloadFile, ...props}) => {
  const [imgLoaded, setImgLoaded] = useState(false)

  const arrowBtn = (direction) => (
    <i className={`fas fa-angle-${direction} fa-3x`} style={{ color: "white", textShadow: "0 0 5px #000"}}></i>
  )
  const nextBtn = files.length > 1 ? arrowBtn("right") : null
  const prevBtn = files.length > 1 ? arrowBtn("left") : null

  useEffect(()=>{
    if (imgLoaded) {
      const container = document.getElementById("container");
      const containerWidth = container.clientWidth
      const containerHeight = container.clientHeight
      files.map((file, index)=>{
        const img = document.getElementById(`image${file.id}`);
        const imgWidth = img.naturalWidth
        const imgHeight = img.naturalHeight

        if (imgWidth > containerWidth)
          img.style.width = "100%"
      })
    }
  }, [imgLoaded])

  const renderFiles = files.map((file, index)=>(
    <Carousel.Item key={file.id} className="text-center">
      <img
        id={`image${file.id}`}
        src={file.file.url}
        alt="First slide"
        onLoad={(e)=>{
          setImgLoaded(true)
        }}
      />
      <Carousel.Caption as="span" className="" style={{textShadow: "0 0 5px blue"}}>
        <h3>
          <a href="#" onClick={()=>downloadFile(file)} style={{color: "lightblue"}}>
            <i className="fas fa-cloud-download-alt mr-1"></i>
            {file.name}
          </a>
        </h3>
      </Carousel.Caption>
    </Carousel.Item>
  ))

  return (
    <Modal show={true} size="xl" className="modal-full-width" id="container" onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>Images</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Carousel defaultActiveIndex={activeIndex} nextIcon={nextBtn} prevIcon={prevBtn} interval={3000}>
          {renderFiles}
        </Carousel>
      </Modal.Body>
    </Modal>
  )
}

const PdfFileModal = ({file, closeModal, downloadFile, ...props}) => {

  useEffect(()=>{
    const elements = document.getElementsByClassName('rpv-toolbar-item')

    // console.log(elements)

    if (elements.length > 5) {
      let el = elements[9]
      el.onclick = function() {
        // console.log("tried to click");
        downloadFile(file)
        return false
      }

      el.innerHTML = `
      <div id="smalldiv"><button class="rpv-core-button"><svg class="rpv-core-icon" height="16px" viewBox="0 0 24 24" width="16px"><path d="M17.5,11.5c3.314,0,6,2.686,6,6s-2.686,6-6,6s-6-2.686-6-6S14.186,11.5,17.5,11.5z
                M17.5,14.5v6
                M17.5,20.5
                l-2.25-2.25
                M17.5,20.5l2.25-2.25
                M10.5,23.5h-9c-0.552,0-1-0.448-1-1v-21c0-0.552,0.448-1,1-1h13.293
                c0.265,0,0.52,0.105,0.707,0.293L19.207,4.5C19.395,4.687,19.5,4.942,19.5,5.207V8.5"></path></svg></button></div>
                `

      const smDiv = el //document.getElementById('smalldiv')

      // smDiv.onmouseenter = function() {
      //   smDiv.innerHTML += `
      //   <div style="left: 0px; position: absolute; top: 0px;"></div><div class="rpv-core-tooltip-body" style="top: 52px; left: 965.922px;"><div class="rpv-core-arrow rpv-core-arrow-bc rpv-core-tooltip-body-arrow"></div><div class="rpv-core-tooltip-body-content">Download</div>
      //   `
      // }
      //
      // smDiv.onmouseleave = function() {
      //   console.log("leaving");
      //   smDiv.innerHTML = `
      //   <button class="rpv-core-button"><svg class="rpv-core-icon" height="16px" viewBox="0 0 24 24" width="16px"><path d="M17.5,11.5c3.314,0,6,2.686,6,6s-2.686,6-6,6s-6-2.686-6-6S14.186,11.5,17.5,11.5z
      //             M17.5,14.5v6
      //             M17.5,20.5
      //             l-2.25-2.25
      //             M17.5,20.5l2.25-2.25
      //             M10.5,23.5h-9c-0.552,0-1-0.448-1-1v-21c0-0.552,0.448-1,1-1h13.293
      //             c0.265,0,0.52,0.105,0.707,0.293L19.207,4.5C19.395,4.687,19.5,4.942,19.5,5.207V8.5"></path></svg></button>
      //             `
      // }

    }
  }, )


  // console.log(DefaultLayoutPluginProps);
  const defaultLayoutPluginInstance = defaultLayoutPlugin()
  const url = file.file.url

  return (
    <Modal show={true} size="xl" id="container" onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>PDF Viewer</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{height: "87vh"}}>
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js" >
            <Viewer
              fileUrl={url}
              plugins={[
                defaultLayoutPluginInstance
              ]}
              />
          </Worker>
        </div>
      </Modal.Body>
    </Modal>
  )
}

const SequenceFileModal = ({file, closeModal, downloadFile, ...props}) => {

  const [blob, setBlob] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [search, setSearch] = useState("")
  const [selection, setSelection] = useState(null)
  // console.log(DefaultLayoutPluginProps);
  const defaultLayoutPluginInstance = defaultLayoutPlugin()
  const url = file.file.url

  const getBlob = () => {
    const token = localStorage.getItem('gf-token')
    axios({
      url: `/api/file_attachments/${file.id}/download`, //your url
      method: 'GET',
      responseType: 'text', // important
      headers: {"Authorization" : `Token ${token}`}
    })
      .then(response => {
        // setBlob(new File([response.data]))
        setBlob(response.data)
        setIsLoading(false)
      })
      .catch(error => {
        console.log(error)
        setIsLoading(false)
      })
  }

  useEffect(()=>{
    getBlob()
  }, [])

  const getExt = (filename) => {
    const re = /(?:\.([^.]+))?$/
    return (re.exec(filename)[1]) ? (re.exec(filename)[1]).toLowerCase() : ""
  }

  return (
    <Modal show={true} size="xl" id="container" onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>Sequence Viewer</Modal.Title>
        <span className="mt-2 ml-2">
          (<a href="#" onClick={()=>downloadFile(file)}><i className="fas fa-cloud-download-alt mr-1"></i>{file.name}</a>)
        </span>
        { (!isLoading && blob) &&
          <InputGroup style={{ position: "absolute", top: "16px", right: "50px", width: "20%", float: "right"}}>
            <InputGroup.Prepend>
                <InputGroup.Text>
                    <i className="fa fa-search"></i>
                </InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
                type="text"
                autoComplete="off"
                id="searchInput"
                placeholder="Search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
            />
          </InputGroup>
        }
      </Modal.Header>
      <Modal.Body>
        <div style={{height: "80vh", width: "100%"}}>
          { !isLoading ?
              blob ? (
                <>
                  { (selection && getExt(file.name) != "fa") &&
                    <p style={{position: "absolute"}}>
                      name: <b>{selection.name}</b>
                      <br/>
                      length: <b>{selection.length}</b>
                      <br/>
                      range: <b>{selection.start} - {selection.end}</b>
                      <br/>
                      GC: <b>{selection.gc}%</b>
                      <br/>
                      TM: <b>{selection.tm}</b>
                    </p>
                  }
                  <SeqViz
                    file={blob}
                    viewer={getExt(file.name) == "fa" ? "linear" : "both"}
                    colors={[
                      "#9DEAED", // cyan
                      "#8FDE8C", // green
                      "#CFF283", // light green
                      "#8CDEBD", // teal
                      "#F0A3CE", // pink
                      "#F7C672", // orange
                      "#F07F7F", // red
                      "#FAA887", // red-orange
                      "#F099F7", // magenta
                      "#C59CFF", // purple
                      "#6B81FF", // blue
                      "#85A6FF" // light blue
                    ]}
                    search={search.length > 0 ? { "query": search, "mismatch": 0 } : null}
                    onSelection={(selection)=> setSelection(selection)}
                  />
                </>
              ) : (
                <div className="text-center">
                  An error occurred while fetching that file.
                </div>
              )
            :
              <div style={{position: "absolute", top: "45%", left: "48%"}}>
                <Spinner animation="border" variant="primary" />
              </div>
          }
        </div>
      </Modal.Body>
    </Modal>
  )
}
