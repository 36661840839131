import React, {useState} from "react";
import limsApi from '../utils/limsApi'
import { ButtonGroup, Button, OverlayTrigger, Popover} from "react-bootstrap";
import { Link, withRouter } from 'react-router-dom'
import LocationTypeSelector from "./locationTypeSelector";
import {ActionButtonGroup, DeleteButton, EditButton, NewButton} from "./buttons/actionButtons";
import {connect} from "react-redux";
import {COMPONENT_NAMES} from "../constants";
import {setExpanded} from "../redux/actions";
import {limsDelete} from "../utils/limsApi";

const handleDeleteLocation = (id) => {
    // TODO: this click is certainly misplaced. Find a better place for this.
    // or a better way of closing the delete confirmation dialog.
    document.body.click()
    limsDelete('locations', id,
        `Location Removed`,
        `Location not empty. Cannot delete.`
    );
};

/**
 * Note children and setChildren are referring to different state named "children". The first are the children of the
 * component tree. The second are the children of the locations tree.
 */
const AddLocationPopover =
    connect(null, {setExpanded}, null, {forwardRef: true})
        (React.forwardRef(({popper, children, setChildren, setExpanded, treeName, node, ...props}, ref) => {
    const [populateNumChildren, setPopulateNumChildren] = useState(1)
    const [populateLocationTypeId, setPopulateLocationTypeId] = useState(null)
    const [populateFormIsLoading, setPopulateFormIsLoading] = useState(false)

    const handlePopulateSubmit = (event) => {
        event.preventDefault()
        setPopulateFormIsLoading(true)

        limsApi.post(`locations/${node.id}/populate`, {
            instructions: {
                childLocationsTypeId: populateLocationTypeId,
                numChildren: populateNumChildren
            }
        }, `${populateNumChildren} Child Containers Created`, (response)=>{
          const newChildren = response.data.locations
          newChildren.forEach((child)=>{child.children = []});
          const allChildren = children.concat(newChildren)
          setChildren(allChildren)
          setExpanded(treeName, node.id, true)
          document.body.click() // close 'populate form' popover
        },
        'Error adding locations')
    }

    return (
        <Popover ref={ref} id="popover-basic" {...props}>
          <Popover.Title as="h4">{node.name}</Popover.Title>
            <Popover.Content>
              <ButtonGroup>
                <Link className="btn btn-primary" onClick={e => document.body.click()} to={`/manage/locations/new?parentId=${node.id}`}>Add<br/>Location</Link>
                <Link className="btn btn-secondary" onClick={e => document.body.click()} to={`/manage/locations/${node.id}/fill`}>Add Multiple Locations<br/>from Template</Link>
              </ButtonGroup>
            </Popover.Content>
        </Popover>
    )
}));

const LocationsActions = ({children, setChildren, treeName, node, ...props}) => {
    return (
        <ActionButtonGroup>
            <OverlayTrigger trigger="click" placement="right"
                            overlay={<AddLocationPopover treeName={treeName} node={node} setChildren={setChildren}/>}
                            rootClose>
                <NewButton/>
            </OverlayTrigger>
            {/*<NewButton actionUrl={`/manage/locations/new?parentId=${node.id}`} />*/}
            <EditButton actionUrl={`/manage/locations/${node.id}/edit/`}/>
        </ActionButtonGroup>);
};

export default LocationsActions;
