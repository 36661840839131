import React, { useState, useEffect, useCallback, useRef } from 'react'
import limsApi from '../utils/limsApi'
import SelectLocation from "./selectLocation"
import EntryRecordField from './fields/entryRecordField'
import EntryRecordFieldDisplay from './fields/entryRecordFieldDisplay'
import ItemTypeSearchField from './fields/itemTypeSearchField'
import ItemTypeFieldSelector from './fields/itemTypeFieldSelector'
import { Typeahead } from 'react-bootstrap-typeahead'

const WorkflowStepList = ({edit,
                          structure,
                          increaseTransformerNumber,
                          reduceTransformerNumber,
                          locations,
                          setLocations,
                          transformerItems,
                          setTransformerItems,
                          itemTypes,
                          setItemTypes,
                          handleInputEntryChange,
                          handleStepTypeChange,
                          handleFieldSelect,
                          ...props}) => {

  const [transEntries, setTransEntries] = useState([])
  const [entries, setEntries] = useState([])
  const [availableItems, setAvailableItems] = useState([])
  // const [itemTypes, setItemTypes] = useState([])
  const ref = useRef([])

  const editText = <small>select on execution</small>

  const fetchItemType = async () => {
    const itemTypeId = props.itemTypeId != undefined ? props.itemTypeId : props.match.params.id

    await limsApi.get(`item_types/${itemTypeId}`, (response)=>{
      const itemTypeData = response.data.itemType
      const inputDataPayload = response.data.itemType.inputData
      setItemType(itemTypeData)
      setInputData(inputDataPayload)
    },
    'Error fetching item type', ()=>{})
  }

  const handleLocationChange = (loc, i) => {
    setLocations(prev => {
      let newLocs = [...prev]
      newLocs[i] = loc
      return(newLocs)
    })
  }

  const handleEntryChange = (entryId, i) => {
    // console.log('types:', typeof(entryId));
    // console.log('id comparison:', entryId == "");

    if (entryId == "") {
      handleItemChange(null, i)
    } else {
      fetchAvailableItems(entryId)
    }


    setTransEntries(prev => {
      let newArr = [...prev]
      newArr[i] = entryId == "" ? null : entryId
      return newArr
    })
  }

  const handleItemTypeChange = (itemTypeId, i) => {
    setItemTypes(prev => {
      let newArr = [...prev]
      newArr[i] = itemTypeId
      return newArr
    })
  }

  const handleItemChange = (itemId, stepI, transI) => {
    setTransformerItems(prev => {
      let newArr = [...prev]
      let stepArr = []
      if (prev[stepI] != undefined) stepArr = [...prev[stepI]]
      stepArr[transI] = itemId
      newArr[stepI] = stepArr
      return newArr
    })
  }

  const fetchAvailableItems = () => {
    structure.map((step, index) => {
      step.inputs.map((entryId, inputI) => {
        limsApi.get(`items?entry_id=${entryId}&status=1`, (response)=>{
          let itemsData = response.data.items
          setAvailableItems((prev) => {
            let newObj = {...prev}
            newObj[entryId] = itemsData
            return newObj

          })
        },
        '')
      })

    })

  }

  useEffect(()=>{
    !edit && fetchAvailableItems()
  }, [])

  const isLast = (index) => {
    return structure.length == index + 1
  }


  return (
    <div>
      {structure.map((step, index) => (
        <div key={`step ${index}`} className="pl-4 pr-4 pb-3" ref={ref}>
          <div className="row">
            <h4 className="mr-3" style={{position: "relative", top: "-2px"}}>Plate {index + 1}:</h4>

              { (edit && !isLast(index)) && (
                  <>
                    Number of inputs:
                    <button type="button" className="btn btn-sm btn-danger mr-3 ml-2" onClick={() => reduceTransformerNumber(index)} disabled={step.inputs.length==0}>
                      <i className="fas fa-minus"></i>
                    </button>
                    <b style={{fontSize: 16, paddingTop: '5px'}}>{step.inputs.length}</b>
                    <button type="button" className="btn btn-sm btn-success ml-3" onClick={() => increaseTransformerNumber(index)} disabled={isLast(index)}>
                      <i className="fas fa-plus"></i>
                    </button>
                  </>
              )}

          </div>
          <div className="">
            <div className="row">
              {edit ?
                <div className="col">
                    <ItemTypeSearchField field={{name: "Plate Type", id: step.item_type}} handleChange={(e)=>handleStepTypeChange(e.target.value, index)} formRef={ref} />
                    { (step.item_type > 0 && index > 0) &&
                      <ItemTypeFieldSelector stepI={index} itemTypeId={step.item_type} selectNumFields={structure[index-1].inputs.length + 1} structure={structure} handleChange={(i, stepI, field, itemTypeId)=>handleFieldSelect(i, stepI, field, itemTypeId)} />
                    }
                </div>
              :
                <>
                  <SelectLocation
                      ref={ref}
                      className="col"
                      location={locations[index]}
                      setLocation={(loc) => handleLocationChange(loc, index)}
                      locationPosition={null}
                      setLocationPosition={null}
                      excludeItem={null}
                      idAsRoot={`popoverRoot${index}`}
                  />
                </>
              }
            </div>
            { step.inputs.length > 0 &&
              <div className="row mt-2">
                <div className="col">
                  {step.inputs.map((entryId, i) => (
                    <div className="" key={`trans${i}`}>
                      { edit ? (
                        <div>
                          <EntryRecordField field={{name: `Input ${i+1}:`, id: 0}} inputData={{0: entryId}} handleChange={(e)=>handleInputEntryChange(e.target.value, i, index)} formRef={ref} />
                        </div>
                        ) : (
                          <div className="row mt-1">
                            <div className="col">
                              <EntryRecordFieldDisplay field={{id: 0}} inputData={{0: entryId}} />
                            </div>
                            <div className="col">
                              { (transformerItems[index] == undefined || true) &&
                                <Typeahead
                                  onChange={(selected) => {
                                    // console.log("onchcand selected:", selected);
                                    if(selected[0]) handleItemChange(selected[0], index, i)
                                    else handleItemChange(null, index, i)
                                  }}
                                  options={availableItems[entryId]}
                                  labelKey="code"
                                  id="search-items"
                                  placeholder="Type an item code"
                                  selected={(transformerItems[index] && transformerItems[index][i]) ? [transformerItems[index][i]] : []}
                                  style={{paddingTop: ''}}
                                />
                              }
                              { (false && transformerItems[index] != undefined && transformerItems[index][i] != undefined) && transformerItems[index][i].code}
                            </div>
                          </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            }
          </div>
        </div>

      ))}
    </div>
  )
}

export default WorkflowStepList
