import React, { useState, useEffect, useRef } from 'react'
import limsApi from '../../utils/limsApi'
import { OverlayTrigger, Popover, Card } from 'react-bootstrap'

const ItemTypeSearchField = ({ field, handleChange, formRef, hasLabel }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [searchPhrase, setSearchPhrase] = useState('')
  const [itemTypes, setItemTypes] = useState([])
  const [currentItemType, setCurrentItemType] = useState(null)

  const fetchEntries = () => {
    limsApi.get('item_types', (response)=>{
      let itemTypesData = response.data.itemTypes
      setItemTypes(itemTypesData)
    },
    '')
  }

  useEffect(() => {
    fetchEntries()
  }, [])

  const fetchItemType = () => {
    console.log("fetching item type:", field.id);
    limsApi.get(`item_types/${field.id}`, (response)=>{
      let itemTypeData = response.data.itemType
      setCurrentItemType(itemTypeData)
    },
    '')
  }

  useEffect(() => {
    console.log("In use effect", field);
    if(field.id) {
      fetchItemType()
    }
  }, [field.id])

  const handleItemTypeSelect = (itemType) => {
    setCurrentItemType(itemType)
    const e = {
      target: {
        name: field.id,
        value: itemType.id
      }
    }
    handleChange(e)
    document.body.click()
  }

  const handleItemTypeDeselect = (event) => {
    event.preventDefault()
    setCurrentItemType(null)
    const e = {
      target: {
        name: field.id,
        value: ""
      }
    }
    handleChange(e)
  }

  return (
    <div>
      { hasLabel != false &&
        <label>
          {field.name}
        </label>
      }
      <Card className="field-input-card">
        <Card.Body className="text-primary">
          <OverlayTrigger
            rootClose
            trigger="click"
            placement="right"
            container={formRef.current}
            onExited={() => { setSearchPhrase('') }}
            overlay={
              <Popover className="foo">
                { hasLabel != false &&
                  <Popover.Title as="h3">{field.name}</Popover.Title>
                }
                <Popover.Content>
                  <div className="p-1 py-2 pb-3" style={{minWidth: "300px"}}>
                    <input
                      type="text"
                      name="searchPhrase"
                      className="form-control"
                      value={searchPhrase}
                      onChange={e => setSearchPhrase(e.target.value)}
                      placeholder="Search item types by name"
                    />
                    { searchPhrase.length > 0 &&
                      <div className="list-group">
                        {itemTypes.filter(itemType => itemType.name?.toLowerCase().includes(searchPhrase.toLowerCase())).map((itemType, index) => (
                          <a
                            href="#"
                            onClick={(e) => { e.preventDefault(); handleItemTypeSelect(itemType); }}
                            key={index}
                            className="list-group-item list-group-item-action"
                          >
                            <strong>{itemType.name}</strong>{/*<span className="text-secondary">{itemType.itemType.name}</span>*/}
                          </a>
                        ))}
                      </div>
                    }
                  </div>
                </Popover.Content>
              </Popover>
            }
          >
            <a
              href="#"
              onClick={(e) => e.preventDefault()}
            >
              {currentItemType == null ? (
                "Select an item type"
              ) : (
                `${currentItemType.name}`
              )}
            </a>
          </OverlayTrigger>
          { currentItemType &&
            <a
              href="#"
              onClick={handleItemTypeDeselect}
            >
              <i className="fas fa-times-circle ml-2 text-muted"></i>
            </a>
          }
        </Card.Body>
      </Card>
    </div>
  )
}

export default ItemTypeSearchField
