import React, {useEffect, useRef, useState} from 'react'
import { Modal, Form } from 'react-bootstrap'
import limsApi from '../utils/limsApi'
import SelectLocation from "./selectLocation"
import ItemForm from './itemForm'

const NewSensor = (props) => {
  const [code, setCode] = useState("")
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleSubmit = (event) => {
    event.preventDefault()

    limsApi.post(`link_sensor/${code}`, undefined, `Sensor Added`, (response)=>{
        const sensorData = response.data.sensor
        props.reload()
        props.closeModal()
      },
      'Error adding sensor', undefined, setIsSubmitting)
  }

  return (
    <Modal backdrop="static" show={true} onHide={() => props.closeModal()} enforceFocus={false}>
      <Modal.Header closeButton>
        <Modal.Title>Add Sensor</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Sensor Code</label>
            <input
              type="text"
              name="code"
              className="form-control"
              value={code}
              onChange={(e)=>setCode(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
            { !isSubmitting ? (
              "Save"
            ) : (
              <i className="fas fa-spinner fa-spin"></i>
            )}
          </button>
          <button className="btn btn-link" onClick={()=>props.closeModal()}>
            Cancel
          </button>
        </form>
      </Modal.Body>
    </Modal>
  )
}

export default NewSensor
