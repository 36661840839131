import React, { useState, useEffect } from 'react'
import { format, parse } from 'date-format-parse'
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap'

const DateField = ({ field, inputData, handleChange, status }) => {
  const [fieldDate, setFieldDate] = useState(null)

  useEffect(() => {
    inputData[field.id] ? setFieldDate(inputData[field.id]) : null
  }, [inputData])

  const handleDateChange = (date) => {
    setFieldDate(date)
    handleChange({
          target: {
          name: field.id,
          value: date
        }
      })
  }

  let afterLabel = (
    <Form.Control type="date" name={field.id} value={fieldDate ? fieldDate : ""} onChange={()=>handleDateChange(event.target.value)} />
  )

  if (Object.keys(field.viewOptions).length > 0) {
    if (field.viewOptions[status] == "hidden") {
      afterLabel = (
        <OverlayTrigger
          placement="right"
          overlay={
            <Tooltip>
              This field is hidden
            </Tooltip>
          }
        >
          <i className="fas fa-eye-slash ml-3"></i>
        </OverlayTrigger>
      )
    } else if (field.viewOptions[status] == "viewable") {
      afterLabel = (
        <Form.Control disabled type="date" name={field.id} value={fieldDate ? fieldDate : ""} onChange={()=>handleDateChange(event.target.value)} />
      )
    }
  }

  return (
    <Form.Group style={{maxWidth: '18rem'}}>
      <Form.Label>{field.name}</Form.Label>
      {afterLabel}
    </Form.Group>
  )
}

export default DateField
