// State related to navigation - like expanded node in tree structures - to be preserved when the user navigates
// between pages.

import {SET_EXPANDED, TOGGLE_EXPANDED} from "../actionTypes";
import { COMPONENT_NAMES } from "../../constants"
import {updateIn} from "../../utils/pureStateFns";

const initialState = { [COMPONENT_NAMES.LOCATION_TREE]: {}, [COMPONENT_NAMES.CATALOG_TREE]: {}};

const navigation = (state = initialState, {type, payload: {treeName, nodeKey, expanded}={}}) => {
    switch (type) {
        case TOGGLE_EXPANDED: {
            return updateIn(state, [treeName, nodeKey], (expanded) => !expanded);
        }
        case SET_EXPANDED: {
            return updateIn(state, [treeName, nodeKey], () => expanded);
        }
        default: {
            return state;
        }
    }
};

export default navigation;