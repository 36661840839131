import React, { useState, useEffect } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink,
  withRouter
} from 'react-router-dom'

const UserSubNav = (props) => {
  return (
    <nav className="sidebar p-3">
      <NavLink
        to="/profile"
        className="nav-item nav-link mr-2"
        activeClassName="active"
        exact={true}
      >
        My Profile
      </NavLink>
      {/*
      <NavLink
        to="/profile/password"
        className="nav-item nav-link mr-2"
        activeClassName="active"
        exact={true}
      >
        My Password
      </NavLink>
      */}
    </nav>
  )
}

export default withRouter(UserSubNav)
