import {TOGGLE_EXPANDED, SET_EXPANDED} from "./actionTypes";

export const toggleExpanded = (treeName, nodeKey) => ({
    type: TOGGLE_EXPANDED,
    payload: {treeName, nodeKey}
});

export const setExpanded = (treeName, nodeKey, expanded) => ({
    type: SET_EXPANDED,
    payload: {treeName, nodeKey, expanded}
});