import React, {useRef} from "react";
import {recursiveMapChildren} from "../../utils/reactSuper";
import {isInputElement} from "../lib/gforms";
import Modal from "react-bootstrap/Modal";
import {Link, withRouter} from "react-router-dom";
import capitalize from 'capitalize'
import DeleteConfirmation from "../deleteConfirmation";
import {Button, Form} from "react-bootstrap";

/**
 * Recurse deeply over children and find all input elements. Modify props of each child by calling the `lesson` fn with
 * the child as the argument. `lesson` returns props to add to the child to make it "smart".
 * @param children
 * @param lesson
 * @returns {Array<Exclude<*, boolean | null | undefined>>}
 */
function educateInputElementChildren(children, lesson) {
    return recursiveMapChildren(children, child => {
        if (isInputElement(child)) {
            return React.cloneElement(child, lesson(child))
        }
        return child
    })
}

/**
 * A basic GDialog might look as follows:
 *   <GDialog title={'A GDialog'} returnPath={'/'}>Press the 'x' to close this dialog.<GDialog>
 *
 * The only real required argument is the `returnPath`. The parent of GDialog should supply this. In doing so, GDialog
 * may be reused anywhere in the application as a modal dialog and return to the path that opened it. If the parent is a
 * specialization of GDialog - that is, a dialog in and of itself - then the parent should take a return path from its
 * parent (GDialog's grand parent) and pass it down to GDialog. For instance:
 *
 *   <GrandParent><SpecialDialog returnPath='/grand-parent-path'/></GrandParent>
 *
 *   const SpecialDialog = ({returnPath, ...props}) => {
 *       return (<GDialog title={'Special Title} returnPath={returnPath}/>)
 *   }
 *
 *   If onSubmit is supplied then save and cancel buttons will be displayed with appropriate functionality.
 *
 *   If editPath is supplied then an edit button will be displayed that goes to the edit path.
 *
 *   The onChange and model props will be automatically connected to all input elements. The onChange is connected
 *   directly to each input element and is expected to be generic enough to handle all input elements. See
 *   `generateOnChange` for an example generic onChange handler. The model prop is considered a map and the name of each
 *   input element will be used to grab values from that map. Those values are used in the `value` props of the input
 *   elements. So there is an automatic mapping of model['some-key'] to the value prop of <input name='some-key'/>.
 *
 *
 * @param title
 * @param subtitle
 * @param returnPath
 * @param editPath
 * @param isLoading
 * @param onChange
 * @param onSubmit
 * @param model
 * @param history
 * @param match
 * @param children
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const GDialog = ({
                     title, subtitle,
                     returnPath, returnPathOnCancel=returnPath, returnPathOnSave=returnPath,
                     editPath,
                     isLoading,
                     onChange, onSubmit, onDelete,
                     deletable, deleteBtnName="Delete", shouldConfirmDelete=true,
                     model, modelName,
                     history, match,
                     children,
                     submitButtonText,
                     ...props
                 }) => {

    const ModelName = modelName ? capitalize(modelName) : ""

    // make all children use the onChange handler, which should handle change events from any input element
    // also, give the value from the model to input elements
    const smartChildren = educateInputElementChildren(children,
            child => {
        return {value: model?.[child.props.name], onChange: onChange}})

    const ref = useRef(null)

    // close dialog and return to caller, which should have supplied returnPath
    const hide = () => {
        history.push(returnPath)
    }

    // the onSubmit passed from the class should raise an exception if the dialog shouldn't be closed
    const onSubmitOurs = async(event) => {
        if (onSubmit==null) return
        event.preventDefault();
        await onSubmit(model)
        history.push(returnPathOnSave)
    }

    const onDeleteOurs = () => {
        if (onDelete==null) return
        onDelete(model)
    }

    const subTitleTag = (subtitle ? <small className="d-block text-secondary">{subtitle}</small> : "")
    const saveButton = (
        <button type="submit" className="btn btn-primary" disabled={isLoading}>
            { isLoading ? (
              <i className="fas fa-spinner fa-spin"></i>
            ) : (
              submitButtonText || "Save"
            )}
        </button>
    )
    const saveAndCancelButtons = (
        <div>
            {saveButton}
            <Link to={returnPathOnCancel} className="btn btn-link">
                Cancel
            </Link>
        </div>
    )
    const editButton = (<Link to={editPath||'/'} className={'btn btn-light'}>Edit</Link>)
    const deleteButton = shouldConfirmDelete ? (
      <DeleteConfirmation modelName={modelName} delete={onDeleteOurs}>
        <Button variant="outline-danger" className="float-right">{deleteBtnName} {ModelName}</Button>
    </DeleteConfirmation>) : (
      <Button variant="outline-danger" className="float-right" onClick={onDeleteOurs}>{deleteBtnName} {ModelName}</Button>
    )

    return (
        <Modal show={true} size="lg" onHide={hide}>
            <Modal.Header closeButton>
                <Modal.Title>{title}{subTitleTag}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form ref={ref} className="mb-1" onSubmit={onSubmitOurs}>
                    {smartChildren}
                    {deletable && onSubmit ? deleteButton : ""}
                    {onSubmit ? saveAndCancelButtons : ""}
                    {/*editPath ? editButton : ""*/}
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default withRouter(GDialog)
