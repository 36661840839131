import React, { useState, useEffect } from 'react'
import limsApi from '../utils/limsApi'
import {Modal, Form, InputGroup, Dropdown, DropdownButton, FormControl, Row, Col, OverlayTrigger, Tooltip} from 'react-bootstrap'
import LabelPreview from './labelPreview'
import axios from 'axios'
import moment from 'moment'

const ObjectsPrintForm = ({objects, closeModal, labelsFor, ...props}) => {
  const [isLoading, setIsLoading] = useState(true)
  const [isFetchingZpl, setIsFetchingZpl] = useState(false)
  const [account, setAccount] = useState({})
  const [printers, setPrinters] = useState([])
  const [labelTemplates, setLabelTemplates] = useState([])
  const [zpl, setZpl] = useState("")
  const [barcodeIsName, setBarcodeIsName] = useState(false) // Used to control what data goes in the barcode

  const [printJob, setPrintJob] = useState({
    labelTemplate: {name: "dummy name", height: 0.5, width: 1, id: 1, numOfLines: 3},
    printer: props.printer ? props.printer : {name: "dummy name", dpi: 300, id: 1},
    lines: props.lines ? props.lines : labelsFor == "items" ? [objects[0].entry.name, moment().format("MMM Do YY")] : [],
    qty: 1,
  })

  const handleChange = (attr, value) => {
    setPrintJob(prev=> {
      let newPrintJob = {...prev, [attr]: value}

      if (attr == "printer") {
        const printersTemplate = labelTemplates.find(t=> t.id == newPrintJob.printer.lastLabelTemplateUsed)
        newPrintJob = printersTemplate ? {...newPrintJob, labelTemplate: printersTemplate} : newPrintJob
      }

      return newPrintJob
    })
  }

  const fetchAccount = (printers) => {
    limsApi.get('accounts/me', ((response) => {
        const accountData = response.data.account
        setAccount(accountData)
        fetchPrinters(accountData)
      }),
      'Error fetching account')
  }

  // Performs a get request to list all the printers
  const fetchPrinters = (account) => {
    limsApi.get('printers', (response)=>{
        let printerData = response.data.printers
        // console.log(printerData);
        setPrinters(printerData)
        fetchLabelTemplates(printerData.length == 0, printerData, account)
      },
      'Error fetching printers')
  }

  const fetchLabelTemplates = (showAll, printers, account) => {
    limsApi.get('label_templates', (response)=>{
      const labelTemplatesData = response.data.labelTemplates

      let templates = labelTemplatesData
      if (!showAll) {
        templates = labelTemplatesData.map(t=>{
          if (t.isEnabled)
            return t
        })
      }
      templates = templates.filter(Boolean) // gets rid of undefined values in the array
      setLabelTemplates(templates)

      if (printers.length > 0 && templates.length > 0) {
        let selectedPrinter = printers[0]
        if (account.lastPrinterUsed && printers.length > 0) {
          let tempPrinter = printers.find(p=>p.id==account.lastPrinterUsed)
          selectedPrinter = tempPrinter ? tempPrinter : selectedPrinter
        }
        handleChange("printer", selectedPrinter)
        let selectedTemplate = templates[0]
        if (selectedPrinter.lastLabelTemplateUsed) {
          let tempTemplate = templates.find(t=>t.id==selectedPrinter.lastLabelTemplateUsed)
          selectedTemplate = tempTemplate ? tempTemplate : selectedTemplate
        }
        handleChange("labelTemplate", selectedTemplate)
      } else {
        templates.length != 0 ? handleChange("labelTemplate", templates[0]) : null
      }

    },
    'Error fetching label templates', undefined, setIsLoading)
  }

  useEffect(() => {
    fetchAccount()
  }, [])

  const printLabels = () => {
    const encodedZpl = Buffer.from(zpl.repeat(printJob.qty)).toString('base64')
    const job = {
      printerId: printJob.printer.printNodeId,
      title: "GenoFAB Label",
      contentType: "raw_base64",
      content: encodedZpl,
      source: labelsFor
    }
    limsApi.get('accounts/me', (response)=>{
        const apiKey = response.data.account.printNodeApiKey
        axios.post('https://api.printnode.com/printjobs', job,
        {auth: { username: apiKey}})
        .then(response => {
          updateLastUsedSettings()
          closeModal()
          window.flash('Printing label...')
        })
        .catch(error => {
          console.log(error)
          window.flash(error.message, 'error')
        })
      },
      'Error fetching account')
  }

  const updateLastUsedSettings = (account) => {
    limsApi.patch(`printers/${printJob.printer.id}`, {lastLabelTemplateUsed: printJob.labelTemplate.id})
    limsApi.patch(`accounts/me`, {lastPrinterUsed: printJob.printer.id})
  }

  const getZpl = () => {
    const token = localStorage.getItem('gf-token')
    let labels = []
    if (labelsFor == "items") {
      objects.map(item => {
        let lineData = [item.code, ...printJob.lines]
        lineData = lineData.map(value=>value == undefined ? "" : value)
        const labelData = {
          lines: lineData,
          barcode_text: barcodeIsName ? item.code : `${item.entry.id}:${item.id}`,
          template_id: printJob.labelTemplate.id,
          dpi: printJob.printer.dpi,
          from: barcodeIsName ? "custom" : "catalog"
        }
        labels.push(labelData)
      })
    } else if (labelsFor == "locations") {
      objects.map(location => {
        let lineData = [location.name, ...printJob.lines]
        lineData = lineData.map(value=>value == undefined ? "" : value)
        const labelData = {
          lines: lineData,
          barcode_text: barcodeIsName ? location.name : `${location.id}`,
          template_id: printJob.labelTemplate.id,
          dpi: printJob.printer.dpi,
          from: barcodeIsName ? "custom" : "locations"
        }
        labels.push(labelData)
      })
    }

    limsApi.post('get_zpl', {labels: labels}, '', (response)=>{
      const zpl = response.data.response
      if (labels[0].template_id == printJob.labelTemplate.id)
        setZpl(zpl)
    },
    '', setIsLoading)
  }

  useEffect(()=>{
    if (!isLoading && printJob.labelTemplate != null && printJob.printer != null)
      getZpl()
  }, [printJob, isLoading, barcodeIsName])

  const handleSubmit = (event) => {
    event.preventDefault();
    printLabels()
  };

  const setLine = (i, str) => {
    let newLines = [...printJob.lines]
    newLines[i] = str
    handleChange("lines", newLines)
  }

  let labelLinesFormInputs = []
  if(printJob.labelTemplate != null) {
    for (let i = 0; i < printJob.labelTemplate.numOfLines; i++) {
        if (i > 0) {
          labelLinesFormInputs[i] =(
        <Form.Group key={i}>
          <Form.Label>{`Line ${i+1}`}</Form.Label>
          <Form.Control type={"text"} placeholder="enter line text" value={printJob.lines[i-1]} onChange={() => setLine(i-1, event.target.value)} />
        </Form.Group>)
      }
    }
  }

  const optionText = isLoading ? "Loading..." : "Configure in Settings"

  let printersJSX = <option>{optionText}</option>
  if(printers.length > 0) {
    printersJSX = printers.map((printer) => (
      <option key={printer.id} value={printer.id}>{printer.name}</option>
    ));
  }

  let labelTemplatesJSX = <option>{optionText}</option>
  if(labelTemplates.length > 0) {
    labelTemplatesJSX = labelTemplates.map((template) => (
      <option key={template.id} value={template.id}>{template.name}</option>
    ));
  }

  return (
    <Modal show={true} size="lg">
      <Modal.Header>
        <h5 className="modal-title">Print Label{objects.length > 1 ? "s" : null}</h5>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={closeModal}>
        <span aria-hidden="true">&times;</span>
        </button>
      </Modal.Header>
      { !isLoading ? (
        <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Row className="align-items-center">
          <Col>
          { printJob.labelTemplate.name != "dummy name" &&
            <Form.Group controlId="exampleForm.ControlSelect1">
              <Form.Label>Select Label Template</Form.Label>
              <Form.Control as="select" value={printJob.labelTemplate ? printJob.labelTemplate.id : 0} onChange={() => handleChange("labelTemplate", labelTemplates.find( ({ id }) => id == event.target.value ))}>
                {labelTemplatesJSX}
              </Form.Control>
            </Form.Group>
          }
          { printJob.printer.name != "dummy name" &&
            <Form.Group controlId="exampleForm.ControlSelect2">
              <Form.Label>Select Printer</Form.Label>
              <Form.Control as="select" value={printJob.printer ? printJob.printer.id : 0} onChange={() => printers.map(p => p.id == event.target.value ? handleChange("printer", p) : null )}>
                {printersJSX}
              </Form.Control>
            </Form.Group>
          }
          {labelLinesFormInputs}
            <Form.Group>
              <Form.Label>Barcode Data</Form.Label>
              <div className="custom-control custom-switch">
                <input type="checkbox" className="custom-control-input" id="customSwitches" checked={barcodeIsName} onChange={()=>setBarcodeIsName(prev=>!prev)} />
                <label className="custom-control-label" htmlFor="customSwitches">
                Set barcode to label title
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip>
                      When enabled the data within the barcode is the same as the title of the label.
                    </Tooltip>
                  }
                >
                  <i className="fas fa-info-circle ml-1 text-muted"></i>
                </OverlayTrigger>
                </label>
              </div>
            </Form.Group>

          { objects.length == 1 &&
            <Form.Group>
              <Form.Label>Copies</Form.Label>
              <Form.Control type="number" step="1" min="1" max="100" value={printJob.qty} onChange={()=>handleChange("qty", parseInt(event.target.value))} />
            </Form.Group>
          }
          </Col>
          <Col className="text-center">
              <div className="text-center">
                <LabelPreview zpl={zpl} labelTemplate={printJob.labelTemplate} dpi={printJob.printer.dpi} numOfLabels={objects.length} />
              </div>
          </Col>
          </Row>
          </Modal.Body>
          <Modal.Footer>
              <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={closeModal}>Close</button>
              { printJob.printer.name == "dummy name" || printJob.labelTemplate.name == "dummy name" ?
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip>
                      Printing setup incomplete. Go to settings or contact admin.
                    </Tooltip>
                  }
                >
                  <div className="float-right" style={{display: 'inline-block'}}>
                    <button type="button" disabled style={{pointerEvents: "none"}} className="btn btn-primary">Print</button>
                  </div>
                </OverlayTrigger>
                :
              <button type="submit" className="btn btn-primary">Print</button>
              }
            </Modal.Footer>
          </Form>
      ) : (
        <>
          <Modal.Body>
            <p>Loading...</p>
          </Modal.Body>
          <Modal.Footer>
            <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={closeModal}>Close</button>
          </Modal.Footer>
        </>
      )}
    </Modal>
  )
}

export default ObjectsPrintForm
