import React, { useState, useRef, useEffect } from 'react'
import { OverlayTrigger, Popover, Tooltip, Button, Container, Row, Col, Form, Card } from 'react-bootstrap'
import { Link, withRouter } from 'react-router-dom'
import limsApi from '../utils/limsApi'
import DeleteConfirmation from './deleteConfirmation'
import LocationPositionGrid from './locationPositionGrid'
import SelectLocation from './selectLocation'
import RichTextEditor from './richTextEditor'

const LocationForm = (props) => {
  const queryString = require('query-string')
  const [isLoading, setIsLoading] = useState(props.create ? false : true)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [location, setLocation] = useState({
    name: "",
    description: "",
    key: "",
    cols: props.create ? 4 : 1,
    rows: props.create ? 3 : 1,
    orientation: "horizontal",
    colLabels: "",
    rowLabels: "",
    moveable: true,
    limitedCapacity: true,
    parentId: null,
    children: []
  })
  const [parentLocation, setParentLocation] = useState('')
  const ref = useRef(null)

  const fetchParentLocation = (id, resetPosition) => {
    limsApi.get(`locations/${id}`, (response)=>{
        let locationData = response.data.location
        setParentLocation(locationData)
        let lPosition = resetPosition == true ? null : location.locationPosition

        // Ensures that a location always has a position selected
        if (resetPosition) {
          for (let pos = 1; pos <= locationData.numPositions; pos++) {
            let position = pos

            // Makes the auto selected position compatible with vertical locations
            if (locationData.orientation == "vertical") {
              let currentRow = Math.ceil((pos)/locationData.cols)
              let currentCol = ((pos-1) % locationData.cols) + 1
              let cellVertPos = ((locationData.rows * (currentCol - 1)) + currentRow)
              position = cellVertPos
            }

            if (!locationData.children.find(child=>(child.locationPosition==position && child.id != location.id)) && !locationData.items.find(i=>i.locationPosition==position)) {
              lPosition = position
              break
            }
          }
        }

        setLocation({...location, parentId: locationData.id, locationPosition: lPosition})
      },
      'Error fetching parent location')
  }

  useEffect(() => {
    // fetch parent location any time it changes
    if(location.parentId) {
      fetchParentLocation(location.parentId, location.locationPosition == null)
    }
  }, [location.parentId])

  useEffect(() => {
    let parsedQuery = queryString.parse(props.location.search)
    let parentId = parsedQuery.parentId

    if(parentId !== undefined) {
      setLocation({...location, parentId: parentId})
    }
  }, [])

  const handleSubmit = (event) => {
    event.preventDefault()

    // create
    if(props.create == true) {
      limsApi.post('locations', { location: location }, `${location.name} Created`, (response)=>{
          const locationData = response.data.locations
          props.onSuccess(locationData)
          props.history.push(props.cancelPath)
        },
        'Error creating location', undefined, setIsSubmitting)
    // update
    } else {
      limsApi.patch(`locations/${location.id}`, { location: location }, `${location.name} Updated`, (response)=>{
          const locationData = response.data.location
          props.onSuccess(locationData)
          props.history.push(props.cancelPath)
        },
        'Error creating location', undefined, setIsSubmitting)
    }
  }

  const handleChange = ({ target }) => {
    let val = target.value

    // convert val to integer if number field
    if(target.type == 'number') {
      val = parseInt(val)
    }

    setLocation({...location, [target.name]: val})
  }

  const handleCheckboxChange = ({ target }) => {
    setLocation({...location, [target.name]: target.checked})
  }

  const fetchLocation = async () => {
    setIsLoading(true)
    await limsApi.get(`locations/${props.match.params.id}`, (response)=>{
      const locationData = response.data.location
      setLocation(locationData)
    },
    'Error fetching location', undefined, setIsLoading)
  }

  const selectParentLocation = (pLocation) => {
    if(!pLocation) {
      setLocation({...location, parentId: null, locationPosition: null}) // deselect location/set to null
      setParentLocation('')
    } else {
      fetchParentLocation(pLocation.id, true)
    }

    document.body.click()
  }

  const locationPositionForDisplay = () => {
    if (parentLocation.orientation == 'vertical') {
      let currentRow = Math.ceil((location.locationPosition)/parentLocation.cols)
      let currentCol = ((location.locationPosition-1) % parentLocation.cols) + 1
      let position = ((parentLocation.rows * (currentCol - 1)) + currentRow)
      return position
    }
    return location.locationPosition
  }

  useEffect(() => {
    if(props.create != true) {
      fetchLocation()
    }
  }, [props.match.params.id])

  // const positionOptions = () => {
  //   let options = []
  //   let firstAvailablePosition
  //   if(!parentLocation?.id) return
  //
  //   // options.push(
  //   //   <option key={0} value=""></option>
  //   // )
  //
  //   for (let i = 1; i <= parentLocation.numPositions; i++) {
  //     const childLocationInPosition = (parentLocation.children?.find(c => c.locationPosition == i) != undefined)
  //     const itemInPosition = (parentLocation.items?.find(item => item.locationPosition == i) != undefined)
  //     const positionFilled = childLocationInPosition || itemInPosition
  //     const optionDisabled = positionFilled && location.locationPosition != i // don't disable current position option
  //
  //     if(!positionFilled) {
  //       if(firstAvailablePosition == undefined) firstAvailablePosition = i
  //     }
  //
  //     options.push(
  //       <option
  //         key={i}
  //         value={i}
  //         disabled={optionDisabled}
  //       >
  //         {`${i} ${optionDisabled ? ' - Filled' : ''}`}
  //       </option>
  //     )
  //   }
  //
  //   if(!location.locationPosition && firstAvailablePosition != undefined) setLocation({...location, locationPosition: firstAvailablePosition})
  //
  //   return options
  // }

  return (
    <form ref={ref} className="mb-1" onSubmit={handleSubmit}>
      <Container style={{ paddingLeft: 0, paddingRight: 0 }}>
        <Row>
          <Col xs={!location.limitedCapacity ? false : "5"}>
            <div className="form-row">
              <div className="form-group col">
                <label>Name</label>
                <input
                  type="text"
                  required
                  name="name"
                  className="form-control"
                  value={location.name}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form-row">
              {location.moveable || props.create ? (
                  <SelectLocation
                      ref={ref}
                      className="form-group col"
                      location={parentLocation}
                      setLocation={selectParentLocation}
                      type="Parent"
                      excludeLocation={location}
                      locationPosition={location.locationPosition}
                      setLocationPosition={(pos)=>setLocation({...location, ["locationPosition"]: pos})}
                  />
              ) : (
                <div className="form-group col">
                  <label>
                    Parent Location
                  </label>
                  <Card className={"field-input-card bg-light"}>
                    <Card.Body className="p-2 px-3">
                      {parentLocation.name} (Position {locationPositionForDisplay()})
                    </Card.Body>
                  </Card>
                </div>
              )}
            </div>
            <div className="form-row mb-2">
              <div className="form-group col">
                <label>Description</label>
                { props.create ? (
                  <RichTextEditor data={location.description || ""} handleChange={(data)=>{setLocation({...location, ["description"]: data})}} />
                ) : (
                  isLoading ? <RichTextEditor data={""} handleChange={()=>{}} /> : <RichTextEditor data={location.description || ""} handleChange={(data)=>{setLocation({...location, ["description"]: data})}} />
                )}
              </div>
            </div>
            <h5 className="mb-3">Physical Characteristics</h5>
            <div className="form-row">
              <div className="form-group col">
                <Form.Check
                  type="switch"
                  id="limited-capacity-switch"
                  name="limitedCapacity"
                  onChange={handleCheckboxChange}
                  label={
                    <span>
                      Structured Layout
                      <OverlayTrigger
                        placement="right"
                        overlay={
                          <Tooltip>
                            A limited capacity location, such as a freezer with a limited number of shelves or a 10x10 box, has a distinct layout with limited available "slots" or positions. By contrast, an <i>unlimited</i> capacity location has no organized structure.
                          </Tooltip>
                        }
                      >
                        <i className="fas fa-info-circle ml-1 text-muted"></i>
                      </OverlayTrigger>
                    </span>
                  }
                  checked={location.limitedCapacity}
                />
              </div>
              <div className="form-group col">
                <Form.Check
                  type="switch"
                  id="moveable-switch"
                  name="moveable"
                  onChange={handleCheckboxChange}
                  label={
                    <span>
                      Moveable
                      <OverlayTrigger
                        placement="right"
                        overlay={
                          <Tooltip>
                            Indicates whether or not this location can be physically relocated.
                          </Tooltip>
                        }
                      >
                        <i className="fas fa-info-circle ml-1 text-muted"></i>
                      </OverlayTrigger>
                    </span>
                  }
                  checked={location.moveable == true ? true : false}
                />
              </div>
            </div>
            { location.limitedCapacity &&
              <div>
                <div className="form-row">
                  <div className="form-group col">
                    <label>Rows</label>
                    <input
                      type="number"
                      name="rows"
                      className="form-control"
                      value={location.rows}
                      max="24"
                      min="1"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group col">
                    <label>Columns</label>
                    <input
                      type="number"
                      name="cols"
                      className="form-control"
                      value={location.cols}
                      max="24"
                      min="1"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col">
                    <label>Row Labels</label>
                    <select
                      name="rowLabels"
                      className="form-control"
                      value={location.rowLabels}
                      onChange={handleChange}
                    >
                      <option value=""></option>
                      <option value="alpha">A, B, C</option>
                      <option value="numeric">1, 2, 3</option>
                    </select>
                  </div>
                  <div className="form-group col">
                    <label>Column Labels</label>
                    <select
                      name="colLabels"
                      className="form-control"
                      value={location.colLabels}
                      onChange={handleChange}
                    >
                      <option value=""></option>
                      <option value="alpha">A, B, C</option>
                      <option value="numeric">1, 2, 3</option>
                    </select>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col">
                    <label>Numbering Orientation</label>
                    <select
                      name="orientation"
                      className="form-control"
                      value={location.orientation}
                      onChange={handleChange}
                    >
                      <option value="horizontal">Horizontal</option>
                      <option value="vertical">Vertical</option>
                    </select>
                  </div>
                </div>
              </div>
            }
            <div className="pt-2">
              { (!props.create && location.children.length == 0 ) &&
                <DeleteConfirmation delete={() => { props.handleDelete(location.id) }}>
                  <Button variant="outline-danger" className="float-right">Delete Location</Button>
                </DeleteConfirmation>
              }
              { (!props.create && location.children.length > 0 ) &&
                <OverlayTrigger placement={'top'} overlay={
                     <Tooltip>
                       Location must be empty to delete it
                     </Tooltip>
                   } >
                 <Button variant="outline-danger" className="float-right disabled">Delete Location</Button>
                </OverlayTrigger>
              }
              <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                { !isSubmitting ? (
                  "Save"
                ) : (
                  <i className="fas fa-spinner fa-spin"></i>
                )}
              </button>
              <Link
                to={props.cancelPath}
                className="btn btn-link"
              >
                Cancel
              </Link>
            </div>
          </Col>
          { location.limitedCapacity &&
            <Col xs="7" className="px-5 py-4 pb-5">
              <LocationPositionGrid
                rows={location.rows}
                cols={location.cols}
                rowLabels={location.rowLabels}
                colLabels={location.colLabels}
                orientation={location.orientation}
              />
            </Col>
          }
        </Row>
      </Container>
    </form>
  )
}

export default withRouter(LocationForm)
