import firebase from 'firebase/app'
import 'firebase/firestore'
import axios from 'axios'
import limsApi from './limsApi'

  // Copied from each firebase app
  const firebaseConfigs = {
    development: {
       apiKey: "AIzaSyByUPh8SxjsZFjwrjlnILQJM-SnJLTlSQc",
       authDomain: "genofab-lims-59ad7.firebaseapp.com",
       projectId: "genofab-lims-59ad7",
       storageBucket: "genofab-lims-59ad7.appspot.com",
       messagingSenderId: "642274153502",
       appId: "1:642274153502:web:986d1d36e2ad0317684a0f"
     },
     staging: {
      apiKey: "AIzaSyC5cwW2m2q46JT4GCd9khp25jejFbaCEzc",
      authDomain: "genofab-lims-staging.firebaseapp.com",
      projectId: "genofab-lims-staging",
      storageBucket: "genofab-lims-staging.appspot.com",
      messagingSenderId: "433471077453",
      appId: "1:433471077453:web:4350aff4d596dbc1aa7004"
     },
     production: {
      apiKey: "AIzaSyCgaOCxXPT2Gm9dQsRSTI0iRkbQfrxxMlg",
      authDomain: "genofab-lims-production.firebaseapp.com",
      projectId: "genofab-lims-production",
      storageBucket: "genofab-lims-production.appspot.com",
      messagingSenderId: "103140373502",
      appId: "1:103140373502:web:8998a865a912ffa9c286af"
     }
  }



  // Select which firestore environment project to use
  axios.get('/api/env')
  .then(response=>{
    if (response.data.version == 'development') {
      firebase.initializeApp(firebaseConfigs.development);
    } else if (response.data.version == 'staging') {
      firebase.initializeApp(firebaseConfigs.staging);
    } else {
      firebase.initializeApp(firebaseConfigs.production);
    }
  })
  .catch(error => {
    console.error("Error initializing firebase");
    console.log(error);
  })

export default firebase
