import React from 'react'
import { Button } from 'react-bootstrap'

const UnactivatedHubspot = (props) => (
  <div className="text-center signup-container p-5 mx-auto">
    <h3 className="mb-4 mt-3 text-center">Your account is almost ready!</h3>
    <div className="mt-3">
      <p>
        We just need to verify your information before you can access your account. This should take no more than 24 hours.
        We'll send you an email as soon as you're verified.
      </p>
    </div>
    <Button className="mt-4 mb-3" variant="primary" type="button" target="_blank" href="https://app.genofab.com" block>
      Go to App
    </Button>
  </div>
)

export default UnactivatedHubspot
