import React, { useState, useEffect } from 'react'
import limsApi from '../utils/limsApi'
import { withRouter } from 'react-router-dom'


const BarcodeReader = (props) => {

  const assert = (condition, message) => {
    if (!condition) {
        throw new Error(message || "Assertion failed");
    }
  }

  const addBarcodeReader = () => {
    // The tags that surround the barcode data
    let barcodeKeyWord = props.barcodeKeyWord
    // An array that holds the last typed letters
    let keysPressed = [];
    // Used to exend the size of the keys pressed array, ensuring that there is not a partial barcode tag
    let lastIndex = -1;
    // A Flag that tells whether we are currently reading barcode data
    let isReadingBarcode = false
    // The actual data of the barcode excluding the tags
    let barcodeData = ''
    // Max length in the barcode data string that will be stored
    const abortLengthBarcodeData = 50
    // Length that the abort will happen, if the user somehow eneters a # every 4 or so keys.
    // Recommended to be larger or equal to abortLengthBarcodeData
    const abortLengthArrayData = 50

    // Adds the keypress event listener to the dom
    document.addEventListener('keydown', (event) => {
      // Avoids registering named keys like 'shift' or 'control'. Added to avoid the shift keys found in the tags
      if (event.key.length == 1) {
        // Checks if it is currently recording barcodeData (characters between the two tags)
        if (isReadingBarcode) {
          barcodeData += event.key
          // Silently aborts
          if (barcodeData.length > abortLengthBarcodeData) {
            barcodeData = ''
            isReadingBarcode = false
          }
          // console.log(barcodeData);
        }

        keysPressed[keysPressed.length] = event.key;
        // console.log(keysPressed);

        // Checks if there is a partial barcode tag in the character array
        if (event.key === barcodeKeyWord[0]) {
          lastIndex = keysPressed.length;
          // console.log("Pressed letter " + lastIndex);
        }

        // Compared the length of the keysPresed array with the tag to avoid the .join in the next if
        if (keysPressed.length >= barcodeKeyWord.length) {
          // Checks if the barcode key word or tag is in the keys pressed array
          if (keysPressed.join('').includes(barcodeKeyWord)) {
            // console.log("Found Tag!");
            // Checks if we were previously reading the barcode which signals the end of the barcode
            if (isReadingBarcode) {
              // In a try-catch because a lot of things can go wrong in the next few steps
              try {
                // Remove ending barcode tag in the keysPressed array
                barcodeData = barcodeData.slice(0, barcodeData.length - barcodeKeyWord.length)
                // Checks which labelling protocol should be used specified by the tag
                if (props.for == "catalog") {
                  barcodeData = barcodeData.split(':')
                  assert(barcodeData.length == 2)
                  props.history.push(`/catalog/entries/${barcodeData[0]}/items/${barcodeData[1]}`)
                } else if (props.for == "locations") {
                  props.history.push(`/locations/${barcodeData}`)
                }
              }
              catch {
                window.flash(`Barcode Invalid: Item cannot be reached`, 'error')
              }
            }
            // The following resets the variables for the next tag
            lastIndex = -1;
            keysPressed = [];
            isReadingBarcode = !isReadingBarcode
            barcodeData = ''
            // This if decides whether to reset the keys pressed array
          } else if (keysPressed.length - (lastIndex + barcodeKeyWord.length - 1) >= 0 || keysPressed.length > abortLengthArrayData) {
            // console.log("Reset Array");
            lastIndex = -1;
            keysPressed = [];
          }
        }
      }
    });
  }

  useEffect(()=>{
    addBarcodeReader()
  }, [])

  return null
}

export default withRouter(BarcodeReader)
