import React, { useState, useEffect, useRef } from 'react'
import { Modal } from 'react-bootstrap'
import { withRouter } from 'react-router-dom'
import limsApi from '../utils/limsApi'

const LowTaskWarning = (props) => {

  const purchaseLinkClicked = () => {
    props.history.push("/manage/account/?showPurchase=true")
    props.closeModal()
  }

  const resetAccountWanringFlag = () =>{
    const updatedAccountInformation = {
      show_low_credit_modal: false,
    }

    limsApi.put('accounts/me', { account: updatedAccountInformation }, '')
  }

  useEffect(()=>{
    resetAccountWanringFlag()
  }, [])

  return (
    <Modal show={true} onHide={props.closeModal}>
      <Modal.Header >
        <Modal.Title>Low Task Credits</Modal.Title>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={props.closeModal}>
          <span aria-hidden="true">&times;</span>
        </button>
      </Modal.Header>
      <Modal.Body>
        Your account only has {props.availableTasks} task credits remaining. Click purchase to get more tasks.
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={props.closeModal}>Close</button>
        <button type="button" className="btn btn-primary" onClick={purchaseLinkClicked}>
          Purchase Task Credits
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default withRouter(LowTaskWarning)
