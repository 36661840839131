import React, { useState, useEffect, useCallback, useRef } from 'react'
import { Tooltip, OverlayTrigger, Table, Popover } from 'react-bootstrap'
import WorkflowForm from './workflowForm.jsx'
import ExecuteWorkflow from './workflowExecute.jsx'
import WorkflowResults from './workflowResults.jsx'
import limsApi from '../utils/limsApi'
import DeleteConfirmation from './deleteConfirmation'
import ReactHtmlParser from 'react-html-parser'


const Workflows = ({...props}) => {

  const [workflows, setWorkflows] = useState([])
  const [showEditWorkflow, setShowEditWorkflow] = useState(-1)
  const [showCreateWorkflow, setShowCreateWorkflow] = useState(false)
  const [showExecuteWorkflow, setShowExecuteWorkflow] = useState(-1)
  const [showWorkflowResults, setShowWorkflowResults] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  const fetchWorkflows = () => {
    limsApi.get('workflows', (response)=>{
      let workflowData = response.data.workflows
      setWorkflows(workflowData)
    },
    'Error fetching workflows', undefined, setIsLoading)
  }

  const deleteWorkflow = (id) => {
    limsApi.delete(`workflows/${id}`, 'Workflow Deleted', (() => {
        fetchWorkflows()
      }),
      'Delete Failed')
  }

  const workflowList = workflows.map((w, index) => (
    <tr key={w.id}>
        <td className={'task-name'}>
          {w.name}
        </td>
        <td className={'task-assigned-to'}>{ReactHtmlParser(w.description)}</td>
        <td className={'task-requested-by'}>{w.structure.length}</td>
        <td className={'task-status'}>
          <div className={/*'btn-group'*/''}>
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip>
                  Execute Workflow
                </Tooltip>
              }
            >
              <button
                type="button"
                className="btn btn-outline-success btn-sm"
                onClick={() => setShowExecuteWorkflow(index)}
              >
                <i className="fas fa-play-circle"></i>
              </button>
            </OverlayTrigger>
            <button
              type="button"
              className="btn btn-light btn-sm"
              onClick={() => setShowEditWorkflow(w.id)}
            >
              <i className="fas fa-edit"></i>
            </button>
            <DeleteConfirmation delete={() => deleteWorkflow(w.id)}>
              <button
                type="button"
                className="btn btn-light btn-sm"
              >
                <i className="fas fa-trash-alt"></i>
              </button>
            </DeleteConfirmation>
            </div>
        </td>
    </tr>
  ))

  useEffect(() => {
    fetchWorkflows()
  }, [])

  return (
    <div className='p-4'>
      <button className="btn btn-primary float-right" onClick={() => setShowCreateWorkflow(true)}>
        Add Workflow
      </button>
      <h3 className="mb-3">Workflows</h3>
      { !isLoading?
        <div>
          { showEditWorkflow > -1 && <WorkflowForm workflowId={showEditWorkflow} closeModal={()=>setShowEditWorkflow(-1)} reload={fetchWorkflows} /> }
          { showCreateWorkflow && <WorkflowForm create closeModal={()=>setShowCreateWorkflow(false)} reload={fetchWorkflows} /> }
          { showExecuteWorkflow > -1 && <ExecuteWorkflow workflow={workflows[showExecuteWorkflow]} closeModal={()=>setShowExecuteWorkflow(-1)} showWorkflowResults={(data)=>setShowWorkflowResults(data)} /> }
          { showWorkflowResults != null && <WorkflowResults results={showWorkflowResults} closeModal={()=>setShowWorkflowResults(null)} />}
          <Table bordered className={'tasks-table'}>
              <thead>
              <tr style={{backgroundColor: "#eee"}}>
                  <th scope="col">Name</th>
                  <th scope="col">Description</th>
                  <th scope="col">Plate No.</th>
                  <th scope="col">Actions</th>
              </tr>
              </thead>
              <tbody>
                {workflowList}
              </tbody>
          </Table>
        </div>
      :
        "Loading"
      }
    </div>
  )
}


export default Workflows
