export const listToTree = (list) => {
  // list.sort((a, b) => (a.locationPosition > b.locationPosition) ? 1 : -1)
  var map = {}, node, roots = [], i;

  for (i = 0; i < list.length; i += 1) {
    map[list[i].id] = i; // initialize the map
    list[i].children = []; // initialize the children
  }

  for (i = 0; i < list.length; i += 1) {
    node = list[i];
    if (node.parentId !== null) {
      // if you have dangling branches check that map[node.parentId] exists
      try {
        list[map[node.parentId]].children.push(node);
      }
      catch(err) {
        console.log('Can\'t find parent node with id ' + node.parentId)
      }
    } else {
      roots.push(node);
    }
  }
  return roots;
}
