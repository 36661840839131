import React, { useState, useRef } from 'react'
import { Modal } from 'react-bootstrap'
import limsApi from '../utils/limsApi'
import EntryForm from './entryForm'

const EditEntry = (props) => {
  const [isDeleting, setIsDeleting] = useState(false)
  const handleSuccess = (entry) => {
    props.updateEntry(entry)
    props.history.push(`/catalog/entries/${entry.id}`)
  }

  const handleDelete = (id) => {
    document.body.click()

    limsApi.delete(`entries/${id}`, `Entry Removed`, (response)=>{
      props.history.push(`/catalog/${props.itemType.id}`)
    },
    `There was a problem deleting that entry.`, ()=>{}, setIsDeleting)
  }

  return (
    <Modal backdrop="static" show={true} size="lg" onHide={() => { props.history.push(`/catalog/entries/${props.match.params.entryId}`) }} enforceFocus={false}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Entry</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <EntryForm
          entryId={props.match.params.entryId}
          itemType={props.itemType}
          onSuccess={handleSuccess}
          cancelPath={`/catalog/entries/${props.match.params.entryId}`}
          handleDelete={handleDelete}
          isDeleting={isDeleting}
        />
      </Modal.Body>
    </Modal>
  )
}

export default EditEntry
