import React, { useState, useEffect } from 'react'
import limsApi from '../utils/limsApi'
import { withRouter, NavLink } from 'react-router-dom'
import { Modal, Button } from 'react-bootstrap'
import LocationTemplateForm from './locationTemplateForm'
import DeleteConfirmation from "./deleteConfirmation"

const EditLocationTemplate = (props) => {
  const handleDelete = (id) => {
    document.body.click()

    limsApi.delete(`location_templates/${id}`, `Location Template Removed`, (response)=>{
      props.refreshLocationTemplates()
      props.history.push(`/manage/locations`)
    },
    `There was a problem deleting that location template.`, ()=>{})
  }

  return (
    <Modal show={true} size="xl" className="modal-full-width" onHide={() => { props.history.push(`/manage/locations`) }}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Location Template</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <LocationTemplateForm
          onSuccess={props.refreshLocationTemplates}
          cancelPath={`/manage/locations`}
          handleDelete={handleDelete}
        />
      </Modal.Body>
    </Modal>
  )
}

export default withRouter(EditLocationTemplate)
