import React, {useEffect, useRef, useState} from 'react'
import { Modal, Form } from 'react-bootstrap'
import limsApi from '../utils/limsApi'
import WorkflowStepList from './workflowStepList.jsx'
import RichTextEditor from './richTextEditor'


const WorkflowForm = ({workflowId, create, reload, closeModal, ...props}) => {

  const [workflow, setWorkflow] = useState({name: "", description: "", structure: [{item_type: null, inputs: [], fields: []}, {item_type: null, inputs: [], fields: []}]})
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isLoading, setIsLoading] = useState(!create)

  const handleSubmit = (event) => {
    event.preventDefault()

    // let wrkFlowFake = {name: workflow.name, description: workflow.description, structure: [{itemType: 5, inputs: [22, 34]}, {itemType: 1, inputs: [12]}]}

    if (create) {
      limsApi.post(`workflows`, { workflow: {...workflow} }, `${workflow.name} Updated`, (response)=>{
          const workflowData = response.data.workflow
          reload()
          closeModal()
        },
        'Error updating workflow', undefined, setIsSubmitting)
    } else {
      limsApi.patch(`workflows/${workflow.id}`, { workflow: {...workflow} }, `${workflow.name} Updated`, (response)=>{
          const workflowData = response.data.workflow
          reload()
          closeModal()
        },
        'Error updating workflow', undefined, setIsSubmitting)
    }

  }

  const handleChange = (event) => {
    setWorkflow({...workflow, [event.target.name]: event.target.value})
  }

  const reduceStepNumber = () => {
    if (workflow.structure.length > 2)
      setWorkflow({...workflow, structure: [...workflow.structure].slice(0, workflow.structure.length-1)})
  }

  const increaseStepNumber = () => {
    // Adds an extra 0 to structure array
    setWorkflow({...workflow, structure: [...workflow.structure, {item_type: null, inputs: []}]})
  }

  const increaseTransformerNumber = (index) => { // TODO: Fix this
    setWorkflow(prev => {
      let array = [...prev.structure]
      array[index].inputs = [...array[index].inputs, null]
      return ({...prev, structure: array})
    })
  }

  const reduceTransformerNumber = (index) => { // TODO: Fix this to work with new structure layout
    if (workflow.structure[index].inputs.length > 0) {
      setWorkflow(prev => {
        let array = [...prev.structure]
        array[index].inputs = array[index].inputs.slice(0, workflow.structure[index].inputs.length-1)
        return ({...prev, structure: array})
      })
    }
  }

  const handleInputEntryChange = (entryId, inputI, stepI) => {
    setWorkflow(prev => {
      let array = [...prev.structure]
      array[stepI].inputs[inputI] = entryId
      return ({...prev, structure: array})
    })
  }

  const handleStepTypeChange = (itemTypeId, stepI) => {
    setWorkflow(prev => {
      let array = [...prev.structure]
      array[stepI].item_type = itemTypeId
      return ({...prev, structure: array})
    })
  }

  const handleFieldSelect = (i, stepI, fieldId, itemTypeId) => {
    setWorkflow(prev => {
      let array = [...prev.structure]
      let prevFields = []
      if (array[stepI].fields) {
        prevFields = [...array[stepI].fields]
      }
      prevFields[i] = parseInt(fieldId)
      array[stepI].fields = prevFields
      return ({...prev, structure: array})
    })
  }

  const fetchWorkflow = () => {
    limsApi.get(`workflows/${workflowId}`, (response)=>{
      const workflowData = response.data.workflow
      setWorkflow(workflowData)
    },
    'Error fetching workflow', undefined, setIsLoading)
  }

  useEffect(()=>{
    !create && fetchWorkflow()
  }, [])

  return (
    <Modal backdrop="static" show={true} size="lg" onHide={() => closeModal()} enforceFocus={false}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Workflow</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      { isLoading ?
        <p>Loading...</p>
        :
        <form onSubmit={handleSubmit}>
          <div className="form-row">
            <div className="form-group col">
              <label>Name</label>
              <input
                type="text"
                name="name"
                className="form-control"
                value={workflow.name}
                onChange={handleChange}
                maxLength="30"
                required
              />
            </div>
            <div className="form-group col">
              Number of Plates:
              <br/>
              <div className="mt-2">
                <button type="button" className="btn btn-sm btn-danger mr-3 ml-2" onClick={reduceStepNumber} disabled={workflow.structure.length <= 2}>
                  <i className="fas fa-minus"></i>
                </button>
                <b style={{fontSize: 18}}>{workflow.structure.length}</b>
                <button type="button" className="btn btn-sm btn-success ml-3" onClick={increaseStepNumber}>
                  <i className="fas fa-plus"></i>
                </button>
              </div>
            </div>
          </div>
          <div className="form-group">
            <label>Description</label>
            { !isLoading &&
              <RichTextEditor data={workflow.description || ""} handleChange={(data)=>{setWorkflow({...workflow, ["description"]: data})}} />
            }
          </div>
          <div className="">
            <WorkflowStepList
              edit
              structure={workflow.structure}
              increaseTransformerNumber={(i) => increaseTransformerNumber(i)}
              reduceTransformerNumber={(i) => reduceTransformerNumber(i)}
              handleInputEntryChange={(entryId, inputI, stepI) => handleInputEntryChange(entryId, inputI, stepI)}
              handleStepTypeChange={(itemTypeId, stepI) => handleStepTypeChange(itemTypeId, stepI)}
              handleFieldSelect={(i, stepI, fieldId, itemTypeId)=>handleFieldSelect(i, stepI, fieldId, itemTypeId)}
              />
          </div>
          <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
            { !isSubmitting ? (
              "Save"
            ) : (
              <i className="fas fa-spinner fa-spin"></i>
            )}
          </button>
          <button className="btn btn-link" onClick={()=>closeModal()}>
            Cancel
          </button>
        </form>
      }

      </Modal.Body>
    </Modal>
  )
}

export default WorkflowForm
