import React, { useState, useRef } from 'react'
import { Modal } from 'react-bootstrap'
import EntryForm from './entryForm'

const NewEntry = (props) => {
  return (
    <Modal backdrop="static" show={true} size="lg" onHide={() => { props.history.push(`/catalog/${props.itemType.id}`) }} enforceFocus={false}>
      <Modal.Header closeButton>
        <Modal.Title>New Entry <small className="d-block text-secondary">{props.itemType.name}</small></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <EntryForm
          create={true}
          itemType={props.itemType}
          onSuccess={(entry) => { props.history.push(`/catalog/entries/${entry.id}`) }}
          cancelPath={`/catalog/${props.itemType.id}`}
        />
      </Modal.Body>
    </Modal>
  )
}

export default NewEntry
