import React, { useState, useEffect } from 'react'
import { Table, Image } from 'react-bootstrap'
import limsApi from '../utils/limsApi'
import DeleteConfirmation from './deleteConfirmation'
import ContentLoader from "react-content-loader"


const UsersList = (props) => {

  // Performs a delete request to destroy a user (needs user id)
  const deleteUser = (id) => {
    limsApi.delete(`user_delete/${id}`, 'User Removed', (response)=>{
        props.reload()
      },
      'Error removing user')
  }

  // Creates a list of user to be rendered to the screen
  let usersList = <tr><td>No users found. Try inviting a user to your account.</td></tr>;
  if (props.users.length > 0) {
    let users = props.users
    users.sort(function(a, b){
        if(a.firstName < b.firstName) { return -1; }
        if(a.firstName > b.firstName) { return 1; }
        return 0;
    })
    usersList = users.map((user) => (
      <tr key={user.id}>
        <td>
          <Image width="40" height="40" src={user.avatarUrl} roundedCircle className="mr-2" />
          {user.firstName + " " + user.lastName}
        </td>
        <td>
          {user.userRole.name}
        </td>
        <td>
          {user.email}
        </td>
        <td>
          <div className={/*'btn-group'*/''}>
            <button
              type="button"
              className="btn btn-light btn-sm"
              onClick={() => props.updateUser(user)}
            >
              <i className="fas fa-edit"></i>
            </button>
            {/* THIS FEATURE WAS REMOVED DUE TO UNKNOWN CONSEQUENCES
              <DeleteConfirmation delete={() => deleteUser(user.id)}>
                <button type="button" className="btn btn-light btn-sm">
                  <i className="fas fa-trash-alt"></i>
                </button>
              </DeleteConfirmation>
            */}
          </div>
        </td>
      </tr>
    ));
  }

  const loadingRow = (
    <tr>
      <td>
        <ContentLoader height="20" width="100%" backgroundColor={'#eee'} foregroundColor={'#ccc'}>
          <rect x="0" y="0" rx="3" ry="3" width="120" height="20" />
        </ContentLoader>
      </td>
      <td>
        <ContentLoader height="20" width="100%" backgroundColor={'#eee'} foregroundColor={'#ccc'}>
          <rect x="0" y="0" rx="3" ry="3" width="170" height="20" />
        </ContentLoader>
      </td>
      <td>
        <ContentLoader height="25" width="100%" backgroundColor={'#eee'} foregroundColor={'#ccc'}>
          <rect x="0" y="0" rx="3" ry="3" width="60" height="25" />
        </ContentLoader>
      </td>
    </tr>
  )

  return (
    <div>
        <Table id="users-list" bordered hover>
          <tbody>
            {!props.isLoading ? usersList : (
              <>
                {loadingRow}
                {loadingRow}
                {loadingRow}
                {loadingRow}
                {loadingRow}
                {loadingRow}
              </>
            )}
          </tbody>
        </Table>
    </div>
  )
}

export default UsersList
