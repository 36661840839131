import React, { useState, useEffect } from 'react'
import { connect } from "react-redux";
import {
  Link,
} from 'react-router-dom'
import TreeBranch from './treeBranch'
import {toggleExpanded, setExpanded} from "../../redux/actions";

const TreeNode = (props) => {
  const [children, setChildren] = useState([])
  const {node} = props

  // listen for props change
  useEffect(() => {
    setChildren(props.node.children || [])
  }, [props.node.children])

  const toggleExpanded = () => {
    props.toggleExpanded(props.treeName, node.id);
  }

  const isExpanded = () => {
    return props.expanded;
  }

  const setExpanded = (expanded) => {
    props.setExpanded(props.treeName, node.id, expanded);
  }

  const isSelected = props.selectedId == node.id

  return (
    <li id={`node-${node.id}`}>
      { children &&
        <div>
          <div className={(children.length ? "" : "leaf")}>
            { children.length > 0 &&
              <a
                onClick={toggleExpanded}
                className="children-toggle"
              >
                <span className="">
                  { isExpanded() ? (
                    <i className="fas fa-angle-down"></i>
                  ) : (
                    <i className="fas fa-angle-right"></i>
                  )}
                </span>
              </a>
            }
            { props.actions ? (
              <span className="tree-node-title">
                {node.name}
              </span>
            ) : (
              <Link to="#" className={`text-dark tree-node-title-action`} onClick={(e) => { e.preventDefault(); props.onSelect(node) }}>
                <span className={`tree-node-title ${isSelected ? "tree-node-title-selected" : ""}`}>
                  {node.name}
                </span>
              </Link>
            )}
            { props.actions && <props.actions treeName={props.treeName} node={node} children={children} setChildren={setChildren} /> }
          </div>

          {/* if node has children, render TreeBranch for them */}
          { ( isExpanded() && children.length > 0 ) &&
            <TreeBranch
                treeName={props.treeName}
                children={children}
                actions={props.actions}
                onSelect={props.onSelect}
                selectedId={props.selectedId}
            />
          }
        </div>
      }
    </li>
  )
}

const mapStateToProps = (state, ownProps) => {
    const expanded = state?.navigation?.[ownProps.treeName]?.[ownProps.node.id];
    return {expanded};
};

export default connect(mapStateToProps, {toggleExpanded, setExpanded})(TreeNode);
