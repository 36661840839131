import React, { useState, useEffect, useRef } from 'react'
import { Modal, Container, Row, Col } from 'react-bootstrap'
import ChangePasswordForm from './changePasswordForm'

const ChangePasswordModal = ({onHide, onSuccess, ...props}) => {
  return (
    <Modal show={true} onHide={onHide}>
      <Modal.Body>
        <ChangePasswordForm onSuccess={onSuccess} />
      </Modal.Body>
    </Modal>
  )
}

export default ChangePasswordModal
