import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Switch, withRouter, Link, Route } from 'react-router-dom'
import Card from 'react-bootstrap/Card'
import { DropdownButton, Dropdown, OverlayTrigger, Tooltip, Breadcrumb } from 'react-bootstrap'
import { Tabs, Tab, Table } from 'react-bootstrap'
import ContentLoader from "react-content-loader"
import Item from './item'
import NewItem from './newItem'
import EditItem from './editItem'
import ObjectsPrintForm from './objectsPrintForm'
import LocationBoxView from './locationBoxView'
import LocationPositionGrid from './locationPositionGrid'
import limsApi from '../utils/limsApi'
import ReactHtmlParser from 'react-html-parser'
import EditLocation from './editLocation'
import NewLocation from './newLocation'
import SensorValue from './sensorValue'

const Location = (props) => {
  const pluralize = require('pluralize')
  const [isLoading, setIsLoading] = useState(true)
  const [location, setLocation] = useState({
    name: "",
    description: "",
    instanceName: "",
    parentId: null,
    fieldsAttributes: []
  })
  const [itemsAreLoading, setItemsAreLoading] = useState(true)
  const [items, setItems] = useState([])
  const [showPrintForm, setShowPrintForm] = useState(false)
  const [locationTemplates, setLocationTemplates] = useState([])

  const fetchLocation = async () => {
    setIsLoading(true)
    const locationId = props.locationId != undefined ? props.locationId : props.match.params.id
    const token = localStorage.getItem('gf-token')

    await axios.get(`/api/locations/${locationId}`,
      { headers: {"Authorization" : `Token ${token}`} })
    .then(response => {
      const locationData = response.data.location
      setLocation(locationData)
      setIsLoading(false)
    })
    .catch(error => console.log(error))
  }

  useEffect(() => {
    fetchLocation()
  }, [props.match.params.id])

  const fetchItems = () => {
    setItemsAreLoading(true)
    const locationId = props.locationId != undefined ? props.locationId : props.match.params.id
    const token = localStorage.getItem('gf-token')

    axios.get(`/api/items?location_id=${locationId}`,
      { headers: {"Authorization" : `Token ${token}`} })
    .then(response => {
      let itemsData = response.data.items
      setItems(itemsData)
      setItemsAreLoading(false)
    })
    .catch(error => {
      console.log(error)
    })
  }

  useEffect(() => {
    fetchItems()
  }, [props.match.params.id])

  const addItem = (item) => {
    const newItems = items.concat(item)
    setItems(newItems)
  }

  const refreshItems = (item) => {
    fetchItems()
  }

  const updateLocation = (location) => {
    const updatedLocations = locations
    const foundIndex = updatedLocations.findIndex(x => x.id == location.id)
    updatedLocations[foundIndex] = location
    setLocations(updatedLocations)
  }

  const fetchLocationTemplates = () => {
    limsApi.get('location_templates', (response)=>{
      let locationTemplatesData = response.data.locationTemplates
      setLocationTemplates(locationTemplatesData)
    },
    'Error fetching location templates', undefined, setIsLoading)
  }

  const renderTooltip = (tip) => (
    <OverlayTrigger
      placement="bottom"
      overlay={
        <Tooltip>
          {tip}
        </Tooltip>
      }
    >
      <i className="fas fa-info-circle ml-1 text-muted"></i>
    </OverlayTrigger>
  )

  const renderedItems = (
    items.length > 0 || (location.children && location.children.length > 0) ? (
      <div className="items-list" style={{position: "relative"}}>
        { (location.children && location.children.length > 0) && (
          <>
            <h5 className="mb-2">Child Locations</h5>
            {
              location.children.map(loc=>(
                <Card key={loc.id} className="mb-2" onClick={() => {props.history.push(`/locations/${loc.id}`)}}>
                  <Card.Body className="p-3">
                    <div className="container" style={{ maxWidth: 'none', paddingLeft: 0, paddingRight: 0 }}>
                      <div className="row">
                        <div key={loc.id} className="col-2" style={{minWidth: "60px"}}>
                          <a href="" onClick={(e) => {e.preventDefault()}}>
                            {loc.name}
                          </a>
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              ))
            }
          </>
        )
        }
        { items.length > 0 &&
          <div>
            <h5 className="mb-2">Items</h5>
            {location.locationTypeId == 8 ? (
              <LocationBoxView items={items} location={location} />
            ) : (
              items.map((item, index) => (
              <Card key={item.id} className="mb-2" onClick={() => {props.history.push(`/locations/${location.id}/items/${item.parentId ? item.parentId : item.id}`)}}>
                <Card.Body className="p-3">
                  <div className="container" style={{ maxWidth: 'none', paddingLeft: 0, paddingRight: 0 }}>
                    <div className="row">
                      <div key={item.id} className="col-2" style={{minWidth: "60px"}}>
                        <a href="" onClick={(e) => {e.preventDefault()}} className="text-success">
                          {item.code}
                        </a>
                      </div>
                      {Object.keys(item.inputDataForListDisplay).map((key, index) => (
                        <div key={item.id + index} className="col-3">
                          <small className="mr-2 text-secondary">{item.inputDataForListDisplay[key].label}</small>
                          {item.inputDataForListDisplay[key].value}
                        </div>
                      ))}
                    </div>
                  </div>
                </Card.Body>
              </Card>
            )))}
          </div>
        }
      </div>
    ) : (
      <Card className="text-center">
        <Card.Body className="p-3">
          <Card.Text className="py-4">
            <span className="text-muted">No Items at this time.</span>
          </Card.Text>
        </Card.Body>
      </Card>
    )
  )

  const loadingItem = id => (
    <Card key={id} className="mb-2">
      <Card.Body className="p-3">
        <div className="container" style={{ maxWidth: 'none', paddingLeft: 0, paddingRight: 0 }}>
          <div className="row">
            <div className="col-2" style={{minWidth: "90px"}}>
              <ContentLoader height="20" width="100" backgroundColor={'#eee'} foregroundColor={'#ccc'}>
                <rect x="0" y="0" rx="3" ry="3" width="90" height="20" />
              </ContentLoader>
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  )

  return (
    <div>
      <Switch>
        <Route path={`${props.match.path}/items/:itemId/edit`} render={(props) => (
          <EditItem {...props} location={location} addItem={addItem} refreshItems={refreshItems} />
        )} />
        <Route path={`${props.match.path}/items/create/new`} render={(props) => (
          <NewItem {...props} location={location} addItem={addItem} refreshItems={refreshItems} />
        )} />
        <Route path={`${props.match.path}/edit`} render={(p) => (
          <EditLocation {...p} onUpdate={(loc)=>setLocation(loc)} from={location} refreshLocations={()=>{props.refreshBrowser()}} />
        )} />
        <Route path={`${props.match.path}/new`} render={(p) => (
          <NewLocation {...p} from={location} onCreate={()=>{props.refreshBrowser(); fetchLocation() }} />
        )} />
      </Switch>
      <Route exact={true} path={`${props.match.path}/items/:itemId`} render={(props) => (
        <Item {...props} location={location} refreshItems={refreshItems} />
      )} />

      { !isLoading ? (
        <div>
          <DropdownButton
            variant='primary'
            className='float-right'
            title='Print Label for Location'
          >
            <Dropdown.Item onClick={() => setShowPrintForm([location])}>Label for {location.name} {renderTooltip("Prints label for this location only")}</Dropdown.Item>
            {location.children && location.children?.length > 0 ? (
                <>
                  <Dropdown.Item onClick={() => setShowPrintForm(location.children)}>Labels for children locations {renderTooltip("Prints labels for children locations one level deep")}</Dropdown.Item>
                  <Dropdown.Item onClick={() => setShowPrintForm([location, ...location.children])}>Both</Dropdown.Item>
                </>
              )
              : null}
          </DropdownButton>
          { location.locationPath.length > 0 &&
            <Breadcrumb>
              { location.locationPath.map(parent=>(
                  <Breadcrumb.Item key={parent.id}>
                    <Link to={`/locations/${parent.id}`}>{parent.name}</Link>
                  </Breadcrumb.Item>
                )) }
              <Breadcrumb.Item active>{location.name}</Breadcrumb.Item>
            </Breadcrumb>
          }

          {/* title */}
          <h3 className="mb-3">
            {location.name}
            <Link to={`/locations/${location.id}/edit/`} className="d-inline-block text-secondary" style={{marginLeft: '0.5rem', fontSize: '1rem', position: 'relative', top: '-3px'}}>
              <i className="far fa-edit"></i>
            </Link>
            <Link to={`/locations/${location.id}/new?parentId=${location.id}`} className="d-inline-block text-secondary" style={{marginLeft: '0.5rem', fontSize: '1rem', position: 'relative', top: '-3px'}}>
              <i className="fas fa-plus"></i>
            </Link>
            { location.sensors?.map((sensor)=> (
              <SensorValue sensor={sensor} />
            ))}
          </h3>



          <div className="mb-2">{ReactHtmlParser(location.description)}</div>

          {showPrintForm ? (
            <ObjectsPrintForm labelsFor="locations" closeModal={() => setShowPrintForm(false)} objects={showPrintForm} />
          ) : null}

          {location.limitedCapacity ?
            <div
              className={`mb-4 lpg-full-width ${location.colLabels && location.colLabels != '' ? 'pt-4' : ' pt-2'} ${location.rowLabels != '' ? 'pl-3' : ''}`}
            >
              <LocationPositionGrid
                locationId={location.id}
                rows={location.rows}
                cols={location.cols}
                rowLabels={location.rowLabels}
                colLabels={location.colLabels}
                orientation={location.orientation}
                browsing={true}
                children={location.children}
                items={items}
              />
            </div>
          :
            <div className="mt-2">{renderedItems}</div>
          }
        </div>
      ) : (
        <div>
          <ContentLoader height="120" width="100%" backgroundColor={'#eee'} foregroundColor={'#ccc'} className="">
            <rect x="0" y="5" rx="3" ry="3" width="150" height="35"/>
            <rect x="90%" y="5" rx="3" ry="3" width="10%" height="35"/>
            <rect x="0" y="55" rx="3" ry="3" width="100%" height="15" />
            <rect x="0" y="80" rx="3" ry="3" width="60%" height="15" />
          </ContentLoader>
          <div className="items-list mb-5" style={{position: "relative"}}>
            {loadingItem("1")}
            {loadingItem("2")}
          </div>
        </div>
      )}

    </div>
  )
}

export default withRouter(Location)
