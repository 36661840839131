import React, { useState, useEffect } from 'react'

const DurationFieldDisplay = ({ field, inputData, ...props }) => {
  const pluralize = require('pluralize')
  const [display, setDisplay] = useState("")
  const precisionFullNames = {
    "mo": "Months",
    "w": "Weeks",
    "d": "Days",
    "h": "Hours",
    "m": "Minutes",
    "s": "Seconds",
  }

  useEffect(() => {
      try {
        const storedDuration = inputData[field.id]

        // make sure data is an array containing the
        // duration "value" and "precision"
        if(!Array.isArray(storedDuration)) {
          setDisplay('')
          return
        }

        const storedDurationValue = inputData[field.id][0]
        const storedDurationPrecision = inputData[field.id][1]

        // make sure duration "value" is set
        if(storedDurationValue == "") {
          setDisplay('')
          return
        }
        const precisionDisplay = pluralize(precisionFullNames[storedDurationPrecision], parseInt(storedDurationValue))

        setDisplay(`${storedDurationValue} ${precisionDisplay}`)
      }
      catch(err) {
        // data is insufficient, dont display anything
      }
  }, [inputData])

  return (
    <div>
      {display}
    </div>
  )
}

export default DurationFieldDisplay
