import React, { useState, useEffect, useRef } from 'react'
import limsApi from '../../utils/limsApi'
import { OverlayTrigger, Popover, Card } from 'react-bootstrap'

const RecordField = ({ field, inputData, entry, handleChange, formRef }) => {
  // const [isLoading, setIsLoading] = useState(true)
  const [searchPhrase, setSearchPhrase] = useState('')
  const [items, setItems] = useState([])
  const [currentItem, setCurrentItem] = useState(null)
  const [inputEntry, setInputEntry] = useState({})

  const fetchAvailableItems = () => {
    const inputEntryId = entry.inputData[field.id]

    limsApi.get(`items?entry_id=${inputEntryId}&status=1`, (response)=>{
      let itemsData = response.data.items
      setItems(itemsData)
    },
    '')
  }

  useEffect(() => {
    fetchAvailableItems()
  }, [])

  const fetchSelectedItem = () => {
    limsApi.get(`items/${inputData[field.id]}`, (response)=>{
      let itemData = response.data.item
      setCurrentItem(itemData)
    },
    '')
  }

  useEffect(() => {
    if(inputData[field.id]) fetchSelectedItem()
  }, [inputData[field.id]])

  const fetchInputEntry = () => {
    const entryId = entry.inputData[field.id]

    limsApi.get(`entries/${entryId}`, (response)=>{
      let entryData = response.data.entry
      setInputEntry(entryData)
    },
    '')
  }

  useEffect(() => {
    if(entry.inputData[field.id]) fetchInputEntry()
  }, [])

  const handleItemSelect = (item) => {
    setCurrentItem(item)
    const e = {
      target: {
        name: field.id,
        value: item.id
      }
    }
    handleChange(e)
    document.body.click()
  }

  const handleItemDeselect = (event) => {
    event.preventDefault()
    setCurrentItem(null)
    const e = {
      target: {
        name: field.id,
        value: ""
      }
    }
    handleChange(e)
  }

  return (
    <>
      {inputEntry?.id &&
        <>
          <label>
            {inputEntry.name ? `${inputEntry.name}` : ""} ({field.name})
          </label>
          <Card className="field-input-card">
            <Card.Body className="text-primary">
              <OverlayTrigger
                rootClose
                trigger="click"
                placement="right"
                container={formRef.current}
                onExited={() => { setSearchPhrase('') }}
                overlay={
                  <Popover style={{width: '500px'}}>
                    <Popover.Title as="h3">Available {inputEntry.name ? `${inputEntry.name}` : ""} Items</Popover.Title>
                    <Popover.Content className="p-1">
                      <div className="list-group list-group-flush">
                        {items.map((item, index) => (
                          <a
                            href="#"
                            onClick={(e) => { e.preventDefault(); handleItemSelect(item); }}
                            key={index}
                            className={`list-group-item list-group-item-action ${item.id == currentItem?.id ? 'active' : ''}`}
                          >
                            <p>
                              <strong>{item.code}</strong>
                                <br/>
                                Entry: {item.entry.name}
                                { Object.keys(item.inputDataForListDisplay).map((key)=>{
                                  inputData = item.inputDataForListDisplay[key]
                                  return(<><br/>{`${inputData.label}: ${inputData.value}`}</>)
                                })

                                }
                                {console.log(item)}
                              </p>
                          </a>
                        ))}
                      </div>
                    </Popover.Content>
                  </Popover>
                }
              >
                <a
                  href="#"
                  onClick={(e) => e.preventDefault()}
                >
                  {currentItem == null ? (
                    `Select an Item`
                  ) : (
                    `${currentItem.code} (${currentItem.entry.name})`
                  )}
                </a>
              </OverlayTrigger>
              { currentItem &&
                <a
                  href="#"
                  onClick={handleItemDeselect}
                >
                  <i className="fas fa-times-circle ml-2 text-muted"></i>
                </a>
              }
            </Card.Body>
          </Card>
        </>
      }
    </>
  )
}

export default RecordField
