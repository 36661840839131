import React, { useState, useEffect } from 'react'
import limsApi from '../utils/limsApi'
import { ButtonGroup, Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import ContentLoader from "react-content-loader"
import DeleteConfirmation from './deleteConfirmation'

const LabelTemplatesList = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [labelTemplates, setLabelTemplates] = useState([])

  // Performs a get request which receives all the templates
  const fetchLabelTemplates = () => {
    limsApi.get('label_templates', ((response) => {
        let templates = response.data.labelTemplates
        // console.log(templates);
        setLabelTemplates(templates)
      }),
      'Error fetching label templates', undefined, setIsLoading)
  }

  // Updates the accountTemplates useState when a toggle is pressed
  const togglePressed = (template) => {
    limsApi.patch(`label_templates/${template.id}`, {labelTemplate: {isEnabled: !template.isEnabled}}, `${template.name} Saved`, undefined, 'Error saving templates')
    setLabelTemplates((prev) => {
      let i = 0
      let found = false
      prev.map(t=>{
        if (t.id==template.id){
          found = true
        }
        found ? null : i++
      })
      console.log(i);
      let newLabelTemplates = [...prev]
      newLabelTemplates[i] = {...template, isEnabled: !template.isEnabled}
      console.log(newLabelTemplates);
      return newLabelTemplates
    })
  }

  // Performs neccessary reloads when printers have been changed
  useEffect(() => {
    fetchLabelTemplates()
  }, [props.reload])

  let templateListItems = <p>ERROR: No Templates were found</p>
  if (!isLoading) {
      templateListItems = labelTemplates.map(template => (
          <li className="list-group-item">
            <div className="custom-control custom-switch mt-1" key={template.id}>
              <input type="checkbox" className="custom-control-input" id={template.id} checked={template.isEnabled} onChange={() => togglePressed(template)} />
              <label className="custom-control-label" htmlFor={template.id}>{template.name}</label>
              <ButtonGroup className="ml-2" style={{position: "relative", bottom: "3px"}}>
                {template.exampleUrl &&
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip>
                        View example label
                      </Tooltip>
                    }
                  >
                    <button
                      type="button"
                      className="btn btn-light btn-sm"
                      onClick={() => window.open(template.exampleUrl, '_blank')}
                    >
                      <i className="fas fa-external-link-square-alt"></i>
                    </button>
                  </OverlayTrigger>
                }
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip>
                      Edit Template
                    </Tooltip>
                  }
                >
                  <button
                    type="button"
                    className="btn btn-light btn-sm"
                    onClick={() => props.updateTemplate(template.id)}
                  >
                    <i className="far fa-edit"></i>
                  </button>
                </OverlayTrigger>
                <DeleteConfirmation delete={() => props.deleteTemplate(template.id)}>
                  <button
                    type="button"
                    className="btn btn-light btn-sm"
                  >
                    <i className="fas fa-trash-alt"></i>
                  </button>
                </DeleteConfirmation>
              </ButtonGroup>
            </div>
          </li>
      ))
  }

  return (
    <div>
      {!isLoading ? (
        <ul className="list-group hover-reveal-btn-groups">
          {templateListItems}
        </ul>
      ) : (
        <ContentLoader height="150" backgroundColor={'#eee'} foregroundColor={'#ccc'}>
          <rect x="0" y="7" rx="3" ry="3" width="270" height="18" />
          <rect x="0" y="34" rx="3" ry="3" width="270" height="18" />
          <rect x="0" y="61" rx="3" ry="3" width="270" height="18" />
          <rect x="0" y="88" rx="3" ry="3" width="270" height="18" />
        </ContentLoader>
      )}
    </div>
  )
}

export default LabelTemplatesList
