import React, {useEffect, useRef, useState} from 'react'
import { Modal, Form } from 'react-bootstrap'
import ItemForm from './itemForm'

const NewItem = (props) => {
  const [modalTitle, setModalTitle] = useState("")

  const closeModalRedirectsTo = `/catalog/entries/${props.entry.id}`

  return (
    <Modal backdrop="static" show={true} size="lg" onHide={() => { props.history.push(closeModalRedirectsTo) }} enforceFocus={false}>
      <Modal.Header closeButton>
        <Modal.Title>{modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ItemForm
          {...props}
          create={true}
          edit={false}
          setModalTitle={(title)=>setModalTitle(title)}
          closeModalRedirectsTo={closeModalRedirectsTo} />
      </Modal.Body>
    </Modal>
  )
}

export default NewItem
