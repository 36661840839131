import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  withRouter,
  Redirect
} from 'react-router-dom'
import axios from 'axios'
import limsApi from '../utils/limsApi'
import { Spinner } from 'react-bootstrap'
import Login from './login'
import MainNav from './mainNav'
import Dashboard from './dashboard'
import Workflows from './workflows'
import TasksBrowser from './tasksBrowser'
import CatalogBrowser from './catalogBrowser'
import LocationsBrowser from './locationsBrowser'
import ManageAccount from './manageAccount'
import ManageLocations from './manageLocations'
import ManageItemTypes from './manageItemTypes'
import ManagePrinters from './managePrinters'
import ManageSensors from './manageSensors'
import ManageLabelTemplates from './manageLabelTemplates'
import ManageUsers from './manageUsers'
import ManageProfile from './manageProfile'
import ManagePassword from './managePassword'
import Bus from '../utils/bus'
import Flash from './flash'
import Signup from './signup'
import SignupHubspot from './signupHubspot'
import Unactivated from './unactivated'
import EmailConfirmed from './emailConfirmed'
import ForgotPassword from './forgotPassword'
import ResetToNewPassword from './resetToNewPassword'
import LowTaskWarning from './lowTaskWarning'
import BarcodeReader from './barcodeReader'
import { connect } from 'react-redux'
import { SET_CURRENT_USER } from "../redux/actionTypes"
import firebase from '../utils/firebase'
import ReactGA from 'react-ga'
import RouteChangeTracker from './routeChangeTracker'
import ErrorBoundary from './errorBoundary'
import HubspotAfterSignup from './hubspotAfterSignup'
import IdleTimerContainer from './idleTimerContainer'
// Needed to update
const App = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [loginIsLoading, setLoginIsLoading] = useState(false)
  const [user, setUser] = useState("")
  const [account, setAccount] = useState({})
  const [loginFlashMessage, setLoginFlashMessage] = useState('')
  const [registrationFlashMessage, setRegistrationFlashMessage] = useState('')
  const [showLowTaskWarning, setShowLowTaskWarning] = useState(false)
  const [redirectPath, setRedirectPath] = useState('/')
  const TRACKING_ID = "UA-131850847-3"
  ReactGA.initialize(TRACKING_ID)

  // Initialize flash messages
  window.flash = (message, type="success") => Bus.emit('flash', ({message, type}));

  const authenticateUser = () => {
    limsApi.get('user', (response)=>{
      let userData = response.data.user
      axios.get(`/api/accounts/me`,
        { headers: {"Authorization" : `Token ${userData.token}`} })
      .then(response => {
        const accountData = response.data.account
        props.setCurrentUser(userData)
        setAccount(accountData)
        accountData.showLowCreditModal ? setShowLowTaskWarning(true) : null
        setUser(userData)
        setIsLoading(false)
      })
      .catch(error => {
        setIsLoading(false)
        console.log("Error: ", error)
      })
    }, '', ()=> {
      setLoginFlashMessage({ message: 'You must be logged in.', alertClass: 'warning' })
      setIsLoading(false)
    })
  }

  useEffect(() => {
    const path = window.location.pathname
    // authenticates user unless on login or signup routes
    if(!path.includes('login') && !path.includes('signup')) {
      authenticateUser()
    } else {
      setIsLoading(false)
    }
  }, [user.email])

  useEffect(()=>{
    enableHubspotChat()
    if (process.env.NODE_ENV == 'production') {
      enableHotJar()
      hubspotTrackingCode()
    }

    const script2 = document.createElement("script")
    script2.src = "//js.hsforms.net/forms/v2.js"
    document.body.appendChild(script2)
  }, [])

  const enableHubspotChat = () => {
    limsApi.getHeaderless('env', (response)=>{
      // console.log(response);
      if (response.data.enable_chat == "true") {
        // Mimic the following in body:
        //<script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/6110515.js"></script>
        const script = document.createElement("script")

        script.src = "//js.hs-scripts.com/6110515.js"
        script.async = true
        script.defer = true

        document.body.appendChild(script)
      }

      if (response.data.stonly_enabled == "true") {
        const stonly = document.createElement("script");
        stonly.text = 'var STONLY_WID = "19f038f6-3bd8-11eb-accc-0ad3430e55ea";'
        document.head.appendChild(stonly);

        const stonly2 = document.createElement("script");
        stonly2.text = `!function(s,t,o,n,l,y,w,g){s.StonlyWidget||((w=s.StonlyWidget=function(){
                        w._api?w._api.apply(w,arguments):w.queue.push(arguments)}).queue=[],(y=t.createElement(o)).async=!0,
                        (g=new XMLHttpRequest).open("GET",n+"version?v="+Date.now(),!0),g.onreadystatechange=function(){
                        4===g.readyState&&(y.src=n+"stonly-widget.js?v="+(200===g.status?g.responseText:Date.now()),
                        (l=t.getElementsByTagName(o)[0]).parentNode.insertBefore(y,l))},g.send())
                        }(window,document,"script","https://stonly.com/js/widget/v2/");`
        document.head.appendChild(stonly2);
      }
    })
  }

  const enableHotJar = () => {
    const hotJar = document.createElement("script")
    hotJar.text = `(function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:2576983,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`
    document.head.appendChild(hotJar);
  }

  const hubspotTrackingCode = () => {
    const hubspot = document.createElement("script")
    hubspot.src = "//js.hs-scripts.com/6110515.js"
    hubspot.async = true
    hubspot.defer = true
    document.head.appendChild(hubspot)
  }

  const onLogin = async (email, password) => {
    setLoginIsLoading(true)

    await axios.post('/api/users/login', { user: { email: email, password: password } }, {})
    .then(response => {
      setLoginFlashMessage('')
      let userData = response.data.user
      localStorage.setItem('gf-token', userData.token)
      axios.get(`/api/accounts/me`,
        { headers: {"Authorization" : `Token ${userData.token}`} })
      .then(response => {
        const accountData = response.data.account
        props.setCurrentUser(userData)
        setAccount(accountData)
        setUser({email: userData.email, token: userData.token})
        setLoginIsLoading(false)
      })
      .catch(error => console.log(error))
    })
    .catch(error => {
      setLoginIsLoading(false)
      setLoginFlashMessage({ message: 'Email or password is invalid.', alertClass: 'warning' })
    })
  }

  const onLogout = (event) => {
    event.preventDefault()
    localStorage.removeItem('gf-token')
    localStorage.removeItem('gf-admin')
    setUser("")
    props.setCurrentUser({})
    setLoginFlashMessage({ message: "You've successfully logged out.", alertClass: 'primary' })
    setLoginIsLoading(false)
  }

  const onIdleLogout = () => {
    // set redirect path to previous location for when they log back in
    if(localStorage.getItem('gf-previous-path')) {
      setRedirectPath(localStorage.getItem('gf-previous-path'))
    }

    localStorage.removeItem('gf-token')
    localStorage.removeItem('gf-admin')
    setUser("")
    props.setCurrentUser({})
    setLoginFlashMessage({ message: "You've been logged out due to inactivity.", alertClass: 'primary' })
    setLoginIsLoading(false)
  }

  const managerRoutes = [
                        <Route key="1" path="/manage/item-types" component={ManageItemTypes} />,
                        <Route key="2" path="/manage/account" component={ManageAccount} />,
                        <Route key="3" path="/manage/locations" component={ManageLocations} />,
                        <Route key="4" exact path="/manage/printers" component={ManagePrinters} />,
                        <Route key="5" exact path="/manage/sensors" component={ManageSensors} />,
                        <Route key="6" exact path="/manage/label_templates" component={ManageLabelTemplates} />,
                        <Route key="7" exact path="/manage/users" component={ManageUsers} />
                      ]

  const managerComponents = []
  if (props.currentUser.userRole) {
    if (props.currentUser.userRole.id == 1) {
      if (showLowTaskWarning) {
        managerComponents.push(<LowTaskWarning key="lowTaskWarning" closeModal={()=>setShowLowTaskWarning(false)} availableTasks={account.availableTasks} />)
      }
    }
  }


  return (
    <div>
      <ErrorBoundary>
      {!isLoading ? (
        <div>
          {user ? (
            <>
              { process.env.NODE_ENV == 'production' &&
                <IdleTimerContainer onIdle={onIdleLogout} />
              }
              { localStorage.getItem('gf-admin') == 'true' &&
                <div id="admin-impersonating-user">
                  <a href="/admin/accounts" className="text-light">
                    <small>
                      <u>Admin</u><i className="fas fa-user mx-2"></i>Logged in as {user.firstName} {user.lastName}
                    </small>
                  </a>
                </div>
              }
              <Router>
                <RouteChangeTracker />
                { account.active ? (
                  <div style={{height: '100vh', overflow: 'hidden'}}>
                    {managerComponents}
                    <MainNav onLogout={onLogout} user={user} />
                    <Flash />
                    <BarcodeReader barcodeKeyWord="#GFCI#" for="catalog" />
                    <BarcodeReader barcodeKeyWord="#GFLI#" for="locations" />
                    <Switch>
                    <Route exact path="/email_confirmed">
                      <Redirect to={`/catalog/${account.firstItemTypeId || ""}/?email_confirmed`} />
                    </Route>
                    <Route exact path="/invited">
                      <Dashboard changePassword />
                    </Route>
                    <Route path="/tasks" component={TasksBrowser} />
                    <Route path="/catalog" component={CatalogBrowser} />
                    <Route path="/locations" component={LocationsBrowser} />
                    <Route exact path="/activity" component={Dashboard} />
                    <Route exact path="/workflows" component={Workflows} />
                    <Route exact path="/profile" component={ManageProfile} />
                    <Route exact path="/profile/password" component={ManagePassword} />
                    <Route exact path="/hubspot_after_signup" component={HubspotAfterSignup} />
                    {(props.currentUser.userRole ? props.currentUser.userRole.id == 1 : false) ? (
                      // Lab Manager Routes
                      managerRoutes
                    ) : null}
                      {/* if no route matched yet, redirect to "/" */}
                      <Route path="/">
                        <Redirect to={`/catalog/${account.firstItemTypeId || ""}`} />
                      </Route>
                    </Switch>
                  </div>
                ) : (
                  <Switch>
                    <Route exact path="/hubspot_after_signup" component={HubspotAfterSignup} />
                    <Route exact path="/email_confirmed" component={EmailConfirmed} />
                    <Route exact path="/unactivated" component={() => <Unactivated onLogout={onLogout} />} />
                    <Route path="/">
                      <Redirect to="/unactivated" />
                    </Route>
                  </Switch>
                ) }
              </Router>
            </>
          ) : (
            <Router>
              <RouteChangeTracker />
              <Flash />
              <Switch>
                <Route exact path="/hubspot_after_signup" component={HubspotAfterSignup} />
                <Route exact path="/forgot_password" component={ForgotPassword} />
                <Route path="/reset_password/:token" component={ResetToNewPassword} />
                <Route exact path="/email_confirmed" component={EmailConfirmed} />
                <Route exact path="/signup">
                 <Signup onLogin={onLogin} />
                </Route>
                <Route exact path="/signup_hubspot">
                 <SignupHubspot onLogin={onLogin} />
                </Route>
                <Route exact path="/login">
                  <Login
                    isLoading={loginIsLoading}
                    onLogin={onLogin}
                    loginFlashMessage={loginFlashMessage}
                    setLoginIsLoading={setLoginIsLoading}
                    setLoginFlashMessage={setLoginFlashMessage}
                  />
                </Route>
                {/* if no route matched yet, default to "/login" */}
                <Route path="/">
                  <Redirect to="/login" />
                </Route>
              </Switch>
            </Router>
          )}
        </div>
      ) : (
        <div className="my-5 py-5 text-center"><Spinner animation="border" variant="primary" /></div>
      )}
      </ErrorBoundary>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    currentUser: state.currentUser
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setCurrentUser: (user) => dispatch({type: SET_CURRENT_USER, currentUser: user})
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
