import React, { useState, useEffect } from 'react'
import { Link, withRouter } from 'react-router-dom'
import limsApi from '../utils/limsApi'

const ForgotPassword = (props) => {
  const [email, setEmail] = React.useState("")

  const putPassword = () => {
    limsApi.putHeaderless('users/forgot_password', {email: email}, "Email Sent")
  }

  return (
    <div className="login-container p-5 mx-auto">
      <h2 className="mb-3 text-center">Reset Password</h2>
      <form onSubmit={(e) => { e.preventDefault(); putPassword() }}>
        {props.loginFlashMessage &&
          <div className={`alert alert-${props.loginFlashMessage.alertClass}`}>{props.loginFlashMessage.message}</div>
        }
        <p className="text-center">We will send you an email with a link to reset your password</p>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            className="form-control"
            type="text"
            name="email"
            onChange={e => setEmail(e.target.value)}
          />
        </div>
        <div className="actions my-4">
          <button type="submit" className="btn btn-primary btn-block" disabled={props.isLoading}>
            { !props.isLoading ? (
              "Send Email"
            ) : (
              <i className="fas fa-spinner fa-spin"></i>
            )}
          </button>
        </div>
      </form>
      <div className="text-center">Back to <Link to="/login">login</Link></div>
    </div>
  );
}

export default ForgotPassword
