import React, { useState, useEffect } from 'react'
import limsApi from '../utils/limsApi'
import firebase from '../utils/firebase'
import { Container } from 'react-bootstrap'
import EventListItem from './eventListItem'

const ItemTimeline = (props) => {
  const [isLoading, setIsLoadingInt] = useState(2)
  const [itemHistory, setItemHistory] = useState([])
  const [users, setUsers] = useState()
  const [numOfEvents, setNumOfEvents] = useState(7)
  const [showSeeMoreBtn, setShowSeeMoreBtn] = useState(true)
  const [showSeeLessBtn, setShowSeeLessBtn] = useState(false)
  const [eventItems, setEventItems] = useState([])

  const setIsLoading = (bool) => {
    if (!bool)
      setIsLoadingInt(prev => (prev-1))
  }

  const fetchItemHistory = () => {
    limsApi.get('accounts/me', (response)=>{
      const accountId = response.data.account.id
      const db = firebase.firestore()
      db.collection(accountId.toString()).where('itemIds', 'array-contains', parseInt(props.itemId)).get()
        .then(function(querySnapshot) {
          let events = [];
          querySnapshot.forEach(doc => {
            events.push({...doc.data(), id: doc.id})
          })
          // SAVE QUERY TO STATE
          fetchEventItems(events)
          setItemHistory(orderEventsByDate(events))
      });
    },
    'Error fetching account', ()=>{console.log('Error in ItemTimeline')}, setIsLoading)
  }

  const increaseNumberOfEvents = () => {
    setNumOfEvents((prev) => {
      return(prev+7)
    })
  }

  const decreaseNumberOfEvents = () => {
    setNumOfEvents((prev) => {
      return(prev-7)
    })
  }

  // Performs a get request which returns the all users in the account
  const fetchUsers = () => {
    limsApi.get('users', (response)=>{
      setUsers(response.data.users)
    },
    'Error fetching users', undefined, setIsLoading)
  }

  const fetchEventItems = (events) => {
    const idsToFetch = getUniqueEventItemsToFetch(events)
    idsToFetch.forEach((id) => {
      limsApi.get(`items/${id}`, (response)=>{
        setEventItems(prev => [...prev, response.data.item])
      },
      '', undefined, undefined, true)
    })
  }

  const getUniqueEventItemsToFetch = (events) => {
    let itemSet = new Set()
    events.map(evt => {
      evt.itemIds.map(itemId => {
        itemSet.add(itemId)
      })
    })
    return itemSet
  }

  const orderEventsByDate = (events) => {
    if (events.length < 1) {return []}
    let newEvents = [events[0]]
    for(let i = 1; i < events.length; i++) {
      const eventDate = getEventTimestamp(events[i])
      for(let j = 0; j < newEvents.length; j++) {
        const newEventDate = getEventTimestamp(newEvents[j])
        if (eventDate.getTime() > newEventDate.getTime()) {
          newEvents.splice(j, 0, events[i])
          break
        }
        if (j+1 == newEvents.length) {
          newEvents.push(events[i])
          break
        }
      }
    }
    return newEvents
  }

  const getEventTimestamp = (evt) => {
    let timestamp = 0
     try { timestamp = new firebase.firestore.Timestamp(evt.timestamp.seconds, evt.timestamp.nanoseconds).toDate() }
     catch { timestamp = new Date() }
     return timestamp
  }

  useEffect(() => {
    fetchItemHistory()
    fetchUsers()
  }, [])

  useEffect(()=>{
    if (itemHistory.length <= numOfEvents)
      setShowSeeMoreBtn(false)
    else
      setShowSeeMoreBtn(true)

    if (numOfEvents > 7)
      setShowSeeLessBtn(true)
    else
      setShowSeeLessBtn(false)
  }, [numOfEvents, itemHistory])


  let listItems = <p className="ml-2">No events have occured to this item.</p>
  if (itemHistory.length > 0 && isLoading <= 0) {
    let i = 0
    listItems = itemHistory.map(evt => {
      if (i < numOfEvents) {
        i++
        return (
          <EventListItem
            key={evt.id}
            event={evt}
            user={users.find( ({ id }) => id == evt.userId )}
            action={evt.action}
            itemIds={evt.itemIds}
            items={eventItems}
            timestamp={getEventTimestamp(evt)}
            hasDivider={evt == itemHistory[0] ? false : true} />
        )
      }
      return
    })
  }

  return(
    <Container>
        {isLoading <= 0 ? listItems : "Loading..."}
        { showSeeMoreBtn &&
          <button
          type="button"
          className="btn btn-secondary mb-1 mt-2 ml-2"
          onClick={() => increaseNumberOfEvents()}
          >
          See more
          </button>
        }
        { showSeeLessBtn &&
          <button
          type="button"
          className="btn btn-outline-secondary mb-1 mt-2 ml-2"
          onClick={() => decreaseNumberOfEvents()}
          >
          See less
          </button>
        }
    </Container>
  )
}

export default ItemTimeline
