import React, { useState, useEffect } from 'react'
import limsApi from '../utils/limsApi'
import AccountSubNav from './accountSubNav'
import Address, {BILLING, SHIPPING} from "./address";
import PurchaseTasksForm from './purchaseTasksForm'
import {parseOrIdentity} from "../utils/pureStateFns";
import { Elements } from "@stripe/react-stripe-js";
import {Card, ProgressBar, Col, Row} from 'react-bootstrap'
import { loadStripe } from "@stripe/stripe-js";
import axios from 'axios'
import HubSpotFormModal from './hubSpotFormModal'

const createAddress = (account, purpose) => {
  const address = {purpose}
  account.addresses.push(address)
  return address
}

const getAddress = (account, purpose) => {
  return account.addresses.find((address)=>address.purpose===purpose) || createAddress(account, purpose)
}
const getShippingAddress = (account) => {
  return getAddress(account, SHIPPING)
}
const getBillingAddress = (account) => {
  return getAddress(account, BILLING)
}

const ManageAccount = (props) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const showPurchase = urlParams.get('showPurchase')

  const [isLoading, setIsLoading] = useState(false)
  const [showPurchaseTaskModal, setShowPurchaseTaskModal] = useState(showPurchase == "true")
  const [showInvoiceModal, setShowInvoiceModal] = useState(false)
  const [stripePromise, setStripePromise] = useState(null)
  const [account, setAccount] = useState({
    company: "",
    phone: "",
    addresses: [{}, {}]
  })

  const fetchAccount = async () => {
    limsApi.get('accounts/me', ((response) => {
        const accountData = response.data.account
        setAccount(accountData)
      }),
      'Error fetching account')
  }

  useEffect(() => {
    fetchAccount()
    initStripe()
  }, [])

  const initStripe = () => {
    let promise = "" // Publishable Key
    axios.get('/api/env')
    .then(response=>{
      if (response.data.version == 'development') {
        promise = loadStripe("pk_test_gnoLnOfyjDotQVw3g5VY4Avs")
      } else if (response.data.version == 'production') {
        promise = loadStripe("pk_live_9FAd8oCx5GmVCSondSk0GSVV")
      } else {
        promise = loadStripe("pk_test_gnoLnOfyjDotQVw3g5VY4Avs")
      }
      setStripePromise(promise)
    })
    .catch(error => {
      console.error("Error initializing Stripe");
      console.log(error);
    })
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    limsApi.patch('accounts/me', { account: account }, 'Account Updated', null, 'Error fetching account', null, setIsLoading)
  }

  const handleChange = (event) => {
    const {name, value} = event.target
    setAccount(account=>{return {...account, [name]: value}})
  }

  const updateAddress= (account, oldAddress, name, value) => {
    // TODO: convert this to use updateIn, this is exactly the purpose of updateIn
    const otherAddresses = [...account.addresses]
    const index = otherAddresses.indexOf(oldAddress)
    otherAddresses.splice(index,1)
    return {...account,
      addresses: [...otherAddresses, {...oldAddress, [name]: parseOrIdentity(value)}]
    }
  }

  const handleAddressChange = (event, getAddressFn) => {
    const {name, value} = event.target
    setAccount(account=>updateAddress(account, getAddressFn(account), name, value))
  }
  const handleShippingAddressChange = (event) => handleAddressChange(event, getShippingAddress)
  const handleBillingAddressChange = (event) => handleAddressChange(event, getBillingAddress)

  return (
    <div>
      <div className="col-fixed bg-light">
        <AccountSubNav />
      </div>
      <div className="col-fluid p-4 bg-white">
        <h3 className="mb-3">Account &amp; Billing</h3>
        <form onSubmit={handleSubmit}>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label>Company</label>
              <input
                type="text"
                className="form-control"
                value={account.company || ""}
                name="company"
                onChange={handleChange}
              />
            </div>
            <div className="form-group col-md-6">
              <label>Phone</label>
              <input
                type="text"
                className="form-control"
                value={account.phone || ""}
                name="phone"
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="mt-3">
            <Row >
              <Col>
                <Address type={SHIPPING} address={getShippingAddress(account)} onChange={handleShippingAddressChange}/>
              </Col>
              { account.isSubscription ?
                <Col>
                  <h5 className="mb-3">Account Seats</h5>
                  <Card className="" style={{width: "100%"}}>
                    <Card.Body className="">
                      <Card.Title className="text-primary" style={{fontSize: "26px"}}>{account.activeUsers.length}/{account.seatNumber}</Card.Title>
                      <Card.Subtitle className="pb-2">Seats Remaining</Card.Subtitle>
                      <ProgressBar now={(account.activeUsers.length/account.seatNumber)*100} className="mt-1 mb-1" />
                    </Card.Body>
                  </Card>
                </Col>
                :
                <Col>
                  <h5 className="mb-3">Credits</h5>
                  <Card className="" style={{width: "100%"}}>
                    <div style={{position:'absolute', top: 20, right: 25, cursor: "pointer"}}>
                      <i className="fas fa-plus-circle fa-2x plusBtn" onClick={()=>setShowPurchaseTaskModal(true)}></i>
                    </div>
                    <Card.Body className="">
                      <Card.Title className="text-primary" style={{fontSize: "26px"}}>{account.availableTasks}</Card.Title>
                      <Card.Subtitle className="pb-2">Credits Remaining</Card.Subtitle>
                      <ProgressBar now={(account.availableTasks/account.tasksPreviouslyPurchased)*100} className="mt-1 mb-1" />
                      {/*<small className="text-muted mt-2">
                        <i className="fas fa-info-circle mr-1 text-muted"></i>
                        Credits are a measure of your lab's productivity. <a href='https://www.genofab.com/pricing' target="_blank">Learn more</a>
                      </small>*/}
                    </Card.Body>
                  </Card>
                </Col>
              }
            </Row>
          </div>
          <button type="submit" className="btn btn-primary" disabled={isLoading}>
            { !isLoading ? (
              "Save"
            ) : (
              <i className="fas fa-spinner fa-spin"></i>
            )}
          </button>
        </form>
      </div>
      { showPurchaseTaskModal ? (
        <Elements stripe={stripePromise}>
          <PurchaseTasksForm closeModal={()=>setShowPurchaseTaskModal(false)} account={account} setAccount={(data)=>setAccount(data)} setShowInvoiceModal={()=>{setShowInvoiceModal(true); setShowPurchaseTaskModal(false)}} />
        </Elements>
      ) : null}
      { showInvoiceModal ? (
        <HubSpotFormModal portalId="6110515" formId="61d8948d-35b0-4939-9477-0dd9eeddaee0" modalTitle="Request an Invoice" closeModal={()=>setShowInvoiceModal(false)} />
      ) : null}
    </div>
  )
}

export default ManageAccount
