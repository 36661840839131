import React, { useState, useEffect } from 'react'
import SensorGraph from './sensorGraph'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import moment from 'moment'

const SensorValue = ({sensor, noName, ...props}) => {

  const [showSensorGraph, setShowSensorGraph] = useState(false)

  const unit = 'C'

  const sensorActive = sensor.lastSensorLog

  const badgeColor = () => {
    if (sensorActive) {
      const lastValue = sensor.lastSensorLog.value
      if (lastValue > sensor.maxValue || lastValue < sensor.minValue)
        return "danger"
      else
        return "success"
    }
    return "warning"
  }

  const badgeContents = () => {
    if (sensorActive) {
      const lastValue = sensor.lastSensorLog.value
      return noName ? `${lastValue} ${unit}` : `${sensor.name}: ${lastValue} ${unit}`
    }
    return noName ? "No Value" : `${sensor.name}: No Value`
  }

  return (
    <h5>
      { showSensorGraph &&
        <SensorGraph sensor={sensor} closeModal={()=>setShowSensorGraph(false)} />
      }
      { sensorActive ?
        (
          <OverlayTrigger
           placement="right"
           overlay={
             <Tooltip>
               {moment(sensor.lastSensorLog.createdAt).fromNow()}
             </Tooltip>
           }
         >
           <span
             className={`badge badge-${badgeColor()} badge-pill ${sensorActive ? "active-sensor" : "unactive-sensor"}`}
             onClick={()=> sensorActive ? setShowSensorGraph(true) : {}}
             >
             {badgeContents()}
           </span>
         </OverlayTrigger>
       ):(
         <span
           className={`badge badge-${badgeColor()} badge-pill ${sensorActive ? "active-sensor" : "unactive-sensor"}`}
           onClick={()=> sensorActive ? setShowSensorGraph(true) : {}}
           >
           {badgeContents()}
         </span>
       )
      }
    </h5>
  )
}

export default SensorValue
