import React, { useState, useEffect } from 'react'
import axios from 'axios'
import limsApi from '../utils/limsApi'
import {Modal, Form, Row, Col, Button} from 'react-bootstrap'
import { Link, withRouter } from 'react-router-dom'
import * as Yup from 'yup'
import { Formik } from 'formik'
import Address, { SHIPPING} from "./address"
import NavLogo from 'images/logo-login.png'

const Signup = (props) => {
  const [accountIsInitializing, setAccountIsInitializing] = useState(false)
  const [account, setAccount] = useState({
    company: "",
    phone: "",
    addresses: []
  })
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

  const schema = Yup.object({
    firstName: Yup.string()
      .required("First name is required."),
    lastName: Yup.string()
      .required("Last name is required."),
    email: Yup.string()
      .email("Invalid email.")
      .required("Email is required."),
    pass: Yup.string()
      .required("Password is required.")
      .min(8, 'Must be at least 8 characters.'),
    companyName: Yup.string()
      .required("Lab name is required.")
  })

  const onLogin = async (email, password, firstItemTypeId) => {
    await axios.post('/api/users/login', { user: { email: email, password: password } }, {})
    .then(async (response) => {
      let userData = response.data.user
      await localStorage.setItem('gf-token', userData.token)
      window.location.href = `/catalog/${firstItemTypeId || ""}`
    })
    .catch(error => {
      setLoginIsLoading(false)
      setLoginFlashMessage({ message: 'Email or password is invalid.', alertClass: 'warning' })
    })
  }

  const createAccount = (values, actions) => {
    setAccountIsInitializing(true)

    const accountObj = {
      company: values.companyName,
      phone: values.phoneNumber,
      addresses: account.addresses
    }
    const userObj = {
      email: values.email,
      password: values.pass,
      firstName: values.firstName,
      lastName: values.lastName,
      userRoleId: 1
    }
    const newAccountAndUser = {
      account: accountObj,
      user: userObj
    }

    limsApi.postHeaderless('accounts/new', newAccountAndUser, "", (response)=>{
      const accountData = response.data.account
      window.gtag('event', 'conversion', { 'send_to': 'AW-716791727/QJsECM-c9-8CEK-_5dUC', 'event_callback': () => {
        onLogin(values.email, values.pass, accountData.firstItemTypeId)
      }});
    }, "", (errors)=>{
      if (errors != null) {
        if (errors[0].includes("Email") || errors[0].includes("email"))
          actions.setFieldError("email", errors[0])
      }

      setAccountIsInitializing(false)
      actions.setSubmitting(false)
    })
  }

  const createAddress = (account, purpose) => {
    const address = {purpose, country: "United States of America", name: `${user.firstName} ${user.lastName}`}
    account.addresses.push(address)
    return address
  }

  const getAddress = (account, purpose) => {
    return account.addresses.find((address)=>address.purpose===purpose) || createAddress(account, purpose)
  }
  const getShippingAddress = (account) => {
    return getAddress(account, SHIPPING)
  }

  const updateAddress= (account, oldAddress, name, value) => {
    const otherAddresses = [...account.addresses]
    const index = otherAddresses.indexOf(oldAddress)
    otherAddresses.splice(index,1)
    return {...account,
      addresses: [...otherAddresses, {...oldAddress, [name]: value}]
    }
  }

  const handleAddressChange = (event, getAddressFn) => {
    const {name, value} = event.target
    setAccount(account=>updateAddress(account, getAddressFn(account), name, value))
  }
  const handleShippingAddressChange = (event) => handleAddressChange(event, getShippingAddress)

  return (
    <div className="signup-container p-5 mx-auto" style={{"overflowY": 'scroll'}}>
      {accountIsInitializing &&
        <Modal show={true} size="lg" onHide={() => { setAccountIsInitializing(false) }}>
          <Modal.Body>
            <div className="py-5 text-center">
              <i className="mb-3 fas fa-spinner fa-spin fa-2x"></i>
              <h3 className="mb-2">Setting up your account...</h3>
              <p>This may take a few moments.</p>
            </div>
          </Modal.Body>
        </Modal>
      }
      <p className="mb-4 text-center"><img src={NavLogo} width="260" /></p>
      <h3 className="mb-3 text-center">Create your GenoFAB Account</h3>
        <Formik
          validationSchema={schema}
          onSubmit={createAccount}
          initialValues={{}} >
          {({
            status,
            setStatus,
            setFieldTouched,
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            dirty,
            errors,
            setErrors,
            isSubmitting
          }) => (
        <Form noValidate  onSubmit={(e) => {
                    e.preventDefault()
                    handleSubmit()
                  }}>
              <Form.Group>
                  <Row>
                    <Col>
                    <Form.Label>First Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="firstName"
                        value={values.firstName}
                        onChange={handleChange}
                        onBlur={e => {
                          setFieldTouched('firstName');
                          handleBlur(e);
                        }}
                        isInvalid={touched["firstName"] || status ? errors.firstName : null} />
                    <Form.Control.Feedback type="invalid">{touched["firstName"] || status ? errors.firstName : null}</Form.Control.Feedback>
                    </Col>
                    <Col>
                    <Form.Label>Last Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="lastName"
                        value={values.lastName}
                        onChange={handleChange}
                        onBlur={e => {
                          setFieldTouched('lastName');
                          handleBlur(e);
                        }}
                        isInvalid={touched["lastName"] || status ? errors.lastName : null} />
                      <Form.Control.Feedback type="invalid">{touched["lastName"] || status ? errors.lastName : null}</Form.Control.Feedback>
                    </Col>
                  </Row>
              </Form.Group>
              <Form.Group>
                <Form.Label>Lab Name</Form.Label>
                <Form.Control
                  type="text"
                  name="companyName"
                  value={values.companyName}
                  onChange={handleChange}
                  onBlur={e => {
                    setFieldTouched('companyName');
                    handleBlur(e);
                  }}
                  isInvalid={touched["companyName"] || status ? errors.companyName : null} />
                <Form.Control.Feedback type="invalid">{touched["companyName"] || status ? errors.companyName : null}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={e => {
                    setFieldTouched('email');
                    handleBlur(e);
                  }}
                  isInvalid={touched["email"] || status ? errors.email : null} />
               <Form.Control.Feedback type="invalid">{touched["email"] || status ? errors.email : null}</Form.Control.Feedback>
              </Form.Group>
              {/*
              <Form.Group>
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  type="text"
                  autoComplete="on"
                  name="phoneNumber"
                  value={values.phoneNumber}
                  onChange={handleChange}
                  onBlur={e => {
                    setFieldTouched('phoneNumber');
                    handleBlur(e);
                  }}
                  isInvalid={touched["phoneNumber"] || status ? errors.phoneNumber : null} />
                <Form.Control.Feedback type="invalid">{touched["phoneNumber"] || status ? errors.phoneNumber : null}</Form.Control.Feedback>
              </Form.Group>
              */}
              <Form.Group>
                <Form.Label>Create a Password</Form.Label>
                <Form.Control
                  type="password"
                  autoComplete="on"
                  name="pass"
                  value={values.pass}
                  onChange={handleChange}
                  onBlur={e => {
                    setFieldTouched('pass');
                    handleBlur(e);
                  }}
                  isInvalid={touched["pass"] || status ? errors.pass : null} />
                <Form.Control.Feedback type="invalid">{touched["pass"] || status ? errors.pass : null}</Form.Control.Feedback>
              </Form.Group>
              <div className="mt-4">
                {/*
                <div>
                  <Address type={SHIPPING} address={getShippingAddress(account)} onChange={handleShippingAddressChange} inSignup={true} />
                </div>
                */}
                <div className="text-center">
                  <Button variant="primary" disabled={isSubmitting || !(isValid && dirty)} type="button" onClick={() => {setStatus(true); handleSubmit()}} block>
                    Create Account
                  </Button>
                  <div className="mt-3">
                    <span style={{color: "#5c5c5c", fontSize: "14px"}}>
                      By clicking "Create Account" I agree to GenoFAB's
                      <br/>
                      <a target="_blank" href="https://www.genofab.com/terms-and-conditions">Terms of Service</a> and <a target="_blank" href="https://www.genofab.com/privacy-policy">Privacy Policy</a>.
                    </span>
                  </div>
                </div>
              </div>
              <div className="text-center mt-3">Already have an account? <Link to="/login">Log In</Link></div>
        </Form>
      )}
      </Formik>
      {/* PAGE 2 */}
      { false ? (
        <Formik
          validationSchema={schema}
          onSubmit={(values) => {createAccount(values)}}
          initialValues={{}} >
          {({
            status,
            setStatus,
            setFieldTouched,
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            dirty,
            errors,
            isSubmitting
          }) => (
        <Form noValidate  onSubmit={(e) => {
                    e.preventDefault()
                    handleSubmit()
                  }}>
        <Form.Group>
          <Form.Label>Lab Name</Form.Label>
          <Form.Control
            type="text"
            name="companyName"
            value={values.companyName}
            onChange={handleChange}
            onBlur={e => {
              setFieldTouched('companyName');
              handleBlur(e);
            }}
            isInvalid={touched["companyName"] || status ? errors.companyName : null} />
          <Form.Control.Feedback type="invalid">{touched["companyName"] || status ? errors.companyName : null}</Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Label>Phone Number</Form.Label>
          <Form.Control
            type="text"
            autoComplete="on"
            name="phoneNumber"
            value={values.phoneNumber}
            onChange={handleChange}
            onBlur={e => {
              setFieldTouched('phoneNumber');
              handleBlur(e);
            }}
            isInvalid={touched["phoneNumber"] || status ? errors.phoneNumber : null} />
          <Form.Control.Feedback type="invalid">{touched["phoneNumber"] || status ? errors.phoneNumber : null}</Form.Control.Feedback>
        </Form.Group>
        <div className="mt-4">
          {/*
          <div>
            <Address type={SHIPPING} address={getShippingAddress(account)} onChange={handleShippingAddressChange} inSignup={true} />
          </div>
          */}
          <div className="text-center">
            <Button variant="primary" disabled={isSubmitting || !(isValid && dirty)} type="button" onClick={() => {setStatus(true); handleSubmit()}} block>
              { !isSubmitting ? (
                "Sign Up"
              ) : (
                <i className="fas fa-spinner fa-spin"></i>
              )}
            </Button>
            <div className="mt-3">
              <span style={{color: "#5c5c5c", fontSize: "14px"}}>
                By clicking "Create Account" I agree to GenoFAB's
                <br/>
                <a target="_blank" href="https://www.genofab.com/terms-and-conditions">Terms of Service</a> and <a target="_blank" href="https://www.genofab.com/privacy-policy">Privacy Policy</a>.
              </span>
            </div>
          </div>
        </div>
        </Form>
      )}
      </Formik>
    ) : null}
    </div>
  );
}

export default Signup
