import React, {useState, useEffect} from "react";
import axios from 'axios'
import {GSelectInput, GTextInput} from "./lib/gforms";

// Constants to represent types of addresses. They may be used for user display.
export const SHIPPING='Shipping';
export const BILLING='Billing';

const InternationalAddress = ({address, onChange}) => {
    return (
        <React.Fragment>
            <GTextInput name={'name'} value={address?.name} onChange={onChange}>Name</GTextInput>
            <GTextInput name={'street'} value={address?.street} onChange={onChange}>Street</GTextInput>
            <GTextInput name={'street2'} value={address?.street2} onChange={onChange}>Street 2</GTextInput>
            <GTextInput name={'street3'} value={address?.street3} onChange={onChange}>Street 3</GTextInput>
            <GTextInput name={'street4'} value={address?.street4} onChange={onChange}>Street 4</GTextInput>
            <GTextInput name={'city'} value={address?.city} onChange={onChange}>City/Town</GTextInput>
            <GTextInput name={'sublocality'} value={address?.sublocality} onChange={onChange}>Sub Locality/County</GTextInput>
            <GTextInput name={'state'} value={address?.state} onChange={onChange}>State/Province/Territory</GTextInput>
            <GTextInput name={'postalCode'} value={address?.postalCode} onChange={onChange}>Postal Code</GTextInput>
        </React.Fragment>
    )
}

const USAddress = ({address, onChange}) => {
    return (
        <React.Fragment>
            <GTextInput name={'name'} value={address?.name} onChange={onChange}>Name</GTextInput>
            <GTextInput name={'street'} value={address?.street} onChange={onChange}>Street</GTextInput>
            <GTextInput name={'street2'} value={address?.street2} onChange={onChange}>Street 2</GTextInput>
            <div className={'form-row'}>
                <GTextInput className={'col-md-6'} name={'city'} value={address?.city} onChange={onChange}>City</GTextInput>
                <GTextInput className={'col-md-4'} name={'state'} value={address?.state} onChange={onChange}>State</GTextInput>
                <GTextInput className={'col-md-2'} name={'postalCode'} value={address?.postalCode} onChange={onChange}>Zip</GTextInput>
            </div>
        </React.Fragment>
    )
}

class Countries {
    constructor() {
        this.promise = undefined;
    }
    async fetchCountries(resolve, reject) {
        const token = localStorage.getItem('gf-token')
        await axios.get('/api/addresses/countries')
            .then(resolve)
            .catch(reject)
    }
    then(fn) {
        if (this.promise === undefined) {
            this.promise = new Promise(this.fetchCountries)
        }
        return this.promise.then(response=>response.data.countries).then(fn)
    }
}

const countriesPromise = new Countries();

const Address = ({type, address, onChange, inSignup, ...props}) => {
    const [countries, setCountries] = useState([])
    const isBilling = () => {return type===BILLING}
    const isSameAsShipping = () => {
        // sameAsShipping can only be true for billing address
        if (!isBilling()) return false
        // The following makes the default value of sameAsShipping 'true'
        if (address?.sameAsShipping == null) return true
        // finally, return the value
        return address?.sameAsShipping && address?.sameAsShipping !== 'false'
    }
    useEffect( () => {
        if (countries.length === 0 ) {
            countriesPromise.then((countries)=>setCountries(countries))
        }
    })

    const addressTitle = (<h5 className="mb-3">Address</h5>)

    return (
      <div className={'form-group'}>
        {isSameAsShipping() ? addressTitle : null}
        <div className={isSameAsShipping() ? '' : 'float-right'} style={{display: isBilling()?'block':'none'}}>
            <input
                type={'checkbox'}
                name={'sameAsShipping'}
                checked={isSameAsShipping()}
                onChange={(e)=>{e.target.value = e.target.checked; return onChange(e)}}
            />&nbsp;
            <label htmlFor={'same-as-shipping'}>same as shipping</label>
        </div>
        {isSameAsShipping() ?
            null :
            <React.Fragment>
              {addressTitle}
                 <GSelectInput name={'country'} value={address?.country} onChange={onChange}
                               options={countries}
                 >Country</GSelectInput>
                {address?.country==null?"":
                 address?.country==="United States of America"?
                    <USAddress address={address} onChange={onChange}/>:
                    <InternationalAddress address={address} onChange={onChange}/>}
            </React.Fragment>
        }
    </div>)
};

export default Address;
