import React, { useState, useEffect } from 'react'
import { Button, Form, Col, Row } from 'react-bootstrap'
import axios from 'axios'
import UserSubNav from './userSubNav'

const ManagePassword = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [email, setEmail] = useState("")
  const [id, setId] = useState()
  const [valueChanged, setValueChanged] = useState(false)

  const [oldPassword, setOldPassword] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")

  // Performs a get request which returns the current user's profile
  const fetchProfile = () => {
    const token = localStorage.getItem('gf-token')
    axios.get('/api/user',
      { headers: {"Authorization" : `Token ${token}`} })
    .then(response => {
      const user = response.data.user
      setId(user.id)
      setEmail(user.email)
      setIsLoading(false)
    })
    .catch(error => {
      console.log(error)
    })
  }

  useEffect(() => {
    fetchProfile()
  }, [])

  // Performs a put request that changes the current user's password, ONLY happens if user passes authentification
  const changePassword = () => {
    const token = localStorage.getItem('gf-token')
    const newAuth = { password: newPassword, password_confirmation: confirmPassword}
    axios.put('/api/users/reset_password', newAuth,
    { headers: {"Authorization" : `Token ${token}`} })
    .then(response => {
      console.log(response.data)
      setOldPassword("")
      setNewPassword("")
      setConfirmPassword("")
    })
    .catch(error => {
      console.log(error)
    })
  }

  // Performs a post request that authenticates the user's current password
  const resetPassword = () => {
    axios.post('/api/users/login', { user: { email: email, password: oldPassword } }, {})
    .then(response => {
      changePassword()
    })
    .catch(error => {
      console.log(error)
    })
  }

  return (
    <div>
      <div className="col-fixed bg-light">
        <UserSubNav />
      </div>
      <div className="col-fluid p-4 bg-white">
        <Form>
          <h3 className="mb-3">Change Password</h3>
          <Form.Group>
            <Form.Label>Current Password</Form.Label>
            <Form.Control type="password" value={oldPassword} onChange={() => {setOldPassword(event.target.value); setValueChanged(true)}} />
          </Form.Group>
          <Form.Group>
            <Form.Label>New Password</Form.Label>
            <Form.Control type="password" value={newPassword} onChange={() => setNewPassword(event.target.value)} />
          </Form.Group>
          <Form.Group>
            <Form.Label>Confirm New Password</Form.Label>
            <Form.Control type="password" value={confirmPassword} onChange={() => setConfirmPassword(event.target.value)} />
          </Form.Group>
          <Button variant="primary" disabled={!valueChanged} onClick={resetPassword}>
            Change
          </Button>
        </Form>
      </div>
    </div>
  )
}

export default ManagePassword
