import React, { useState, useEffect } from 'react'
import { Button, Modal, Form, ProgressBar } from 'react-bootstrap'
import limsApi from '../utils/limsApi'

const UploadAvatarForm = (props) => {
  const [file, setFile] = useState()
  const [uploadProgress, setUploadProgress] = useState(-1)
  const [enableUploadBtn, setEnableUploadBtn] = useState(true)

  const uploadAvatar = () => {
    setEnableUploadBtn(false)
    setUploadProgress(0)
    const fd = new FormData();
    fd.append('user[avatar]', file)
    fd.append('user[id]', props.profile.id)
    limsApi.putFile('user', fd, (progressEvent)=>options(progressEvent), 'Avatar Saved', ()=>{
        props.reload()
        props.closeModal()
      },
      'Error saving avatar')
  }

  const options = (progressEvent) => {
    let percentage = Math.floor( (progressEvent.loaded*100) / progressEvent.total)
    setUploadProgress(percentage)
  }

  return(
    <Modal show={true} onHide={props.closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Upload Avatar Picture</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.File
            id="avatarFileUpload"
            accept="image/x-png,image/gif,image/jpeg"
            label="Please choose a file"
            onChange={() => setFile(event.target.files[0])} />
            {uploadProgress < 0 ? null :
              (<ProgressBar animated now={uploadProgress} className={"mt-3"} label={`${uploadProgress}%`} />)}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.closeModal}>
            Close
          </Button>
          <Button variant="primary" onClick={uploadAvatar} disabled={!enableUploadBtn}>
            {file == null ? "Reset to default" : "Upload"}
          </Button>
        </Modal.Footer>
      </Modal>
  )
}

export default UploadAvatarForm
