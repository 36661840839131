import React, { useState, useEffect, useRef } from 'react'
import limsApi from '../utils/limsApi'
import { Modal, Container, Row, Col } from 'react-bootstrap'
import SelectLocationRange from './selectLocationRange'
import events from '../utils/events'

const EditStatusForm = ({items, closeModal, instanceName, reload, ...props}) => {
  const [status, setStatus] = useState(items.length > 0 ? items[0].status : null)
  const [location, setLocation] = useState(null)
  const [newPositions, setNewPositions] = useState([])
  const [isSubmitting, setIsSubmitting] = useState(false)
  const ref = useRef(null)

  const pluralize = require('pluralize')

  const statuses = [
    { id: 2, name: "Requested"},
    { id: 3, name: "Processing"},
    { id: 1, name: "Available"},
    { id: 4, name: "Archived"},
    { id: 5, name: "Cancelled"},
  ]

  const numOfLocationPositions = () => {
    let numLoc = 0
    items.map(item=>{
      if (item.isLot){
        numLoc += item.lotItems.length
      } else {
        numLoc++
      }
    })
    return numLoc
  }

  const selectLocation = (location) => {
    if(!location) {
      setLocation(location) // deselect location/set to null
    } else {
      fetchLocation(location.id)
    }
  }

  const fetchLocation = async(locationId) => {
    await limsApi.get(`locations/${locationId}`, (response)=>{
      const locationData = response.data.location
      setLocation(locationData)
    }, 'Error fetching location')
  }

  const handleSubmit = ({ target }) => {
    limsApi.patch(`edit_items_bulk`, { bulk_edit: { status: status, ids: items.map(i=>i.id), location_id: location? location.id : null, positions: newPositions }}, `${pluralize(instanceName, items.length)} Saved`, async(response)=>{
      const itemData = response.data.item
      reload()
      closeModal()
      // events.createLinkedEditEvent(`edited ${item.entry.name} ${instanceName.toLowerCase()}: #insertlink#.`, item, events.createEditedFieldsMap(preEditedItem, itemData, preEditedInputData, itemData.inputDataForDisplay))
    },
    'Error saving item', setIsSubmitting)
  }

  return (
    <Modal size="md" show={true} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Status</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className="mb-1"
          onSubmit={(event)=>{
            event.preventDefault()
            setIsSubmitting(true)
            handleSubmit(event)
          }}>
          <div className="form-row">
            <div className="form-group col">
              <label>Status</label>
              <select
                name="status"
                className="form-control"
                value={status}
                onChange={(e)=>setStatus(e.target.value)}
              >
                {statuses.map(option => (
                  <option
                    key={option.id}
                    value={option.id}
                  >
                    {option.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="form-row">
            <SelectLocationRange
                ref={ref}
                className="form-group col"
                location={location}
                setLocation={selectLocation}
                locationPositions={newPositions}
                setLocationPositions={setNewPositions}
                excludeItems={null/*item.item ? item.lotItems.map(item=>location ? item.locationId==location.id ? item.id : undefined : undefined) : undefined*/}
                numPositions={numOfLocationPositions()} />
          </div>
          <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
            { !isSubmitting ? (
              "Save"
            ) : (
              <i className="fas fa-spinner fa-spin"></i>
            )}
          </button>
        </form>
      </Modal.Body>
    </Modal>
  )
}

export default EditStatusForm
