import React, { useState, useEffect } from 'react'
import {Modal, Form, Row, Col, Button} from 'react-bootstrap'
import axios from 'axios'
import limsApi from '../utils/limsApi'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { connect } from 'react-redux'
import { SET_CURRENT_USER } from "../redux/actionTypes"
import events from '../utils/events'

// This component acts as the form for inviting a new new and upating existing ones
const UserForm = (props) => {
  const [userRoles, setUserRoles] = useState([])
  const [reassignTasks, setReassignTasks] = useState('')

  const userSchema = Yup.object({
    firstName: Yup.string()
      .required("First name is required."),
    lastName: Yup.string()
      .required("Last name is required."),
    email: Yup.string()
      .email("Invalid email.")
      .required("Email is required."),
    userRole: Yup.number()
      .required("User Role is required.")
      .test(`test-name`, `Your account must have at least one Lab Manager`,
            function(value) {
                return(props.create || value == 1 || props.user.id != props.currentUser.id ? true : canSetSelfToTech())
            }),
    isActive: Yup.boolean(),
  })

  const canSetSelfToTech = () => {
    let numManagers = 0
    props.users.map(user => {
      user.userRole.id == 1 ? numManagers++ : null
    })
    return numManagers > 1 ? true : false
  }

  const fetchUserRoles = () => {
    limsApi.get("user_roles", (response)=>{
      setUserRoles(response.data.userRoles)
    })
  }

  useEffect(()=>{
    fetchUserRoles()
  }, [])

  // Sends a post request which creates a new user with an auto generated password
  const inviteUser = (values, actions) => {
    const newUser = {first_name: values.firstName, last_name: values.lastName, email: values.email, user_role_id: values.userRole, is_active: values.isActive}
    limsApi.post('user', newUser, 'User Invited', (response)=>{
        props.reload()
        events.createLinklessEvent(`invited ${values.firstName} ${values.lastName} to join your team.`)
        props.closeModal()
      },
      'Error inviting user')
  }

  // Sends a put request which updates a user
  const updateUser = (values, actions) => {
    const updatedUser = {first_name: values.firstName, last_name: values.lastName, email: values.email, id: props.user.id, user_role_id: values.userRole, is_active: values.isActive, reassign_tasks_to: reassignTasks}
    limsApi.put('user', updatedUser, 'User Updated', (response)=>{
        if (response.data.user.id == props.currentUser.id) {
          // Current User Updated themselves
          props.setCurrentUser(response.data.user)
        }
        if(response.data.user.isActive == false) {
          props.deactivatedUser(response.data.user.id)
        }
        props.reload()
        props.closeModal()
      },
      'Error updating user')
  }


  return (
    <Formik
      validationSchema={userSchema}
      onSubmit={props.create ? inviteUser : updateUser}
      initialValues={props.user ? {...props.user, userRole: props.user.userRole.id} : {userRole: 2}} >
      {({
        status,
        setStatus,
        setFieldTouched,
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        dirty,
        errors,
        setFieldValue
      }) => (
    <Form noValidate  onSubmit={(e) => {
                e.preventDefault()
                handleSubmit()
              }}>
      <Modal show={true}>
        <Modal.Header>
          <h5 className="modal-title">{props.create ? "Invite User" : "Edit User"}</h5>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={props.closeModal}>
          <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              name="email"
              value={values.email}
              onChange={handleChange}
              onBlur={e => {
                setFieldTouched('email');
                handleBlur(e);
              }}
              isInvalid={touched["email"] || status ? errors.email : null} />
           <Form.Control.Feedback type="invalid">{touched["email"] || status ? errors.email : null}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Name</Form.Label>
              <Row>
                <Col>
                  <Form.Control
                    type="text"
                    placeholder="First Name"
                    name="firstName"
                    value={values.firstName}
                    onChange={handleChange}
                    onBlur={e => {
                      setFieldTouched('firstName');
                      handleBlur(e);
                    }}
                    isInvalid={touched["firstName"] || status ? errors.firstName : null} />
                <Form.Control.Feedback type="invalid">{touched["firstName"] || status ? errors.firstName : null}</Form.Control.Feedback>
                </Col>
                <Col>
                  <Form.Control
                    type="text"
                    placeholder="Last Name"
                    name="lastName"
                    value={values.lastName}
                    onChange={handleChange}
                    onBlur={e => {
                      setFieldTouched('lastName');
                      handleBlur(e);
                    }}
                    isInvalid={touched["lastName"] || status ? errors.lastName : null} />
                  <Form.Control.Feedback type="invalid">{touched["lastName"] || status ? errors.lastName : null}</Form.Control.Feedback>
                </Col>
              </Row>
          </Form.Group>
          <Form.Group>
            <Form.Label>
              Role
            </Form.Label>
            <Form.Control
              as="select"
              type="select"
              name="userRole"
              value={values.userRole}
              onChange={e => {
                setFieldTouched('userRole')
                handleChange(e)
              }}
              isInvalid={touched["userRole"] || status ? errors.userRole : null}>
                {userRoles && userRoles.map((role)=><option key={role.id} value={role.id}>{role.name}</option>)}
            </Form.Control>
           <Form.Control.Feedback type="invalid">{touched["userRole"] || status ? errors.userRole : null}</Form.Control.Feedback>
        </Form.Group>
        { !props.create &&
          <Form.Group>
            <Form.Check
              type="checkbox"
              label=" Is active"
              name="isActive"
              value={values.isActive}
              checked={values.isActive}
              onChange={e => {
                setFieldTouched('isActive')
                handleChange(e)
                if (e.target.value == true) { // User just deactivated User
                  let newUser = null
                  props.users.map(u=>{
                    if (newUser == null && u.isActive)
                      newUser = u.id
                  })
                  setReassignTasks(newUser)
                } else {
                  setReassignTasks('')
                }
              }}
            isInvalid={touched["isActive"] || status ? errors.isActive : null} />
           <Form.Control.Feedback type="invalid">{touched["isActive"] || status ? errors.isActive : null}</Form.Control.Feedback>
          </Form.Group>
        }
        { (!props.create && !values.isActive && touched["isActive"]) &&
          <Form.Group>
            <Form.Label>
              Reassign Tasks to
            </Form.Label>
            <Form.Control
              as="select"
              type="select"
              name="reassignTasks"
              value={reassignTasks}
              onChange={e => {
                setReassignTasks(e.target.value)
              }}>
                <option disabled key={-1} value={-1}>Select User</option>
                {props.users &&
                  props.users.map((u)=>{
                    if (u.isActive && u.id != props.user.id)
                      return(<option key={u.id} value={u.id}>{u.firstName} {u.lastName}</option>)
                    })}
            </Form.Control>
          </Form.Group>
        }
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={props.closeModal}>Close</button>
          <Button variant="primary" disabled={!dirty} type="submit" onClick={() => {setStatus(true); handleSubmit()}}>
            {props.create ? "Send" : "Update"}
          </Button>
        </Modal.Footer>
      </Modal>
    </Form>
  )}
  </Formik>
  )
}

const mapStateToProps = state => {
  return {
    currentUser: state.currentUser
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setCurrentUser: (user) => dispatch({type: SET_CURRENT_USER, currentUser: user})
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserForm)
