import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Form, ProgressBar, Button, Card, InputGroup, FormControl } from 'react-bootstrap'
import limsApi from '../../utils/limsApi'
import FileAttachmentFieldDisplay from './fileAttachmentFieldDisplay'

const FileAttachmentField = ({ field, inputData, handleChange, setFileUploading, status }) => {
  const [reload, setReload] = useState(false) //used to reload the display component
  const [uploadProgress, setUploadProgress] = useState(-1)  // -1 indicates no upload started, 0-100 indicates a percentage
  const [isLoading, setIsLoading] = useState(true)
  const [files, setFiles] = useState(null)  // set file to null if no file is uploaded
  const [fileIds, setFileIds] = useState([])
  const [uploader, setUploader] = useState(null)

  const fetchUploader = () => {
    limsApi.get(`uploaders/new`, (response)=>{
      const uploaderData = response.data.uploader
      // console.log(uploaderData)
      setUploader(uploaderData)
    },
    'Error fetching uploader', undefined, undefined)
  }

  useEffect(() => {
    fetchUploader()
  }, [])

  const fileType = field.fieldOptionsAttributes[0] ? field.fieldOptionsAttributes[0].value : null

  const fetchFile = () => {
    const token = localStorage.getItem('gf-token')
    setFiles(null)
    setFileIds(null)
    if (Array.isArray(inputData[field.id])) {
      inputData[field.id].map(attachmentId => {
        axios.get(`/api/file_attachments/${attachmentId}`, { headers: {"Authorization" : `Token ${token}`} })
          .then(response => {
            setFiles(prev => [...(prev!=null ? prev : []), response.data])
            setFileIds(prev => [...(prev!=null ? prev : []), response.data.id])
            setIsLoading(false)
          })
          .catch(error => {
            console.log(error)
            setIsLoading(false)
          })
      })

    } else {
      axios.get(`/api/file_attachments/${inputData[field.id]}`, { headers: {"Authorization" : `Token ${token}`} })
        .then(response => {
          setFiles([response.data])
          setIsLoading(false)
        })
        .catch(error => {
          console.log(error)
          setIsLoading(false)
        })
    }
  }

  const uploadFiles = async(evt) => {
    const newFiles = evt.target.files
    const newFileIds = fileIds ? [...fileIds] : []
    const fileAttachments = files ? [...files] : []

    setFileUploading(true)
    setUploadProgress(0)

    for (let i = 0; i < newFiles.length; i++) {
      const file = newFiles[i]
      const uploadedFile = await uploadFile(file, newFiles.length, i)
      // console.log(uploadedFile);
      newFileIds.push(uploadedFile.id)
      fileAttachments.push(uploadedFile)
    }

    document.getElementById("fileAttachmentInput").value = "";
    setFiles(fileAttachments)
    setFileIds(newFileIds)
    handleChange(field.id, newFileIds)
    setReload(prev => (!prev))
    setFileUploading(false)
    setUploadProgress(-1)
  }

  const uploadFile = async(file, numOfFiles, index) => {
    const token = localStorage.getItem('gf-token')
    const fd = new FormData()
    // fd.append('file_attachment[name]', file?.name || "")
    fd.append('key', uploader["key"])
    fd.append('acl', uploader["acl"])
    fd.append('policy', uploader["policy"])
    fd.append('X-Amz-Signature', uploader["x-Amz-Signature"])
    fd.append('X-Amz-Credential', uploader["x-Amz-Credential"])
    fd.append('X-Amz-Algorithm', uploader["x-Amz-Algorithm"])
    fd.append('X-Amz-Date', uploader["x-Amz-Date"])
    fd.append('success_action_redirect', uploader["successActionRedirect"])
    fd.append('file', file)

    let fileAttachment = {}
      await axios.post(uploader["directFogUrl"], fd, {onUploadProgress: (progressEvent) => options(progressEvent, numOfFiles, index)} )
        .then(response => {
          // fileAttachmentData = response.data
          fileAttachment = response.data
          // console.log(response)
        })
        .catch(error => {
          console.log(error);
        })
        return fileAttachment
  }

  const removeFiles = async() => {
    setFileUploading(true)
    setUploadProgress(0)

    for (let i = 0; i < fileIds.length; i++) {
      await removeFile(fileIds[i], fileIds.length, i)
    }

    handleChange(field.id, [])
    setReload(prev => (!prev))
    setFiles(null)
    setFileIds([])
    setUploadProgress(-1)
    setFileUploading(false)
  }

  const removeFileAtId = async(id) => {
    setFileUploading(true)
    setUploadProgress(0)

    await removeFile(id, 1)

    setFiles(prevFiles => {
      let newFiles = [...prevFiles]
      let idToDelete
      for (let i = 0; i < newFiles.length; i++) {
        if (newFiles[i].id == id)
          idToDelete = i
      }
      newFiles.splice(idToDelete, 1)
      return newFiles
    })

    let newFileIds = [...fileIds]
    for (let i = 0; i < newFileIds.length; i++) {
      if (newFileIds[i] == id) {
        newFileIds.splice(i, 1)
        break
      }
    }


    setFileIds(newFileIds)
    handleChange(field.id, newFileIds)
    setUploadProgress(-1)
    setFileUploading(false)
    setReload(prev => (!prev))
  }


  const removeFile = async (id, numOfFiles, index=0) => {
    const token = localStorage.getItem('gf-token')
    const fd = new FormData()
    fd.append('file_attachment[file]', null)
    fd.append('file_attachment[name]', "")

    await axios.patch(`/api/file_attachments/${id}`, fd, { headers: {"Authorization" : `Token ${token}`}, onUploadProgress: (progressEvent) => options(progressEvent, numOfFiles, index)} )
     .then(response => {
       // console.log("deleted: ", id);
     })
     .catch(error => {
       console.log(error);
     })
  }

  const options = (progressEvent, numOfFiles, index=0) => {
    let percentage = Math.floor( (progressEvent.loaded*100) / (progressEvent.total))
    setUploadProgress(prev => ((100*index + percentage)/numOfFiles)|0)
  }

  const isFileEmpty = () => {
    if (files != null) {
        return true
    }
    return false
  }

  useEffect(() => {
    inputData[field.id] != undefined ? fetchFile() : null
  }, [inputData[field.id]])

  const hasFile = isFileEmpty()

  let disableField = false

  if (Object.keys(field.viewOptions).length > 0) {
    if (field.viewOptions[status] == "viewable") {
      disableField = true
    }
  }

  return (
    <div>
      <label>{field.name}</label>
      <Card className="field-input-card" style={!hasFile ? {display: 'none'} : {}}>
        <Card.Body className="text-primary">
          {inputData[field.id] ? (<FileAttachmentFieldDisplay field={field} inputData={inputData} reload={reload} edit={!disableField} editFunc={(id) => removeFileAtId(id)} fileAttachments={files} />) : null}
          {/* files && files.length > 1 ? (
            <a
              href='#'
              className='mt-1 d-inline-block'
              style={{color: 'red'}}
              onClick={(e) => {e.preventDefault(); removeFiles()}}
            >
              Clear All
            </a>
          ) : null */}
        </Card.Body>
      </Card>
      <input
        disabled={disableField}
        id='fileAttachmentInput'
        type="file"
        accept={fileType}
        multiple
        className="form-control"
        name={field.id}
        onChange={uploadFiles}
        style={{height: 'auto'}}
      />
      {uploadProgress < 0 ? null :
        (<ProgressBar animated now={uploadProgress} className="mt-3" label={`${uploadProgress}%`} />)}
    </div>
  )
}

export default FileAttachmentField
