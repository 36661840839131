import React, { useState, useEffect } from 'react'
import TreeNode from './treeNode'

const TreeBranch = (props) => {
  const [children, setChildren] = useState(props.children)

  const removeNode = (id) => {
    const newChildren = children.filter((node) => {
      return node.id !== id
    })

    setChildren(newChildren)
  }

  const updateChildren = () => {
    setChildren(props.children)
  }

  useEffect(() => {
    updateChildren()
  }, [props.children])

  return (
    <ul>
      {children.map((node, i) => (
        <TreeNode
          treeName={props.treeName}
          node={node}
          key={node.id}
          removeNode={removeNode}
          actions={props.actions}
          onSelect={props.onSelect}
          selectedId={props.selectedId}
        />
      ))}
    </ul>
  )
}

export default TreeBranch
