import React, { useState, useEffect } from 'react'
import { Link, withRouter, useHistory } from 'react-router-dom'
import limsApi from '../utils/limsApi'

const ResetToNewPassword = (props) => {
  const [password, setPassword] = React.useState("")
  const [confirmPassword, setConfirmPassword] = React.useState("")
const history = useHistory();
  const putPassword = () => {
    limsApi.putHeaderless(`users/reset_forgot_password/${props.match.params.token}`, { password: password, password_confirmation: confirmPassword},
      "Password Reset", (response)=>{
        history.push('/login');
      })
  }

  return (
    <div className="login-container p-5 mx-auto">
      <h2 className="mb-3 text-center">Reset Password</h2>
      <form onSubmit={(e) => { e.preventDefault(); putPassword() }}>
        {props.loginFlashMessage &&
          <div className={`alert alert-${props.loginFlashMessage.alertClass}`}>{props.loginFlashMessage.message}</div>
        }
        <div className="form-group">
          <label htmlFor="password">New Password</label>
          <input
            className="form-control"
            type="password"
            name="password"
            onChange={e => setPassword(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password Confirmation</label>
          <input
            className="form-control"
            type="password"
            name="password_confirmation"
            onChange={e => setConfirmPassword(e.target.value)}
          />
        </div>
        <div className="actions my-4">
          <button type="submit" className="btn btn-primary btn-block" disabled={props.isLoading}>
            { !props.isLoading ? (
              "Reset Password"
            ) : (
              <i className="fas fa-spinner fa-spin"></i>
            )}
          </button>
        </div>
      </form>
      <div className="text-center">Back to <Link to="/login">login</Link></div>
    </div>
  );
}

export default ResetToNewPassword
