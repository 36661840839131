import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import limsApi from '../utils/limsApi'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  withRouter
} from 'react-router-dom'
import { Card } from 'react-bootstrap'
import ContentLoader from "react-content-loader"
import AccountSubNav from './accountSubNav'
import ItemTypesList from './itemTypesList'
import NewItemType from './newItemType'
import EditItemType from './editItemType'
import {COMPONENT_NAMES} from "../constants";
import ItemTypesActions from "./itemTypesActions";

const ManageItemTypes = ({ match }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [itemTypes, setItemTypes] = useState([])

  const fetchItemTypes = () => {
    limsApi.get('item_types', (response)=>{
      let itemTypeData = response.data.itemTypes
      setItemTypes(itemTypeData)
    },
    'Error fetching item types', undefined, setIsLoading)
  }

  useEffect(() => {
    fetchItemTypes()
  }, [])

  const addItemType = (itemType) => {
    const newItemTypes = itemTypes.concat(itemType)
    setItemTypes(newItemTypes)
  }

  const updateItemType = (itemType) => {
    const updatedItemTypes = itemTypes
    const foundIndex = updatedItemTypes.findIndex(x => x.id == itemType.id)
    updatedItemTypes[foundIndex] = itemType
    setItemTypes(updatedItemTypes)
  }

  return (
    <div>
      <div className="col-fixed bg-light">
        <AccountSubNav />
      </div>
      <div className="col-fluid p-4 bg-white">
        <Route path={`${match.path}/:id/edit`} render={(props) => (
          <EditItemType {...props} onUpdate={updateItemType} refreshItemTypes={fetchItemTypes} />
        )} />
        <Route path={`${match.path}/new`} render={(props) => (
          <NewItemType {...props} onCreate={addItemType} />
        )} />
        <Route path={`${match.path}`}>
          { itemTypes?.length > 0 &&
            <Link
              to="/manage/item-types/new"
              className="btn btn-primary float-right item-type-new-action"
            >
              Add Catalog Type
            </Link>
          }
          <h3 className="mb-3">Catalog Definitions</h3>
          {!isLoading ? (
            <>
              {itemTypes?.length > 0 ? (
                <ItemTypesList
                    treeName={COMPONENT_NAMES.MANAGE_CATALOG_TREE}
                    itemTypes={itemTypes}
                    actions={ItemTypesActions}
                />
              ) : (
                <Card className="text-center mt-3">
                  <Card.Body className="p-3">
                    <Card.Text className="py-4">
                      <span className="text-muted">No catalog types created yet.</span>
                      <br/>
                      <Link
                        to="/manage/item-types/new"
                        className="btn btn-primary btn-sm mt-2 item-type-new-action"
                      >
                        Add Catalog Type
                      </Link>
                    </Card.Text>
                  </Card.Body>
                </Card>
              )}
            </>
          ) : (
            <ContentLoader height="170" backgroundColor={'#eee'} foregroundColor={'#ccc'}>
              <rect x="3" y="7" rx="3" ry="3" width="22" height="19" />
              <rect x="35" y="7" rx="3" ry="3" width="115" height="19" />
              <rect x="3" y="48" rx="3" ry="3" width="22" height="19" />
              <rect x="35" y="48" rx="3" ry="3" width="115" height="19" />
              <rect x="3" y="89" rx="3" ry="3" width="22" height="19" />
              <rect x="35" y="89" rx="3" ry="3" width="115" height="19" />
              <rect x="3" y="130" rx="3" ry="3" width="22" height="19" />
              <rect x="35" y="130" rx="3" ry="3" width="115" height="19" />
            </ContentLoader>
          )}
        </Route>
      </div>
    </div>
  )
}

export default withRouter(ManageItemTypes)
