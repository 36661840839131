import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import limsApi from '../utils/limsApi'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  withRouter
} from 'react-router-dom'
import { Card, Modal, Tabs, Tab, ListGroup } from 'react-bootstrap'
import ContentLoader from "react-content-loader"
import AccountSubNav from './accountSubNav'
import LocationsList from './locationsList'
import NewLocation from './newLocation'
import EditLocation from './editLocation'
import NewLocationTemplate from './newLocationTemplate'
import EditLocationTemplate from './editLocationTemplate'
import LocationFillForm from './locationFillForm'
import LocationsActions from "./locationsActions"
import {COMPONENT_NAMES} from "../constants";

const ManageLocations = ({ match }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [locations, setLocations] = useState([])
  const [locationTemplates, setLocationTemplates] = useState([])
  const [tab, setTab] = useState('locations')

  const fetchLocations = () => {
    limsApi.get('locations', (response)=>{
      let locationData = response.data.locations
      setLocations(locationData)
    },
    'Error fetching locations', undefined, setIsLoading)
  }

  useEffect(() => {
    fetchLocations()
  }, [])

  const fetchLocationTemplates = () => {
    limsApi.get('location_templates', (response)=>{
      let locationTemplatesData = response.data.locationTemplates
      setLocationTemplates(locationTemplatesData)
    },
    'Error fetching location templates', undefined, setIsLoading)
  }

  useEffect(() => {
    fetchLocationTemplates()
  }, [])

  const addLocation = (location) => {
    const newLocations = locations.concat(location)
    setLocations(newLocations)
  }

  const updateLocation = (location) => {
    const updatedLocations = locations
    const foundIndex = updatedLocations.findIndex(x => x.id == location.id)
    updatedLocations[foundIndex] = location
    setLocations(updatedLocations)
  }

  return (
    <div>
      <div className="col-fixed bg-light">
        <AccountSubNav />
      </div>
      <div className="col-fluid p-4 bg-white">
        <Switch>
          <Route path={`${match.path}/templates/:id/edit`} render={(props) => (
            <EditLocationTemplate {...props} onUpdate={updateLocation} refreshLocationTemplates={fetchLocationTemplates} />
          )} />
          <Route path={`${match.path}/templates/new`} render={(props) => (
            <NewLocationTemplate {...props} onCreate={fetchLocationTemplates} />
          )} />
          <Route path={`${match.path}/:id/fill`} render={(props) => (
            <LocationFillForm {...props} refreshLocations={fetchLocations} locationTemplates={locationTemplates} />
          )} />
          <Route path={`${match.path}/:id/edit`} render={(props) => (
            <EditLocation {...props} onUpdate={updateLocation} refreshLocations={fetchLocations} />
          )} />
          <Route path={`${match.path}/new`} render={(props) => (
            <NewLocation {...props} onCreate={addLocation} />
          )} />
        </Switch>
        <Route path={`${match.path}`}>
          <>
            { (tab == 'locations' && locations.length > 0) &&
              <Link
                to="/manage/locations/new"
                className="btn btn-primary float-right location-new-action"
              >
                Add Location
              </Link>
            }
            { (tab == 'templates' && locationTemplates.length > 0) &&
              <Link
                to="/manage/locations/templates/new"
                className="btn btn-primary float-right location-template-new-action"
              >
                Add Location Template
              </Link>
            }
          </>
          <h3 className="mb-2">Manage Locations</h3>
          <Tabs variant="pills" className="mb-3" activeKey={tab} onSelect={(k) => { setTab(k) }}>
            <Tab eventKey="locations" title={`Locations`}>
              {!isLoading ? (
                <>
                  {locations?.length > 0 ? (
                    <LocationsList
                        treeName={COMPONENT_NAMES.MANAGE_LOCATION_TREE}
                        locations={locations}
                        actions={LocationsActions}
                    />
                  ) : (
                    <Card className="text-center mt-3">
                      <Card.Body className="p-3">
                        <Card.Text className="py-4">
                          <span className="text-muted">No locations created yet.</span>
                          <br/>
                          <Link
                            to="/manage/locations/new"
                            className="btn btn-primary btn-sm mt-2 location-new-action"
                          >
                            Add Location
                          </Link>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  )}
                </>
              ) : (
                <ContentLoader height="170" backgroundColor={'#eee'} foregroundColor={'#ccc'}>
                  <rect x="3" y="7" rx="3" ry="3" width="22" height="19" />
                  <rect x="35" y="7" rx="3" ry="3" width="125" height="19" />
                  <rect x="3" y="48" rx="3" ry="3" width="22" height="19" />
                  <rect x="35" y="48" rx="3" ry="3" width="125" height="19" />
                  <rect x="3" y="89" rx="3" ry="3" width="22" height="19" />
                  <rect x="35" y="89" rx="3" ry="3" width="125" height="19" />
                  <rect x="3" y="130" rx="3" ry="3" width="22" height="19" />
                  <rect x="35" y="130" rx="3" ry="3" width="125" height="19" />
                </ContentLoader>
              )}
            </Tab>
            <Tab eventKey="templates" title={`Location Templates`}>
              {locationTemplates?.length > 0 ? (
                <ListGroup variant="flush" style={{position: 'relative', top: '-0.25rem'}}>
                  {locationTemplates.map(template => (
                    <ListGroup.Item key={template.id}>
                      <Link to={`/manage/locations/templates/${template.id}/edit`}>{template.name}</Link>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              ) : (
                <Card className="text-center mt-3">
                  <Card.Body className="p-3">
                    <Card.Text className="py-4">
                      <span className="text-muted">No templates created yet.</span>
                      <br/>
                      <Link
                        to="/manage/locations/templates/new"
                        className="btn btn-primary btn-sm mt-2 location-template-new-action"
                      >
                        Add Location Template
                      </Link>
                    </Card.Text>
                  </Card.Body>
                </Card>
              )}
            </Tab>
          </Tabs>
        </Route>
      </div>
    </div>
  )
}

export default withRouter(ManageLocations)
