import React, { useState, useEffect } from 'react'
import { Button, Form, Col, Row, Image } from 'react-bootstrap'
import { Formik } from 'formik'
import * as Yup from 'yup'
import limsApi from '../utils/limsApi'
import { connect } from 'react-redux'
import { SET_CURRENT_USER } from "../redux/actionTypes"
import UserSubNav from './userSubNav'
import UploadAvatarForm from './uploadAvatarForm'
import ChangePasswordForm from './changePasswordForm'

const ManageProfile = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [profile, setProfile] = useState({})
  const [showUploadAvatarForm, setShowUploadAvatarForm] = useState(false)


  const profileSchema = Yup.object({
    firstName: Yup.string()
      .required("First name is required."),
    lastName: Yup.string()
      .required("Last name is required."),
    email: Yup.string()
      .email("Invalid email.")
      .required("Email is required.")
  })

  const passSchema = Yup.object({
    currentPass: Yup.string()
      .required("Current password is required."),
    newPass: Yup.string()
      .required("New password is required.")
      .min(8, 'Must be at least 8 characters.')
      .test('passwords-match', 'Must be different than your current password.', function(value) {
        return this.parent.currentPass != value;
      }),
    confirmPass: Yup.string()
      .required("Confirmation password is required.")
      .test('passwords-match', 'Passwords must match.', function(value) {
        return this.parent.newPass === value;
      })
      .test('passwords-match', 'Must be different than your current password.', function(value) {
        return this.parent.currentPass != value;
      })
      .min(8, 'Must be at least 8 characters.')
  })

  // Performs a get request which returns the current user's profile
  const fetchProfile = () => {
    limsApi.get('user', (response)=>{
        setProfile(response.data.user)
      },
      'Error fetching profile', undefined, setIsLoading)
  }

  // Performs a put request that updates the current user's profile
  const saveProfile = (values, actions) => {
    // const token = localStorage.getItem('gf-token')
    const newProfile = {first_name: values.firstName, last_name: values.lastName, email: values.email, id: profile.id}

    limsApi.put('user', newProfile, 'Profile Saved', (response)=>{
        actions.resetForm({values: {firstName: values.firstName, lastName: values.lastName, email: values.email}})
      },
      'Error saving profile')
  }

  // Performs a post request that authenticates the user's current password
  const resetPassword = (values, actions) => {
    limsApi.post('users/login', { user: { email: profile.email, password: values.currentPass } }, '', (response)=>{
      const newAuth = { password: values.newPass, password_confirmation: values.confirmPass}

      limsApi.put('users/reset_password', newAuth, 'Password Changed', (response)=>{
          actions.resetForm({values: {currentPass: "", newPass: "", confirmPass: ""}})
        },
        '', (error)=>{
          actions.setFieldError('confirmPass', "Password " + error.response.data.errors["password"])
          actions.setFieldError('newPass', "Password " + error.response.data.errors["password"])
        })
    },
    '', (error)=>{
      actions.setFieldError('currentPass', 'Password ' + error.response.data.errors['email or password'])
    })
  }

  useEffect(() => {
    fetchProfile()
  }, [])

  return (
    <div>
      <div className="col-fixed bg-light">
        <UserSubNav />
      </div>
      <div className="col-fluid p-4 bg-white">
        <div className="float-right">
          <a href="#" className="mr-2" onClick={(e) => { e.preventDefault(); setShowUploadAvatarForm(true) }}>Change avatar</a>
          <Image width="60" height="60" src={profile.avatarUrl} roundedCircle onClick={(e) => { e.preventDefault(); setShowUploadAvatarForm(true) }} />
        </div>
        <h3 className="mb-3">My Profile</h3>
        {!isLoading ?
          (<>
            <Formik
              validationSchema={profileSchema}
              onSubmit={saveProfile}
              initialValues={profile}
            >
              {({
                status,
                setStatus,
                setFieldTouched,
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                dirty,
                errors,
              }) => (
            <Form noValidate  onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit();
                  }}>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                name="email"
                value={values.email}
                onChange={handleChange}
                onBlur={e => {
                  setFieldTouched('email');
                  handleBlur(e);
                }}
                isInvalid={touched["email"] || status ? errors.email : null} />
             <Form.Control.Feedback type="invalid">{touched["email"] || status ? errors.email : null}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>Name</Form.Label>
                <Row>
                  <Col>
                    <Form.Control
                      type="text"
                      placeholder="First Name"
                      name="firstName"
                      value={values.firstName}
                      onChange={handleChange}
                      onBlur={e => {
                        setFieldTouched('firstName');
                        handleBlur(e);
                      }}
                      isInvalid={touched["firstName"] || status ? errors.firstName : null} />
                  <Form.Control.Feedback type="invalid">{touched["firstName"] || status ? errors.firstName : null}</Form.Control.Feedback>
                  </Col>
                  <Col>
                    <Form.Control
                      type="text"
                      placeholder="Last Name"
                      name="lastName"
                      value={values.lastName}
                      onChange={handleChange}
                      onBlur={e => {
                        setFieldTouched('lastName');
                        handleBlur(e);
                      }}
                      isInvalid={touched["lastName"] || status ? errors.lastName : null} />
                    <Form.Control.Feedback type="invalid">{touched["lastName"] || status ? errors.lastName : null}</Form.Control.Feedback>
                  </Col>
                </Row>
            </Form.Group>
            <Button variant="primary mb-4" disabled={!dirty} type="submit" onClick={() => setStatus(true)}>
              Save
            </Button>
            </Form>
          )}
          </Formik>
          <ChangePasswordForm />
        {showUploadAvatarForm ? (
          <UploadAvatarForm closeModal={() => setShowUploadAvatarForm(false)} profile={profile} reload={fetchProfile} />
        ) : null}
        </>)
          : null}
      </div>
    </div>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    setUser: (user) => dispatch({type: SET_CURRENT_USER, currentUser: user})
  }
}

export default connect(null, mapDispatchToProps)(ManageProfile)
