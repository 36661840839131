import NewForm from "./newForm";
import React, {useEffect, useState} from "react";
import {GSelectInput, GTextInput, GTextArea} from "./lib/gforms";
import {limsGet, limsUpdate, limsDelete} from "../utils/limsApi";
import {fullname} from "../utils/userFns";
import GDialog from "./dialogs/gDialog";
import {Modal, Table, Container, Row, Col, DropdownButton, Dropdown} from "react-bootstrap";
import {formatDate} from "../utils/formatFns";
import moment from 'moment'
import {Link, Route, Switch, withRouter} from "react-router-dom";
import {generateOnChange} from "../utils/defaultHandlers";
import ReactHtmlParser from 'react-html-parser'
const {TASK_STATUS} = require('../shared-constants.yaml')

export const NewTask = ({entry, ...props}) => {
    return (
        <NewForm {...props} title={'New Request'} modelName={'tasks'} create newQueryString={`entry_id=${entry.id}&item_type_id=${entry.itemTypeId}`} submitButtonText="Request">
            <div className={'mb-4'}>
                <h5>{entry.name}</h5>
                <div>{ReactHtmlParser(entry.description)}</div>
            </div>
            {/*<GTextArea label={'Request Notes'} name={'description'}/>*/}
        </NewForm>
    )
}

export const EditTask = ({task_id, onUpdate, onDelete, ...props}) => {
    const [task, setTask] = useState()
    const [users, setUsers] = useState([])
    useEffect(()=>{
        limsGet(`tasks/${task_id}`, (data)=>{setTask(data.task)})
        limsGet(`users`, (data)=>setUsers(data.users))
    },[])

    function onDeleteOurs() {
        onDelete?.()
    }

    const updateStatus = (newStatus) => {
        setTask({...task, status: newStatus})
        limsUpdate('tasks', {...task, status: newStatus}, () => {
          window.flash('Task Declined')
          onUpdate?.()
        })
    }

    function handleTaskDecline(model) {
      updateStatus("Cancelled")
      props.history.push('/tasks')
      document.body.click()
    }

    function onUpdateOurs() {
        window.flash('Task saved')
        onUpdate?.()
    }

    function onSubmitFn(event) {
      if (task.assignedTo)
        limsUpdate('tasks', {...task, assignedToId: task.assignedTo.id}, onUpdateOurs)
      else
        limsUpdate('tasks', {...task}, onUpdateOurs)
    }

    return (
        <GDialog {...props} title="Edit Task" subtitle={`Requested ${formatDate(task?.requestedOn)}`}
                 model={task}
                 onChange={generateOnChange(setTask)}
                 onSubmit={onSubmitFn}
                 deletable={true}
                 deleteBtnName="Decline"
                 shouldConfirmDelete={false}
                 onDelete={handleTaskDecline}
        >
            <GTextInput label={'Name'} name={'name'}/>
            <GSelectInput label={'Assigned To'} name={'assignedTo'} options={users} render={fullname}/>
            <GSelectInput label={'Status'} name={'status'} options={Object.values(TASK_STATUS)}/>
        </GDialog>
    )
}

export const Task = ({task_id, returnPath, onUpdate, onDelete, ...props}) => {
    const [task, setTask] = useState({
      name: ""
    })
    useEffect(()=>{
        limsGet(`tasks/${task_id}`, (data)=>{setTask(data.task)})
    }, [task_id])

    const updateStatus = (newStatus) => {
        setTask({...task, status: newStatus})
        limsUpdate('tasks', {...task, status: newStatus}, () => {
          window.flash('Status updated')
          onUpdate?.()
        })
    }

    return (
                <GDialog {...props} title={`Task: ${task?.name}`} subtitle={
                  <span style={{fontSize: '1rem'}}>Requested {(task) ? moment(task.requestedOn).fromNow() : ""}</span>
                }
                         model={task}
                         modelName={'task'}
                         returnPath={returnPath}
                >
                  <Container fluid={true} style={{ paddingLeft: 0, paddingRight: 0 }}>
                    <Row noGutters={true}>
                      <Col xs={9}>
                        <Table bordered>
                            <tbody>
                            <tr>
                                <td style={{width: "25%"}}>Catalog Item</td>
                                <td>
                                  <Link to={`/catalog/entries/${task?.item?.entryId}/items/${task?.item?.id}`}>
                                    {task?.item?.code}
                                  </Link>
                                </td>
                            </tr>
                            <tr>
                                <td>Status</td>
                                <td>{task?.status}</td>
                            </tr>
                            <tr>
                                <td>Assigned To</td>
                                <td>{fullname(task?.assignedTo)}</td>
                            </tr>
                            <tr>
                                <td>Requested By</td>
                                <td>{fullname(task?.requestedBy)}</td>
                            </tr>
                            <tr>
                                <td>Requested On</td>
                                <td>{formatDate(task?.requestedOn)}</td>
                            </tr>
                            </tbody>
                        </Table>
                      </Col>
                      <Col>
                        <div className="pl-3 mb-4">
                          <DropdownButton
                            variant='primary'
                            title={
                              <span className="pr-1">{task?.status}</span>
                            }
                            className="btn-group btn-block"
                          >
                            <Dropdown.Item className="py-2" onClick={() => { updateStatus("Backlog") }}>Backlog</Dropdown.Item>

                            <Dropdown.Item className="py-2" onClick={() => { updateStatus("Todo") }}>Todo</Dropdown.Item>

                            <Dropdown.Item className="py-2" onClick={() => { updateStatus("In Progress") }}>In Progress</Dropdown.Item>

                            <Dropdown.Item className="py-2" onClick={() => { updateStatus("Done") }}>Done</Dropdown.Item>

                            <Dropdown.Item className="py-2" onClick={() => { updateStatus("Cancelled") }}>Cancelled</Dropdown.Item>
                          </DropdownButton>
                          <Link to={`/tasks/${task_id}/edit`} className={'btn btn-light btn-block'}>Edit</Link>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </GDialog>
    )
}
