import React from 'react'
import { Button } from 'react-bootstrap'

const HubspotAfterSignup = (props) => (
  <div className="text-center signup-container p-5 mx-auto">
    <h3 className="mb-3 mt-3 text-center">Thank you for signing up</h3>
    <p>
      Click go to app to get started.
    </p>
    <Button className="mt-1 mb-3" variant="primary" type="button" target="_blank" href="https://app.genofab.com">
      Go to App
    </Button>
  </div>
)

export default HubspotAfterSignup
