import React, { useState, useEffect } from 'react'
import {Modal, Form, Row, Col} from 'react-bootstrap'
import axios from 'axios'
import limsApi from '../utils/limsApi'
import LabelPreview from './labelPreview'

const LabelForm = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [printers, setPrinters] = useState([])
  const [account, setAccount] = useState({})
  const [labelTemplates, setLabelTemplates] = useState([])
  const [zpl, setZpl] = useState("")

  const [printJob, setPrintJob] = useState({
    labelTemplate: {name: "dummy name", height: 0.5, width: 1, id: 1, numOfLines: 3},
    printer: props.printer ? props.printer : {name: "dummy name", dpi: 300, id: 1},
    title: props.title ? props.title : "",
    lines: props.lines ? props.lines : labelsFor == "items" ? [objects[0].entry.itemType.name] : [],
    barcodeData: props.barcodeData ? props.barcodeData : "",
    qty: 1
  })

  const handleChange = (attr, value) => {
    setPrintJob(prev=> {
      let newPrintJob = {...prev, [attr]: value}

      if (attr == "printer") {
        const printersTemplate = labelTemplates.find(t=> t.id == newPrintJob.printer.lastLabelTemplateUsed)
        newPrintJob = printersTemplate ? {...newPrintJob, labelTemplate: printersTemplate} : newPrintJob
      }

      return newPrintJob
    })
  }

  const fetchAccount = (printers) => {
    limsApi.get('accounts/me', ((response) => {
        const accountData = response.data.account
        setAccount(accountData)
        fetchPrinters(accountData)
      }),
      'Error fetching account')
  }

  // Performs a get request to list all the printers
  const fetchPrinters = (account) => {
    limsApi.get('printers', (response)=>{
        let printerData = response.data.printers
        setPrinters(printerData)
        fetchLabelTemplates(printerData.length == 0, printerData, account)
      },
      'Error fetching printers')
  }

  const fetchLabelTemplates = (showAll, printers, account) => {
    limsApi.get('label_templates', (response)=>{
      const labelTemplatesData = response.data.labelTemplates

      let templates = labelTemplatesData
      if (!showAll) {
        templates = labelTemplatesData.map(t=>{
          if (t.isEnabled)
            return t
        })
      }
      templates = templates.filter(Boolean)
      setLabelTemplates(templates)

      let selectedPrinter = printJob.printer.name == 'dummy name' ? printers[0] : printJob.printer
      if (account.lastPrinterUsed && printers.length > 0 && printJob.printer.name == 'dummy name') {
        let tempPrinter = printers.find(p=>p.id==account.lastPrinterUsed)
        selectedPrinter = tempPrinter ? tempPrinter : selectedPrinter
      }
      handleChange("printer", selectedPrinter)
      let selectedTemplate = templates[0]
      if (selectedPrinter.lastLabelTemplateUsed) {
        let tempTemplate = templates.find(t=>t.id==selectedPrinter.lastLabelTemplateUsed)
        selectedTemplate = tempTemplate ? tempTemplate : selectedTemplate
      }
      handleChange("labelTemplate", selectedTemplate)
    },
    'Error fetching label templates', undefined, setIsLoading)
  }

  useEffect(() => {
    fetchAccount()
  }, [])

  // sends post request to PrintNode with label zpl
  const printLabel = () => {
      const encodedZpl = Buffer.from(zpl.repeat(printJob.qty)).toString('base64')
      const job = {
        printerId: printJob.printer.printNodeId,
        title: "GenoFAB Label",
        contentType: "raw_base64",
        content: encodedZpl,
        source: "Sample Label Print in Manage Printers"
      }
      limsApi.get('accounts/me', (response)=>{
          const apiKey = response.data.account.printNodeApiKey
          axios.post('https://api.printnode.com/printjobs', job,
          {auth: { username: apiKey}})
          .then(response => {
            updateLastUsedSettings()
            props.closeModal()
            window.flash('Printing label...')
          })
          .catch(error => {
            console.log(error)
            window.flash(error.message, 'error')
          })
        },
        'Error fetching account')
  }

  const updateLastUsedSettings = (account) => {
    limsApi.patch(`printers/${printJob.printer.id}`, {lastLabelTemplateUsed: printJob.labelTemplate.id}, "", (response)=>{
      props.reload()
    })
    limsApi.patch(`accounts/me`, {lastPrinterUsed: printJob.printer.id})
  }

  const getZpl = () => {
    let lineData = [printJob.title, ...printJob.lines]
    lineData = lineData.map(value=>value == undefined ? "" : value)
    const newLabelData = {lines: lineData,
                          barcode_text: printJob.barcodeData,
                          template_id: printJob.labelTemplate? printJob.labelTemplate.id : 1,
                          dpi: printJob.printer.dpi,
                          from: ""}

    limsApi.post('get_zpl', {labels: [newLabelData]}, '', (response)=>{
      const zpl = response.data.response

      if (newLabelData.template_id == printJob.labelTemplate.id)
        setZpl(zpl)
    },
    '')
  }

  useEffect(()=>{
    if (!isLoading && printJob.labelTemplate != null && printJob.printer != null)
      getZpl()
  }, [printJob, isLoading])

  const handleSubmit = (event) => {
    event.preventDefault();
    printLabel()
  };

  const setLine = (i, str) => {
    let newLines = [...printJob.lines]
    newLines[i] = str
    handleChange("lines", newLines)
  }

  let printersJSX = <option>Loading...</option>
  if(printers.length > 0) {
    printersJSX = printers.map((printer) => (
      <option key={printer.id} value={printer.id}>{printer.name}</option>
    ));
  }

  let labelTemplatesJSX = <option>Loading...</option>
  if(labelTemplates.length > 0) {
    labelTemplatesJSX = labelTemplates.map((template) => {
      if (template.isEnabled)
        return (<option key={template.id} value={template.id}>{template.name}</option>)
    });
  }

  let labelLinesFormInputs = []
  if(printJob.labelTemplate != null) {
    for (let i = 0; i < printJob.labelTemplate.numOfLines; i++) {
        if (i == 0) {
          labelLinesFormInputs[i] = (
          <Form.Group key={i}>
            <Form.Label>Title</Form.Label>
            <Form.Control type="text" placeholder="enter line text" value={printJob.title.length > 20 ? printJob.title.substring(0,20) : printJob.title} onChange={() => handleChange("title", event.target.value)} required maxLength={20} />
            <Form.Control.Feedback type="invalid">Please enter a title.</Form.Control.Feedback>
          </Form.Group>)
        } else {
          labelLinesFormInputs[i] =(
        <Form.Group key={i}>
          <Form.Label>{`Line ${i+1}`}</Form.Label>
          <Form.Control type="text" placeholder="enter line text" value={printJob.lines[i-1]} onChange={() => setLine(i-1, event.target.value)} />
        </Form.Group>)
      }
    }
  }

  return (
    <Modal show={true} size="lg">
      <Modal.Header>
        <h5 className="modal-title">Print Label</h5>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={props.closeModal}>
        <span aria-hidden="true">&times;</span>
        </button>
      </Modal.Header>
      {!isLoading && printJob.printer && printJob.labelTemplate ? (
        <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Row className="align-items-center">
          <Col>
          <Form.Group controlId="exampleForm.ControlSelect1">
            <Form.Label>Select Label Template</Form.Label>
            <Form.Control as="select" value={printJob.labelTemplate ? printJob.labelTemplate.id : 0} onChange={() => handleChange("labelTemplate", labelTemplates.find( ({ id }) => id == event.target.value ))}>
              {labelTemplatesJSX}
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlSelect2">
            <Form.Label>Select Printer</Form.Label>
            <Form.Control as="select" value={printJob.printer ? printJob.printer.id : 0} onChange={() => printers.map(p => p.id == event.target.value ? handleChange("printer", p) : null )}>
              {printersJSX}
            </Form.Control>
          </Form.Group>
          {labelLinesFormInputs}
          <Form.Group controlId="exampleForm.ControlTextarea1">
            <Form.Label>Barcode Data</Form.Label>
            <Form.Control as="textarea" rows={2} value={printJob.barcodeData} onChange={() => handleChange("barcodeData", event.target.value)} />
          </Form.Group>
          <Form.Group>
            <Form.Label>Copies</Form.Label>
            <Form.Control type="number" step="1" min="1" max="100" value={printJob.qty} onChange={()=>handleChange("qty", parseInt(event.target.value))} />
          </Form.Group>
          </Col>
          <Col className="text-center">
            <LabelPreview zpl={zpl} labelTemplate={printJob.labelTemplate} dpi={printJob.printer.dpi} numOfLabels={1} />
          </Col>
            </Row>
            </Modal.Body>
            <Modal.Footer>
              <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={props.closeModal}>Close</button>
              <button type="submit" className="btn btn-primary">Print</button>
            </Modal.Footer>
          </Form>
      ) : (
        <>
          <Modal.Body>
            <p>{isLoading ? "Loading..." : ("Go enable some label templates in the Label Templates tab.")}</p>
          </Modal.Body>
          <Modal.Footer>
            <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={props.closeModal}>Close</button>
          </Modal.Footer>
        </>
      )}
    </Modal>
  )
}

export default LabelForm
