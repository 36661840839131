import React, { useState, useEffect, useRef } from 'react'
import axios from "axios"
import limsApi from '../utils/limsApi'
import { connect } from 'react-redux'
import { Switch, withRouter, Link, Route } from 'react-router-dom'
import { Modal, Card, Tabs, Tab, DropdownButton, Dropdown, Button, Table, OverlayTrigger, Tooltip } from 'react-bootstrap'
import ContentLoader from 'react-content-loader'
import Item from './item'
import NewItem from './newItem'
import EditItem from './editItem'
import ItemCard from './itemCard'
import ObjectsPrintForm from './objectsPrintForm'
import EditStatusForm from './editStatusForm'
import EditEntry from './editEntry'
import {NewTask} from "./taskForms"
import { FieldDisplay } from './fields/fieldUtils'
import ReactHtmlParser from 'react-html-parser'
import BootstrapTable from 'react-bootstrap-table-next'
import moment from 'moment'
import ToolkitProvider from 'react-bootstrap-table2-toolkit'
import NetworkViewer from './networkViewer'
import filterFactory, { textFilter, dateFilter, selectFilter, multiSelectFilter } from 'react-bootstrap-table2-filter'

const Entry = ({currentUser, ...props}) => {
  const pluralize = require('pluralize')
  const [isLoading, setIsLoading] = useState(true)
  const [itemType, setItemType] = useState({
    name: "",
    description: "",
    instanceName: "",
    parentId: null,
    fieldsAttributes: []
  })
  const [entry, setEntry] = useState({
    name: "",
    description: "",
    instanceName: ""
  })
  const [itemsAreLoading, setItemsAreLoading] = useState(true)
  const [items, setItems] = useState([])
  const [filteredItems, setFilteredItems] = useState(null)
  const [selectedItems, setSelectedItems] = useState([])
  const [tab, setTab] = useState('available')
  const [showPrintModal, setShowPrintModal] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)
  const [showTaskCreateConfirmation, setShowTaskCreateConfirmation] = useState(false)
  const [showInsufficientTasks, setShowInsufficientTasks] = useState(false)
  const [account, setAccount] = useState(null)
  const [isExporting, setIsExporting] = useState(false)
  const [showItemsGraph, setShowItemsGraph] = useState(false)

  const tableRef = useRef()

  const statuses = [
    { id: 2, name: "Requested"},
    { id: 3, name: "Processing"},
    { id: 1, name: "Available"},
    { id: 4, name: "Archived"},
    { id: 5, name: "Cancelled"},
  ]

  const GlobalItemActions = ({className, itemType, currentUser, ...props}) => {
    if(!account) return null
    const classNameSafe = className != null ? className : '';

    const linkClicked = () => {
      account.availableTasks > 0 ? null : setShowInsufficientTasks(true)
    }

    const fetchExport = () => {
      setIsExporting(true)
      let url = `${window.location.origin}/api/export_entry/${entry.id}.csv`
      axios.request({
          url: url,
          method: "GET",
          responseType: 'blob', //important
        })
        .then(({ data }) => {
          let file = window.URL.createObjectURL(new Blob([data]))
          const link = document.createElement('a')
          link.href = file
          link.setAttribute('download', `${entry.name}_export.csv`)
          document.body.appendChild(link)
          link.click()
          link.remove()
          setIsExporting(false)
        });
    }

    const tooltipText = currentUser.userRole.id == 1 ? "Add" : "Request"
    let link = `/catalog/entries/${entry.id}/items/create/new`
    link = account && account.availableTasks && account.availableTasks > 0 ? link : "#"

    return (
      <>
        <Link to={link} className={`${classNameSafe} btn btn-primary btn-sm plus-btn`} onClick={linkClicked}>
          <i className="fas fa-plus" style={{marginRight: '.4rem'}}></i>{tooltipText}
        </Link>
        <button className="btn btn-info btn-sm ml-2" onClick={fetchExport}>
          { !isExporting ?
              <><i className="fas fa-file-export" style={{marginRight: '.4rem'}}></i>Export</>
            :
              <i className="fas fa-spinner fa-spin"></i>
          }
        </button>
        <button className="btn btn-success btn-sm ml-2" onClick={()=>setShowItemsGraph(true)}>
              <><i className="fas fa-project-diagram" style={{marginRight: '.4rem'}}></i>Network</>
        </button>
      </>
    )
  }

  const CustomToggleList = ({
    columns,
    onColumnToggle,
    toggles
  }) => (
    <div className="btn-group btn-group-toggle btn-group-horizontal" data-toggle="buttons">
      {
        columns
          .map(column => ({
            ...column,
            toggle: toggles[column.dataField]
          }))
          .map(column => (
            <div className="custom-control custom-switch mr-3 mb-2" key={column.dataField} onClick={()=>onColumnToggle(column.dataField)}>
              <input
                type="checkbox"
                className="custom-control-input"
                id={column.dataField}
                checked={column.toggle}
                onChange={()=> {/* This does nothing*/}} />
              <label className="custom-control-label" style={{whiteSpace: "pre"}} htmlFor={column.dataField}>{column.text}</label>
            </div>
          ))
      }
    </div>
  )

  const fetchEntry = async () => {
    const entryId = props.entryId != undefined ? props.entryId : props.match.params.entryId

    await limsApi.get(`entries/${entryId}`, (response)=>{
      const entryData = response.data.entry
      setEntry(entryData)
      fetchItemType(entryData.itemTypeId)
    },
    'Error fetching entry', undefined)
  }

  const fetchAccount = async () => {
    await limsApi.get('accounts/me', (response)=>{
      setAccount(response.data.account)
    })
  }

  useEffect(() => {
    fetchEntry()
  }, [props.match.params.entryId])

  useEffect(()=>{
    fetchAccount()
  }, [])

  const purchaseLinkClicked = () => {
    props.history.push("/manage/account/?showPurchase=true")
  }

  const fetchItemType = async (itemTypeId) => {
    await limsApi.get(`item_types/${itemTypeId}`, (response)=>{
      const itemTypeData = response.data.itemType
      setItemType(itemTypeData)
    },
    'Error fetching item type', undefined, setIsLoading)
  }

  const fetchItems = () => {
    const entryId = props.entryId != undefined ? props.entryId : props.match.params.entryId

    limsApi.get(`items?entry_id=${entryId}`, (response)=>{
      let itemsData = response.data.items
      setItems(itemsData)

      let updatedFilteredItems = {
        "available": [],
        "processing": [],
        "requested": [],
        "archived": [],
        "cancelled": [],
        "no status": []
      }

      itemsData.forEach((item) => {
        switch (item.status) {
          case '1':
            updatedFilteredItems["available"].push(item)
            break
          case '2':
            updatedFilteredItems["requested"].push(item)
            break
          case '3':
            updatedFilteredItems["processing"].push(item)
            break
          case '4':
            updatedFilteredItems["archived"].push(item)
            break
          case '5':
            updatedFilteredItems["cancelled"].push(item)
            break
          default:
            updatedFilteredItems["no status"].push(item)
        }
      })
      setFilteredItems(updatedFilteredItems)

      // Smart select tab so that if no items are available the another tab is selected
      if (updatedFilteredItems["available"].length == 0 && itemsData.length > 0) {
        let keyWithMostItems = "available"
        let keys = Object.keys(updatedFilteredItems)
        for (const key of keys) {
          if (updatedFilteredItems[key].length > 0) {
            keyWithMostItems = key
            break
          }
        }
        setTab(keyWithMostItems)
      }
      // setItemsAreLoading(false)
    },
    'Error fetching item types', undefined, setItemsAreLoading)
  }

  const selectItem = (item) => {
    setSelectedItems(prev => {
      let newSelectedItems = prev ? [...prev] : []
      const index = newSelectedItems.indexOf(item)
      if (index == -1) {
        newSelectedItems.push(item)
      }
      return newSelectedItems
    })
  }

  const deselectItem = (item) => {
    setSelectedItems(prev => {
      let newSelectedItems = prev ? [...prev] : []
      const index = newSelectedItems.indexOf(item)
      if (index > -1) {
        newSelectedItems.splice(index, 1);
      }
      return newSelectedItems
    })
  }

  const selectAllBtns = (tabName) => {
    if(filteredItems[tabName].length)
    {
      return (
        <div className=" d-flex flex-row" style={{paddingTop: '0.4rem'}}>
          {/* selectedItems.length == 0 &&
            <div className="mr-2">
              <Button size="md" variant={`outline-secondary`} className="mb-2" onClick={() => filteredItems[tabName].map(item => selectItem(item))}>Select All</Button>
            </div>
          */}
          { selectedItems.length ? (
            <div className="mr-2">
              <DropdownButton
                variant='primary'
                title={`Selected (${selectedItems.length})`}
                className="mb-2 btn-group"
                size="md"
              >
                <Dropdown.Item onClick={() => setShowEditModal(true)}>Edit {pluralize(entry.itemType?.instanceName ? entry.itemType?.instanceName : "Item", selectedItems.length)}</Dropdown.Item>
                <Dropdown.Item onClick={() => setShowPrintModal(true)}>Print Labels</Dropdown.Item>
                {/*
                <Dropdown.Divider />
                <Dropdown.Item onClick={() => filteredItems[tabName].map(item => selectItem(item))}>Select All</Dropdown.Item>
                <Dropdown.Item className="text-danger btn-outline-danger" onClick={() => filteredItems[tabName].map(item => deselectItem(item))}>Deselect All</Dropdown.Item>
                */}
              </DropdownButton>
            </div>
          ) : (
            ""
          )}
        </div>
      )
    } else {
      return ""
    }
  }

  useEffect(() => {
    fetchItems()
  }, [props.match.params.entryId])

  const refreshItems = (item) => {
    fetchItems()
  }

  const onTaskCreateSuccess = () => {
    setShowTaskCreateConfirmation(true)
    refreshItems()
    setTab('requested')
  }

  const loadingItem = id => (
    <Card key={id} className="mb-2">
      <Card.Body className="p-3">
        <div className="container" style={{ maxWidth: 'none', paddingLeft: 0, paddingRight: 0 }}>
          <div className="row">
            <div className="col-2" style={{minWidth: "90px"}}>
              <ContentLoader height="20" width="100" backgroundColor={'#eee'} foregroundColor={'#ccc'}>
                <rect x="0" y="0" rx="3" ry="3" width="90" height="20" />
              </ContentLoader>
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  )

  const renderedItems = (
    (items.length && filteredItems != null) ? (
      filteredItems[tab].map((item, index) => (
        <div key={item.id} className="items-list mt-2">
        <ItemCard
          key={item.id}
          item={item}
          selectItem={() => selectItem(item)}
          deselectItem={() => deselectItem(item)}
          isSelected={selectedItems.indexOf(item) < 0 ? false : true}
        />
        </div>
      ))
    ) : (
      <Card className="text-center mt-3">
        <Card.Body className="p-3">
          <Card.Text className="py-4">
            <span className="text-muted">No {entry.itemType?.instanceName ? pluralize(entry.itemType?.instanceName?.toLowerCase(), 2) : "items"} at this time.</span>
          </Card.Text>
        </Card.Body>
      </Card>
    )
  )

  const tabs = (
    <>
      {(items.length != 0 && filteredItems != null) &&
        <div className="" style={{position: "relative"}}>
          <div style={{position: "absolute", top: "0.1rem", right: 0}}>
            {selectAllBtns(tab)}
          </div>
          <Tabs variant="pills" className="mb-3" activeKey={tab} onSelect={(k) => {setTab(k); setSelectedItems([])}}>
            <Tab eventKey="requested" title={`Requested ${filteredItems["requested"].length ? `(${filteredItems["requested"].length})` : ""}`}>
            </Tab>
            <Tab eventKey="processing"  title={`Processing ${filteredItems["processing"].length ? `(${filteredItems["processing"].length})` : ""}`}>
            </Tab>
            <Tab eventKey="available" title={`Available ${filteredItems["available"].length ? `(${filteredItems["available"].length})` : ""}`}>
            </Tab>
            <Tab eventKey="archived" title={`Archived ${filteredItems["archived"].length ? `(${filteredItems["archived"].length})` : ""}`}>
            </Tab>
            <Tab eventKey="cancelled" title={`Cancelled ${filteredItems["cancelled"].length ? `(${filteredItems["cancelled"].length})` : ""}`}>
            </Tab>
          </Tabs>
          { showPrintModal &&
            <ObjectsPrintForm
              labelsFor="items"
              closeModal={()=>setShowPrintModal(false)}
              objects={selectedItems} />
          }
          { showEditModal &&
            <EditStatusForm
              closeModal={()=>setShowEditModal(false)}
              items={selectedItems}
              instanceName={entry.itemType?.instanceName ? entry.itemType?.instanceName : "Item"}
              reload={() => {refreshItems(); setSelectedItems([])}} />
          }
        </div>
      }
    </>
  )

  const managerLinks = [<Route key="1" path={`${props.match.path}/edit`} render={(props) => (
                          <EditEntry {...props} itemType={itemType} updateEntry={setEntry} setReload={()=>setReload(prev=>!prev)} />
                        )} />,
                        <Route key="2" path={`${props.match.path}/items/create/new`} render={(props) => (
                          <NewItem {...props} itemType={itemType} entry={entry} refreshItems={refreshItems} fromTab={items.length > 0 ? tab : 'available'} />
                        )} />]

  const idFormatter = (cellValue, item, itemIndex) => {
    return (
      <Link to={`/catalog/entries/${item.entryId}/items/${item.id}`}>{item.code}</Link>
    )
  }

  const fieldFormatter = (cellValue, item, itemIndex, formatExtraData) => {
    return (
      <FieldDisplay field={formatExtraData} obj={item} isEntry={false} />
    )
  }

  // loop through all instance fields (including "input" type fields pertaining to this entry)
  // and create a table column for each.
  const fieldColumns = itemType?.allTypeFields?.filter(field => field.fieldTypeId == 7).concat(itemType?.allInstanceFields).filter(field=>{
    if (Object.keys(field.viewOptions).length > 0){
      if (field.viewOptions[statuses.find(s=> s.name.toLowerCase() == tab).id] == "hidden")
        return false
    }
    return true
  }).map((field) => {
    let colWidth = '200px'


    // set col width based on field type
    if([8,11,12].includes(field.fieldTypeId)) {
      colWidth = '125px'
    } else if([5].includes(field.fieldTypeId)) {
      colWidth = '220px'
    }

    const selectOptions = {}
    field.fieldOptionsAttributes.map(opt => { selectOptions[opt.id] =  opt.label })

    let filterForColumn = [1,2,8,11,12].includes(field.fieldTypeId) ? textFilter() : null
    if (field.fieldTypeId == 3)
      filterForColumn = multiSelectFilter({options: selectOptions})
    else if (field.fieldTypeId == 4)
      filterForColumn = selectFilter({options: selectOptions})
    else if (field.fieldTypeId == 5)
      filterForColumn = dateFilter()

    return {
      dataField: `inputData[${field.id}]`,
      hidden: field.fieldTypeId == 2,
      text: field.name,
      filter: filterForColumn,
      sort: [1,2,5,8,10,11,12].includes(field.fieldTypeId), // only certain field types are sortable
      formatter: fieldFormatter,
      formatExtraData: {
        id: field.id,
        fieldTypeId: field.fieldTypeId,
        fieldOptionsAttributes: field.fieldOptionsAttributes
      },
      style: {
        wordWrap: 'break-word'
      },
      headerStyle: {
        wordWrap: 'break-word',
        width: colWidth
      },
      sortFunc: (a, b, order, dataField) => {
        try {
          if (order === 'asc' || !order) {
            return b.localeCompare(a, navigator.languages[0] || navigator.language, {numeric: true, ignorePunctuation: true})
          }
          return a.localeCompare(b, navigator.languages[0] || navigator.language, {numeric: true, ignorePunctuation: true})
        } catch (e) {
          return 0
        }
      }
    }
  })

  const allColumns = () => {
    const idCol = {
      dataField: 'code',
      text: 'ID',
      sort: true,
      sortFunc: (a, b, order, dataField, rowA, rowB) => {
        const aNum = a.replace( /^\D+/g, '')
        const bNum = b.replace( /^\D+/g, '')
        if (order === 'asc') {
          return bNum - aNum
        }
        return aNum - bNum // desc
      },
      formatter: idFormatter,
      filter: textFilter(),
      headerStyle: {
        wordWrap: 'break-word',
        width: '100px'
      }
    }

    if(fieldColumns != undefined && fieldColumns.length > 0) {
      fieldColumns.unshift(idCol)
      return fieldColumns
    }
    else {
      return [idCol]
    }
  }

  const rowEvents = {
    onClick: (e, item, rowIndex) => {
      if(e.target.tagName != 'A') {
        props.history.push(`/catalog/entries/${item.entryId}/items/${item.id}`)
      }
    }
  }

  const selectRow = {
    mode: 'checkbox',
    clickToSelect: false,
    onSelect: (item, isSelect, rowIndex, e) => {
      if(isSelect) {
        selectItem(item)
      } else {
        deselectItem(item)
      }
    },
    onSelectAll: (isSelect, items, e) => {
      if(isSelect) {
        items.map(item => selectItem(item))
      } else {
        items.map(item => deselectItem(item))
      }
    }
  }

  return (
    <div>
      { !isLoading ? (
        <>
          {showTaskCreateConfirmation &&
            <Modal show={true} size="md">
              <Modal.Body>
                <div className="p-3 text-center">
                  <h1 className="display-4 mb-3 text-success"><i className="far fa-check-circle"></i></h1>
                  <h4 className="mb-3">Request Submitted</h4>
                  <p className="mb-4">Your lab manager has been notified of your request for {entry.name}. You will receive a notification as soon as your request has been filled.</p>
                  <button className="btn btn-primary btn-block" onClick={() => setShowTaskCreateConfirmation(false)}>Continue</button>
                </div>
              </Modal.Body>
            </Modal>
          }
          {showInsufficientTasks &&
            <Modal show={true} onHide={()=>setShowInsufficientTasks(false)}>
                <Modal.Header closeButton>
                  <Modal.Title>No Credits Remaining</Modal.Title>
                </Modal.Header>
                <Modal.Body>Your account has no task credits remaining. {currentUser.userRole.id == 1 ? "Purchase additional credits to continue." : "Ask your manager to purchase additional credits."}</Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={()=>setShowInsufficientTasks(false)}>
                    Close
                  </Button>
                  <button type="button" className="btn btn-primary" onClick={purchaseLinkClicked}>
                    Purchase Task Credits
                  </button>
                </Modal.Footer>
              </Modal>
          }
          { showItemsGraph &&
            <NetworkViewer entry={entry} closeModal={()=>setShowItemsGraph(false)}/>
          }
          <Switch>
            { currentUser.userRole.id == 1 ? managerLinks : null}
            <Route key="1" path={`${props.match.path}/items/:itemId/edit`} render={(props) => (
              <EditItem {...props} itemType={itemType} entry={entry} refreshItems={refreshItems} />
            )} />,
            <Route path={`${props.match.path}/tasks/new`} render={(props) => (
              <NewTask {...props} returnPath={`/catalog/entries/${entry.id}`} entry={entry} onSuccess={onTaskCreateSuccess} />
            )} />,
            <Route key="2" path={`${props.match.path}/items/create/new`} render={(props) => (
              <NewItem {...props} itemType={itemType} entry={entry} refreshItems={refreshItems} fromTab={items.length > 0 ? tab : 'available'} />
            )} />
          </Switch>
          <Route exact={true} path={`${props.match.path}/items/:itemId`} render={(props) => (
            <Item {...props} itemType={entry.itemType} refreshItems={refreshItems} />
          )} />
          <div>
            <p><Link to={`/catalog/${entry.itemTypeId}`}><i className="fas fa-arrow-up mr-2"></i>All {entry.itemType?.name}</Link></p>
            <h3 className="mb-3">
              {entry.name}
              { currentUser.userRole.id == 1 &&
                <Link to={`${entry.id}/edit`} className="d-inline-block text-secondary" style={{marginLeft: '0.5rem', fontSize: '1rem', position: 'relative', top: '-3px'}}>
                  <i className="far fa-edit"></i>
                </Link>
              }
            </h3>
            { (entry.description != null && entry.description != "") &&
              <div className="mb-4">{ReactHtmlParser(entry.description)}</div>
            }
            { (itemType.allTypeFields && Object.keys(entry?.inputDataForDisplay).length !== 0) &&
              <Table className="mb-4" bordered size="sm">
                <tbody>
                  {itemType.allTypeFields.map((field, index) => (
                    <tr key={field.id}>
                      <td className="heading-left">
                        <span className="pr-3">{field.name}</span>
                      </td>
                      <td>
                        <FieldDisplay field={field} obj={entry} isEntry={true} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            }
            <div className="d-block mb-1">
              <h4 className="d-inline">{entry.itemType?.instanceName ? pluralize(entry.itemType?.instanceName, 2) : "Items"}</h4>
              <div className="d-inline ml-2" style={{position: "relative", top: "-4px"}}>
                <GlobalItemActions itemType={entry.itemType} currentUser={currentUser} />
              </div>
            </div>
            {tabs}
            { !itemsAreLoading ? (
              <ToolkitProvider
                keyField="id"
                bootstrap4
                hover
                responsive
                data={filteredItems[tab]}
                columns={allColumns()}
                rowEvents={rowEvents}
                rowStyle={{cursor: 'pointer'}}
                columnToggle
              >
                {
                  props => (
                    <div>
                      <div ref={tableRef} style={{width: '100%', height: 'calc(100vh - 105px)', overflowX: 'scroll'}}>
                        <CustomToggleList { ...props.columnToggleProps } className="mb-3" />
                        <BootstrapTable
                          filter={ filterFactory() }
                          selectRow={selectRow}
                          { ...props.baseProps }
                        />
                      </div>
                    </div>
                  )
                }
              </ToolkitProvider>

            ) : (
              <div>
                <ContentLoader height="116" width="100%" backgroundColor={'#eee'} foregroundColor={'#ccc'} className="">
                  <rect x="91%" y="10" rx="3" ry="3" width="9%" height="35"/>
                  <rect x="80%" y="10" rx="3" ry="3" width="10%" height="35"/>
                  <rect x="12" y="17" rx="3" ry="3" width="100" height="20"/>
                  <rect x="149" y="17" rx="3" ry="3" width="100" height="20"/>
                  <rect x="286" y="17" rx="3" ry="3" width="100" height="20"/>
                  <rect x="423" y="17" rx="3" ry="3" width="100" height="20"/>
                  <rect x="560" y="17" rx="3" ry="3" width="100" height="20"/>
                  <rect x="0" y="65" rx="3" ry="3" width="75" height="30"/>
                </ContentLoader>
                <div className="items-list mb-5" style={{position: "relative"}}>
                  {loadingItem("1")}
                  {loadingItem("2")}
                  {loadingItem("3")}
                  {loadingItem("4")}
                  {loadingItem("5")}
                  {loadingItem("6")}
                </div>
              </div>
            )}
          </div>
        </>
      ) : (
        <div>
          <ContentLoader height="236" width="100%" backgroundColor={'#eee'} foregroundColor={'#ccc'} className="">
            <rect x="0" y="5" rx="3" ry="3" width="100" height="20"/>
            <rect x="0" y="45" rx="3" ry="3" width="150" height="35"/>
            <rect x="0" y="95" rx="3" ry="3" width="100" height="25" />
            <rect x="91%" y="130" rx="3" ry="3" width="9%" height="35"/>
            <rect x="80%" y="130" rx="3" ry="3" width="10%" height="35"/>
            <rect x="12" y="137" rx="3" ry="3" width="100" height="20"/>
            <rect x="149" y="137" rx="3" ry="3" width="100" height="20"/>
            <rect x="286" y="137" rx="3" ry="3" width="100" height="20"/>
            <rect x="423" y="137" rx="3" ry="3" width="100" height="20"/>
            <rect x="560" y="137" rx="3" ry="3" width="100" height="20"/>
            <rect x="0" y="185" rx="3" ry="3" width="75" height="30"/>
          </ContentLoader>
          <div className="items-list mb-5" style={{position: "relative"}}>
            {loadingItem("1")}
            {loadingItem("2")}
            {loadingItem("3")}
            {loadingItem("4")}
            {loadingItem("5")}
            {loadingItem("6")}
          </div>
        </div>
      )}
    </div>
  )
}

const mapStateToProps = state => {
  return {
    currentUser: state.currentUser
  }
}

export default connect(mapStateToProps)(withRouter(Entry))
