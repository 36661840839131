import React, { useState, useEffect, useRef } from 'react'
import { Form, Row, Col } from 'react-bootstrap'
import { v4 as uuidv4 } from 'uuid'

const ItemTypeFieldCreator = (props) => {
  const [field, setField] = useState({
    id: uuidv4(),
    newRecord: true,
    name: "",
    fieldTypeId: 1,
    appliesToInstances: props.appliesToInstances
  })

  const nameInputRef = useRef(null)

  useEffect(() => {
    nameInputRef.current.focus()
  }, [])

  const handleChange = (event) => {
    setField({...field, [event.target.name]: event.target.value})
  }

  const handleRadioChange = (event) => {
    setField({...field, fieldTypeId: event.target.value})
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    const fieldTypeName = props.fieldTypes.find(type => type.id.toString() == field.fieldTypeId)?.name || ""
    const fieldName = (field.name != "") ? field.name : `${fieldTypeName} Field`
    props.addField({...field, name: fieldName})
    document.body.click()
  }

  return (
    <Form>
      <div className="form-group">
        <label>Name</label>
        <input
          name="name"
          ref={nameInputRef}
          className="form-control"
          value={field.name}
          onChange={handleChange}
          type="text"
        />
      </div>
      <div className="form-group pl-1 mb-3">
        <Row>
          <Col>
            {props.fieldTypes.slice(0,6).map((fieldType, index) => (
              <div key={fieldType.id} className="mb-2">
                <Form.Check
                  type="radio"
                  id={`field-type-${fieldType.id}`}
                  value={fieldType.id}
                  label={
                    <span>
                      <i className={`fas fa-${fieldType.icon} text-center`} style={{minWidth: "1.75rem", paddingRight: ".3rem  "}}></i>
                      {fieldType.name}
                    </span>
                  }
                  checked={field.fieldTypeId == fieldType.id}
                  onChange={handleRadioChange}
                />
              </div>
            ))}
          </Col>
          <Col>
            {props.fieldTypes.slice(6,props.fieldTypes.length).map((fieldType, index) => (
              <div key={fieldType.id} className="mb-2">
                <Form.Check
                  type="radio"
                  id={`field-type-${fieldType.id}`}
                  value={fieldType.id}
                  label={
                    <span>
                      <i className={`fas fa-${fieldType.icon} text-center`} style={{minWidth: "1.75rem", paddingRight: ".3rem  "}}></i>
                      {fieldType.name}
                    </span>
                  }
                  checked={field.fieldTypeId == fieldType.id}
                  onChange={handleRadioChange}
                />
              </div>
            ))}
          </Col>
        </Row>
      </div>
      <button type="submit" className={`btn mb-2 btn-primary`} onClick={handleSubmit}>
        Add
      </button>
    </Form>
  )
}

export default ItemTypeFieldCreator
