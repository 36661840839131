import React, { useState, useEffect } from 'react'
import limsApi from '../../utils/limsApi'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

const TeamMemberField = ({ field, inputData, handleChange, status }) => {
  const [users, setUsers] = useState([])

  const fetchUsers = () => {
    limsApi.get('users', (response)=>{
      let userData = response.data.users
      setUsers(userData)
    },
    'Error fetching users')
  }

  useEffect(() => {
    fetchUsers()
  }, [])

  let afterLabel = (
    <select
      name={field.id}
      className="form-control"
      value={inputData[field.id] || ""}
      onChange={handleChange}
      rows="4"
    >
      <option value=""></option>
      {users.map(user => (
        <option
          key={user.id}
          value={user.id}
        >
          {user.fullName}
        </option>
      ))}
    </select>
  )

  if (Object.keys(field.viewOptions).length > 0) {
    if (field.viewOptions[status] == "hidden") {
      afterLabel = (
        <OverlayTrigger
          placement="right"
          overlay={
            <Tooltip>
              This field is hidden
            </Tooltip>
          }
        >
          <i className="fas fa-eye-slash ml-3"></i>
        </OverlayTrigger>
      )
    } else if (field.viewOptions[status] == "viewable") {
      afterLabel = (
        <select
          disabled
          name={field.id}
          className="form-control"
          value={inputData[field.id] || ""}
          onChange={handleChange}
          rows="4"
        >
          <option value=""></option>
          {users.map(user => (
            <option
              key={user.id}
              value={user.id}
            >
              {user.fullName}
            </option>
          ))}
        </select>
      )
    }
  }

  return (
    <div>
      <label>{field.name}</label>
      {afterLabel}
    </div>
  )
}

export default TeamMemberField
