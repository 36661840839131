import React, { useState, useRef, useEffect } from 'react'
import { OverlayTrigger, Popover, Tooltip, Button, Container, Row, Col, Form, Card } from 'react-bootstrap'
import { Link, withRouter } from 'react-router-dom'

const LocationPositionGrid = (props) => {
  const boardRef = useRef(null)

  useEffect(() => {
    boardRef.current?.style?.setProperty("--num-cols", parseInt(props.cols))
  }, [props.cols])

  useEffect(() => {
    boardRef.current?.style?.setProperty("--num-rows", parseInt(props.rows))
  }, [props.rows])

  const extraWideClassName = () => {
    return parseInt(props.cols) > 13 ? "grid-extra-wide" : ""
  }

  const extraTallClassName = () => {
    return parseInt(props.rows) > 13 ? "grid-extra-tall" : ""
  }

  const createGrid = () => {
    let html = []

    // loop through all of location's positions
    for (let i = 0; i < props.rows * props.cols; i++) {
      let cellId = i + 1
      let currentRow = Math.ceil((cellId)/props.cols)
      let currentCol = (i % props.cols) + 1
      let cellLabel = props.orientation != "vertical" ? cellId : ((props.rows * (currentCol - 1)) + currentRow)
      let containedLocation = props.children ? props.children.find(c => c.locationPosition == cellId) : undefined
      let containedItem = props.items ? props.items.find(i => i.locationPosition == cellId) : undefined

      // determine current column label
      let currentColLabel
      switch(props.colLabels) {
        case "":
          currentColLabel = ""
          break
        case "alpha":
          currentColLabel = (currentCol + 9).toString(36).toUpperCase()
          break
        case "numeric":
          currentColLabel = currentCol
          break
      }

      // determine current row label
      let currentRowLabel
      switch(props.rowLabels) {
        case "":
          currentRowLabel = ""
          break
        case "alpha":
          currentRowLabel = (currentRow + 9).toString(36).toUpperCase()
          break
        case "numeric":
          currentRowLabel = currentRow
          break
      }

      html.push(
        <div key={i} className="square">
          {currentCol == 1 ? <div className="row-label">{currentRowLabel}</div> : ""}
          {currentRow == 1 ? <div className="col-label">{currentColLabel}</div> : ""}
          {!props.browsing ? (
            <div className="text-center">
              { ( props.rows <= 6 && props.cols <= 7 ) &&
                <small className="d-block">Position</small>
              }
              <span className={`text-dark`}>{cellLabel}</span>
            </div>
          ) : (
            <div className="text-center w-100 h-100">
              { (extraWideClassName() == "" || (!containedLocation && !containedItem)) &&
                <span className={containedLocation ? "text-primary" : "text-muted"}>
                  <small>{cellLabel}</small>
                </span>
              }
              { containedLocation &&
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip>
                      {cellLabel}: {containedLocation.name}
                    </Tooltip>
                  }
                >
                  <Link
                    to={`/locations/${containedLocation.id}`}
                    className="d-flex align-items-center justify-content-center w-100 h-100 p-2"
                  >
                    {extraWideClassName() == "" ? (
                      <>{containedLocation.name}</>
                    ) : (
                      <i className="fas fa-circle"></i>
                    )}
                  </Link>
                </OverlayTrigger>
              }
              { containedItem &&
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip>
                      {cellLabel}: {containedItem.code} {containedItem.entry?.name}
                    </Tooltip>
                  }
                >
                  {containedItem.parentId && containedItem.parentId != '' ? (
                    <Link
                      to={`/locations/${props.locationId}/items/${containedItem.parentId}`}
                      className="contained-item d-flex align-items-center justify-content-center w-100 h-100"
                    >
                      {extraWideClassName() == "" ? (
                        <>{containedItem.code}</>
                      ) : (
                        <i className="fas fa-circle"></i>
                      )}
                    </Link>
                  ) : (
                    <Link
                      to={`/locations/${props.locationId}/items/${containedItem.id}`}
                      className="contained-item d-flex align-items-center justify-content-center w-100 h-100"
                    >
                      {extraWideClassName() == "" ? (
                        <>{containedItem.code}</>
                      ) : (
                        <i className="fas fa-circle"></i>
                      )}
                    </Link>
                  )}
                </OverlayTrigger>
              }
            </div>
          )}
        </div>
      )
    }
    return html
  }

  return (
    <div ref={boardRef} className={`board ml-3 ${extraWideClassName()} ${extraTallClassName()}`}>
      {createGrid()}
    </div>
  )
}

export default withRouter(LocationPositionGrid)
