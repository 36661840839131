import React, { useState, useRef, useEffect } from 'react'
import { OverlayTrigger, Popover, Tooltip, Button, Container, Row, Col, Form, Card } from 'react-bootstrap'
import { Link, withRouter } from 'react-router-dom'
import limsApi from '../utils/limsApi'
import DeleteConfirmation from './deleteConfirmation'
import LocationPositionGrid from './locationPositionGrid'
import SelectLocation from './selectLocation'

const LocationTemplateForm = (props) => {
  const queryString = require('query-string')
  const [isLoading, setIsLoading] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [locationTemplate, setLocationTemplate] = useState({
    name: "",
    cols: props.create ? 4 : 1,
    rows: props.create ? 3 : 1,
    orientation: "horizontal",
    colLabels: "",
    rowLabels: "",
    moveable: true,
    limitedCapacity: true
  })
  const ref = useRef(null)

  const handleSubmit = (event) => {
    event.preventDefault()

    // create
    if(props.create == true) {
      limsApi.post('location_templates', { locationTemplate: locationTemplate }, `${locationTemplate.name} Created`, (response)=>{
          const locationTemplateData = response.data.locationTemplates
          props.onSuccess(locationTemplateData)
          props.history.push(`/manage/locations/`)
        },
        'Error creating locationTemplate', undefined, setIsSubmitting)
    // update
    } else {
      limsApi.patch(`location_templates/${locationTemplate.id}`, { locationTemplate: locationTemplate }, `${locationTemplate.name}  Updated`, (response)=>{
          const locationTemplateData = response.data.locationTemplate
          props.onSuccess(locationTemplateData)
          props.history.push(`/manage/locations/`)
        },
        'Error creating locationTemplate', undefined, setIsSubmitting)
    }
  }

  const handleChange = ({ target }) => {
    let val = target.value

    // convert val to integer if number field
    if(target.type == 'number') {
      val = parseInt(val)
    }

    setLocationTemplate({...locationTemplate, [target.name]: val})
  }

  const handleCheckboxChange = ({ target }) => {
    setLocationTemplate({...locationTemplate, [target.name]: target.checked})
  }

  const fetchLocationTemplate = async () => {
    setIsLoading(true)
    await limsApi.get(`location_templates/${props.match.params.id}`, (response)=>{
      const locationTemplateData = response.data.locationTemplate
      setLocationTemplate(locationTemplateData)
    },
    'Error fetching location template', undefined, setIsLoading)
  }

  useEffect(() => {
    if(props.create != true) {
      fetchLocationTemplate()
    }
  }, [props.match.params.id])

  return (
    <form ref={ref} className="mb-1" onSubmit={handleSubmit}>
      <Container style={{ paddingLeft: 0, paddingRight: 0 }}>
        <Row>
          <Col>
            <div className="form-row">
              <div className="form-group col">
                <label>Name</label>
                <input
                  type="text"
                  required
                  name="name"
                  className="form-control"
                  value={locationTemplate.name}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group col-3">
                <label>
                  Key
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip>
                        A short, unique reference name.
                      </Tooltip>
                    }
                  >
                    <i className="fas fa-info-circle ml-1 text-muted"></i>
                  </OverlayTrigger>
                </label>
                <input
                  type="text"
                  name="key"
                  className="form-control"
                  value={locationTemplate.key}
                  onChange={handleChange}
                />
              </div>
            </div>
            <h5 className="mb-3">Physical Characteristics</h5>
            <div className="form-row">
              <div className="form-group col">
                <Form.Check
                  type="switch"
                  id="limited-capacity-switch"
                  name="limitedCapacity"
                  onChange={handleCheckboxChange}
                  label={
                    <span>
                      Structured Layout
                      <OverlayTrigger
                        placement="right"
                        overlay={
                          <Tooltip>
                            A limited capacity locationTemplate, such as a freezer with a limited number of shelves or a 10x10 box, has a distinct layout with limited available "slots" or positions. By contrast, an <i>unlimited</i> capacity locationTemplate has no organized structure.
                          </Tooltip>
                        }
                      >
                        <i className="fas fa-info-circle ml-1 text-muted"></i>
                      </OverlayTrigger>
                    </span>
                  }
                  checked={locationTemplate.limitedCapacity}
                />
              </div>
              <div className="form-group col">
                <Form.Check
                  type="switch"
                  id="moveable-switch"
                  name="moveable"
                  onChange={handleCheckboxChange}
                  label={
                    <span>
                      Moveable
                      <OverlayTrigger
                        placement="right"
                        overlay={
                          <Tooltip>
                            Indicates whether or not this locationTemplate can be physically relocated.
                          </Tooltip>
                        }
                      >
                        <i className="fas fa-info-circle ml-1 text-muted"></i>
                      </OverlayTrigger>
                    </span>
                  }
                  checked={locationTemplate.moveable == true ? true : false}
                />
              </div>
            </div>
            { locationTemplate.limitedCapacity &&
              <div>
                <div className="form-row">
                  <div className="form-group col">
                    <label>Rows</label>
                    <input
                      type="number"
                      name="rows"
                      className="form-control"
                      value={locationTemplate.rows}
                      max="24"
                      min="1"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group col">
                    <label>Columns</label>
                    <input
                      type="number"
                      name="cols"
                      className="form-control"
                      value={locationTemplate.cols}
                      max="24"
                      min="1"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col">
                    <label>Row Labels</label>
                    <select
                      name="rowLabels"
                      className="form-control"
                      value={locationTemplate.rowLabels}
                      onChange={handleChange}
                    >
                      <option value=""></option>
                      <option value="alpha">A, B, C</option>
                      <option value="numeric">1, 2, 3</option>
                    </select>
                  </div>
                  <div className="form-group col">
                    <label>Column Labels</label>
                    <select
                      name="colLabels"
                      className="form-control"
                      value={locationTemplate.colLabels}
                      onChange={handleChange}
                    >
                      <option value=""></option>
                      <option value="alpha">A, B, C</option>
                      <option value="numeric">1, 2, 3</option>
                    </select>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col">
                    <label>Numbering Orientation</label>
                    <select
                      name="orientation"
                      className="form-control"
                      value={locationTemplate.orientation}
                      onChange={handleChange}
                    >
                      <option value="horizontal">Horizontal</option>
                      <option value="vertical">Vertical</option>
                    </select>
                  </div>
                </div>
              </div>
            }
            <div className="pt-2">
              { !props.create &&
                <DeleteConfirmation delete={() => { props.handleDelete(locationTemplate.id) }}>
                  <Button variant="outline-danger" className="float-right">Delete Location Template</Button>
                </DeleteConfirmation>
              }
              <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                { !isSubmitting ? (
                  "Save"
                ) : (
                  <i className="fas fa-spinner fa-spin"></i>
                )}
              </button>
              <Link
                to={props.cancelPath}
                className="btn btn-link"
              >
                Cancel
              </Link>
            </div>
          </Col>
          { locationTemplate.limitedCapacity &&
            <Col xs="7" className="mx-3 px-5 py-4 pb-5">
              <LocationPositionGrid
                rows={locationTemplate.rows}
                cols={locationTemplate.cols}
                rowLabels={locationTemplate.rowLabels}
                colLabels={locationTemplate.colLabels}
                orientation={locationTemplate.orientation}
              />
            </Col>
          }
        </Row>
      </Container>
    </form>
  )
}

export default withRouter(LocationTemplateForm)
