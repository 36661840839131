import React, { useState, useEffect } from 'react'
import axios from "axios"
import limsApi from '../utils/limsApi'
import { connect } from 'react-redux'
import { Switch, withRouter, Link, Route } from 'react-router-dom'
import ContentLoader from 'react-content-loader'
import Card from 'react-bootstrap/Card'
import { Tabs, Tab, DropdownButton, Dropdown, Button, Table } from 'react-bootstrap'
import Entry from './entry'
import NewEntry from './newEntry'
import ReactHtmlParser from 'react-html-parser'
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd'
import Importer from './importer'
import { FieldDisplay } from './fields/fieldUtils'
import BootstrapTable from 'react-bootstrap-table-next'

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  // update "position" attrs
  const finalResult = result.map((field, index) => {
    const updatedField = field
    updatedField.position = index
    return updatedField
  })

  return finalResult
}

const ItemType = (props) => {
  const pluralize = require('pluralize')
  const [isLoading, setIsLoading] = useState(true)
  const [itemType, setItemType] = useState({
    name: "",
    description: "",
    instanceName: "",
    parentId: null,
    fieldsAttributes: []
  })
  const [entriesAreLoading, setEntriesAreLoading] = useState(true)
  const [entries, setEntries] = useState([])
  const [isReordering, setIsReordering] = useState(false)
  const [showImporter, setShowImporter] = useState(false)
  const [isExporting, setIsExporting] = useState(false)

  const fetchItemType = async () => {
    const itemTypeId = props.itemTypeId != undefined ? props.itemTypeId : props.match.params.id

    await limsApi.get(`item_types/${itemTypeId}`, (response)=>{
      const itemTypeData = response.data.itemType
      setItemType(itemTypeData)
    },
    'Error fetching item type', undefined, setIsLoading)
  }

  useEffect(() => {
    fetchItemType()
  }, [props.match.params.id])

  const fetchEntries = () => {
    const itemTypeId = props.itemTypeId != undefined ? props.itemTypeId : props.match.params.id

    limsApi.get(`entries?item_type_id=${itemTypeId}`, (response)=>{
      let entriesData = response.data.entries
      setEntries(entriesData)
    },
    'Error fetching item types', undefined, setEntriesAreLoading)
  }

  useEffect(() => {
    fetchEntries()
  }, [props.match.params.id])

  const refreshEntries = () => {
    fetchEntries()
  }

  const onTypeFieldDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const reorderedEntries = reorder(
      entries,
      result.source.index,
      result.destination.index
    )

    let entry = reorderedEntries[result.destination.index]
    limsApi.patch(`entries/${entry.id}`, {...entry, position: result.destination.index}, "Entry Order Saved")

    setEntries(reorderedEntries)
  }

  const fetchExport = () => {
    setIsExporting(true)
    let url = `${window.location.origin}/api/export_item_type/${itemType.id}.csv`
    axios.request({
        url: url,
        method: "GET",
        responseType: 'blob', //important
      })
      .then(({ data }) => {
        let file = window.URL.createObjectURL(new Blob([data]))
        const link = document.createElement('a')
        link.href = file
        link.setAttribute('download', `${itemType.name}_export.csv`)
        document.body.appendChild(link)
        link.click()
        link.remove()
        setIsExporting(false)
      });
  }


  const renderedEntries = (
    (entries.length) ? (
      isReordering ? (
        <DragDropContext onDragEnd={onTypeFieldDragEnd}>
          <Droppable droppableId="entries">
            {provided => (
              <div
                className="items-list mb-5"
                style={{position: "relative"}}
                ref={provided.innerRef}
                {...provided.droppableProps}
                >
                {entries.map((entry, index) => (
                  <Draggable draggableId={entry.id ? entry.id.toString() : null} index={index} key={entry.id ? entry.id.toString() : null}>
                    {(provided, snapshot) => (
                      <Card
                        key={entry.id}
                        className={`mb-2 ${snapshot.isDragging ? "is-dragging" : ""}`}
                        onClick={(e) => { e.preventDefault(); props.history.push(`/catalog/entries/${entry.id}`)}}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                        >
                        <Card.Body className="p-3">
                          <div className="container" style={{ maxWidth: 'none', paddingLeft: 0, paddingRight: 0 }}>
                            <div className="row">
                              <div className="col" style={{maxWidth: "10px"}}>
                                <i className="fas fa-bars"></i>
                              </div>
                              <div className="col-2" style={{minWidth: "90px"}}>
                                <a href="">
                                  {entry.name}
                                </a>
                              </div>
                              {Object.keys(entry.inputDataForListDisplay).map((key, index) => (
                                <div key={entry.id + index} className="col-3">
                                  <small className="mr-2 text-secondary">{entry.inputDataForListDisplay[key].label}</small>
                                  {entry.inputDataForListDisplay[key].value}
                                </div>
                              ))}
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      ) : (
        <div className="items-list mb-5" style={{position: "relative"}}>
        {entries.map((entry, index) => (
          <Card key={entry.id} className="mb-2" onClick={(e) => { e.preventDefault(); props.history.push(`/catalog/entries/${entry.id}`)}}>
            <Card.Body className="p-3">
              <div className="container" style={{ maxWidth: 'none', paddingLeft: 0, paddingRight: 0 }}>
                <div className="row">
                  <div className="col-2" style={{minWidth: "90px"}}>
                    <a href="">
                      {entry.name}
                    </a>
                  </div>
                  {Object.keys(entry.inputDataForListDisplay).map((key, index) => (
                    <div key={entry.id + index} className="col-3">
                      <small className="mr-2 text-secondary">{entry.inputDataForListDisplay[key].label}</small>
                      {entry.inputDataForListDisplay[key].value}
                    </div>
                  ))}
                </div>
              </div>
            </Card.Body>
          </Card>
        ))}
      </div>
      )

    ) : (
      <Card className="text-center mt-3">
        <Card.Body className="p-3">
          <Card.Text className="py-4">
            <span className="text-muted">No entries at this time.</span>
            <br/>
          </Card.Text>
        </Card.Body>
      </Card>
    )
  )

  const loadingItem = id => (
    <Card key={id} className="mb-2">
      <Card.Body className="p-3">
        <div className="container" style={{ maxWidth: 'none', paddingLeft: 0, paddingRight: 0 }}>
          <div className="row">
            <div className="col-2" style={{minWidth: "90px"}}>
              <ContentLoader height="20" width="100" backgroundColor={'#eee'} foregroundColor={'#ccc'}>
                <rect x="0" y="0" rx="3" ry="3" width="90" height="20" />
              </ContentLoader>
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  )

  const managerLinks = [<Route key="2" path={`${props.match.path}/entries/new`} render={(props) => (
                          <NewEntry {...props} itemType={itemType} refreshEntries={refreshEntries} />
                        )} />]

  return (
    <div>
      <Switch>
        { props.currentUser.userRole.id == 1 ? managerLinks : null }
      </Switch>

      { !isLoading ? (
        <div>
          { (props.currentUser.userRole.id == 1 && entries.length > 0) &&
            <a href=""
              to={`/catalog/${itemType.id}/entries/new`}
              className="btn btn-secondary float-right ml-2"
              onClick={(e) => {e.preventDefault(); setIsReordering(prev=>!prev)}}
            >
              <i className="fas fa-arrows-alt-v" style={{marginRight: '.4rem'}}></i>
              {isReordering ? 'Done Reordering' : 'Reorder'}
            </a >
          }
          <button className="btn btn-secondary float-right ml-2" onClick={fetchExport}>
            { !isExporting ?
              <><i className="fas fa-file-export" style={{marginRight: '.4rem'}}></i>Export</>
              :
              <i className="fas fa-spinner fa-spin"></i>
            }

          </button>
          <button className="btn btn-info float-right ml-2" onClick={() => setShowImporter(true)}>
            <i className="fas fa-file-import" style={{marginRight: '.4rem'}}></i>Import
          </button>
          { props.currentUser.userRole.id == 1 &&
            <Link
              to={`/catalog/${itemType.id}/entries/new`}
              className="btn btn-primary float-right entry-new-action ml-2"
            >
              <i className="fas fa-plus" style={{marginRight: '.4rem'}}></i>
              Add Entry
            </Link>
          }
          { showImporter &&
            <Importer
              itemType={itemType}
              onHide={() => setShowImporter(false)}
              refreshEntries={refreshEntries}
            />
          }
          <h3 className="mb-3">{itemType.name}</h3>
          <div className="mb-3">{ReactHtmlParser(itemType.description)}</div>
          { !entriesAreLoading ? (
            <div className="mt-2">
              {renderedEntries}
            </div>
          ) : (
            <div className="items-list mb-5" style={{position: "relative"}}>
              {loadingItem("1")}
              {loadingItem("2")}
            </div>
          )}
        </div>
      ) : (
        <div>
          <ContentLoader height="120" width="100%" backgroundColor={'#eee'} foregroundColor={'#ccc'} className="">
            <rect x="0" y="5" rx="3" ry="3" width="150" height="35"/>
            <rect x="90%" y="5" rx="3" ry="3" width="10%" height="35"/>
            <rect x="0" y="55" rx="3" ry="3" width="100%" height="15" />
            <rect x="0" y="80" rx="3" ry="3" width="60%" height="15" />
          </ContentLoader>
          <div className="items-list mb-5" style={{position: "relative"}}>
            {loadingItem("1")}
            {loadingItem("2")}
          </div>
        </div>
      )}

    </div>
  )
}

const mapStateToProps = state => {
  return {
    currentUser: state.currentUser
  }
}

export default connect(mapStateToProps)(withRouter(ItemType))
