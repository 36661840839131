import React, { useRef } from 'react'
import IdleTimer from 'react-idle-timer'

const IdleTimerContainer = (props) => {
  const idleTimerRef = useRef(null)

  // currently fires after 30 minutes of being idle

  return (
    <div>
      <IdleTimer
        ref={idleTimerRef}
        timeout={60 * 1000 * 30}
        onIdle={props.onIdle}
      >
      </IdleTimer>
    </div>
  )
}

export default IdleTimerContainer
