// State related to navigation - like expanded node in tree structures - to be preserved when the user navigates
// between pages.

import { SET_CURRENT_USER } from "../actionTypes"
// import { COMPONENT_NAMES } from "../../constants"
// import {updateIn} from "../../utils/pureStateFns"

const initialState = {
    name: "name"
}

const currentUser = (state = initialState, action) => {
    switch (action.type) {
        case SET_CURRENT_USER: {
            return {
              ...action.currentUser
            }
        }
        default: {
            return state;
        }
    }
};

export default currentUser;
