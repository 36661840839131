import React, { useState, useEffect, useRef } from 'react'
import { Form, InputGroup, Card} from 'react-bootstrap'
import { v4 as uuidv4 } from 'uuid'
import { numberTypes, currencyTypes } from './fields/fieldUtils'

const ItemTypeFieldViewabilityEditor = (props) => {
  const [field, setField] = useState({
    name: "",
    fieldTypeId: "",
    fieldOptionsAttributes: []
  })

  useEffect(() => {
    let fieldProp = props.field
    fieldProp.fieldOptionsAttributes = fieldProp.fieldOptionsAttributes || [{
      id: uuidv4(),
      newRecord: true,
      label: "",
      value: props.field.fieldTypeId == "12" ? "usd" : ""
    }]
    setField(fieldProp)
  }, [props.field])

  const handleChange = (status, value) => {
    let newViewOptions = {}

    if (field.viewOptions == null || Object.keys(field?.viewOptions).length == 0) {
      console.log("inHere");
      newViewOptions = {
        "1": "editable",
        "2": "editable",
        "3": "editable",
        "4": "editable",
        "5": "editable",
      }
      const statusId = statuses.find(s=>s.name == status).id
      newViewOptions[`${statusId}`] = value
    } else {
      newViewOptions = field.viewOptions
      const statusId = statuses.find(s=>s.name == status).id
      newViewOptions[`${statusId}`] = value
    }
    setField({...field, viewOptions: newViewOptions})
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    props.updateField(field)
    document.body.click()
  }

  const isChecked = (status, option) => {

    if (field.viewOptions == null || Object.keys(field.viewOptions).length == 0) {
      return option == "editable"
    } else {
      const statusId = statuses.find(s=>s.name == status)?.id
      const value = statusId ? field.viewOptions[statusId] : null

      if (value == option)
        return true
    }
    return false
  }

  const statusNames = ["Requested", "Processing", "Available", "Archived", "Cancelled"]

  const statuses = [
    { id: 2, name: "Requested"},
    { id: 3, name: "Processing"},
    { id: 1, name: "Available"},
    { id: 4, name: "Archived"},
    { id: 5, name: "Cancelled"},
  ]

  return (
    <>
      {field.fieldTypeId != "" && statusNames.map(status=>
        <div className="form-group" key={status}>
          <label><b>{status}</b></label>
          <br/>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name={`${status}Radio`}
              id={`${status}Radio1`}
              value="editable"
              onChange={(e)=>handleChange(status, e.target.value)}
              checked={isChecked(status, "editable")}
            />
            <label className="form-check-label" htmlFor={`${status}Radio1`}>
              Editable
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name={`${status}Radio`}
              id={`${status}Radio2`}
              value="viewable"
              onChange={(e)=>handleChange(status, e.target.value)}
              checked={isChecked(status, "viewable")}
            />
            <label className="form-check-label" htmlFor={`${status}Radio2`}>
              Viewable
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio" name={`${status}Radio`}
              id={`${status}Radio3`}
              value="hidden"
              onChange={(e)=>handleChange(status, e.target.value)}
              checked={isChecked(status, "hidden")}
            />
            <label className="form-check-label" htmlFor={`${status}Radio3`}>
              Hidden
            </label>
          </div>
        </div>
      )}
      <button type="submit" className={`btn mb-2 btn-primary`} onClick={handleSubmit}>
        Done
      </button>
    </>
  )
}

export default ItemTypeFieldViewabilityEditor
