import React, { useState, useEffect } from 'react'
import { Form, Col, Tooltip, OverlayTrigger } from 'react-bootstrap'

const DurationField = ({ field, inputData, handleChange, status }) => {
  const [duration, setDuration] = useState(["", "d"])

  useEffect(() => {
    setDuration(inputData[field.id] || ["", "d"])
  }, [field, inputData])

  const onChange = (e) => {
    let durationData

    if(e.target.name == 'durationValue') {
      // duration value changed
      durationData = [e.target.value, duration[1]]
    } else {
      // duration precision changed
      durationData = [duration[0], e.target.value]
    }

    setDuration(durationData)

    const fakeEvent = {
      target: {
        value: durationData,
        name: field.id
      }
    }

    handleChange(fakeEvent)
  }

  let disableField = false

  if (Object.keys(field.viewOptions).length > 0) {
    if (field.viewOptions[status] == "viewable") {
      disableField = true
    }
  }

  return (
    <div style={{maxWidth: '18rem'}}>
      <Form.Row>
        <Col>
          <label>{field.name}</label>
          <input
            disabled={disableField}
            type="number"
            step="any"
            name="durationValue"
            className="form-control"
            value={duration[0] || ""}
            onChange={onChange}
          />
        </Col>
        <Col>
          <label>&nbsp;</label>
          <select
            disabled={disableField}
            name="durationPrecision"
            className="form-control"
            value={duration[1] || "d"}
            onChange={onChange}
          >
            <option value="mo">Months</option>
            <option value="w">Weeks</option>
            <option value="d">Days</option>
            <option value="h">Hours</option>
            <option value="m">Minutes</option>
            <option value="s">Seconds</option>
          </select>
        </Col>
      </Form.Row>
    </div>
  )
}

export default DurationField
