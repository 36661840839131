import React, { useState, useEffect } from 'react'
import { Switch, withRouter, Link, Route } from 'react-router-dom'
import { Card, Container, Row, Col } from 'react-bootstrap'

const ItemCard = (props) => {

  const checkedBoxChanged = (checked) => {
    if (checked) {
      props.selectItem()
    } else {
      props.deselectItem()
    }
  }

  const handleClick = (e) => {
    if(e.target.tagName != 'INPUT') {
      e.preventDefault()
      props.history.push(`/catalog/entries/${props.item.entryId}/items/${props.item.id}`)
    }
  }

  return (
    <Card className="mb-2" onClick={handleClick}>
      <Card.Body className="p-3">
        <div className="container" style={{ maxWidth: 'none', paddingLeft: 0, paddingRight: 0 }}>
          <div className="row">
            <div key={props.item.id} className="col-2">
              <input type="checkbox" id="scales" name="scales" className='mr-2' onChange={() => checkedBoxChanged(event.target.checked)} checked={props.isSelected}/>
              <a href="">
                {props.item.code}
              </a>
            </div>
            {Object.keys(props.item.inputDataForListDisplay).map((key, index) => (
              <div key={props.item.id + index} className="col-3">
                <small className="mr-2 text-secondary">{props.item.inputDataForListDisplay[key].label}</small>
                {props.item.inputDataForListDisplay[key].value}
              </div>
            ))}
          </div>
        </div>
      </Card.Body>
    </Card>
  )
}

export default withRouter(ItemCard)
