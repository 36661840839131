import React, {useEffect, useRef, useState} from 'react'
import ReactHtmlParser from 'react-html-parser'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import RecordFieldDisplay from './recordFieldDisplay'
import EntryRecordFieldDisplay from './entryRecordFieldDisplay'
import FileAttachmentFieldDisplay from './fileAttachmentFieldDisplay'
import UrlFieldDisplay from './urlFieldDisplay'
import DurationFieldDisplay from './durationFieldDisplay'
import ContentLoader from "react-content-loader"

export const fieldTypes = [
  { id: 1, name: "Single Line Text", icon: "font"},
  { id: 2, name: "Multi Line Text", icon: "align-left"},
  { id: 3, name: "Checklist", hasOptions: true, icon: "check-circle"},
  { id: 4, name: "Dropdown", hasOptions: true, icon: "caret-square-down"},
  { id: 8, name: "Number", hasOptions: true, icon: "hashtag"},
  { id: 5, name: "Date", icon: "calendar-alt"},
  { id: 6, name: "Team Member", icon: "user"},
  { id: 7, name: "Input", icon: "sign-in-alt"},
  { id: 9, name: "File Attachment", hasOptions: true, icon: "file"},
  { id: 10, name: "URL", icon: "link"},
  { id: 11, name: "Duration", icon: "clock"},
  { id: 12, name: "Currency", hasOptions: true, icon: "money-bill-wave"},
]

export const itemFieldTypes = fieldTypes.filter(field=> {
  return field.id != 7
})

export const FieldDisplay = ({field, obj, isEntry, ...props}) => {

  const [expandText, setExpandText] = useState(false)

  if (Object.keys(field).includes("name")) {
    if (Object.keys(field.viewOptions).length > 0){
      if (field.viewOptions[obj.status] == "hidden")
        return (
          <OverlayTrigger
            placement="right"
            overlay={
              <Tooltip>
                This field is hidden
              </Tooltip>
            }
          >
            <i className="fas fa-eye-slash"></i>
          </OverlayTrigger>
        )
    }
  }

  switch (field.fieldTypeId) {
    case 2:
      let data = obj.inputDataForDisplay[field.id]
      let text = ReactHtmlParser(data)
      let showExpandBtn = false
      if (data != null && data.length > 200) {
        if (!expandText) {
          let regex = /<[^>]*>/g;
          let cleanString = data.replace(regex, '');
          text = <p>{cleanString.substring(0, 100)} ...</p>
        }
        showExpandBtn = true
      }
      return (
        <div>
          {text}
          { showExpandBtn &&
            <button className="btn btn-secondary btn-sm" onClick={()=>setExpandText(prev=>!prev)}>
              {!expandText ?
                <><i className="fas fa-arrow-down mr-1"></i>expand</>
                :
                <><i className="fas fa-arrow-up mr-1"></i>condense</>
              }

            </button>
          }
        </div>
      )

    case 7:
      if (isEntry)
        return (<EntryRecordFieldDisplay field={field} inputData={obj.inputData} />)
      else
        return (<RecordFieldDisplay field={field} inputData={obj.inputData} />)

    case 9:
      return (<FileAttachmentFieldDisplay field={field} inputData={obj.inputData} />)

    case 10:
      return (<UrlFieldDisplay field={field} inputData={obj.inputData} />)

    case 11:
      return (<DurationFieldDisplay field={field} inputData={obj.inputData} />)

    default:
      return (`${obj.inputDataForDisplay[field.id] != undefined ? obj.inputDataForDisplay[field.id] : ""}`)
  }
}

export const numberTypes = [
  { name: "Default (Units)", value: "", precision: [""] },
  { name: "Concentrations (moles per liter)", value: "concentrations", precision: ["M/l", "mM/l", "μM/l", "nM/l"] },
  { name: "Concentrations (grams per liter)", value: "concentrations(g/l)", precision: ["g/l", "μg/ml", "ng/μl"] },
  { name: "Current (Ampere)", value: "current", precision: ["A", "mA", "μA", "nA"] },
  { name: "Molecular Qtys (moles)", value: "moles", precision: ["M", "mM", "μM", "nM"] },
  { name: "Percentage (%)", value: "percentage", precision: ["%"] },
  { name: "Pressure (Pacal)", value: "pressure", precision: ["Pa"] },
  { name: "Temperature (C)", value: "temperature", precision: ["C"] },
  { name: "Voltage (volts)", value: "voltage", precision: ["V", "mV", "μV", "nV"] },
  { name: "Volume (liters)", value: "volume", precision: ["l", "ml", "μl", "nl"] },
  { name: "Weight (grams)", value: "weight", precision: ["kg", "g", "mg", "μg", "ng"] }
]

export const currencyTypes = [
  { name: "Dollars (USD)", value: "usd", symbol: "$", step: "0.01" },
  { name: "Euro (EUR)", value: "eur", symbol: "€", step: "0.01" },
  { name: "British Pound (GBP)", value: "gbp", symbol: "£", step: "0.01" },
  { name: "Canadian Dollar (CAD)", value: "cad", symbol: "C$", step: "0.01" },
  { name: "Swiss Franc (CHF)", value: "chf", symbol: "₣", step: "0.01" },
  { name: "Yen (JPY)", value: "jpy", symbol: "¥", step: "1" },
  { name: "Australian Dollar (AUD)", value: "aud", symbol: "A$", step: "0.01" }
]
