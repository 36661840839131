import React, { useState, useEffect } from 'react'
import {Modal} from 'react-bootstrap'
import AccountSubNav from './accountSubNav'
import LabelTemplatesList from './labelTemplatesList'
import LabelTemplateCreator from './labelTemplateCreator'
import limsApi from '../utils/limsApi'

const ManageLabelTemplates = () => {

  const [showLabelTemplateCreate, setShowLabelTemplateCreate] = useState(false)
  const [showLabelTemplateEdit, setShowLabelTemplateEdit] = useState(false)

  const [reloadList, setReloadList] = useState(false)

  const closeTemplateCreator = () => {
    setShowLabelTemplateCreate(false)
    setShowLabelTemplateEdit(false)
  }

  const deleteLabelTemplate = (id) => {
    limsApi.delete(`label_templates/${id}`, "Template Deleted", ()=>{setReloadList(prev=>!prev)})
  }

  const modalForLabelTemplateCreator = (
    <Modal show={showLabelTemplateCreate || showLabelTemplateEdit} size="xl" className="modal-full-width" enforceFocus={false} onHide={closeTemplateCreator}>
      <Modal.Header closeButton>
        <Modal.Title>Label Template</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <LabelTemplateCreator
          create={showLabelTemplateCreate}
          templateId={showLabelTemplateEdit}
          handleDelete={()=>console.log("handle delete")}
          closeModal={closeTemplateCreator}
          onSave={()=>{setReloadList(prev=>!prev)}}
          />
      </Modal.Body>
    </Modal>
  )

  return (
        <div>
          <div className="col-fixed bg-light">
            <AccountSubNav />
          </div>
          <div className="col-fluid p-4 bg-white">
            <button className="btn btn-primary float-right" onClick={() => setShowLabelTemplateCreate(true)}>
              Create Template
            </button>
            <h3 className="mb-2">Manage Label Templates</h3>
            <p className="mb-4">Select the label templates that are compatible with the labels in your lab.</p>
            <LabelTemplatesList reload={reloadList} updateTemplate={(id)=>setShowLabelTemplateEdit(id)} deleteTemplate={(id)=>deleteLabelTemplate(id)} />
          </div>
          {modalForLabelTemplateCreator}
        </div>
  )
}

export default ManageLabelTemplates
