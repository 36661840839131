import React, { useState, useEffect } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink,
  withRouter
} from 'react-router-dom'

const AccountSubNav = (props) => {
  return (
    <nav className="sidebar p-3">
      <NavLink
        to="/manage/item-types"
        className="nav-item nav-link mr-2"
        activeClassName="active"
      >
        Catalog
      </NavLink>
      <NavLink
        to="/manage/locations"
        className="nav-item nav-link mr-2"
        activeClassName="active"
      >
        Locations
      </NavLink>
      <NavLink
        to="/manage/users"
        className="nav-item nav-link mr-2"
        activeClassName="active"
      >
        Users
      </NavLink>
      <NavLink
        to="/manage/printers"
        className="nav-item nav-link mr-2"
        activeClassName="active"
        exact
      >
        Printers
      </NavLink>
      <NavLink
        to="/manage/sensors"
        className="nav-item nav-link mr-2"
        activeClassName="active"
        exact
      >
        Sensors
      </NavLink>
      <NavLink
        to="/manage/label_templates"
        className="nav-item nav-link mr-2"
        activeClassName="active"
        exact
      >
        Label Templates
      </NavLink>
      <NavLink
        to="/manage/account"
        className="nav-item nav-link mr-2"
        activeClassName="active"
      >
        Account &amp; Billing
      </NavLink>
    </nav>
  )
}

export default withRouter(AccountSubNav)
