import React, { useState, useRef, useEffect } from 'react'
import { Modal, OverlayTrigger, Popover, Tooltip, Button } from 'react-bootstrap'
import { Link, withRouter } from 'react-router-dom'
import axios from "axios"
import limsApi from '../utils/limsApi'
import SingleLineTextField from './fields/singleLineTextField'
import MultiLineTextField from './fields/multiLineTextField'
import ChecklistField from './fields/checklistField'
import DropdownField from './fields/dropdownField'
import NumberField from './fields/numberField'
import DateField from './fields/dateField'
import TeamMemberField from './fields/teamMemberField'
import EntryRecordField from './fields/entryRecordField'
import FileAttachmentField from './fields/fileAttachmentField'
import UrlField from './fields/urlField'
import DurationField from './fields/durationField'
import CurrencyField from './fields/currencyField'
import DeleteConfirmation from './deleteConfirmation'
import RichTextEditor from './richTextEditor'

const EntryForm = (props) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [fileUploading, setFileUploading] = useState(false)
  const [entry, setEntry] = useState({
    name: "",
    description: "",
    itemTypeId: props.itemType.id,
    code: "",
    instanceName: ""
  })
  const [inputData, setInputData] = useState({})
  const [inputDataIsLoading, setInputDataIsLoading] = useState(true)
  const ref = useRef(null)
  const pluralize = require('pluralize')
  const instanceNameSingular = "Item"
  const instanceNamePlural = pluralize(instanceNameSingular, 2)

  const handleSubmit = (event) => {
    event.preventDefault()

    // create
    if(props.create == true) {
      limsApi.post('entries', { entry: entry, inputData: inputData }, `${entry.name} Created`, (response)=>{
          const entryData = response.data.entry
          props.onSuccess(entryData)
        },
        'Error creating entry', undefined, setIsSubmitting)
    // update
    } else {
      limsApi.patch(`entries/${entry.id}`, { entry: entry, inputData: inputData }, `${entry.name} Updated`, (response)=>{
          const entryData = response.data.entry
          props.onSuccess(entryData)
        },
        'Error creating entry', undefined, setIsSubmitting)
    }
  }

  const handleChange = (event) => {
    setEntry({...entry, [event.target.name]: event.target.value})
  }

  const handleInputDataChange = (event) => {
    setInputData({...inputData, [event.target.name]: event.target.value})
  }

  const handleFileDataChange = (key, value) => {
    // console.log(event.target.name);
    setInputData({...inputData, [key]: value})
  }

  const handleChecklistChange = (field, checkedItems) => {
    setInputData({...inputData, [field.id]: checkedItems})
  }

  const fetchEntry = async () => {
    await limsApi.get(`entries/${props.entryId}`, (response)=>{
      const entryData = response.data.entry
      const inputDataPayload = response.data.entry.inputData
      setEntry(entryData)
      setInputData(inputDataPayload)
      setInputDataIsLoading(false)
    },
    'Error fetching entry', undefined, setIsLoading)
  }

  const fetchExport = async () => {
    let url = `${window.location.origin}/api/export_entry/${entry.id}.csv`
    await axios.request({
        url: url,
        method: "GET",
        responseType: 'blob', //important
      })
      .then( ({ data }) => {
        let file = window.URL.createObjectURL(new Blob([data]))
        const link = document.createElement('a')
        link.href = file
        link.setAttribute('download', `${entry.name}_export.csv`)
        document.getElementById("deleteConfirmationPopover").appendChild(link)  // The link gets added to the delete confirmation so that it stays open (due to rootClose prop)
        link.click()
        link.remove()
      });
  }

  useEffect(() => {
    if(props.create != true) {
      fetchEntry()
    } else {
      setInputDataIsLoading(false)
    }
  }, [props.entryId])

  return (
    <form ref={ref} className="mb-1" onSubmit={handleSubmit}>
      <div className="form-group">
        <label>Name</label>
        <input
          type="text"
          name="name"
          className="form-control"
          value={entry.name}
          onChange={handleChange}
          required
        />
      </div>
      <div className="form-group">
        <label>Description</label>
        { !isLoading &&
          <RichTextEditor data={entry.description || ""} handleChange={(data)=>{setEntry({...entry, ["description"]: data})}} />
        }
      </div>
      { props.itemType?.allTypeFields && !inputDataIsLoading ? (
        <div>
          {props.itemType.allTypeFields.map((field, index) => (
            <div key={field.id} className="form-group">
              {
                {
                  1: <SingleLineTextField field={field} inputData={inputData} handleChange={handleInputDataChange} />,
                  2: <MultiLineTextField field={field} inputData={inputData} handleChange={handleInputDataChange} />,
                  3: <ChecklistField field={field} inputData={inputData} handleChange={handleChecklistChange} />,
                  4: <DropdownField field={field} inputData={inputData} handleChange={handleInputDataChange} />,
                  5: <DateField field={field} inputData={inputData} handleChange={handleInputDataChange} />,
                  6: <TeamMemberField field={field} inputData={inputData} handleChange={handleInputDataChange} />,
                  7: <EntryRecordField field={field} inputData={inputData} handleChange={handleInputDataChange} formRef={ref} />,
                  8: <NumberField field={field} inputData={inputData} handleChange={handleInputDataChange} />,
                  9: <FileAttachmentField field={field} inputData={inputData} handleChange={handleFileDataChange} setFileUploading={(bool) => setFileUploading(bool)} />,
                  10:<UrlField field={field} inputData={inputData} handleChange={handleInputDataChange} />,
                  11:<DurationField field={field} inputData={inputData} handleChange={handleInputDataChange} />,
                  12:<CurrencyField field={field} inputData={inputData} handleChange={handleInputDataChange} />
                }[field.fieldTypeId]
              }
            </div>
          ))}
        </div>
      ) : (
        <div className="my-5 py-5 text-center">Loading...</div>
      )}
      { !props.create ? (
          entry.canBeDeleted || true ? (
              <DeleteConfirmation
                modelName="Entry"
                delete={()=>props.handleDelete(entry.id)}
                requiresKey
                forEntry
                keyAnswer={entry.name}
                onEnter={()=>fetchExport()}
              >

                    <Button variant="outline-danger" className="float-right">
                      {!props.isDeleting ?
                        "Delete Entry"
                        :
                        <i className="fas fa-spinner fa-spin"></i>
                      }
                      </Button>
              </DeleteConfirmation>
            ) : (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip>
                    Cannot delete this entry because it has items associated with it.
                  </Tooltip>
                }
              >
                <div className="float-right" style={{display: 'inline-block'}}>
                  <button disabled className="btn btn-outline-danger float-right" style={{pointerEvents: "none"}}>Delete Entry</button>
                </div>
              </OverlayTrigger>
            )
        ) : null
      }
      <button type="submit" className="btn btn-primary" disabled={isSubmitting || fileUploading}>
        { !isSubmitting ? (
          "Save"
        ) : (
          <i className="fas fa-spinner fa-spin"></i>
        )}
      </button>
      <Link
        to={props.cancelPath}
        className="btn btn-link"
      >
        Cancel
      </Link>
    </form>
  )
}

export default EntryForm
