import {parseOrIdentity} from "./pureStateFns";

/**
 * Given a state `setterFn` (setter function), generate a default onChange handler for handling input onChange events.
 * @param setterFn
 */
export const generateOnChange = (setterFn) => {
    return (event) => {
        const {name, value} = event.target
        setterFn(model => {
            // parsing value allows for objects as values since value must be a string in HTML
            return {...model, [name]: parseOrIdentity(value)}
        })
    }
}