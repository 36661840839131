import React, { useState, useEffect } from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
// import BalloonEditor from '@ckeditor/ckeditor5-build-balloon'

const RichTextEditor = ({ data, handleChange, disabled }) => {
  return (
    <CKEditor
        editor={ ClassicEditor }
        data={data}
        disabled={disabled}
        config={{
          mediaEmbed: {
            previewsInData: true,
          },
          toolbar: [
            'heading', '|',
            'bold', 'italic', 'link', 'bulletedList', 'numberedList', '|',
            'outdent', 'indent', '|',
            'blockQuote', 'insertTable'
        ],
        }}
        onReady={ editor => {
          // console.log("Has setting changed");
            // You can store the "editor" and use when it is needed.
            // console.log( 'Editor is ready to use!', editor );
        } }
        onChange={ ( event, editor ) => {
            const newData = editor.getData();
            // console.log("old:", data, "new:", newData);
            handleChange(newData)
            // console.log( { event, editor, data } );
        } }
    />
  )
}

export default RichTextEditor
