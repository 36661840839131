import React, { useState, useEffect } from 'react'
import {Modal, Button} from 'react-bootstrap'

const SetupPrintingModal = (props) => {

  const getOS = () => {
    const platform = window.navigator.platform
    if (platform.includes('Win')) {
      return 'Windows'
    }
    return 'Other'
  }

  const handleHide = () => {
    props.reload()
    props.closeModal()
  }

  let instrutionsList = <p>The printing client is only supported on Windows. Please locate a Windows machine in your lab and restart this proccess.</p>
  if (getOS() == 'Windows') {
    instrutionsList = (
      <ol>
        <li>
          Connect your ZPL capable label printer to your network.
        </li>
        <li>
          Find a computer that will always be connected to the printer.
        </li>
        <li>
          Download the printing client on that computer.
          <a href='https://app.printnode.com/download/client/genofab/windows' style={{display: "inline-block", padding: "0px", margin: "0px"}}>Click to download</a>
        </li>
        <li>
          Log in to the printing client using your account credentials.<br/>
          <small>Tip: All lab members can use their own credentials.</small>
        </li>
        <li>
          Go to the printers tab in the client and only select your label printer as active.
        </li>
        <li>
          You are all setup! Close this window and add your printer.
        </li>
      </ol>)
  }

  return(
    <Modal show={true} onHide={handleHide}>
        <Modal.Header closeButton onClick={handleHide}>
          <Modal.Title>Setup Printing</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {instrutionsList}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleHide}>
            Done
          </Button>
        </Modal.Footer>
      </Modal>
  )
}

export default SetupPrintingModal
