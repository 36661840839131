import React from "react";
import {parseOrIdentity, identity} from "../../utils/pureStateFns";

/**
 * These are GenoFAB components used in forms. Standardized and generalized.
 * The onChange handler can expect to receive a target with a name and value representing the "field" to be changed in
 * the model/state. A handler might look something like:
 * const handler = (event) => {
 *   const {name, value} = event.target
 *   setState(state=>{return {...state, name: value}})
 * }
 *
 * All input based components eventually use GFormGroup to get the basic layout and UI.
 */
const GFormGroup = ({name, label, className, children, ...props}) => (
    <div className={'form-group ' + (className || '')}>
        {label && <label htmlFor={name}>{label}</label>}
        {children}
    </div>
)

export const GInput = ({type, name, value, onChange, label, controlClassName, autoFocus, children, ...props}) => {
    return (
        <GFormGroup label={label || children} name={name} {...props}>
            <input type={type} className={`form-control ${controlClassName || ''}`} value={value || ""}
                   id={name} name={name} onChange={onChange} autoFocus={autoFocus}/>
        </GFormGroup>
    )
}

/**
 * Example usage: <GTextInput label={'Address'} name={'address'} value={account?.address} onChange={onChangeHandler}/>
 * An example onChangeHandler may look as follows:
  const onChangeHandler = (event) => {
    const {name, value} = event.target
    setAccount(account=>{return {...account, [name]: value}}))
  }
 * A few important things to note in the above example:
 * - setAccount is a React state function: `const [account, setAccount] = useState()`
 * - event is a standard javascript DOM event generated from a standard form field, nothing special
 * - the 'name' and 'value' are copied from the 'event' before calling the setAccount. This is imperative!
 *   `setAccount` is asynchronous and `event` will not be valid when the code inside `setAccount` is executed.
 *   Therefore, the values inside `event` must be retrieved ahead of time.
 * - Notice that `onChangeHandler` is generic. So it could be reused for multiple (maybe all) form fields for an
 *   object. e.g., `<GTextInput label={'Email'} name={'email'} value={account.email} onChange{onChangeHandler}/>` will work
 *   with exactly the same change handler. Reuse change handlers to make your code DRY.
 * - account.address does not need to be initialized. GTextInput intelligently handles null or undefined.
 * @param children
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export const GTextInput = ({children, ...props}) => {
    return (<GInput type={'text'} {...props}>{children}</GInput>)
}

export const GTextArea = ({name, value, onChange, ...props}) => {
    return (
        <GFormGroup name={name} {...props}>
            <textarea id={name} name={name} onChange={onChange}
                      className={"form-control"} rows="4" value={value}
            ></textarea>
        </GFormGroup>
    )
}

function resolveValue(value, options) {
    if (typeof(value) === 'object' && value?.id ) {
        return options.find((opt)=>value.id===opt?.id)
    }
    return value
}

/**
 * Examples:
 *   <GSelectInput label={"Country"} name={"country"} options={['USA', 'Canada']}/>
 *   <GSelectInput label={"Country"} name={"country"}
 *                 options={[{id: 1, name: 'USA'}, {id: 2, name:'Canada'}]}
 *                 render={(country)=>country.name}/>
 *
 * `value` should match exactly one of the elements in the `options` array, not the `id` or some field if the
 * array elements are objects/maps. Therefore, in the second example, to have a value of USA, `value` must be
 * `{id: 1, name: 'USA'}`. In order to facilitate this, JSON.stringify is called on each array element and on the
 * `value` property. The `onChange` handler can suitably parse using parseOrIdentity.
 *
 * @param name
 * @param value
 * @param onChange
 * @param className
 * @param options
 * @param label
 * @param render
 * @param required
 * @param children
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export const GSelectInput = ({name, value, onChange, controlClassName, options, label, render, required, disabled, children, ...props}) => {
    const renderFn = render || identity;
    const canonicalValue = resolveValue(value, options)
    return (
        <GFormGroup name={name} label={label || children} {...props}>
            <select id={name} name={name} className={`form-control ${controlClassName || ''}`} onChange={onChange}
                    value={JSON.stringify(canonicalValue)} disabled={disabled}>
                {!required?<option key={'null'} value={JSON.stringify(null)}></option>:""}
                {options.map(opt => <option key={opt?.id || opt} value={JSON.stringify(opt)}>{renderFn(opt)}</option>)}
            </select>
        </GFormGroup>
    )
}

const inputElementTypes = new Set([GInput, GTextInput, GTextArea, GSelectInput])

export const isInputElement = (element) => {
    return React.isValidElement(element) && inputElementTypes.has(element.type)
}
