import React, { useState, useEffect } from 'react'
import { Image, Row, Col, Container, OverlayTrigger, Tooltip } from 'react-bootstrap'
import moment from 'moment'
import {Link, withRouter} from 'react-router-dom'
import EventListItemEditHistory from './eventListItemEditHistory'

const EventListItem = (props) => {
  const [showEditHistory, setShowEditHistory] = useState(false)
  const hasEditHistory = props.event.editedFields ? true : false

  const toggleItemHistory = () => {
    if (hasEditHistory) {
      setShowEditHistory((prev)=>(!prev))
    }
  }

  const itemUrl = (item) => {
    if(item.entry?.itemType) {
      return `/catalog/entries/${item.entryId}/items/${item.id}`
    }
  }

  let eventDescription = {}
  const nameArr = props.user ? props.user.fullName.split(' ') : ["A user"]
  const str = `${nameArr.length > 1 ? `${nameArr[0]} ${nameArr[1].charAt(0)}` : nameArr[0]} ${props.action}`
  let arr = str.split('#insertlink#')
  if (props.items != null && arr.length > 1) {
    const aTags = props.itemIds.map(id => {
      const item = props.items.find(item => item.id == id)
      if (item) {
        return(
          <Link
            key={props.event.id}
            to={itemUrl(item)}
            style={{display: "inline-block",
                    padding: "0px",
                    margin: "0px"}}
          >
            {item.code}
          </Link>)
        }
      })
    let out = []
    let key = 0
    out.push(<React.Fragment key={key}>{arr.shift()}</React.Fragment>)
    for(let j = 0; j < arr.length; j++) {
       key++
       out.push(aTags.shift())
       out.push(<React.Fragment key={key}>{arr.shift()}</React.Fragment>)
       j--
     }
    eventDescription = out

  } else {
    eventDescription = str
  }

  let tooltipText = ""
  if (hasEditHistory)
    tooltipText = showEditHistory ? "Collapse" : "More details"

  return(
        <Container className={hasEditHistory ? "eventListItem pb-2" : "pb-2"} onClick={toggleItemHistory} style={props.hasDivider ? {'borderTop': '1px solid rgba(0, 0, 0, 0.125)'} : {}}>
          <Row className={!props.hasDivider ? "pt-2" : ""}>
            <Col className="d-flex" >
              <div className={props.hasDivider ? "mr-2 mt-2" : "mr-2"} >
                <Image width="40" height="40" src={props.user ? props.user.avatarUrl : "/assets/fallback/default.png"} roundedCircle />
              </div>
              <div className={props.hasDivider ? "mt-2" : ""}>
                {eventDescription}
                <br/>
                <small style={{color: 'grey'}}>
                  {moment(props.timestamp).fromNow()}
                </small>
                { hasEditHistory && ( !showEditHistory && (
                  <OverlayTrigger
                    placement="top"
                    overlay={ hasEditHistory ? (
                          <Tooltip>
                            {tooltipText}
                          </Tooltip>
                        ) : (<div></div>)
                      }
                  >
                  <span className="ml-4">
                  ...
                  </span>
                  </OverlayTrigger>
                ))}
              </div>
            </Col>
          </Row>
          { hasEditHistory && showEditHistory &&
              <Row>
                <Col>
                  <EventListItemEditHistory event={props.event} items={props.items} />
                </Col>
              </Row>
          }
        </Container>
      )
}

export default EventListItem
