import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import EventsList from './eventsList'
import Leaderboard from './leaderboard'
import ActivityReport from './activityReport'
import ChangePasswordModal from './changePasswordModal'

const Dashboard = ({emailConfirmed, changePassword, ...props}) => {
  const history = useHistory();
  if (emailConfirmed)
    window.flash("Email Verified", 'success')

  const passwordChanged = () => {
    history.push('/') // Clears the modal from the screen
  }

  return (
    <div>
      {changePassword && <ChangePasswordModal onSuccess={passwordChanged} />}
      <div className="p-4 bg-white col-fluid">
        <div className="mb-4">
          <ActivityReport />
        </div>
      </div>
      <div className="col-fixed bg-light">
        <EventsList />
      </div>
    </div>
  )
}

export default Dashboard
