import React, { useState, useEffect } from 'react'
import { Table } from 'react-bootstrap'
import { Switch, withRouter, Link, Route } from 'react-router-dom'

const LocationBoxView = ( { items, location } ) => {

  const colsNum = 10
  const rowsNum = 10
  let itemIndex = 0

  const itemLink = (item) => (
    <Link to={`/locations/${location.id}/items/${item.id}`}>
      {item.code}
    </Link>
  )

  const renderRow = (rowIndex) => {
    let rowItems = []
    for (let i = 0; i < colsNum; i++) {
      const itemIndex = rowIndex * colsNum + i;
      rowItems.push(
        <td
          key={itemIndex}
          className="text-center"
          style={{position:'relative'}}
        >
          {items.length -1 >= itemIndex ? (
            <>
              <small className='text-secondary' style={{position:'absolute', top: -11, left: 2}}>
                {String.fromCharCode(65 + i)}{rowIndex+1}
              </small>
              {itemLink(items[itemIndex])}
            </>
      ) : (
        <span style={{visibility: 'hidden'}}>#XXX</span>)}
      </td>)
    }
    return rowItems
  }

  const renderRows = () => {
    let rows = []
    for(let r = 0; r < rowsNum; r++) {
      rows.push(<tr key={r} style={{ lineHeight: '40px'}}>{renderRow(r)}</tr>)
    }
    return (rows)
  }

  const tableData = renderRows()

  return (
    <Table striped bordered>
      <tbody>
        {tableData}
      </tbody>
    </Table>
  )
}

export default LocationBoxView
