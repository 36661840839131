import React, { useState, useRef, useEffect } from 'react'
import { OverlayTrigger, Popover, Tooltip, Button, Container, Row, Col, Form, Card } from 'react-bootstrap'
import { Link, withRouter } from 'react-router-dom'
import limsApi from '../utils/limsApi'
import DeleteConfirmation from './deleteConfirmation'
import LabelPreview from './labelPreview'

const LabelTemplateCreator = (props) => {
  const [isLoading, setIsLoading] = useState(props.create ? false : true)
  const [isUpdatingImage, setIsUpdatingImage] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [didCreate, setDidCreate] = useState(false)
  const [zpl, setZpl] = useState("")
  const [didHandleChange, setDidHandleChange] = useState(true)
  const [labelInputs, setLabelInputs] = useState({
    lines: ["Line 1", "Line 2", "Line 3"],
    barcode_text: "Sample data",
    dpi: 300,
    from: ""
  })
  const [labelTemplate, setLabelTemplate] = useState(
    props.create ?
    {
    name: "",
    width: 1,
    height: 0.5,
    numOfLines: 3,
    topShift: 0,
    leftShift: 0
  } : {})
  const [lineFields, setLineFields] = useState(
    props.create ?
    [
      { line_num: 1, x: 70, y: 20, font_size: 34 },
      { line_num: 2, x: 20, y: 70, font_size: 22 },
      { line_num: 3, x: 20, y: 100, font_size: 22 }
    ]
    : []
  )
  const [barcodeField, setBarcodeField] = useState(props.create ? {
    x: 10,
    y: 10,
    size: 3,
    shape: "square"
  } : {})

  const MAX_LINES_PER_SQUARE_INCH = 7
  const longView = labelTemplate.height / labelTemplate.width > 0.8

  const getZpl = () => {

    const newLabelData = {...labelInputs}
    // console.log(labelTemplate);
    // console.log(lineFields);

    limsApi.post('get_unsaved_template_zpl', {label: {labels: [newLabelData]}, labelTemplate: labelTemplate, lineFields: convertArrayToObject(lineFields, "line_num"), barcodeField: barcodeField}, '', (response)=>{
      const zpl = response.data.response
      // console.log(zpl);
      setZpl(zpl)
    },
    '', null, setIsUpdatingImage)
  }

  useEffect(()=>{
    if (!isLoading && !isUpdatingImage)
      getZpl()
  }, [labelInputs, labelTemplate, lineFields, barcodeField, isLoading])

  const convertArrayToObject = (array, key) => {
    const initialValue = {};
    return array.reduce((obj, item) => {
      return {
        ...obj,
        [item[key]]: item,
      };
    }, initialValue);
  };

  const fetchTemplate = () => {
    limsApi.get(`label_templates/${props.templateId}`, (response)=>{
      console.log(response);
      const template = response.data.labelTemplate
      setLabelTemplate(template)
      setBarcodeField(template.barcodeField)
      setLineFields(Object.values(template.lineFields))
    }, 'Error fetching template', undefined, setIsLoading)
  }

  useEffect(()=>{
    if (props.templateId)
      fetchTemplate()
  }, [])

  const handleSubmit = (event) => {
    event.preventDefault()

    const labelTemplateData = {
      ...labelTemplate,
      numOfLines: lineFields ? lineFields.length : 0
    }

    // create
    if(props.create && !didCreate) {
      limsApi.post('label_templates', { labelTemplate: labelTemplateData, lineFields: convertArrayToObject(lineFields, "line_num"), barcodeField: barcodeField }, ``, (response)=>{
          const labelTemplateData = response.data.labelTemplate
          // console.log(labelTemplateData);

          setLabelTemplate(labelTemplateData)
          setDidCreate(true)
          props.onSave()
          props.closeModal()
        },
        '', undefined, setIsSubmitting)
    // update
    } else {
      limsApi.patch(`label_templates/${labelTemplate.id}`, { labelTemplate: labelTemplateData, lineFields: convertArrayToObject(lineFields, "line_num"), barcodeField: barcodeField }, ``, (response)=>{
        const labelTemplateData = response.data.labelTemplate
        // console.log(labelTemplateData);

        setLabelTemplate(labelTemplateData)
        props.onSave()
        props.closeModal()
      },
      '', undefined, setIsSubmitting)
    }
  }

  const handleTemplateChange = ({ target }) => {
    setDidHandleChange(prev=>!prev)
    let val = target.value
    setLabelTemplate({...labelTemplate, [target.name]: val})
  }

  const handleLineChange = (target, num) => {
    setDidHandleChange(prev=>!prev)
    let val = target.value
    setLineFields((prev)=> {
      let newLineFields = [...prev]
      newLineFields[num] = {...lineFields[num], [target.name]: val}
      return newLineFields
    })
  }

  const handleBarcodeChange = ({ target }) => {
    setDidHandleChange(prev=>!prev)
    let val = target.value
    setBarcodeField({...barcodeField, [target.name]: val})
  }

  const handleInputChange = ({target}) => {
    setDidHandleChange(prev=>!prev)
    let val = target.value
    setLabelInputs({...labelInputs, [target.name]: val})
  }

  const handleInputLineChange = (target, lineIndex) => {
    setDidHandleChange(prev=>!prev)
    let val = target.value
    setLabelInputs(prev=> {
      let newValue = {...prev}
      newValue.lines[lineIndex] = val
      return newValue
    })
  }

  const addInputLine = () => {
    const newNumOfLines = labelTemplate.numOfLines + 1
    setLabelTemplate(prev=>({...prev, numOfLines: newNumOfLines}))
    setLineFields(prev=> {
      let newLineFields = [...prev]
      newLineFields.push({ line_num: newNumOfLines, x: 50, y: 100, font_size: 22 })
      return newLineFields
    })

    if (labelInputs.lines.length < newNumOfLines) {
      setLabelInputs(prev=>{
        let newLabelInputs = {...prev}
        newLabelInputs.lines.push(`Line ${newNumOfLines}`)
        return newLabelInputs
      })
    }
    setDidHandleChange(prev=>!prev)
  }

  const removeInputLine = () => {
    setLabelTemplate(prev=>({...prev, numOfLines: prev.numOfLines-1}))
    setLineFields(prev=> {
      let newLineFields = [...prev]
      newLineFields.pop()
      return newLineFields
    })
    setDidHandleChange(prev=>!prev)
  }

  const maxDotValue = (forWhat) => {
    const dots = forWhat == "x" ? labelTemplate.width : labelTemplate.height
    return `${(dots * 300) - 10}`
  }

  const renderInputLines = () => {
    let html = []
    for (let i = 0; i < lineFields.length; i++) {
      html.push(
        <div className="form-row" key={i}>
          <div className="form-group col">
            <label>Line {i+1}</label>
            <input
              type="text"
              name="lines"
              className="form-control"
              value={labelInputs.lines[i]}
              onChange={()=>handleInputLineChange(event.target, i)}
            />
          </div>
        </div>
      )
    }
    return html
  }

  const labelInputsForm = (
    <>
      <h5 className="mb-3">Sample Text</h5>
      {renderInputLines()}
      <div className="form-row">
        <div className="form-group col">
          <label>Barcode</label>
          <input
            type="textarea"
            name="barcode_text"
            className="form-control"
            value={labelInputs.barcode_text}
            onChange={handleInputChange}
          />
        </div>
      </div>
    </>
  )

  const barcodeFieldFormRow = (
    <>
    <h5 className="mb-1">Barcode</h5>
    <div className="form-row">
      <div className="form-group col">
        <label>X (dots)</label>
        {/*
        <input
          type="number"
          name="x"
          step="any"
          required
          className="form-control"
          value={barcodeField.x}
          onChange={handleBarcodeChange}
        />
        */}
        <input
          type="range"
          name="x"
          step="5"
          className="form-control"
          max={maxDotValue("x")}
          min="0"
          value={barcodeField.x}
          onChange={handleBarcodeChange}
        />
      </div>
      <div className="form-group col">
        <label>Y (dots)</label>
        {/*
        <input
          type="number"
          name="y"
          step="any"
          required
          className="form-control"
          value={barcodeField.y}
          onChange={handleBarcodeChange}
        />
        */}
        <input
          type="range"
          name="y"
          step="5"
          className="form-control"
          max={maxDotValue("y")}
          min="0"
          value={barcodeField.y}
          onChange={handleBarcodeChange}
        />
      </div>
    </div>
    <div className="form-row">
      <div className="form-group col">
        <label>Size</label>
        <select
          name="size"
          className="form-control"
          value={barcodeField.size}
          onChange={handleBarcodeChange}
        >
          <option value={2}>small</option>
          <option value={3}>medium</option>
          <option value={4}>large</option>
        </select>
      </div>
      <div className="form-group col">
        <label>Shape</label>
        <select
          name="shape"
          className="form-control"
          value={barcodeField.shape}
          onChange={handleBarcodeChange}
        >
          <option value="square">square</option>
          <option value="rectangle">rectangle</option>
        </select>
      </div>
    </div>
    </>
  )

  const renderLineFields = () => {
    let html = []
    for (let i = 0; i < lineFields.length; i++) {
      html.push(lineFieldFormRow(i))
    }
    return html
  }

  const lineFieldFormRow = (num) => {
    const field = lineFields[num]
    return (
      <>
        <h5 className="mb-1">Line {num+1}</h5>
        <div className="form-row">
          <div className="form-group col">
            <label>X (dots)</label>
            {/*
            <input
              type="number"
              name="x"
              step="any"
              required
              className="form-control"
              value={field.x}
              onChange={()=>handleLineChange(event.target, num)}
            />
            */}
            <input
              type="range"
              name="x"
              step="5"
              className="form-control"
              max={maxDotValue("x")}
              min="0"
              value={field.x}
              onChange={()=>handleLineChange(event.target, num)}
            />
          </div>
          <div className="form-group col">
            <label>Y (dots)</label>
            {/*
            <input
              type="number"
              name="y"
              step="any"
              required
              className="form-control"
              value={field.y}
              onChange={()=>handleLineChange(event.target, num)}
            />
            */}
            <input
              type="range"
              name="y"
              step="5"
              className="form-control"
              max={maxDotValue("y")}
              min="0"
              value={field.y}
              onChange={()=>handleLineChange(event.target, num)}
              />
          </div>
          <div className="form-group col-3">
            <label>Font Size</label>
            <input
              type="number"
              name="font_size"
              step="1"
              min="10"
              max="999"
              required
              className="form-control"
              value={field.font_size}
              onChange={()=>handleLineChange(event.target, num)}
            />
          </div>
        </div>
      </>
    )
  }

  return (
    <form className="mb-1" onSubmit={handleSubmit}>
      <Container style={{ paddingLeft: 0, paddingRight: 0 }}>
        <Row className="align-items-center">
          <Col>
            <div className="form-row">
              <div className="form-group col">
                <label>Name</label>
                <input
                  type="text"
                  name="name"
                  required
                  className="form-control"
                  value={labelTemplate.name}
                  onChange={handleTemplateChange}
                />
              </div>
              <div className="form-group col">
                <label>Label Url</label>
                <input
                  type="text"
                  name="exampleUrl"
                  className="form-control"
                  value={labelTemplate.exampleUrl}
                  onChange={handleTemplateChange}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col">
                <label>Width (inches)</label>
                <input
                  type="number"
                  name="width"
                  step="any"
                  max="99"
                  min="0"
                  required
                  className="form-control"
                  value={labelTemplate.width}
                  onChange={handleTemplateChange}
                />
              </div>
              <div className="form-group col">
                <label>Height (inches)</label>
                <input
                  type="number"
                  name="height"
                  step="any"
                  max="99"
                  min="0"
                  required
                  className="form-control"
                  value={labelTemplate.height}
                  onChange={handleTemplateChange}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col">
                <label>Top Shift (inches)</label>
                <input
                  type="number"
                  name="topShift"
                  step="any"
                  className="form-control"
                  value={labelTemplate.topShift}
                  onChange={handleTemplateChange}
                />
              </div>
              <div className="form-group col">
                <label>Left Shift (inches)</label>
                <input
                  type="number"
                  name="leftShift"
                  step="any"
                  className="form-control"
                  value={labelTemplate.leftShift}
                  onChange={handleTemplateChange}
                />
              </div>
            </div>
            {renderLineFields()}

            <div className="form-row mb-2">
              <button
                type="button"
                className="btn btn-outline-danger btn-sm mr-2"
                disabled={labelTemplate.numOfLines <= 1}
                onClick={removeInputLine}
              >
                Remove line
              </button>
              <button
                type="button"
                className="btn btn-outline-success btn-sm"
                disabled={labelTemplate.numOfLines >= MAX_LINES_PER_SQUARE_INCH * labelTemplate.height * labelTemplate.width}
                onClick={addInputLine}
              >
                Add line
              </button>
            </div>

            {barcodeFieldFormRow}

            <div className="pt-2">
              <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                { !isSubmitting ? (
                  "Save"
                ) : (
                  <i className="fas fa-spinner fa-spin"></i>
                )}
              </button>
              <Link
                to={'#'}
                onClick={props.closeModal}
                className="btn btn-link"
              >
                Cancel
              </Link>
            </div>
          </Col>
          { longView ? (
            <>
              <Col>
                <div className="text-center mb-3">
                  <LabelPreview zpl={zpl} labelTemplate={labelTemplate} dpi={300} index={0} />
                </div>
              </Col>
              <Col md={3}>
                {labelInputsForm}
              </Col>
            </>
          ) : (
            <Col>
              <div className="text-center mb-3">
                <LabelPreview zpl={zpl} labelTemplate={labelTemplate} dpi={300} index={0} />
              </div>
              {labelInputsForm}
            </Col>
          )}
        </Row>
      </Container>
    </form>
  )
}

export default LabelTemplateCreator
