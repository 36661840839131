import React, { useRef, useState } from 'react'
import { OverlayTrigger, Popover } from 'react-bootstrap'

// This is a delete confirmation Popover that displays as a warning
const DeleteConfirmation = ({modelName, requiresKey, keyAnswer, onEnter=()=>{}, forEntry, ...props}) => {
  let [key, setKey] = useState("")
  let ref = React.useRef(null);
  const name = modelName || 'item'

  const allowDelete = () => {
    if (requiresKey)
      return key == keyAnswer
    return true
  }

  return (
    <OverlayTrigger
      placement="auto"
      trigger="click"
      onEnter={()=>onEnter()}
      rootClose
      overlay={
        <Popover arrowProps={{style: { display: "none" }}} id={`popover-positioned-bottom`}>
          <Popover.Title as="h3">Are you sure?</Popover.Title>
          <Popover.Content className="text-center" id="deleteConfirmationPopover">
            { !forEntry ?
              <div className="mb-2">Once deleted, this {name} cannot be recovered.</div>
              :
              <div className="mb-2 text-danger"><b>Once deleted this Entry and all of its<br/>items cannot be recovered.</b></div>
            }
            { requiresKey &&
              <div className="form-group">
                {forEntry &&
                  <div>
                    <div className="mb-2">
                      Please verify that the entry has been<br/>exported before deleting.
                      <br/>
                      <a href="#" onClick={()=>onEnter()}>
                        Download again
                      </a>
                    </div>
                    <label>Confirm the deletion by entering the<br/>name of this entry: <b>{keyAnswer}</b></label>
                  </div>
                }

                <input
                  type="text"
                  name="name"
                  placeholder={keyAnswer}
                  className="form-control"
                  value={key}
                  onChange={(e)=>setKey(e.target.value)}
                  required
                />
              </div>
            }
            <div className="mb-2">
              <button
                type="button"
                className={`btn btn-danger btn-sm mr-2 ${!allowDelete() ? "disabled" : ""}`}
                onClick={()=>{
                  if (allowDelete()) {
                    props.delete()
                    document.body.click()
                  }
                }}
              >Delete</button>
              <button
                type="button"
                className="btn btn-secondary btn-sm"
                onClick={() => document.body.click()}
              >Cancel</button>
            </div>
          </Popover.Content>
        </Popover>
      }
    >
     {props.children}
    </OverlayTrigger>
  )
}

export default DeleteConfirmation
