import React, { useState, useEffect } from 'react'
import limsApi from '../utils/limsApi'
import { withRouter, Link } from 'react-router-dom'
import { Modal } from 'react-bootstrap'
import * as Yup from 'yup'
import { Formik } from 'formik'
import LocationForm from './locationForm'

const NewLocation = (props) => {
  const cancelPath = props.from ? `/locations/${props.from.id}` : `/manage/locations`
  return (
    <Modal show={true} size="xl" className="modal-full-width" onHide={() => { props.history.push(cancelPath) }} enforceFocus={false}>
      <Modal.Header closeButton>
        <Modal.Title>New Location</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <LocationForm
          create={true}
          onSuccess={props.onCreate}
          cancelPath={cancelPath}
        />
      </Modal.Body>
    </Modal>
  )
}

export default withRouter(NewLocation)
