/**
 * Create a modified copy of map by applying fn to the value at the location specified by keys. keys specifies a nested
 * location in map, as in <code>map[key[0]][key[1]][key[2]]...</code>
 * map is not modified. The returned map is equivalent to the map as if the statement
 * <pre>
 *   map[key[0]][key[1]]... = fn(map[key[0]][key[1]]...);
 * </pre>
 * was executed.<br>
 * Some examples:
 * <pre>
 * const m = { 'a': 1, 'b': 2 };
 * const m2 = updateIn(m, ['b'], ()=>3);
 * m2 is now { 'a': 1, 'b': 3 } and m is unmodified.
 * const m = { 'a': {'c': 1}, 'b': 2};
 * const m2 = updateIn(m, ['a', 'c'], (v)=>3+v);
 * m2 is now { 'a': {'c': 4}, 'b': 2} and m is unmodified.
 * const m = { 'a': {'c': {'d': true}}};
 * const m2 = updateIn(m, ['a','c','d'], (v)=>!v);
 * m2 is now { 'a': {'c': {'d': false}}} and m is unmodified.
 * </pre>
 */
export const updateIn = (map, keys, fn) => {
    if (keys == null || keys.length == 0) return map;
    if (map == null) map = {};
    if (keys.length == 1) return {...map, [keys[0]]: fn(map[keys[0]])};
    return {...map, [keys[0]]: updateIn(map[keys[0]], keys.slice(1), fn)}
}

export const identity = (value) => { return value }

export function parseOrIdentity(value) {
    try {
        return JSON.parse(value);
    } catch (_) {
        return value;
    }
}