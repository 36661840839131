import React, {useEffect, useRef, useState} from 'react'
import limsApi from '../utils/limsApi'
import {Link, withRouter} from 'react-router-dom'
import {Card, Col, Container, Form, Modal, OverlayTrigger, Popover, Row, Tooltip, Button} from 'react-bootstrap'
import ItemTypeFieldCreator from './itemTypeFieldCreator'
import ItemTypeFieldEditor from './itemTypeFieldEditor'
import ItemTypeFieldViewabilityEditor from './itemTypeFieldViewabilityEditor'
import DeleteConfirmation from './deleteConfirmation'
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd'
import * as Yup from 'yup'
import {Formik} from 'formik'
import RichTextEditor from './richTextEditor'
import { fieldTypes, itemFieldTypes } from './fields/fieldUtils'

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  // update "position" attrs
  const finalResult = result.map((field, index) => {
    const updatedField = field
    updatedField.position = index
    return updatedField
  })

  return finalResult
}

const EditItemType = (props) => {
  const pluralize = require('pluralize')
  const [isLoading, setIsLoading] = useState(false)
  const [fileUploading, setFileUploading] = useState(false)
  const [itemTypes, setItemTypes] = useState([])
  const [itemType, setItemType] = useState({
    name: "",
    locationId: "",
    code: "",
    description: "",
    instanceName: "",
    parentId: null,
    fieldsAttributes: [],
    itemsHaveLocation: true
  })
  const [inputData, setInputData] = useState({})
  const [addedOptionedField, setAddedOptionedField] = useState(false)
  const ref = useRef(null)
  const instanceNameSingular = !itemType.instanceName ? "Item" : pluralize(itemType.instanceName, 1)
  const instanceNamePlural = pluralize(instanceNameSingular, 2)

  const itemTypeSchema = Yup.object({
    name: Yup.string()
      .required("Name is required."),
    code: Yup.string()
      .max(6, "Must be less than 7 letters.")
  })

  const fetchItemTypes = () => {
    const token = localStorage.getItem('gf-token')

    limsApi.get('item_types', (response)=>{
      let itemTypeData = response.data.itemTypes
      setItemTypes(itemTypeData)
    },
    'Error fetching all item types', undefined, setIsLoading)
  }

  useEffect(() => {
    fetchItemTypes()
  }, [])

  const fetchItemType = async () => {
    const itemTypeId = props.itemTypeId != undefined ? props.itemTypeId : props.match.params.id

    await limsApi.get(`item_types/${itemTypeId}`, (response)=>{
      const itemTypeData = response.data.itemType
      const inputDataPayload = response.data.itemType.inputData
      setItemType(itemTypeData)
      setInputData(inputDataPayload)
    },
    'Error fetching item type', ()=>{})
  }

  useEffect(() => {
    fetchItemType()
  }, [])

  const handleSubmit = () => {
    setIsLoading(true)

    let sanitizedOptions
    const sanitizedFields = itemType.fieldsAttributes.map((field, index) => {
      // remove temp id from field
      if(field.newRecord) {
        delete field.newRecord
        delete field.id
      }

      // remove temp id from field options
      if(field.fieldOptionsAttributes) {
        sanitizedOptions = field.fieldOptionsAttributes.map((option, index) => {
          if(option.newRecord) {
            delete option.newRecord
            delete option.id
          }

          return option
        })

        field.fieldOptionsAttributes = sanitizedOptions
      }

      return field
    })

    let sanitizedItemType = itemType
    sanitizedItemType.fieldsAttributes = sanitizedFields

    limsApi.patch(`item_types/${itemType.id}`, { itemType: sanitizedItemType, inputData: inputData }, `Type "${sanitizedItemType.name}" Saved`, (response)=>{
      setIsLoading(false)
      const itemTypeData = response.data.itemType
      props.onUpdate(itemTypeData)
      props.refreshItemTypes()
      props.history.push(`/manage/item-types/`)
    },
    'Error saving item type', null, setIsLoading)
  }

  const handleDelete = (id) => {
    document.body.click()

    limsApi.delete(`item_types/${id}`, `Type Removed`, (response)=>{
      props.refreshItemTypes()
      props.history.push(`/manage/item-types/`)
    },
    `Could not delete catalog type.`, ()=>{})
  }

  const handleChange = (event) => {
    if (event.target.name == 'code')
      setItemType({...itemType, [event.target.name]: event.target.value.toUpperCase().replace(/[^A-Z]/g, "")})  // Forces item type code to only be uppercase letters
    else if (event.target.name == 'itemsHaveLocation')
      setItemType({...itemType, [event.target.name]: event.target.checked})
    else
      setItemType({...itemType, [event.target.name]: event.target.value})
  }

  const handleCKEditorChange = (data) => {
    setItemType({...itemType, ["description"]: data})
  }

  const addField = (field) => {
    const newFieldsAttributes = itemType.fieldsAttributes.concat(field)
    setItemType({...itemType, fieldsAttributes: newFieldsAttributes})

    const fileType = fieldTypes.find((type)=>parseInt(field.fieldTypeId) == type.id)
    if (fileType.hasOptions)
      setAddedOptionedField(field)
  }

  useEffect(()=>{
    if (addedOptionedField) {
      const field = addedOptionedField
      const fileType = fieldTypes.find((type)=>parseInt(field.fieldTypeId) == type.id)
      if (fileType.hasOptions) {
        let el = document.getElementById(`edit-button-${field.id}`)
        if (el) {
          // console.log("AUTO OPEN EDIT Popover");
          el.click()
          setAddedOptionedField(false)
        }
      }
    }
  })

  const updateField = (field) => {
    let prevFields = itemType.fieldsAttributes
    const foundIndex = prevFields.findIndex(x => x.id == field.id)
    prevFields[foundIndex] = field
    setItemType({...itemType, fieldsAttributes: prevFields})
  }

  const removeField = (field) => {
    let prevFields = itemType.fieldsAttributes
    const foundIndex = prevFields.findIndex(x => x.id == field.id)
    prevFields[foundIndex] = {...field, _destroy: true}
    setItemType({...itemType, fieldsAttributes: prevFields})
    document.body.click()
  }

  const addTypeFieldPopover = (
    <Popover style={{width: '370px'}}>
      <Popover.Title as="h4">New Entry Field</Popover.Title>
      <Popover.Content>
        <ItemTypeFieldCreator fieldTypes={fieldTypes} addField={addField} />
      </Popover.Content>
    </Popover>
  )

  const addInstanceFieldPopover = (
    <Popover style={{width: '370px'}}>
      <Popover.Title as="h4">New {instanceNameSingular} Field</Popover.Title>
      <Popover.Content>
        <ItemTypeFieldCreator fieldTypes={fieldTypes.filter(type => type.id != 7)} addField={addField} appliesToInstances={true} />
      </Popover.Content>
    </Popover>
  )

  const onTypeFieldDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const fields = reorder(
      itemType.fieldsAttributes.filter((field) => { return !field.appliesToInstances }),
      result.source.index,
      result.destination.index
    )

    const reorderFields = fields.concat(itemType.fieldsAttributes.filter((field) => { return field.appliesToInstances }))
    setItemType({...itemType, fieldsAttributes: reorderFields})
  }

  const onInstanceFieldDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const fields = reorder(
      itemType.fieldsAttributes.filter((field) => { return field.appliesToInstances }),
      result.source.index,
      result.destination.index
    )

    const reorderFields = fields.concat(itemType.fieldsAttributes.filter((field) => { return !field.appliesToInstances }))
    setItemType({...itemType, fieldsAttributes: reorderFields})
  }

  const parentItemTypeOptions = itemTypes.map((type) =>
    <option
      key={type.id}
      value={type.id}
    >
      {type.depth > 0 ? `${'\u00A0\u00A0\u00A0\u00A0'.repeat(type.depth)}- ${type.name}` : type.name}
    </option>
  )

  const positionOptions = [...Array(10).keys()].map((num) =>
    /* TODO: exclude self */
    <option
      key={num}
      value={num + 1}
    >
      {num + 1}
    </option>
  )

  return (
    <Modal enforceFocus={false} show={true} size="lg" onHide={() => { props.history.push(`/manage/item-types/`) }}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Catalog Type</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          validationSchema={itemTypeSchema}
          onSubmit={handleSubmit}
          initialValues={itemType}
          enableReinitialize={true}
          >
          {({
            status,
            setStatus,
            setFieldValue,
            setFieldTouched,
            handleSubmit,
            handleBlur,
            values,
            touched,
            isValid,
            dirty,
            errors,
          }) => (
            <Form noValidate ref={ref} onSubmit={(e) => { e.preventDefault(); handleSubmit() }} className="mb-2">
              <div className="form-row">
                <Form.Group className="col-4">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    data-lpignore="true"
                    className="form-control"
                    value={itemType.name}
                    onChange={(e) => { handleChange(e); setFieldValue('name', e.target.value) }}
                    onBlur={e => {
                      setFieldTouched('name');
                      handleBlur(e);
                    }}
                    isInvalid={touched["name"] || status ? errors.name : null} />
                 <Form.Control.Feedback type="invalid">{touched["name"] || status ? errors.name : null}</Form.Control.Feedback>
                </Form.Group>
                <div className="form-group col-2">
                  <label>
                    Key
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>
                          A short, unique reference for all {instanceNamePlural.toLowerCase()} of this type.
                        </Tooltip>
                      }
                    >
                      <i className="fas fa-info-circle ml-1 text-muted"></i>
                    </OverlayTrigger>
                  </label>
                  <input
                    type="text"
                    name="code"
                    data-lpignore="true"
                    className="form-control"
                    value={itemType.code}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group col">
                  <label>
                    Instance Name
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>
                          How to refer to instances of this type e.g. "Items", "Batches", "Orders", etc.
                        </Tooltip>
                      }
                    >
                      <i className="fas fa-info-circle ml-1 text-muted"></i>
                    </OverlayTrigger>
                  </label>
                  <input
                    type="text"
                    name="instanceName"
                    data-lpignore="true"
                    className="form-control"
                    value={itemType.instanceName || ""}
                    onChange={handleChange}
                    placeholder="Items (default)"
                  />
                </div>
              </div>
              <div className="form-row">
                <Form.Group className="col">
                  <Form.Label>
                    Inherits from Type
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>
                          When a catalog type inherits from another type, it automatically receives all of the parent type's fields.
                        </Tooltip>
                      }
                    >
                      <i className="fas fa-info-circle ml-1 text-muted"></i>
                    </OverlayTrigger>
                  </Form.Label>
                  <Form.Control
                    as="select"
                    name="parentId"
                    className="form-control"
                    value={itemType.parentId}
                    onChange={(e) => { handleChange(e) }}
                  >
                    <option
                      value={""}
                    >
                      None
                    </option>
                   {parentItemTypeOptions}
                 </Form.Control>
                </Form.Group>
                <Form.Group className="col">
                  <Form.Label>Position</Form.Label>
                  <Form.Control
                    type="text"
                    name="position"
                    className="form-control"
                    value={itemType.position}
                    onChange={(e) => { handleChange(e) }}
                  />
                </Form.Group>
              </div>
              <div className="form-group">
                <label>Description</label>
                {!isLoading && itemType.id ? (
                  <RichTextEditor data={itemType.description || ""} handleChange={(data)=>handleCKEditorChange(data)} />
                ) : (null) }
              </div>
              <div className="form-group mb-3 custom-control custom-switch">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  data-toggle="toggle"
                  id="test"
                  checked={itemType.itemsHaveLocation}
                  name="itemsHaveLocation"
                  onChange={handleChange} />
                <label className="custom-control-label" htmlFor="test">
                  Items of this type have physical locations
                </label>
              </div>
              <Container fluid={true} className="mb-3" style={{ paddingLeft: 0, paddingRight: 0 }}>
                <Row noGutters={true}>
                  <Col className="pr-2">
                    <h5 className="mb-1">Entry Fields</h5>
                    <p><small>Fields that describe entries of this type.</small></p>
                    {/* inherited fields */}
                    {itemType.allTypeFields?.filter((field) => { return field.itemTypeId != itemType.id }).map((field, index) => (
                      <Card
                        className={`mb-2`}
                        style={{"user-select": "none"}}
                      >
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip>
                              Inherited from "{field.itemTypeName}" Type
                            </Tooltip>
                          }
                        >
                        <Card.Body
                          className="p-2"
                          style={{opacity: '0.5', background: '#fff'}}
                        >
                          <Card.Text className="align-middle" style={{paddingTop: "3px"}}>
                            <i className={`mx-1 mr-2 fas fa-${fieldTypes.find(x => x.id.toString() == field.fieldTypeId).icon}`}></i> {field.name}
                          </Card.Text>
                        </Card.Body>
                      </OverlayTrigger>
                      </Card>
                    ))}
                    <DragDropContext onDragEnd={onTypeFieldDragEnd}>
                      <Droppable droppableId="type-fields">
                        {provided => (
                          <div
                            ref={provided.innerRef}
                            id="item-type-fields"
                            {...provided.droppableProps}
                          >
                            {itemType.fieldsAttributes.filter((field) => { return !field.appliesToInstances && !field._destroy }).map((field, index) => (
                              <Draggable draggableId={field.id ? field.id.toString() : null} index={index} key={field.id ? field.id.toString() : null}>
                                {(provided, snapshot) => (
                                  <Card
                                    className={`mb-2 ${snapshot.isDragging ? "is-dragging" : ""}`}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    ref={provided.innerRef}
                                  >
                                    <Card.Body
                                      className="p-2"
                                    >
                                      <div className="btn-group dropright float-right" role="group">
                                        <OverlayTrigger
                                          rootClose
                                          trigger="click"
                                          placement="right"
                                          container={ref.current}
                                          overlay={
                                            <Popover className="popover-md-width">
                                              <Popover.Title as="h3">Edit Field</Popover.Title>
                                              <Popover.Content>
                                                <ItemTypeFieldEditor field={field} fieldTypes={fieldTypes} updateField={updateField} />
                                              </Popover.Content>
                                            </Popover>
                                          }
                                        >
                                          <button type="button" id={`edit-button-${field.id}`} className="btn btn-light btn-sm">
                                            <i className="fas fa-edit"></i>
                                          </button>
                                        </OverlayTrigger>
                                        <DeleteConfirmation delete={() => { removeField(field) }}>
                                          <button type="button" className="btn btn-light btn-sm">
                                            <i className="fas fa-trash-alt"></i>
                                          </button>
                                        </DeleteConfirmation>
                                      </div>
                                      <Card.Text className="align-middle" style={{paddingTop: "3px"}}>
                                        <i className={`mx-1 mr-2 fas fa-${fieldTypes.find(x => x.id.toString() == field.fieldTypeId).icon}`}></i> {field.name}
                                      </Card.Text>
                                    </Card.Body>
                                  </Card>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                    <OverlayTrigger trigger="click" placement="right" overlay={addTypeFieldPopover} rootClose>
                      <button
                        id="add-type-field"
                        className="btn btn-secondary btn-block"
                        onClick={(e) => { e.preventDefault() }}
                      >
                        Add Field
                      </button>
                    </OverlayTrigger>
                  </Col>
                  <Col className="pl-2">
                    <h5 className="mb-1">{instanceNameSingular} Fields</h5>
                    <p><small>Fields that describe each new {instanceNameSingular.toLowerCase()} of this type.</small></p>
                    {/* inherited fields */}
                    {itemType.allInstanceFields?.filter((field) => { return field.itemTypeId != itemType.id }).map((field, index) => (
                      <Card
                        className={`mb-2`}
                        style={{"user-select": "none"}}
                      >
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip>
                              Inherited from "{field.itemTypeName}" Type
                            </Tooltip>
                          }
                        >
                        <Card.Body
                          className="p-2"
                          style={{opacity: '0.5', background: '#fff'}}
                        >
                          <Card.Text className="align-middle" style={{paddingTop: "3px"}}>
                            <i className={`mx-1 mr-2 fas fa-${fieldTypes.find(x => x.id.toString() == field.fieldTypeId).icon}`}></i> {field.name}
                          </Card.Text>
                        </Card.Body>
                      </OverlayTrigger>
                      </Card>
                    ))}
                    <DragDropContext onDragEnd={onInstanceFieldDragEnd}>
                      <Droppable droppableId="instance-fields">
                        {provided => (
                          <div
                            ref={provided.innerRef}
                            id="item-instance-fields"
                            {...provided.droppableProps}
                          >
                            {itemType.fieldsAttributes.filter((field) => { return field.appliesToInstances && !field._destroy }).map((field, index) => (
                              <Draggable draggableId={field.id ? field.id.toString() : null} index={index} key={field.id ? field.id.toString() : null}>
                                {(provided, snapshot) => (
                                  <Card
                                    className={`mb-2 ${snapshot.isDragging ? "is-dragging" : ""}`}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    ref={provided.innerRef}
                                  >
                                    <Card.Body
                                      className="p-2"
                                    >
                                      <div className="btn-group dropright float-right" role="group">
                                        <OverlayTrigger
                                          rootClose
                                          trigger="click"
                                          placement="left"
                                          container={ref.current}
                                          overlay={
                                            <Popover className="popover-md-width">
                                              <Popover.Title as="h3">Edit Viewing Options</Popover.Title>
                                              <Popover.Content>
                                                <ItemTypeFieldViewabilityEditor field={field} fieldTypes={itemFieldTypes} updateField={updateField} />
                                              </Popover.Content>
                                            </Popover>
                                          }
                                        >
                                          <button type="button" id={`view-button-${field.id}`} className="btn btn-light btn-sm">
                                            <i className="fas fa-eye"></i>
                                          </button>
                                        </OverlayTrigger>
                                        <OverlayTrigger
                                          rootClose
                                          trigger="click"
                                          placement="left"
                                          container={ref.current}
                                          overlay={
                                            <Popover className="popover-md-width">
                                              <Popover.Title as="h3">Edit Field</Popover.Title>
                                              <Popover.Content>
                                                <ItemTypeFieldEditor field={field} fieldTypes={itemFieldTypes} updateField={updateField} />
                                              </Popover.Content>
                                            </Popover>
                                          }
                                        >
                                          <button type="button" id={`edit-button-${field.id}`} className="btn btn-light btn-sm">
                                            <i className="fas fa-edit"></i>
                                          </button>
                                        </OverlayTrigger>
                                        <DeleteConfirmation delete={() => { removeField(field) }}>
                                          <button type="button" className="btn btn-light btn-sm">
                                            <i className="fas fa-trash-alt"></i>
                                          </button>
                                        </DeleteConfirmation>
                                      </div>
                                      <Card.Text className="align-middle" style={{paddingTop: "3px"}}>
                                        <i className={`mx-1 mr-2 fas fa-${fieldTypes.find(x => x.id.toString() == field.fieldTypeId).icon}`}></i> {field.name}
                                      </Card.Text>
                                    </Card.Body>
                                  </Card>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                    <OverlayTrigger trigger="click" placement="left" overlay={addInstanceFieldPopover} rootClose>
                      <button
                        id="add-item-field"
                        className="btn btn-secondary btn-block"
                        onClick={(e) => { e.preventDefault() }}
                      >
                        Add Field
                      </button>
                    </OverlayTrigger>
                  </Col>
                </Row>
              </Container>
              {itemType.canBeDeleted ? (
                <DeleteConfirmation delete={() => { handleDelete(itemType.id) }}>
                  <Button variant="outline-danger" className="float-right">Delete Catalog Type</Button>
                </DeleteConfirmation>
              ) : (
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip>
                      Cannot delete this catalog type because it has items associated with it.
                    </Tooltip>
                  }
                >
                  <div className="float-right" style={{display: 'inline-block'}}>
                    <button disabled className="btn btn-outline-danger float-right" style={{pointerEvents: "none"}}>Delete Catalog Type</button>
                  </div>
                </OverlayTrigger>
              )}
              <button type="submit" className="btn btn-primary" disabled={isLoading || !isValid || fileUploading}>
                { !isLoading ? (
                  "Save"
                ) : (
                  <i className="fas fa-spinner fa-spin"></i>
                )}
              </button>
              <Link
                to="/manage/item-types"
                className="btn btn-link"
              >
                Cancel
              </Link>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default withRouter(EditItemType)
