import React, { useState, useEffect } from 'react'
import limsApi from '../utils/limsApi'
import { withRouter, NavLink } from 'react-router-dom'
import { Modal, Button } from 'react-bootstrap'
import LocationForm from './locationForm'
import DeleteConfirmation from "./deleteConfirmation"

const EditLocation = (props) => {

  let cancelPath = `/manage/locations`
  let deletePath = `/manage/locations`

  if (props.from) {
    cancelPath = `/locations/${props.from.id}`
    deletePath = `/locations/${props.from.parentId ? props.from.parentId : ""}`
  }

  const handleDelete = (id) => {
    document.body.click()

    limsApi.delete(`locations/${id}`, `Location Removed`, (response)=>{
      props.refreshLocations()
      props.history.push(deletePath)
    },
    `There was a problem deleting that location.`, ()=>{})
  }

  return (
    <Modal show={true} size="xl" className="modal-full-width" onHide={() => { props.history.push(cancelPath) }} enforceFocus={false}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Location</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <LocationForm
          onSuccess={props.refreshLocations}
          cancelPath={cancelPath}
          handleDelete={handleDelete}
        />
      </Modal.Body>
    </Modal>
  )
}

export default withRouter(EditLocation)
