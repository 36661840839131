import React, { useState, useEffect } from 'react'
import limsApi from '../utils/limsApi'
import { Modal, Card, Container, Tabs, Tab, ToggleButton, ToggleButtonGroup, Form, Row, Col, OverlayTrigger, Tooltip as BSTooltip, Spinner } from 'react-bootstrap'
import moment from 'moment'
import { LineChart, BarChart, Bar, Area, Line, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer, Legend, ReferenceLine } from 'recharts'

const SensorGraph = ({sensor, ...props}) => {

  const [isLoading, setIsLoading] = useState(true)
  const [sensorLogs, setSensorLogs] = useState([])
  const [graphData, setGraphData] = useState([])
  const [fromPast, setFromPast] = useState("day")
  const [fromLocation, setFromLocation] = useState("all")
  const [hasQueriedAll, setHasQueriedAll] = useState(false)
  const [locationsWithData, setLocationsWithData] = useState([])

  let dateFormat = "MM/DD hh:mm"

  const [locations, setLocations] = useState([])

  const fetchLocations = () => {
    limsApi.get('locations', (response)=>{
      let locationData = response.data.locations
      setLocations(locationData)
    },
    'Error fetching locations', undefined, setIsLoading)
  }

  useEffect(() => {
    fetchLocations()
  }, [])


  const fetchRecentSensorLogs = () => {
    limsApi.get(`get_recent_sensor_logs/${sensor.id}`, (response)=>{
      let sensorLogData = response.data.sensorLogs
      setSensorLogs(sensorLogData)
    },
    'Error fetching sensors logs', undefined, setIsLoading)
  }

  const fetchAllSensorLogs = () => {
    limsApi.get(`get_all_sensor_logs/${sensor.id}`, (response)=>{
      let sensorLogData = response.data.sensorLogs
      setSensorLogs(sensorLogData)
    },
    'Error fetching sensors logs')
  }

  useEffect(()=>{
    fetchRecentSensorLogs()
  }, [])

  useEffect(()=>{
    if (!hasQueriedAll && fromPast != "day") {
      fetchAllSensorLogs()
      setHasQueriedAll(true)
    }
  }, [fromPast])

  useEffect(()=>{
    if (fromPast != "all") {
      let newGraphData = []
      let compareDate = moment().subtract(1, fromPast)
      sensorLogs.map(sensorLog=>{
        if (moment(sensorLog.createdAt).isAfter(compareDate) && (sensorLog.locationId == fromLocation || fromLocation == "all")) {
          newGraphData = [...newGraphData, sensorLog]
        }
      })
      setGraphData(newGraphData)
    } else {
      let newGraphData = []
      sensorLogs.map(sensorLog=>{
        if (sensorLog.locationId == fromLocation || fromLocation == "all") {
          newGraphData = [...newGraphData, sensorLog]
        }
      })
      setGraphData(newGraphData)
    }

  }, [sensorLogs, fromPast, fromLocation])

  useEffect(()=>{
    const locationIds = queryLocations(sensorLogs)
    let newLocationsWithData = []
    locations.map(location=>{
      if (locationIds.includes(location.id))
        newLocationsWithData = [...newLocationsWithData, location]
    })
    setLocationsWithData(newLocationsWithData)
  }, [sensorLogs])

  const queryLocations = (logs) => {
    let locationIds = []
    logs.map(log=>{
      if (!locationIds.includes(log.locationId))
        locationIds = [...locationIds, log.locationId]
    })
    return locationIds
  }

  return (
    <Modal show={true} size="lg" onHide={() => props.closeModal()} enforceFocus={false}>
      <Modal.Header closeButton>
        <Modal.Title>Sensor Graph</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          { !isLoading ?
            <div>
              <ResponsiveContainer width="100%" height={500}>
                <LineChart data={graphData} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
                 <Line type="monotone" dataKey="value" dot={false} strokeWidth="3" stroke="#007bff" fill="#007bff" fillOpacity={0.4} />
                 <ReferenceLine y={sensor.maxValue} label="Max" stroke="red" strokeWidth="2" strokeDasharray="6 3" />
                 <ReferenceLine y={sensor.minValue} label="Min" stroke="red" strokeWidth="2" strokeDasharray="6 3" />
                 <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                 <XAxis dataKey="createdAt" tickFormatter={(tick) => (moment(tick).format(dateFormat))} />
                 <YAxis />
                 <Tooltip labelFormatter={(tick) => (moment(tick).format(dateFormat))} />
                </LineChart>
              </ResponsiveContainer>
              <div className="form-row form-group d-flex justify-content-center mt-1">
                  <div className="col-sm-1.5 form-label mt-1">
                    <label>From past:</label>
                  </div>
                  <div className="col-sm-1.5">
                    <select
                      name="from"
                      className="form-control"
                      value={fromPast}
                      onChange={(e)=>setFromPast(e.target.value)}
                    >
                      <option key="d" value="day" >
                        Day
                      </option>
                      <option key="w" value="week" >
                        Week
                      </option>
                      <option key="m" value="month" >
                        Month
                      </option>
                      <option key="all" value="all" >
                        All Time
                      </option>
                    </select>
                  </div>
                  <div className="col-sm-1.5 form-label mt-1">
                    { locationsWithData.length > 1 &&
                      <label>Location:</label>
                    }
                    { locationsWithData.length == 1 &&
                      <label>Location: <strong>{locationsWithData[0].name}</strong></label>
                    }
                    { locationsWithData.length == 0 &&
                      <label>Location: <strong>None</strong></label>
                    }

                  </div>
                  { locationsWithData.length > 1 &&
                    <div className="col-sm-1.5">
                      <select
                        name="location"
                        className="form-control"
                        value={fromLocation}
                        onChange={(e)=>setFromLocation(e.target.value)}
                      >
                        <option key="all" value="all">
                          All
                        </option>
                        { locationsWithData.map(location => (
                          <option key={location.id} value={location.id}>
                            {location.name}
                          </option>
                        ))
                        }
                      </select>
                    </div>
                  }
                </div>
              </div>
            :
             "Loading"
          }
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default SensorGraph
