import React, { useState, useEffect } from 'react'
import { Link, withRouter, useHistory } from 'react-router-dom'
import { Button, Form, Col, Row, Image } from 'react-bootstrap'
import { connect } from 'react-redux'
import { Formik } from 'formik'
import * as Yup from 'yup'
import limsApi from '../utils/limsApi'

const ChangePasswordForm = ({ currentUser, onSuccess, ...props}) => {
  const [password, setPassword] = React.useState("")
  const [confirmPassword, setConfirmPassword] = React.useState("")
  const history = useHistory();

  const passSchema = Yup.object({
    currentPass: Yup.string()
      .required("Current password is required."),
    newPass: Yup.string()
      .required("New password is required.")
      .min(8, 'Must be at least 8 characters.')
      .test('passwords-match', 'Must be different than your current password.', function(value) {
        return this.parent.currentPass != value;
      }),
    confirmPass: Yup.string()
      .required("Confirmation password is required.")
      .test('passwords-match', 'Passwords must match.', function(value) {
        return this.parent.newPass === value;
      })
      .test('passwords-match', 'Must be different than your current password.', function(value) {
        return this.parent.currentPass != value;
      })
      .min(8, 'Must be at least 8 characters.')
  })

  // Performs a post request that authenticates the user's current password
  const resetPassword = (values, actions) => {
    limsApi.post('users/login', { user: { email: currentUser.email, password: values.currentPass } }, '', (response)=>{
      const newAuth = { password: values.newPass, password_confirmation: values.confirmPass}

      limsApi.put('users/reset_password', newAuth, 'Password Changed', (response)=>{
          actions.resetForm({values: {currentPass: "", newPass: "", confirmPass: ""}})
          window.flash("Password changed", 'success')
          onSuccess ? onSuccess() : null
        },
        '', (error)=>{
          actions.setFieldError('confirmPass', "Password " + error.response.data.errors["password"])
          actions.setFieldError('newPass', "Password " + error.response.data.errors["password"])
        })
    },
    '', (error)=>{
      actions.setFieldError('currentPass', 'Password ' + (error.response.data.errors['email or password'] || "is invalid"))
    })
  }


  return (
    <Formik
      validationSchema={passSchema}
      onSubmit={resetPassword}
      initialValues={{}}
      validateOnChange={false}
    >
      {({
        status,
        setStatus,
        setFieldTouched,
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        dirty,
        errors,
      }) => (
    <Form noValidate  onSubmit={handleSubmit}>
        <h3 className="mb-3">Change Password</h3>
        <Form.Group>
          <Form.Label>Current Password</Form.Label>
          <Form.Control
            type="password"
            autoComplete="on"
            placeholder="Enter current password"
            name="currentPass"
            value={values.currentPass}
            onChange={handleChange}
            onBlur={e => {
              setFieldTouched('currentPass');
              handleBlur(e);
            }}
            isInvalid={touched["currentPass"] || status ? errors.currentPass : null} />
          <Form.Control.Feedback type="invalid">{touched["currentPass"] || status ? errors.currentPass : null}</Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Label>New Password</Form.Label>
          <Form.Control
            type="password"
            autoComplete="on"
            placeholder="Enter new password"
            name="newPass"
            value={values.newPass}
            onChange={handleChange}
            onBlur={e => {
              setFieldTouched('newPass');
              handleBlur(e);
            }}
            isInvalid={touched["newPass"] || status ? errors.newPass : null} />
          <Form.Control.Feedback type="invalid">{touched["newPass"] || status ? errors.newPass : null}</Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Label>Confirm New Password</Form.Label>
          <Form.Control
            type="password"
            autoComplete="on"
            placeholder="Re-enter current password"
            name="confirmPass"
            value={values.confirmPass}
            onChange={handleChange}
            onBlur={e => {
              setFieldTouched('confirmPass');
              handleBlur(e);
            }}
            isInvalid={touched["confirmPass"] || status ? errors.confirmPass : null} />
          <Form.Control.Feedback type="invalid">{touched["confirmPass"] || status ? errors.confirmPass : null}</Form.Control.Feedback>
        </Form.Group>
        <Button variant="primary" type='submit' disabled={!dirty} onClick={() => setStatus(true)}>
          Change
        </Button>
    </Form>
  )}
  </Formik>
  );
}

const mapStateToProps = state => {
  return {
    currentUser: state.currentUser
  }
}

export default connect(mapStateToProps, null)(ChangePasswordForm)
