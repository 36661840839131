import React from 'react'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const EmailConfirmed = (props) => (
  <div className="text-center signup-container p-5 mx-auto">
    <h3 className="mb-4 mt-3 text-center">Your email has been verified!</h3>
    <div className="mt-3">
      <p>
        Thank you for verifying your email.
      </p>
    </div>
    {/*(
      <Button className="mt-4 mb-3" variant="primary" type="button" target="_blank" href="https://www.genofab.com" block>
        Visit GenoFAB.com
      </Button>
    )*/}
    <Link to="/">
      Return to App
    </Link>
  </div>
)

export default EmailConfirmed
