import React from 'react'
import limsApi from '../utils/limsApi'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo
    })
    // You can also log error messages to an error reporting service here
    const errorObj = {
                      error:
                            {
                              description: error.toString(),
                              componentStack: errorInfo.componentStack,
                              url: window.location.href
                            }
                      }
    // limsApi.post("front_end_error/notify_staff", errorObj, "")
  }

  dismissError() {
      history.back()
      this.setState({
        error: null,
        errorInfo: null
      })
  }

  render() {
    if (this.state.errorInfo) {
      // Error path
      return (
        <div className="text-center pt-5" >
          <i className="mt-5 mb-4 fas fa-exclamation-triangle fa-4x text-warning"></i>
          <h3 className="mb-2">Sorry, we couldn't process that request.</h3>
          <p className="mb-4">Our team has been notified, and we're looking into it.</p>
          <button className="btn btn-secondary" onClick={()=>this.dismissError()}>Dismiss</button>
        </div>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}

export default ErrorBoundary
