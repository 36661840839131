import React, {useEffect, useState} from 'react'
import { connect } from 'react-redux'
import {Link, Route, Switch} from 'react-router-dom'
import {Tab, Table, Tabs} from 'react-bootstrap'
import {EditTask, Task} from './taskForms'
import {limsGet} from "../utils/limsApi";
import {fullname} from "../utils/userFns";
import {identity} from "../utils/pureStateFns";
import {formatDate} from "../utils/formatFns";
import GDialog from "./dialogs/gDialog";
import {GSelectInput} from "./lib/gforms";
import {generateOnChange} from "../utils/defaultHandlers";

const {TASK_STATUS: {BACKLOG, TODO, IN_PROGRESS, DONE, CANCELLED}} = require('../shared-constants.yaml')


const generateFilterTasksByStatus = (status) => {
    return (tasks) => {
        return tasks.filter((task)=>task.status===status)
    }
}

const symbolize = (str) => {
    return str.replace(' ', '-').replace(/[^a-zA-Z-]/g, '');
}


const resolveTaskFilterFn = (filterFn, status) => {
    if (filterFn == null && status != null) {
        filterFn = generateFilterTasksByStatus(status)
    }
    return filterFn || identity
}


const TaskRow = ({task}) => {
    return (
        <tr>
            <td className={'task-name'}>
                <Link to={`/tasks/${task.id}`}>
                    {task.name}
                </Link>
            </td>
            <td className={'task-assigned-to'}>{fullname(task.assignedTo)}</td>
            <td className={'task-requested-by'}>{fullname(task.requestedBy)}</td>
            <td className={'task-requested-on'}>{formatDate(task.requestedOn)}</td>
            <td className={'task-status'}>{task.status}</td>
        </tr>
    )
}


const TasksTable = ({tasks, status, filterFn}) => {
    filterFn = resolveTaskFilterFn(filterFn, status)
    const ourTasks = filterFn(tasks)
    return (
        <Table bordered className={'tasks-table'}>
            <thead>
            <tr style={{backgroundColor: "#eee"}}>
                <th scope="col">Task</th>
                <th scope="col">Assigned To</th>
                <th scope="col">Requested By</th>
                <th scope="col">Requested On</th>
                <th scope="col">Status</th>
            </tr>
            </thead>
            <tbody>
            {ourTasks.map((task) => (<TaskRow key={task.id} task={task}/>))}
            </tbody>
        </Table>
    )
}


const TasksTab = ({title, tasks, status, filterFn}) => {
    filterFn = resolveTaskFilterFn(filterFn, status)
    const ourTasks = filterFn(tasks)
    return (
        <Tab eventKey={symbolize(title)} title={`${title} (${ourTasks.length})`}>
            <TasksTable tasks={ourTasks}/>
        </Tab>
    );
};

const FilterTasksDialog = ({filters, onSubmit, ...props}) => {
    const [newFilters, setNewFilters] = useState({})
    const [users, setUsers] = useState([])
    useEffect(()=>{setNewFilters(filters)}, [filters])
    useEffect(()=>{
        limsGet('users', (data)=>setUsers(data.users))
    }, [])
    const onChange = generateOnChange(setNewFilters)
    return (
        <GDialog
            title={'Filter Tasks'}
            returnPath={'/tasks'}
            model={newFilters}
            onChange={onChange}
            onSubmit={onSubmit}
        >
            <GSelectInput label={'Assigned To'} name={'assignedTo'} options={users} render={fullname}/>
            <GSelectInput label={'Requested By'} name={'requestedBy'} options={users} render={fullname}/>
        </GDialog>
    )
}


const TasksBrowser = ({match, history, currentUser}) => {
    const [isLoading, setIsLoading] = useState(true)
    const [tasks, setTasks] = useState([])
    const [filters, setFilters] = useState({})

    function loadTasks() {
        limsGet('tasks', (data) => {
            setTasks(data.tasks)
            setIsLoading(false)
        })
    }

    useEffect(loadTasks, [])

    const compare = (task, entry) => {
        if (entry[1]==null) return true
        if (typeof entry[1] === 'object') return task[entry[0]]?.id === entry[1]?.id
        return task[entry[0]] === entry[1]
    }

    const filter = (filters, task) => {
        if (Object.entries(filters).length==0) return true
        const first = Object.entries(filters)[0]
        const rest = Object.assign({}, filters)
        delete rest[first[0]]
        return compare(task, first) && filter(rest, task)
    }

    // TODO: this needs to update when state changes... will it??
    const filterFn = (filters, status) => {
        return (tasks) => {
            return tasks.filter((task)=>filter({status, ...filters}, task))
        }
    }

    const clearFilters = () => setFilters({})

    const count = (filters) => Object.entries(filters).length

    const renderEditTask = (props) => {
        const task_id = props.match.params.id
        return (
            <EditTask {...props}
                      task_id={task_id}
                      returnPath={'/tasks'}
                      returnPathOnCancel={`/tasks/${task_id}`}
                      returnPathOnSave={`/tasks/${task_id}`}
                      onUpdate={loadTasks}
                      onDelete={loadTasks} />
        )
    }

    return (
      <div>
        {!isLoading ?
          <>
            {tasks.length > 0 ? (
              <div className="col-fluid" style={{width: "100%"}}>
                  <Switch>
                      <Route path={`/tasks/filter`}>
                          <FilterTasksDialog onSubmit={setFilters} filters={filters}/>
                      </Route>
                      <Route path={`${match.path}/:id/edit`} render={renderEditTask}/>
                      <Route path={`${match.path}/:id`} render={(props) => (
                          <Task task_id={props.match.params.id} returnPath={'/tasks'} onUpdate={loadTasks} onDelete={loadTasks}/>
                      )}/>
                  </Switch>
                  <Route path={`${match.path}`}>
                      <div className="p-4">
                          { currentUser.userRole.id == 1 &&
                            <div className={'float-right'}>
                                {count(filters) > 0 ?
                                    <button className={'btn btn-outline-secondary'}
                                            onClick={clearFilters}
                                    >Clear Filters</button>
                                    : ""}
                                <Link to={`${match.path}/filter`} className={'btn btn-outline-secondary ml-2'}>
                                    Filter ({count(filters)})</Link>
                            </div>
                          }
                          <h3 className="mb-3">{(currentUser.userRole.id == 1) ? 'Tasks' : 'My Tasks'}</h3>
                          <Tabs
                            variant="pills"
                            defaultActiveKey={(currentUser.userRole.id == 1) ? symbolize(BACKLOG) : symbolize(TODO)}
                            className="mb-4 no-pt ml-0"
                          >
                            { currentUser.userRole.id == 1 &&
                              TasksTab({title: 'All', filterFn: filterFn(filters), tasks: tasks})
                            }
                            { currentUser.userRole.id == 1 &&
                              TasksTab({title: 'Backlog', filterFn: filterFn(filters,BACKLOG), tasks: tasks})
                            }
                            {TasksTab({title: 'Todo', filterFn: filterFn(filters,TODO), tasks: tasks})}
                            {TasksTab({title: 'In Progress', filterFn: filterFn(filters,IN_PROGRESS), tasks: tasks})}
                            {TasksTab({title: 'Done', filterFn: filterFn(filters,DONE), tasks: tasks})}
                            {TasksTab({title: 'Cancelled', filterFn: filterFn(filters,CANCELLED), tasks: tasks})}
                          </Tabs>
                      </div>
                  </Route>
              </div>
            ) : (
              <div className="mx-auto mt-5 py-3 px-4 text-center" style={{maxWidth: "30rem"}}>
                <h1 className="display-4 mb-4 text-success"><i className="fas fa-info-circle"></i></h1>
                <h4 className="mb-3">No tasks yet.</h4>
                <p className="mb-4">Once your lab members start requesting new items from your catalog, your tasks will be displayed here.</p>
              </div>
            )}
          </>
        :
          <div className="my-5 py-5 text-center">Loading...</div>
        }
      </div>
    )
}

const mapStateToProps = state => {
  return {
    currentUser: state.currentUser
  }
}

export default connect(mapStateToProps)(TasksBrowser)
