import React, { useState, useEffect } from 'react'
import { Button, Form, Col, Row, Modal, Tooltip, OverlayTrigger } from 'react-bootstrap'
import { Formik } from 'formik'
import * as Yup from 'yup'
import limsApi from '../utils/limsApi'
import events from '../utils/events'

// This component acts as the form for creating a new printer and upating existing ones
const PrinterForm = (props) => {
  const [printerName, setPrinterName] = useState(!props.createNewPrinter ? props.printer.name : "")
  const [printNodeId, setPrintNodeId] = useState(!props.createNewPrinter ? props.printer.printNodeId : props.printNodePrinters[0] ? props.printNodePrinters[0].id : null)

  const printerSchema = Yup.object({
    printerName: Yup.string()
      .required("Printer name is required.")
      .min(3, "Chose a more specific name."),
    printNodeId: Yup.number()
      .required("You must choose a printer."),
    dpi: Yup.number()
      .required("Please select your printer's dpi.")
  })

  // POST request ONLY FOR CREATING NEW PRINTER
  const postPrinter = (values, actions) => {
    const newPrinter = {name: values.printerName, print_node_id: values.printNodeId, dpi: values.dpi}

    limsApi.post('printers', newPrinter, 'Printer Created', (() => {
        props.reload()
        events.createLinklessEvent(`added a new printer called, '${values.printerName}'.`)
        props.closeModal()
      }),
      'Printer Failed')
  }

  const getDPIFromPrinter = (key, curr) => {
    let printer = {}
    props.printNodePrinters.map(p => {
      if (p.id == key)
        printer = p
    })
    const dpiStr = printer != null ? printer.capabilities.dpis[0] : null
    if (dpiStr == "300x300") {
      return 300
    } else if (dpiStr == "203x203") {
      return 203
    }
    return curr
  }

  const handlePrinterChangedSideAffects = (e, setFieldValue, values) => {
    setFieldValue('dpi', getDPIFromPrinter(e.target.value, values.dpi))
  }

  // PUT request ONLY FOR UPDATING EXISTING PRINTERS
  const putPrinter = (values, actions) => {
    // const token = localStorage.getItem('gf-token')
    const updatedPrinter = {name: values.printerName, print_node_id: values.printNodeId, dpi: values.dpi, id: props.printer.id}
    limsApi.put(`printers/${props.printer.id}`, updatedPrinter, 'Printer Updated', (() => {
        props.reload()
        props.closeModal()
      }),
      'Printer Failed')
  }

  let printerOptions = <option>No Printers connected</option>;
  if(props.printNodePrinters.length > 0) {
    printerOptions = props.printNodePrinters.map((printNodePrinter) => (
      <option key={printNodePrinter.id} value={printNodePrinter.id}>{`${printNodePrinter.name}, ${printNodePrinter.state}`}</option>
    ));
  }

  if (props.printNodePrinters[0]) {
    return (
      <Formik
        validationSchema={printerSchema}
        onSubmit={props.createNewPrinter ? postPrinter : putPrinter}
        initialValues={{
          printerName: props.printer ? props.printer.name : "",
          printNodeId: props.printer ? props.printer.printNodeId : props.printNodePrinters[0].id,
          dpi: props.printer ? props.printer.dpi : getDPIFromPrinter(props.printNodePrinters[0].id)
        }}
      >
        {({
          status,
          setStatus,
          setFieldTouched,
          setFieldValue,
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          dirty,
          errors,
        }) => (
      <Form noValidate  onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}>
      <Modal show={true}>
        <Modal.Header>
          <h5 className="modal-title">{props.createNewPrinter ? "Create" : "Edit"} Printer</h5>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={props.closeModal}>
          <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>
              Name
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip>
                    The printer will be referenced by this name throughout the app.
                  </Tooltip>
                }
              >
                <i className="fas fa-info-circle ml-1 text-muted"></i>
              </OverlayTrigger>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter printer name"
              name="printerName"
              value={values.printerName}
              onChange={handleChange}
              onBlur={e => {
                setFieldTouched('printerName');
                handleBlur(e);
              }}
              isInvalid={touched["printerName"] || status ? errors.printerName : null} />
           <Form.Control.Feedback type="invalid">{touched["printerName"] || status ? errors.printerName : null}</Form.Control.Feedback>
           </Form.Group>
          <Form.Group>
            <Form.Label>
              Choose Printer
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip>
                    Don't see your printer?
                  </Tooltip>
                }
              >
                <i className="fas fa-question-circle ml-1 text-muted"></i>
              </OverlayTrigger>
            </Form.Label>
            <Form.Control
              as="select"
              htmlSize={4}
              type="select"
              name="printNodeId"
              value={values.printNodeId}
              onChange={e => {
                handleChange(e)
                handlePrinterChangedSideAffects(e, setFieldValue, values)
              }}
              onBlur={e => {
                setFieldTouched('printNodeId');
                handleBlur(e);
              }}
              isInvalid={touched["printNodeId"] || status ? errors.printNodeId : null}>
              {printerOptions}
            </Form.Control>
            <Form.Control.Feedback type="invalid">{touched["printNodeId"] || status ? errors.printNodeId : null}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>
             DPI
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip>
                    This field will auto fill for certain printers. Please verify the label printer's dpi. It usually stated in its manual or online.
                  </Tooltip>
                }
              >
                <i className="fas fa-info-circle ml-1 text-muted"></i>
              </OverlayTrigger>
            </Form.Label>
            <Form.Control
              as="select"
              type="select"
              name="dpi"
              value={  values.dpi }
              onChange={handleChange}
              onBlur={e => {
                setFieldTouched('dpi')
                handleBlur(e)
              }}
              isInvalid={touched["dpi"] || status ? errors.dpi : null}>
               <option value={150}>150</option>
               <option value={203}>203</option>
               <option value={300}>300</option>
               <option value={600}>600</option>
            </Form.Control>
           <Form.Control.Feedback type="invalid">{touched["dpi"] || status ? errors.dpi : null}</Form.Control.Feedback>
        </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={props.closeModal}>Close</button>
          <Button variant="primary" disabled={!dirty} type="submit" onClick={() => {setStatus(true); handleSubmit()}}>
            {props.createNewPrinter ? "Create" : "Save"}
          </Button>
        </Modal.Footer>
      </Modal>
      </Form>
    )}
    </Formik>
    )
  } else {
    return (
      <Modal show={true} onHide={props.closeModal}>
        <Modal.Header>
          <h5 className="modal-title">{props.createNewPrinter ? "Create" : "Edit"} Printer</h5>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={props.closeModal}>
          <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <b>No Printers Connected.</b>
          <br />
          Please connect a printer to the client application to proceed.
        </Modal.Body>
      </Modal>
    )
  }

}

export default PrinterForm
