import React, { useState, useEffect, useRef } from 'react'
import limsApi from '../../utils/limsApi'
import { withRouter, Link } from 'react-router-dom'

const RecordFieldDisplay = ({ field, inputData, handleChange, formRef }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [item, setItem] = useState(null)

  const fetchItem = () => {
    limsApi.get(`items/${inputData[field.id]}`, (response)=>{
      let itemData = response.data.item
      setItem(itemData)
    },
    '', undefined, setIsLoading)
  }

  useEffect(() => {
    setIsLoading(true)

    if(inputData[field.id]) {
      fetchItem()
    }
    else {
      setItem(null)
      setIsLoading(false)
    }
  }, [inputData[field.id]])

  return (
    <>
      {inputData[field?.id] ? (
        <div>
          {(!isLoading) ? (
            <>
              {item?.entry &&
                <Link to={`/catalog/entries/${item.entryId}/items/${item.id}`}>
                  {`${item.code} (${item.entry.name})`}
                </Link>
              }
            </>
          ) : (
            <i className="fas fa-spinner fa-spin"></i>
          )}
        </div>
      ) : (
        ""
      )}
    </>
  )
}

export default withRouter(RecordFieldDisplay)
