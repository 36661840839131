import React, {useEffect, useState} from 'react'
import limsApi from '../utils/limsApi'
import ReactDOM from 'react-dom'
import axios from 'axios'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  withRouter
} from 'react-router-dom'
import ContentLoader from "react-content-loader"
import ItemTypesList from './itemTypesList'
import ItemType from './itemType'
import Entry from './entry'
import {COMPONENT_NAMES} from "../constants";

const CatalogBrowser = ({ match, history }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [itemTypes, setItemTypes] = useState([])
  const [location, setLocation] = useState({})

  // Notifies the user when their email has been confirmed
  if (window.location.href.includes("email_confirmed"))
      window.flash("Email Verified", 'success')

  const fetchItemTypes = () => {
    const token = localStorage.getItem('gf-token')

    limsApi.get('item_types', (response)=>{
      let itemTypeData = response.data.itemTypes
      setItemTypes(itemTypeData)
    },
    'Error fetching item types', undefined, setIsLoading)
  }

  useEffect(() => {
    fetchItemTypes()
  }, [])

  const handleSelect = (location) => {
    setLocation(location)
    history.push(`/catalog/${location.id}`)
  }

  const urlId = window.location.href.includes("entries") ? null : window.location.href.substring(window.location.href.lastIndexOf('/') + 1)
  if (urlId != null && urlId != location.id) {
    setLocation({id: urlId})
  }
  const selectedId = urlId ? urlId : location ? location.id : null

  return (
    <div>
      {!isLoading ?
        <>
          {itemTypes.length > 0 ? (
            <>
              <div className="col-fixed bg-light">
                <Route path={`${match.path}`}>
                  <div className="p-3">
                      <ItemTypesList
                          treeName={COMPONENT_NAMES.CATALOG_TREE}
                          itemTypes={itemTypes}
                          noHeading={true}
                          onSelect={handleSelect}
                          selectedId={selectedId}
                          additionalClassNames="limited-width"
                      />
                  </div>
                </Route>
              </div>
              <div className="col-fluid p-4 bg-white">
                <Switch>
                  <Route path={`/catalog/entries/:entryId`} render={(props) => (
                    <Entry {...props} />
                  )} />
                  <Route path={`${match.path}/:id`} render={(props) => (
                    <ItemType {...props} />
                  )} />
                </Switch>
              </div>
            </>
          ) : (
            <div className="mx-auto mt-5 py-3 px-4 text-center" style={{maxWidth: "39rem"}}>
              <h1 className="display-4 mb-4 text-success"><i className="fas fa-list-alt"></i></h1>
              <h4 className="mb-3">It's time to define the types of items<br/>kept in your lab.</h4>
              <p className="mb-4">In GenoFAB, this is referred to as your Catalog, and it's the key to tracking and gaining valuable insight into the items used and produced in your lab.</p>
              <Link to="/manage/item-types" className="btn btn-primary">Define My Catalog</Link>
            </div>
          )}
        </>
      :
      <ContentLoader height="200" backgroundColor={'#eee'} foregroundColor={'#ccc'} className="p-3">
        <rect x="3" y="7" rx="3" ry="3" width="22" height="19" />
        <rect x="35" y="7" rx="3" ry="3" width="115" height="19" />
        <rect x="3" y="48" rx="3" ry="3" width="22" height="19" />
        <rect x="35" y="48" rx="3" ry="3" width="115" height="19" />
        <rect x="3" y="89" rx="3" ry="3" width="22" height="19" />
        <rect x="35" y="89" rx="3" ry="3" width="115" height="19" />
        <rect x="3" y="130" rx="3" ry="3" width="22" height="19" />
        <rect x="35" y="130" rx="3" ry="3" width="115" height="19" />
      </ContentLoader>
      }
    </div>
  )
}

export default withRouter(CatalogBrowser)
