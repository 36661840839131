import React, { useState, useEffect, useRef } from 'react'
import limsApi from '../../utils/limsApi'
import { OverlayTrigger, Popover, Card } from 'react-bootstrap'

const ItemTypeFieldSelector = ({ stepI, itemTypeId, structure, selectNumFields, handleChange }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [itemType, setItemType] = useState([])


  let fieldsArr = []
  for (let i=0; i < selectNumFields; i++){
    fieldsArr.push(1)
  }

  const fetchItemType = () => {
    // console.log("fetching item type:", itemTypeId);
    limsApi.get(`item_types/${itemTypeId}`, (response)=>{
      let itemTypeData = response.data.itemType
      // console.log(itemTypeData);
      setItemType(itemTypeData)
    }, '', undefined, setIsLoading)
  }

  useEffect(() => {
    fetchItemType()
  }, [itemTypeId])

  return (
    <div>
      {isLoading ? (
        "Loading..."
      ) : (
        <div className="mt-2">
        {fieldsArr.map((z, i)=>(
          <div>
            <label>Select Field for {i == 0 ? "Previous Plate" : `Input ${i}`}</label>
            <select
              name={itemType.id}
              className="form-control"
              value={structure[stepI].fields ? structure[stepI].fields[i] : ""}
              onChange={(e)=>handleChange(i, stepI, e.target.value, itemTypeId)}
            >
              <option value=""></option>
              {itemType.allTypeFields.filter(f => f.fieldTypeId == 7).map(field => (
                    <option
                      key={field.id}
                      value={field.id}
                    >
                      {field.name}
                    </option>
              ))}
            </select>
          </div>
        ))}
        </div>
      )}
    </div>
  )
}

export default ItemTypeFieldSelector
