import React, { useState, useEffect } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import RichTextEditor from '../richTextEditor'

const MultiLineTextField = ({ field, inputData, handleChange, status }) => {

  const handleCKEditorChange = (data) => {
    const fakeEvent = {
      target:{
        name: field.id,
        value: data
      }}
      handleChange(fakeEvent)
  }

  let afterLabel = (
    <RichTextEditor
      data={inputData[field.id] || ""}
      handleChange={(data)=>{handleCKEditorChange(data)} }
    />
  )

  if (Object.keys(field.viewOptions).length > 0) {
    if (field.viewOptions[status] == "hidden") {
      afterLabel = (
        <OverlayTrigger
          placement="right"
          overlay={
            <Tooltip>
              This field is hidden
            </Tooltip>
          }
        >
          <i className="fas fa-eye-slash ml-3"></i>
        </OverlayTrigger>
      )
    } else if (field.viewOptions[status] == "viewable") {
      // In Future add tooltip explaining that the field is disabled
      afterLabel = (
          <RichTextEditor
            disabled
            data={inputData[field.id] || ""}
            handleChange={(data)=>{handleCKEditorChange(data)} }
          />
      )
    }
  }

  return (
    <div>
      <label>{field.name}</label>
      {afterLabel}
    </div>
  )
}

export default MultiLineTextField
