import React, { useState, useEffect, useRef } from 'react'
import { Modal, Container, Row, Col } from 'react-bootstrap'

const HelpModal = (props) => {
  return (
    <Modal size="lg" show={true} onHide={props.onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Help Resources</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container style={{ paddingLeft: 0, paddingRight: 0 }}>
          <Row noGutters={true} className="pt-1 pb-2">
            <Col className="text-center">
              <a href="https://support.genofab.com/" className="help-modal-action" target="_blank" rel="noopener noreferrer">
                <i className="fas fa-book fa-3x mb-3 text-primary"></i>
                <h4 className="text-dark">Knowledge Base</h4>
                <p className="text-secondary">In-depth articles for both beginners and advanced users.</p>
              </a>
            </Col>
            <Col className="text-center">
              <a href="https://community.genofab.com/" className="help-modal-action" target="_blank" rel="noopener noreferrer">
                <i className="fas fa-comments fa-3x mb-3 text-success"></i>
                <h4 className="text-dark">Community</h4>
                <p className="text-secondary">Submit and vote on feature requests, bug reports and more.</p>
              </a>
            </Col>
            <Col className="text-center">
              <a href="https://support.genofab.com/kb-tickets/new" className="help-modal-action" target="_blank" rel="noopener noreferrer">
                <i className="fas fa-life-ring fa-3x mb-3 text-warning"></i>
                <h4 className="text-dark">Contact Support</h4>
                <p className="text-secondary">Still need help? We have humans standing by.</p>
              </a>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  )
}

export default HelpModal
