import React, {useEffect, useRef, useState} from 'react'
import limsApi from '../utils/limsApi'
import {Link, withRouter} from 'react-router-dom'
import { Modal, Form } from 'react-bootstrap'
import SingleLineTextField from './fields/singleLineTextField'
import MultiLineTextField from './fields/multiLineTextField'
import ChecklistField from './fields/checklistField'
import DropdownField from './fields/dropdownField'
import NumberField from './fields/numberField'
import DateField from './fields/dateField'
import TeamMemberField from './fields/teamMemberField'
import RecordField from './fields/recordField'
import SelectLocation from "./selectLocation"
import SelectLocationRange from './selectLocationRange'
import FileAttachmentField from './fields/fileAttachmentField'
import UrlField from './fields/urlField'
import DurationField from './fields/durationField'
import CurrencyField from './fields/currencyField'
import events from '../utils/events'

const ItemForm = (props) => {
  const pluralize = require('pluralize')
  const [isLoading, setIsLoading] = useState(true)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [fileUploading, setFileUploading] = useState(false)
  const [item, setItem] = useState(props.create ? { status: 2, numOfLotItems: 1 } : {})
  const [inputData, setInputData] = useState({})
  const [inputDataIsLoading, setInputDataIsLoading] = useState(true)
  const ref = useRef(null)
  const itemId = props.itemId != undefined ? props.itemId : props.match.params.itemId
  const itemTypeId = props.itemTypeId != undefined ? props.itemTypeId : props.match.params.id
  const instanceName = props.entry?.itemType?.instanceName ? pluralize(props.entry.itemType.instanceName, 1) : "Item"
  const [itemType, setItemType] = useState({...props.itemType})
  const [location, setLocation] = useState(null)
  const [preEditedItem, setPreEditedItem] = useState({})
  const [preEditedInputData, setPreEditedInputData] = useState({})
  const [newLotPositions, setNewLotPositions] = useState([])

  useEffect(()=>{
    if (!isLoading) {
      if (props.edit) {
        props.setModalTitle(
          <>
            {item.code}
            <small className="d-block text-secondary">{props?.entry?.name}</small>
          </>
        )
      }
    }
    if (props.create) {
      props.setModalTitle(
        <>
          New {instanceName}
          <small className="d-block text-secondary">{props.entry.name}</small>
        </>
      )
    }
  }, [isLoading])

  // New Item Use Effect
  useEffect(() => {
    if (props.create)
      setItem({...item, itemTypeId: props.entry.itemTypeId, entryId: props.entry.id})
  }, [props.entry])

  const handleSubmit = async(event) => {
    event.preventDefault()
    setIsSubmitting(true)

    if (props.create) {
      await limsApi.post('items', { item: item, inputData: inputData }, "", async(response)=>{
          const itemData = response.data.item
          await saveLotPositions(itemData)
          // console.log(itemData);
          const statusId = item.status
          let taskStatus = statusId == 1 || statusId == 4 ? "Done" : statusId == 2 ? "Backlog" : statusId == 3 ? "In Progress" : "Cancelled"
          limsApi.post('tasks', { name: `${itemData.entry.name}: ${itemData.code}`, status: taskStatus, itemId: itemData.id}, `New ${instanceName} Created`, (response)=>{
            props.refreshItems()
            events.createLinkedEvent( `added a new ${itemData.entry.name} ${instanceName.toLowerCase()}: #insertlink#.`, itemData)
            props.history.push(`/catalog/entries/${itemData.entry.id}/items/${itemData.id}`)
          }, undefined, undefined, setIsSubmitting)
        },
        'Error creating item')
    }
    else {
      await limsApi.patch(`items/${item.id}`, { item: item, inputData: inputData }, `${instanceName} Saved`, async(response)=>{
        const itemData = response.data.item
        await saveLotPositions(itemData)
        // console.log(itemData);
        props.refreshItems()
        // events.createLinkedEvent(`edited ${item.entry.name} ${instanceName.toLowerCase()}: #insertlink#.`, item)
        events.createLinkedEditEvent(`edited ${item.entry.name} ${instanceName.toLowerCase()}: #insertlink#.`, item, events.createEditedFieldsMap(preEditedItem, itemData, preEditedInputData, itemData.inputDataForDisplay))

        const redirectPath = `${props.closeModalRedirectsTo}/items/${item.id}`

        props.history.push(redirectPath)
      },
      'Error saving item', setIsSubmitting)
    }
  }

  const saveLotPositions = async(item) => {
    // Save Lot item positions
    if (item.isLot && location) {

      if (location.limitedCapacity && newLotPositions.length == item.lotItems.length) {
        // Structured Location
        let pos = newLotPositions
        pos.sort(function(a, b) {
          return a - b
        })

        await item.lotItems.map(async(item)=>{
          if (pos.length > 0) {
            const newItem = {
              item: {
                locationPosition: pos[0],
                locationId: location.id
              }
            }
            pos.splice(0, 1)
            await limsApi.patch(`items/${item.id}`, newItem, "")
          }
        })
      } else {
        // Unstructured Location
        await item.lotItems.map(async(item)=>{
            const newItem = {
              item: {
                locationId: location.id
              }
            }
            await limsApi.patch(`items/${item.id}`, newItem, "")
        })
      }
    }
  }

  const handleChange = ({ target }) => {
    setItem({...item, [target.name]: target.value})
  }

  const handleCheckedChange = ({ target }) => {
    setItem({...item, [target.name]: target.checked})
  }

  const handleInputDataChange = ({ target }) => {
    setInputData({...inputData, [target.name]: target.value})
  }

  const handleChecklistChange = (field, checkedItems) => {
    setInputData({...inputData, [field.id]: checkedItems})
  }

  const handleFileDataChange = (key, values) => {
    // console.log(event.target.name);
    setInputData({...inputData, [key]: values})
  }

  const fetchItem = async () => {
    limsApi.get(`items/${itemId}`, (response)=>{
      let itemData = response.data.item
      if (itemData.lotItems) {
        itemData["numOfLotItems"] = itemData.lotItems.length
      } else {
        itemData["numOfLotItems"] = 1
      }
      // console.log(itemData);
      const inputDataPayload = response.data.item.inputData
      setItem(itemData)
      setPreEditedItem(itemData)
      setInputData(inputDataPayload)
      setPreEditedInputData(itemData.inputDataForDisplay)
      setInputDataIsLoading(false)
    },
    'Error fetching item', undefined, setIsLoading)
  }

  // fetch item
  useEffect(() => {
    if(props.edit) {
      fetchItem()
    } else {
      setInputDataIsLoading(false)
    }
  }, [])

  const fetchItemType = async (itemTypeId) => {
    await limsApi.get(`item_types/${itemTypeId}`, (response)=>{
      const itemTypeData = response.data.itemType
      setItemType(itemTypeData)
    }, 'Error fetching item type', undefined, setIsLoading)
  }

  // fetch item types once item is fetched
  useEffect(() => {
    if(props.edit && item.itemTypeId) {
      fetchItemType(item.itemTypeId)
    }
  }, [item.itemTypeId])

  const fetchLocation = async (locationId, resetPosition) => {
    await limsApi.get(`locations/${locationId}`, (response)=>{
      const locationData = response.data.location
      setLocation(locationData)
      let lPosition = resetPosition == true ? null : item.locationPosition

      // Ensures that a location always has a position selected
      if (resetPosition) {
        for (let pos = 1; pos <= locationData.numPositions; pos++) {
          let position = pos

          // Makes the auto selected position compatible with vertical locations
          if (locationData.orientation == "vertical") {
            let currentRow = Math.ceil((pos)/locationData.cols)
            let currentCol = ((pos-1) % locationData.cols) + 1
            let cellVertPos = ((locationData.rows * (currentCol - 1)) + currentRow)
            position = cellVertPos
          }

          if (!locationData.children.find(child=>child.locationPosition==position) && !locationData.items.find(i=>(i.locationPosition==position && i.id != item.id))) {
            lPosition = position
            break
          }
        }
      }

      setItem({...item, locationId: locationData.id, locationPosition: lPosition})
    }, 'Error fetching location')
  }

  const fetchLotLocation = async(locationId) => {
    await limsApi.get(`locations/${locationId}`, (response)=>{
      const locationData = response.data.location
      setLocation(locationData)
    }, 'Error fetching location')
  }

  // fetch location once item is fetched
  useEffect(() => {
    if(props.edit && item.location) {
      fetchLocation(item.locationId)
    }
  }, [item.id])

  const selectLocation = (location) => {
    if(!location) {
      setLocation(location) // deselect location/set to null
      setItem({...item, locationId: null, locationPosition: null})
    } else {
      fetchLocation(location.id, true)
    }

    document.body.click()
  }

  const selectLotLocation = (location) => {
    if(!location) {
      setLocation(location) // deselect location/set to null
    } else {
      fetchLotLocation(location.id)
    }
  }

  const locationPositionOptions = () => {
    let options = []
    let firstAvailablePosition
    if(!location) return

    // options.push(
    //   <option key={0} value=""></option>
    // )

    for (let i = 1; i <= location.numPositions; i++) {
      const childLocationInPosition = (location.children?.find(c => c.locationPosition == i) != undefined)
      const itemInPosition = (location.items?.find(item => item.locationPosition == i) != undefined)
      const positionFilled = childLocationInPosition || itemInPosition
      const optionDisabled = positionFilled && item.locationPosition != i // don't disable current position option

      if(!positionFilled) {
        if(firstAvailablePosition == undefined) firstAvailablePosition = i
      }

      options.push(
        <option
          key={i}
          value={i}
          disabled={optionDisabled}
        >
          {`${i} ${optionDisabled ? ' - Filled' : ''}`}
        </option>
      )
    }

    if(!item.locationPosition) setItem({...item, locationPosition: firstAvailablePosition})

    return options
  }

  const statuses = [
    { id: 2, name: "Requested"},
    { id: 3, name: "Processing"},
    { id: 1, name: "Available"},
    { id: 4, name: "Archived"},
    { id: 5, name: "Cancelled"},
  ]

  return (
    <form ref={ref} className="mb-1"
      onSubmit={(event)=>{
        event.preventDefault()
        setIsSubmitting(true)
        handleSubmit(event)
      }}>
      <div className="ml-1 mb-2 row">
          <div className="mb-1 custom-control custom-switch">
            <input type="checkbox" className="custom-control-input" data-toggle="toggle" id="test" checked={item.isLot} name="isLot" onChange={handleCheckedChange} />
            <label className="custom-control-label" htmlFor="test">
              {item.isLot ? "Lot containing" : "Lot containing multiple elements"}
            </label>
          </div>
          {item.isLot && <input type="number" min="1" max="300" name="numOfLotItems" className="form-control d-inline mr-2 ml-2" style={{width: "70px", position: "relative", top: "-5px"}} onChange={handleChange} value={item.numOfLotItems} /> }
          {item.isLot &&  "elements"}
      </div>
      <div className="form-row">
        <div className="form-group col">
          <label>Status</label>
          <select
            name="status"
            className="form-control"
            value={item.status || "Available"}
            onChange={handleChange}
          >
            {statuses.map(option => (
              <option
                key={option.id}
                value={option.id}
              >
                {option.name}
              </option>
            ))}
          </select>
        </div>
        { itemType.itemsHaveLocation ? item.isLot ?
          <SelectLocationRange
              ref={ref}
              className="form-group col"
              location={location}
              setLocation={selectLotLocation}
              locationPositions={newLotPositions}
              setLocationPositions={setNewLotPositions}
              excludeItems={item.item ? item.lotItems.map(item=>location ? item.locationId==location.id ? item.id : undefined : undefined) : undefined}
              numPositions={item.numOfLotItems} />
          :
          <SelectLocation
              ref={ref}
              className="form-group col"
              location={location}
              setLocation={selectLocation}
              locationPosition={item.locationPosition}
              setLocationPosition={(id)=>{setItem({...item, ["locationPosition"]: id})}}
              excludeItem={item}
          />
          :
          null
        }

      </div>
      {/* entry input fields From EDIT ITEM Component in past */}
      { props.edit && itemType?.allTypeFields && !inputDataIsLoading &&
        <div>
          {itemType.allTypeFields.filter(f => (f.fieldTypeId == 7 && item?.entry?.inputData[f.id])).map((field, index) => (
            <div key={field.id} className="form-group">
              <RecordField
                field={field}
                inputData={inputData}
                entry={item.entry}
                handleChange={handleInputDataChange}
                formRef={ref}
              />
            </div>
          ))}
        </div>
      }
      { (props.edit && itemType?.allInstanceFields && !isLoading && !inputDataIsLoading) &&
        <div>
          {itemType.allInstanceFields.map((field, index) => {
            if (Object.keys(field.viewOptions).length > 0) {
              if (field.viewOptions[item.status] == "hidden")
                return null
            }
            return (
              <div key={field.id} className="form-group">
                {
                  {
                    1: <SingleLineTextField field={field} inputData={inputData} handleChange={handleInputDataChange} status={item.status} />,
                    2: <MultiLineTextField field={field} inputData={inputData} handleChange={handleInputDataChange} status={item.status} />,
                    3: <ChecklistField field={field} inputData={inputData} handleChange={handleChecklistChange} status={item.status} />,
                    4: <DropdownField field={field} inputData={inputData} handleChange={handleInputDataChange} status={item.status} />,
                    5: <DateField field={field} inputData={inputData} handleChange={handleInputDataChange} status={item.status} />,
                    6: <TeamMemberField field={field} inputData={inputData} handleChange={handleInputDataChange} status={item.status} />,
                    8: <NumberField field={field} inputData={inputData} handleChange={handleInputDataChange} status={item.status} />,
                    9: <FileAttachmentField field={field} inputData={inputData} handleChange={handleFileDataChange} setFileUploading={(bool) => setFileUploading(bool)} status={item.status} />,
                    10:<UrlField field={field} inputData={inputData} handleChange={handleInputDataChange} status={item.status} />,
                    11:<DurationField field={field} inputData={inputData} handleChange={handleInputDataChange} status={item.status} />,
                    12:<CurrencyField field={field} inputData={inputData} handleChange={handleInputDataChange} status={item.status} />
                  }[field.fieldTypeId]
                }
              </div>
            )
          })}
        </div>
      }

      {/* entry input fields From CREATE ITEM Component in past */}
      { (props.create && props.itemType.allTypeFields && !inputDataIsLoading) && (
        <div>
          {props.itemType.allTypeFields.filter(f => (f.fieldTypeId == 7 && props.entry.inputData[f.id])).map((field, index) => (
            <div key={field.id} className="form-group">
              <RecordField
                field={field}
                inputData={inputData}
                entry={props.entry}
                handleChange={handleInputDataChange}
                formRef={ref}
              />
            </div>
          ))}
        </div>
      )}
      { (props.create && props.itemType?.allInstanceFields && !inputDataIsLoading) && (
        <div>
          {props.itemType.allInstanceFields.map((field, index) => {
            if (Object.keys(field.viewOptions).length > 0) {
              if (field.viewOptions[item.status] == "hidden")
                return null
            }
            return (
              <div key={field.id} className="form-group">
                {
                  {
                    1: <SingleLineTextField field={field} inputData={inputData} handleChange={handleInputDataChange} status={item.status} />,
                    2: <MultiLineTextField field={field} inputData={inputData} handleChange={handleInputDataChange} status={item.status} />,
                    3: <ChecklistField field={field} inputData={inputData} handleChange={handleChecklistChange} status={item.status} />,
                    4: <DropdownField field={field} inputData={inputData} handleChange={handleInputDataChange} status={item.status} />,
                    5: <DateField field={field} inputData={inputData} handleChange={handleInputDataChange} status={item.status} />,
                    6: <TeamMemberField field={field} inputData={inputData} handleChange={handleInputDataChange} status={item.status} />,
                    7: <RecordField field={field} inputData={inputData} handleChange={handleInputDataChange} formRef={ref} status={item.status} />,
                    8: <NumberField field={field} inputData={inputData} handleChange={handleInputDataChange} status={item.status} />,
                    9: <FileAttachmentField field={field} inputData={inputData} handleChange={handleFileDataChange} setFileUploading={(bool) => setFileUploading(bool)} status={item.status} />,
                    10:<UrlField field={field} inputData={inputData} handleChange={handleInputDataChange} status={item.status} />,
                    11:<DurationField field={field} inputData={inputData} handleChange={handleInputDataChange} status={item.status} />,
                    12:<CurrencyField field={field} inputData={inputData} handleChange={handleInputDataChange} status={item.status} />
                  }[field.fieldTypeId]
                }
              </div>
            )
          })}
        </div>
      )}
      <button type="submit" className="btn btn-primary" disabled={isSubmitting || fileUploading}>
        { !isSubmitting ? (
          "Save"
        ) : (
          <i className="fas fa-spinner fa-spin"></i>
        )}
      </button>
      <Link
        to={props.create ? props.closeModalRedirectsTo :`${props.closeModalRedirectsTo}/items/${itemId}`}
        className="btn btn-link"
      >
        Cancel
      </Link>
    </form>
  )
}

export default withRouter(ItemForm)
