import React, { useState, useEffect, useRef } from 'react'
import limsApi from '../../utils/limsApi'
import { withRouter, Link } from 'react-router-dom'

const EntryRecordFieldDisplay = ({ field, inputData, handleChange, formRef }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [entry, setEntry] = useState(null)

  const fetchEntry = () => {
    limsApi.get(`entries/${inputData[field.id]}`, (response)=>{
      let entryData = response.data.entry
      setEntry(entryData)
    },
    '', undefined, setIsLoading)
  }

  useEffect(() => {
    setIsLoading(true)

    if(inputData[field.id]) {
      fetchEntry()
    }
    else {
      setEntry(null)
      setIsLoading(false)
    }
  }, [inputData[field.id]])

  return (
    <>
      {inputData[field?.id] ? (
        <div>
          {(!isLoading) ? (
            <>
              {entry &&
                <Link to={`/catalog/entries/${entry.id}`}>
                  {`${entry.name}`}
                </Link>
              }
            </>
          ) : (
            <i className="fas fa-spinner fa-spin"></i>
          )}
        </div>
      ) : (
        ""
      )}
    </>
  )
}

export default withRouter(EntryRecordFieldDisplay)
