import React, { useState, useEffect } from 'react'
import axios from "axios"

const LabelPreview = ({zpl, labelTemplate, dpi, numOfLabels, ...props}) => {
  const [imageError, setImageError] = useState(false)
  const [oldUrl, setOldUrl] = useState("")
  const [index, setIndex] = useState(0)

  const width = labelTemplate.width
  const height = labelTemplate.height
  const dpmm = dpi == 300 ? 12 : dpi == 203 ? 8 : dpi == 150 ? 6 : 24


  useEffect(()=>{
    setImageError(false)
  }, [zpl, labelTemplate, dpi, index])

  const incrementIndex = () => {
    setIndex(prev=>(
      prev+1 < numOfLabels ? prev+1 : prev
    ))
  }

  const decrementIndex = () => {
    setIndex(prev=>(
      prev > 0 ? prev-1 : prev
    ))
  }

  let filteredZpl = zpl
  filteredZpl = encodeURI(filteredZpl)
  filteredZpl = filteredZpl.replaceAll("#", "%23")
  filteredZpl = filteredZpl.replaceAll(`^LT${labelTemplate.topShift}`, " ")   // Remove top shift from preview
  filteredZpl = filteredZpl.replaceAll(`^LS${labelTemplate.leftShift}`, " ")  // Remove left shift from preview

  const labelImageUrl = `https://api.labelary.com/v1/printers/${dpmm}dpmm/labels/${width}x${height}/${index}/${filteredZpl}`
  const labelPDFUrl = `https://api.labelary.com/v1/printers/${dpmm}dpmm/labels/${width}x${height}/`

  const downloadPDF = () => {
    axios.request({
        url: labelPDFUrl,
        method: "POST",
        data: zpl,
        headers: {"Accept": "application/pdf", "X-Page-Size": "Letter", "X-Page-Orientation": "Portrait", "X-Page-Layout": "3x4"},
        responseType: 'blob', //important
      })
      .then(({ data }) => {
        let file = window.URL.createObjectURL(new Blob([data]))
        const link = document.createElement('a')
        link.href = file
        link.setAttribute('download', 'GenoFAB_label.pdf')
        document.body.appendChild(link)
        link.click()
        link.remove()
      });
  }

  return (
    <>
      { filteredZpl && (
        <>
          {imageError ?
            <img src={oldUrl} style={{border: "4px solid black", width: "100%"}} />
            :
            <img src={labelImageUrl} style={{border: "4px solid black", width: "100%"}} onError={()=>setImageError(true)} onLoad={()=>setOldUrl(labelImageUrl)} />
           }
           { numOfLabels > 1 &&
             <div className="text-center mt-2">
               <button type="button" disabled={index==0} className="btn btn-outline-secondary btn-sm mr-2" onClick={decrementIndex}>&larr;</button>
               <span>{index+1} of {numOfLabels}</span>
               <button type="button" disabled={index+1==numOfLabels} className="btn btn-outline-secondary btn-sm ml-2" onClick={incrementIndex}>&rarr;</button>
             </div>
           }
           <a href="#" onClick={downloadPDF} style={{position: 'relative', top: '8px'}}>
             <i className="fas fa-file-pdf mr-2"></i>
             Download PDF
           </a>
         </>
      )}

    </>

  )
}

export default LabelPreview
