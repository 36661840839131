import React, { useState, useEffect } from 'react'

const UrlFieldDisplay = ({ field, inputData, ...props }) => {

  let url = inputData[field.id]

  let urlForDisplay = url

  if (urlForDisplay && urlForDisplay.length > 40) {
    urlForDisplay = `${urlForDisplay.substring(0,29)}...${urlForDisplay.substring(urlForDisplay.length-10)}`
  }

  return (
    <div>
      { url &&
        <a href={url} target="_blank">
          {urlForDisplay}
          <i className="fas fa-external-link-alt ml-2"></i>
        </a>
      }
    </div>
  )
}

export default UrlFieldDisplay
