import React, { useState, useEffect, useRef } from 'react'
import { Modal } from 'react-bootstrap'

const HubSpotFormModal = (props) => {

  useEffect(()=>{
    const script3 = document.createElement("script")
    script3.innerHTML = `hbspt.forms.create({
                    portalId: "${props.portalId}",
                    formId: "${props.formId}"
                    });`
    document.getElementById("modal-body").appendChild(script3)
  }, [])

  return (
    <Modal show={true} onHide={()=>{}}>
      <Modal.Header >
        <Modal.Title>{props.modalTitle}</Modal.Title>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={props.closeModal}>
          <span aria-hidden="true">&times;</span>
        </button>
      </Modal.Header>
      <Modal.Body id="modal-body">

      </Modal.Body>
    </Modal>
  )
}

export default HubSpotFormModal
