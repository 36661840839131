import React, { useState, useEffect, useCallback, useRef } from 'react'
import limsApi from '../utils/limsApi'
import { Modal, Form } from 'react-bootstrap'
import SelectLocation from "./selectLocation"
import WorkflowStepList from './workflowStepList.jsx'

const ExecuteWorkflow = ({workflow, closeModal, showWorkflowResults, ...props}) => {

  const [locations, setLocations] = useState([])
  const [transformerItems, setTransformerItems] = useState([])
  const [isValid, setIsValid] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const ref = useRef(null)

  const handleExecute = () => {
    let locationIds = locations.map(loc=>(loc.id))
    let itemIds = []
    transformerItems.map(itemArr=>{
      itemIds = [...itemIds, itemArr.map(item=>(item.id))]
    })

    limsApi.post(`workflows/execute/${workflow.id}`, { workflow: {location_ids: locationIds, item_arr_ids: itemIds} }, `${workflow.name} executed`, (response)=>{
        console.log("Workflow results:", response.data.workflow)
        let results = response.data.workflow
        results.workflow = workflow
        showWorkflowResults(results)
        closeModal()
      },
      'Error creating item', undefined, setIsSubmitting)

  }


  const handleVerify = () => {
    let locs = locations.map(loc => (loc.id))
    let its = transformerItems.map(item=>(item.id))
    limsApi.post(`workflows/verify/${workflow.id}`, { workflow: {locations: locs, items: its} }, `${workflow.name} verified`, (response)=>{
        // console.log("Handle Verify: ", response)
        if (response)
          setIsValid(true)

      },
      'Error creating item', undefined, setIsSubmitting)
  }

  const canExecute = () => {
    let valid = true

    // First check if all items are filled
    workflow.structure.map((step, stepI) => {

      step.inputs.map((inputEntry, entryI) => {
        if (transformerItems[stepI] == undefined || transformerItems[stepI][entryI] == undefined)
          valid = false
      })
    })

    // check that all locations are filled
    if (locations.length < workflow.structure.length) {
      valid = false
    }

    return valid
  }

  useEffect(()=>{
    if (isValid) {
      setIsValid(false)
    }
  }, [locations, transformerItems])

  const executeButtonDisable = !canExecute()

  const genDataStructure = () => {
    let locs = [...locations]
    return workflow.structure.map((structure, index) => {
      let stepStruct = {batchLoc: null, batchEntry: null, transformers: []}
      batchLoc = locs.shift()

    })
  }

  return (
    <Modal backdrop="static" show={true} size="lg" onHide={() => closeModal()} enforceFocus={false} ref={ref}>
      <Modal.Header closeButton>
        <Modal.Title>Execute: {workflow.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <WorkflowStepList
          structure={workflow.structure}
          locations={locations}
          setLocations={(locs)=>setLocations(locs)}
          transformerItems={transformerItems}
          setTransformerItems={(items)=>setTransformerItems(items)}
          increaseTransformerNumber={() => {}}
          reduceTransformerNumber={() => {}}
          />
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-secondary" onClick={closeModal}>Close</button>
        {isValid ?
          <button className="btn btn-success ml-2" disabled={executeButtonDisable || isSubmitting}  onClick={()=>handleExecute()}>
            { !isSubmitting ? (
              "Execute"
            ) : (
              <i className="fas fa-spinner fa-spin"></i>
            )}
          </button>
          :
          <button className="btn btn-warning ml-2" disabled={executeButtonDisable || isSubmitting}  onClick={()=>handleVerify()}>
            { !isSubmitting ? (
              "Verify"
            ) : (
              <i className="fas fa-spinner fa-spin"></i>
            )}
          </button>
        }
      </Modal.Footer>
    </Modal>
  )
}

export default ExecuteWorkflow
