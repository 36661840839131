import React, { useState, useEffect } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

const SingleLineTextField = ({ field, inputData, handleChange, status }) => {

  let afterLabel = (
    <input
      type="text"
      name={field.id}
      className="form-control"
      value={inputData[field.id] || ""}
      onChange={handleChange}
    />
  )

  if (Object.keys(field.viewOptions).length > 0) {
    if (field.viewOptions[status] == "hidden") {
      afterLabel = (
        <OverlayTrigger
          placement="right"
          overlay={
            <Tooltip>
              This field is hidden
            </Tooltip>
          }
        >
          <i className="fas fa-eye-slash ml-3"></i>
        </OverlayTrigger>
      )
    } else if (field.viewOptions[status] == "viewable") {
      afterLabel = (
        <OverlayTrigger
          placement="bottom-start"
          overlay={
            <Tooltip>
              This field is not editable
            </Tooltip>
          }
        >
          <input
            type="text"
            disabled
            name={field.id}
            className="form-control"
            value={inputData[field.id] || ""}
            onChange={handleChange}
          />
        </OverlayTrigger>
      )
    }
  }

  return (
    <div>
      <label>{field.name}</label>
      {afterLabel}
    </div>
  )
}

export default SingleLineTextField
