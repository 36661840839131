import React, { useState, useEffect } from 'react'
import axios from 'axios'
import limsApi from '../utils/limsApi'
import { Table } from 'react-bootstrap'
import ContentLoader from "react-content-loader"
import AccountSubNav from './accountSubNav'
import PrintersList from './printersList'
import PrinterForm from './printerForm'
import LabelForm from './labelForm'
import SetupPrintingModal from './setupPrintingModal'

const ManagePrinters = () => {
  const [showCreatePrinterForm, setShowCreatePrinterForm] = useState(false)  // Contains boolean, used to trigger a modal for a new Printer
  const [showUpdatePrinterForm, setShowUpdatePrinterForm] = useState(null)   // Contains printer object, triggers update modal, passes printer into printer form
  const [showTestLabelModal, setShowTestLabelModal] = useState(null)         // Contains printer object to send the printjob to.
  const [reloadPage, setReloadPage] = useState(false)                        // Contains boolean, used to trigger a re-render cycle in useEffect, needed in PrintersList
  const [isLoading, setIsLoadingInt] = useState(4)
  const [printNodePrinters, setPrintNodePrinters] = useState([])
  const [printers, setPrinters] = useState([])
  const [printNodeApiKey, setPrintNodeApiKey] = useState("")
  const [labelTemplates, setLabelTemplates] = useState([])
  const [showSetupPrintingModal, setShowSetupPrintingModal] = useState(false)

  const setIsLoading = (bool) => {
    if (!bool)
      setIsLoadingInt((prevState) => prevState-1)
  }

  // Performs a get request to list all the printers
  const fetchPrinters = () => {
    limsApi.get('printers', (response)=>{
      let printerData = response.data.printers
      setPrinters(printerData)
    },
    'Error fetching printers', undefined, setIsLoading)
  }

  const fetchPrintNodePrinters = (apiKey) => {
    axios.get('https://api.printnode.com/printers', {
      auth: { username: apiKey}
    })
    .then(response => {
      setPrintNodePrinters(response.data)
      setIsLoading(false)
    })
    .catch(error => {
      console.log(error)
      setIsLoading(false)
    })
  }

  const fetchLabelTemplates = () => {
    limsApi.get('label_templates', (response)=>{
      const labelTemplatesData = response.data.labelTemplates
      setLabelTemplates(labelTemplatesData)
    }, "Error fetching label templates", undefined, setIsLoading)
  }

  const fetchAccountAndPrinters = () => {
    limsApi.get('accounts/me', (response)=>{
      const apiKey = response.data.account.printNodeApiKey
      setPrintNodeApiKey(apiKey)
      apiKey==null ? null : fetchPrintNodePrinters(apiKey)
    },
    'Error fetching account', undefined, setIsLoading)
  }

  const loadingRow = (
    <tr>
      <td>
        <ContentLoader height="20" width="100%" backgroundColor={'#eee'} foregroundColor={'#ccc'}>
          <rect x="0" y="0" rx="3" ry="3" width="150" height="20" />
        </ContentLoader>
      </td>
      <td>
        <ContentLoader height="20" width="100%" backgroundColor={'#eee'} foregroundColor={'#ccc'}>
          <rect x="0" y="0" rx="3" ry="3" width="60" height="20" />
        </ContentLoader>
      </td>
      <td>
        <ContentLoader height="25" width="100%" backgroundColor={'#eee'} foregroundColor={'#ccc'}>
          <rect x="0" y="0" rx="3" ry="3" width="100" height="25" />
        </ContentLoader>
      </td>
    </tr>
  )

  const getOS = () => {
    const platform = window.navigator.platform
    if (platform.includes('Win')) {
      return 'Windows'
    }
    return 'Other'
  }

  // Used to update the printers List
  const reload = () => {
    setReloadPage(prevReloadPageState => !prevReloadPageState)
  }

  useEffect(() => {
    fetchAccountAndPrinters()
    fetchLabelTemplates()
  }, [])

  useEffect(() => {
    fetchPrinters()
  }, [reloadPage])

  return (
    <div>
      <div className="col-fixed bg-light">
        <AccountSubNav />
      </div>
      <div className="col-fluid p-4 bg-white">
        {printNodeApiKey != null ? (
          <>
            <button className="btn btn-primary float-right" onClick={() => setShowCreatePrinterForm(true)}>
              Add Printer
            </button>
            {getOS() == 'Windows' ? (
              <button className="btn btn-secondary float-right mr-2" onClick={() => setShowSetupPrintingModal(true)}>
                Printing Client
              </button>
            ) : null}
            <h3 className="mb-3">Manage Printers</h3>
          </>
        ) : (
          <>
            <h3 className="mb-3">Manage Printers</h3>
            <p>Your account needs to setup printing.</p>
            <button className="btn btn-primary float-middle" onClick={() => setShowSetupPrintingModal(true)}>
              Setup Printing
            </button>
          </>
        )}

        {isLoading<=0 && printNodeApiKey != null ? (
          <div>
        <PrintersList
          reload={reloadPage}
          printers={printers}
          printerDeleted={reload}
          updatePrinter={(printer) => setShowUpdatePrinterForm(printer)}
          sendTestLabel={(printer) => setShowTestLabelModal(printer)}
          printNodePrinters={printNodePrinters}
          labelTemplates={labelTemplates}
          />
        {showCreatePrinterForm ?
          (<PrinterForm
            closeModal={() => setShowCreatePrinterForm(false)}
            reload={reload}
            createNewPrinter
            printNodePrinters={printNodePrinters} />)
            : null}
        {showUpdatePrinterForm ?
          (<PrinterForm
            closeModal={() => setShowUpdatePrinterForm(null)}
            reload={reload}
            printer={showUpdatePrinterForm}
            printNodePrinters={printNodePrinters} />)
            : null}
        {showTestLabelModal ?
          (<LabelForm
            closeModal={() => setShowTestLabelModal(null)}
            printer={showTestLabelModal}
            title="GenoFAB"
            lines={["test", "label"]}
            barcodeData={"This is a test label."}
            reload={reload}
            />)
            : null}
            </div>
          ) : (
            isLoading > 0 && printNodeApiKey != null ?
              (<Table id="printers-list" bordered hover>
                <tbody>
                  {loadingRow}
                  {loadingRow}
                  {loadingRow}
                </tbody>
              </Table>)
              :
              (showSetupPrintingModal ? (
                <SetupPrintingModal
                  closeModal={() => setShowSetupPrintingModal(false)}
                  reload={() => fetchAccountAndPrinters()}
                  />
              ) : null)
          )}
          {showSetupPrintingModal ? (
            <SetupPrintingModal
              closeModal={() => setShowSetupPrintingModal(false)}
              reload={() => fetchAccountAndPrinters()}
              />
          ) : null}
      </div>
    </div>

  )
}

export default ManagePrinters
