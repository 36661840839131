import React, {useEffect, useState} from "react"
import Card from "react-bootstrap/Card"
import {OverlayTrigger, Row, Col, IconButton, Container} from "react-bootstrap"
import Popover from "react-bootstrap/Popover"
import LocationsList from "./locationsList"
import limsApi from '../utils/limsApi'
import {COMPONENT_NAMES} from "../constants"
import LocationPositionRangeSelectorGrid from "./locationPositionRangeSelectorGrid"

const UpdatingPopover = React.forwardRef(({ popper, children, show: _, ...props }, ref) => {
    useEffect(() => {
      popper.scheduleUpdate();
    }, [children, popper]);

    return (
      <Popover ref={ref} {...props}>
          <Popover.Title as="h3">
            Select a starting Location
            <button type="button" className="close float-right ml-2" onClick={()=>document.getElementById("popoverRoot").click()}>&times;</button>
          </Popover.Title>
          {children}
      </Popover>
    );
  },
);

const SelectLocationRange = React.forwardRef(({type, className, setLocation, location, locationPositions, setLocationPositions, excludeLocation, excludeItems, numPositions, ...props}, ref) => {
    const [locations, setLocations] = useState([])

    const fetchLocations = () => {
      limsApi.get('locations', (response)=>{
          let locationData = response.data.locations
          setLocations(locationData)
        },
        'Error Fetching locations')
    }

    useEffect(() => {
        fetchLocations()
    }, [])

    // const locationPositionForDisplay = () => {
    //   if (location.orientation == 'vertical') {
    //     let currentRow = Math.ceil((locationPosition)/location.cols)
    //     let currentCol = ((locationPosition-1) % location.cols) + 1
    //     let position = ((location.rows * (currentCol - 1)) + currentRow)
    //     return position
    //   }
    //   return locationPosition
    // }

    const popoverContent = (
      <Popover.Content>
        <Container>
          <Row>
          { locations.length > 0 &&
            <Col>
              <div style={{overflowY: "scroll", maxHeight: "36rem"}}>
                <LocationsList
                    treeName={COMPONENT_NAMES.SELECT_LOCATION_TREE}
                    locations={locations}
                    noHeading={true}
                    noActions={true}
                    onSelect={setLocation}
                    selectedId={location ? location.id : location}
                />
              </div>
            </Col>
          }
          { location && location.limitedCapacity &&
            <Col className="mb-2">
              <LocationPositionRangeSelectorGrid
                locationId={location.id}
                rows={location.rows}
                cols={location.cols}
                rowLabels={location.rowLabels}
                colLabels={location.colLabels}
                orientation={location.orientation}
                browsing={true}
                children={location.children}
                items={location.items}
                setSelectedPositions={(array)=>{setLocationPositions(array)}}
                selectedPositions={locationPositions}
                excludeLocation={excludeLocation}
                excludeItems={excludeItems}
                numPositions={numPositions}
              />
            </Col>
          }
          </Row>
        </Container>
      </Popover.Content>
    )

    return (<div className={className}>
          <label>
              {type?type:""} Location
          </label>
          <Card className={"field-input-card"}>
              <Card.Body className="p-2 px-3 text-primary">
                      <div className={"location-actions"} >
                        <OverlayTrigger
                            trigger="click"
                            placement="bottom"
                            overlay={
                                <UpdatingPopover id="popover-contained" style={{maxWidth: "100%"}}>{popoverContent}</UpdatingPopover>
                            }
                        >
                          <a className={"select-location"} style={{width:'100%'}} id="popoverRoot"
                             href="#" onClick={(e) => e.preventDefault()}>
                              {!location ? ("Select Location") : (`${location.name} (${location.limitedCapacity ? locationPositions.length : numPositions} Positions)`)}
                          </a>
                        </OverlayTrigger>

                        {location?
                        <a
                          href="#"
                          onClick={(e)=>{
                            e.preventDefault()
                            // This must be null for the id (first arg), because null will be passed down through
                            // the API and set in the database. Where undefined will not. For the name (second arg),
                            // we don't care.
                            setLocationPositions([])
                            setLocation(null)
                          }}
                        >
                          <i className="fas fa-times-circle ml-2 text-muted"></i>
                        </a>:""}
                    </div>
                  </Card.Body>
                </Card>
            </div>);
});

export default SelectLocationRange
