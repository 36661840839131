import React from 'react'
import TreeBranch from './treeBranch'

const TreeRoot = (props) => {
  return (
    <div className={`tree ${props.additionalClassNames}`}>
      <TreeBranch
        treeName={props.treeName}
        children={props.children}
        actions={props.actions}
        onSelect={props.onSelect}
        selectedId={props.selectedId}
      />
    </div>
  )
}

export default TreeRoot
