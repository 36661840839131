import React, { useState, useEffect } from 'react'
import limsApi from '../utils/limsApi'
import { withRouter, NavLink } from 'react-router-dom'
import { Form, Modal, Button } from 'react-bootstrap'

const LocationFillForm = (props) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [options, setOptions] = useState([])
  const [fillSettings, setFillSettings] = useState({
    childLocationsTemplateId: "",
    numChildren: 1
  })
  const [parentLocation, setParentLocation] = useState({})

  const fetchParentLocation = (id) => {
    limsApi.get(`locations/${id}`, (response)=>{
        let locationData = response.data.location
        setParentLocation(locationData)
      },
      'Error fetching parent location')
  }

  // fetch parent location
  useEffect(() => {
    if(props.match.params.id) {
      fetchParentLocation(props.match.params.id)
    }
  }, [props.match.params.id])

  const fetchOptions = () => {
    let options = []
    let firstAvailablePosition
    if(!parentLocation?.id) return
    if(!parentLocation?.limitedCapacity) return

    for (let i = 1; i <= parentLocation.numPositions; i++) {
      const childLocationInPosition = (parentLocation.children?.find(c => c.locationPosition == i) != undefined)
      const itemInPosition = (parentLocation.items?.find(item => item.locationPosition == i) != undefined)

      if (parentLocation.orientation == 'vertical') {
        childLocationInPosition = (parentLocation.children?.find(c =>{
          let currentRow = Math.ceil((c.locationPosition)/parentLocation.cols)
          let currentCol = ((c.locationPosition-1) % parentLocation.cols) + 1
          let position = ((parentLocation.rows * (currentCol - 1)) + currentRow)
          if (position == i)
            return c
        }) != undefined)

        itemInPosition = (parentLocation.items?.find(item =>{
          let currentRow = Math.ceil((item.locationPosition)/parentLocation.cols)
          let currentCol = ((item.locationPosition-1) % parentLocation.cols) + 1
          let position = ((parentLocation.rows * (currentCol - 1)) + currentRow)
          if (position == i)
            return item
        }) != undefined)
      }
      const positionFilled = childLocationInPosition || itemInPosition
      const optionDisabled = positionFilled

      if(!positionFilled) {
        if(firstAvailablePosition == undefined) firstAvailablePosition = i
      }

      options.push(
        <option
          key={i}
          value={i}
          disabled={optionDisabled}
        >
          {`${i} ${optionDisabled ? ' - Filled' : ''}`}
        </option>
      )
    }

    // setFillSettings({...fillSettings, startingPosition: firstAvailablePosition})

    setOptions(options)
    return firstAvailablePosition
  }

  useEffect(() => {
    const firstAvailablePosition = fetchOptions()
    setFillSettings({...fillSettings, startingPosition: firstAvailablePosition})
  }, [parentLocation])

  // select first template
  useEffect(() => {
    setFillSettings({...fillSettings, childLocationsTemplateId: props.locationTemplates[0]?.id})
  }, [props.locationTemplates])

  const handleChange = ({ target }) => {
    let val = target.value

    // convert val to integer if number field
    if(target.type == 'number') {
      val = parseInt(val)
    }

    setFillSettings({...fillSettings, [target.name]: val})
  }

  const handleSubmit = (event) => {
      event.preventDefault()
      setIsSubmitting(true)

      limsApi.post(`locations/${props.match.params.id}/populate`, {
        instructions: {
          childLocationsTemplateId: fillSettings.childLocationsTemplateId,
          numChildren: fillSettings.numChildren,
          startingPosition: fillSettings.startingPosition,
        }
      }, `${fillSettings.numChildren} Locations added to ${parentLocation.name}`, (response)=>{
        // const newChildren = response.data.locations
        props.refreshLocations()
        props.history.push(`/manage/locations/`)
      },
      'Error adding locations', undefined, setIsSubmitting)
  }

  return (
    <Modal show={true} size="md" onHide={() => { props.history.push(`/manage/locations`) }}>
      <Modal.Header closeButton>
        <Modal.Title>
          Add Locations from Template
          <small className="d-block text-secondary">To {parentLocation.name}</small>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit} className="mb-1">
          <div className="form-group">
            <label>Location Template</label>
            <select
              className="form-control"
              name="childLocationsTemplateId"
              value={fillSettings.childLocationsTemplateId}
              onChange={handleChange}
            >
              {props.locationTemplates.map(template => (
                <option
                  key={template.id}
                  value={template.id}
                >
                  {template.name}
                </option>
              ))}
            </select>
          </div>
          <div className="form-row">
            <div className="form-group col">
              <label>Quantity</label>
              <input
                name="numChildren"
                className="form-control"
                value={fillSettings.numChildren}
                onChange={handleChange}
                type="number"
              />
            </div>
            { parentLocation?.limitedCapacity &&
              <div className="form-group col">
                <label>Starting Position</label>
                { parentLocation?.id &&
                  <Form.Control
                    as="select"
                    name="startingPosition"
                    className="form-control"
                    value={fillSettings.startingPosition}
                    onChange={handleChange}
                  >
                    {options.length ? options : ""}
                  </Form.Control>
                }
              </div>
            }
          </div>
          <button type="submit" className="mr-2 btn btn-primary" disabled={isSubmitting}>
            { !isSubmitting ? (
              `Add to ${parentLocation.name}`
            ) : (
              <i className="fas fa-spinner fa-spin"></i>
            )}
          </button>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => { props.history.push(`/manage/locations/`) }}
          >Cancel</button>
        </form>
      </Modal.Body>
    </Modal>
  )
}

export default withRouter(LocationFillForm)
