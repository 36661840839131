import React, { useState, useEffect } from 'react'
import limsApi from '../utils/limsApi'
import { Switch, withRouter, Link, Route } from 'react-router-dom'
import { Form, InputGroup, Col, Dropdown, OverlayTrigger, Tooltip, Popover } from 'react-bootstrap'

const Search = (props) => {
  const [results, setResults] = useState(null)
  const [searchWord, setSearchWord] = useState('')
  const [showDropdown, setShowDropdown] = useState(false)
  const [mouseOver, setMouseOver] = useState(false)

  const fetchResults = (query) => {
    let cleaned_query = query.replace("#", "%23")
    // Avoid making an empty search
    if (query.length > 0) {
      limsApi.get(`search/${cleaned_query}`, (response)=>{
        setResults(response.data)
      })
    } else {
      setResults(null)
    }
  }

  const handleSearchChange = (event) => {
    let search = event.target.value
    var patt = new RegExp("^[a-zA-Z0-9_# ]*$")
    if (patt.test(search)) {
      fetchResults(search)
      setSearchWord(search)
    }
  }

  const goTo = link => {
    setMouseOver(false)
    setShowDropdown(false)
    setSearchWord('')
    props.history.push(link)
  }

  const itemPreviewPopover = (props, result, preview) => (
    <Popover id="popover-basic" {...props}>
      <Popover.Title as="h3">{result.code}</Popover.Title>
      <Popover.Content>
        {preview.map(p=> <span key={p}>{p}<br/></span>)}
      </Popover.Content>
    </Popover>
  );

  const entryPreviewPopover = (props, result, preview) => (
    <Popover id="popover-basic" {...props}>
      <Popover.Title as="h3">{result.name}</Popover.Title>
      <Popover.Content>
        { preview.length > 0 ?
          preview.map(p=> <span key={p}>{p}<br/></span>)
          :
          "No entry attributes"
        }
      </Popover.Content>
    </Popover>
  );

let resultList = null

if (results) {
  if (results.locations && results.tasks && results.entries && results.items) {
    if (results.locations.length > 0 || results.tasks.length > 0 || results.entries.length > 0 || results.items.length > 0) {
      resultList = []
      if (results.entries.length > 0) {
        resultList.push(<Dropdown.Header className="text-dark" key="entry">Entries</Dropdown.Header>)

        results.entries.map((result, index) => {
          let preview = []

          Object.keys(result.input_data_for_list_display).map((key, index)=> {
            let hash = result.input_data_for_list_display[key]
            preview = [...preview, <>{hash.label}: <b>{hash.value}</b></>]
          })

          resultList.push(
            <OverlayTrigger
              placement="left"
              eventKey={`entry:${index}`}
              key={`entry:${index}`}
              overlay={(props)=>entryPreviewPopover(props, result, preview)}
            >
              <Dropdown.Item className="search-result-item text-primary" eventKey={`entry:${index}`} key={`entry:${index}`} onClick={()=>goTo(`/catalog/entries/${result.id}`)}>
                {result.name}
              </Dropdown.Item>
            </OverlayTrigger>
          )
        })
      }

      if (results.items.length > 0) {
        resultList.push(<Dropdown.Header className="text-dark" key="item">Items</Dropdown.Header>)

        results.items.map((result, index) => {
          let preview = [<>Entry: <b>{result.entry_name}</b></>]

          Object.keys(result.input_data_for_list_display).map((key, index)=> {
            let hash = result.input_data_for_list_display[key]
            preview = [...preview, <>{hash.label}: <b>{hash.value}</b></>]
          })

          resultList.push(
            <OverlayTrigger
              placement="left"
              eventKey={`item:${index}`}
              key={`item:${index}`}
              overlay={(props)=>itemPreviewPopover(props, result, preview)}
            >
              <Dropdown.Item className="search-result-item text-primary" eventKey={`item:${index}`} key={`item:${index}`} onClick={()=>goTo(`/catalog/entries/${result.entry_id}/items/${result.id}`)}>
                {result.code}
              </Dropdown.Item>
            </OverlayTrigger>
          )
        })
      }

      if (results.tasks.length > 0) {
        resultList.push(<Dropdown.Header className="text-dark" key="task">Tasks</Dropdown.Header>)
        results.tasks.map((result, index) => {
          resultList.push(
            <Dropdown.Item className="search-result-item text-primary" eventKey={`task:${index}`} key={`task:${index}`} onClick={()=>goTo(`/tasks/${result.id}`)}>
              {result.name}
            </Dropdown.Item>)
        })
      }

      if (results.locations.length > 0) {
        resultList.push(<Dropdown.Header className="text-dark" key="loc">Locations</Dropdown.Header>)
        results.locations.map((result, index) => {
          resultList.push(
            <Dropdown.Item className="search-result-item text-primary" eventKey={`loc:${index}`} key={`loc:${index}`} onClick={()=>goTo(`/locations/${result.id}`)}>
              {result.name}
            </Dropdown.Item>)
        })
      }
    } else {
      resultList = (<Dropdown.ItemText>No results found</Dropdown.ItemText>)
    }
  }
}


  return (
    <Form.Row>
        <Form.Group as={Col}>
                <div className="dropdown" onFocus={()=>setShowDropdown(true)} onBlur={()=>!mouseOver ? setShowDropdown(false) : null}>
                  <Dropdown show={showDropdown}>
                    <InputGroup>
                      <InputGroup.Prepend>
                          <InputGroup.Text>
                              <i className="fa fa-search"></i>
                          </InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                          type="text"
                          autoComplete="off"
                          id="searchInput"
                          placeholder="Search"
                          value={searchWord}
                          onChange={handleSearchChange}
                      />
                    </InputGroup>
                    <Dropdown.Toggle as={Form} id="dropdown-custom-components" className='search-dropdown-toggle' >
                    </Dropdown.Toggle>
                    { resultList != null ? (
                      <Dropdown.Menu className="dropdown-menu" onMouseEnter={()=>setMouseOver(true)} onMouseLeave={()=>setMouseOver(false)} show={searchWord.length > 0} renderOnMount style={searchWord.length > 0 ? {} : {visibility: "hidden"}}>
                         {resultList}
                      </Dropdown.Menu>
                    ) : null}
                  </Dropdown>
                </div>
        </Form.Group>
    </Form.Row>
  )
}

export default withRouter(Search)
