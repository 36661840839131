import {ActionButtonGroup, DeleteButton, EditButton, NewButton} from "./buttons/actionButtons";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import React from "react";
import {limsDelete} from "../utils/limsApi";

const handleDeleteItemType = (id) => {
    // TODO: this click is certainly misplaced. Find a better place for this.
    // or a better way of closing the delete confirmation dialog
    document.body.click()
    limsDelete('item_types', id,
        `Type Removed`,
        `Items belong to this type. Cannot delete.`
    );
};

const ItemTypesActions = ({setChildren, treeName, node, ...props}) => {
    return (
        <ActionButtonGroup>
            <NewButton actionUrl={`/manage/item-types/new?parentId=${node.id}`}/>
            <EditButton actionUrl={`/manage/item-types/${node.id}/edit/`}/>
        </ActionButtonGroup>);
};

export default ItemTypesActions;
