import React, { useState, useRef, useEffect } from 'react'
import { OverlayTrigger, Popover, Tooltip, Button, Container, Row, Col, Form, Card } from 'react-bootstrap'
import { Link, withRouter } from 'react-router-dom'
import limsApi from '../utils/limsApi'

const LocationPositionSelectorGrid = ({selectedPosition, setSelectedPosition, excludeLocation, excludeItem, ...props}) => {
  const boardRef = useRef(null)
  const [items, setItems] = useState([])
  const [itemIdsForDisplay, setItemIdsForDisplay] = useState({})
  const [grid, setGrid] = useState(true)

  useEffect(() => {
    boardRef.current?.style?.setProperty("--num-cols", parseInt(props.cols))
  }, [props.cols])

  useEffect(() => {
    boardRef.current?.style?.setProperty("--num-rows", parseInt(props.rows))
  }, [props.rows])

  const extraWideClassName = () => {
    return parseInt(props.cols) > 9 ? "grid-extra-wide" : ""
  }

  const extraTallClassName = () => {
    return parseInt(props.rows) > 9 ? "grid-extra-tall" : ""
  }

  useEffect(()=>{
    props.items.map(item=>{
      fetchItem(item.id)
    })
  }, [props.items])

  const fetchItem = (id) => {
    limsApi.get(`items/${id}`, (response)=>{
      const item = response.data.item
      setItems(prev=>[...prev, item])
      const itemTypeCode = item.entry?.itemType?.code ? item.entry.itemType.code.toUpperCase() : ""
      const display = `#${itemTypeCode}${item.parentId ? `${item.parentId}` : item.id}`

      setItemIdsForDisplay(prev => {
        let arr = prev
        arr[item.id] = display
        return arr
      })
      setGrid(prev=>!prev)
    },
    'Error fetching item')
  }
  // console.log(itemIdsForDisplay);

  const createGrid = () => {
    let html = []

    // loop through all of location's positions
    for (let i = 0; i < props.rows * props.cols; i++) {
      let cellId = i + 1
      let currentRow = Math.ceil((cellId)/props.cols)
      let currentCol = (i % props.cols) + 1
      let cellLabel = props.orientation != "vertical" ? cellId : ((props.rows * (currentCol - 1)) + currentRow)
      let containedLocation = props.children && i != selectedPosition-1 ? props.children.find(c => c.locationPosition == cellId) : undefined
      let containedItem = props.items && i != selectedPosition-1 ? props.items.find(i => i.locationPosition == cellId) : undefined

      if (containedLocation && excludeLocation) {
        if (containedLocation.id == excludeLocation.id) {
          // The selected postion is the item so override it
          containedLocation = undefined
        }
      }

      if (containedItem && excludeItem) {
        if (containedItem.id == excludeItem.id) {
          // The selected postion is the item so override it
          containedItem = undefined
        }
      }

      // determine current column label
      let currentColLabel
      switch(props.colLabels) {
        case "":
          currentColLabel = ""
          break
        case "alpha":
          currentColLabel = (currentCol + 9).toString(36).toUpperCase()
          break
        case "numeric":
          currentColLabel = currentCol
          break
      }

      // determine current row label
      let currentRowLabel
      switch(props.rowLabels) {
        case "":
          currentRowLabel = ""
          break
        case "alpha":
          currentRowLabel = (currentRow + 9).toString(36).toUpperCase()
          break
        case "numeric":
          currentRowLabel = currentRow
          break
      }

      html.push(
        <div key={i} className="square align-items-center">
          {currentCol == 1 ? <div className="row-label">{currentRowLabel}</div> : ""}
          {currentRow == 1 ? <div className="col-label">{currentColLabel}</div> : ""}
          {!props.browsing ? (
            <div className="text-center">
              { ( props.rows <= 6 && props.cols <= 7 ) &&
                <small className="d-block">Position</small>
              }
              <span className={`text-dark`}>{cellLabel}</span>
            </div>
          ) : (
            <div className="text-center w-100 h-100 align-items-center justify-content-center">
              { !containedItem && !containedLocation &&
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip>
                      Select Position {cellLabel}
                    </Tooltip>
                  }
                >
                <div
                  onClick={() => setSelectedPosition(cellId)}
                  className={"d-flex align-items-center justify-content-center h-100"}
                  style={selectedPosition == cellId ? {border: "2px solid #0d6efd", cursor: "pointer", padding: "4px"} : {cursor: "pointer", padding: "6px"}}
                  >
                  <i className={(selectedPosition == cellId ? "fas text-primary" : "far") + " fa-circle" }></i>
                </div>
                </OverlayTrigger>
              }
              { containedLocation &&
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip>
                      {cellLabel}: {containedLocation.name}
                    </Tooltip>
                  }
                >
                <div className={"d-flex align-items-center justify-content-center text-center w-100 h-100"} style={{position: "absolute", padding: "6px", background: "#eee"}}>
                    <i className="fas fa-circle"></i>
                  </div>
                </OverlayTrigger>
              }
              { containedItem &&
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip>
                      {cellLabel}: {itemIdsForDisplay[containedItem.id]} {containedItem.entry?.name}
                    </Tooltip>
                  }
                >
                  <div className={"d-flex align-items-center justify-content-center text-center w-100 h-100"} style={{position: "absolute", padding: "6px", background: "#eee"}}>
                    <i className="fas fa-circle"></i>
                  </div>
                </OverlayTrigger>
              }
            </div>
          )}
        </div>
      )
    }
    return html
  }

  return (
    <div className={`${props.colLabels == "" ? "" : "pt-4"} ${props.rowLabels == "" ? "" : "pl-4"}`}>
      <div ref={boardRef} className={`board condensed ${extraWideClassName()} ${extraTallClassName()}`}>
        {createGrid()}
      </div>
    </div>
  )
}

export default withRouter(LocationPositionSelectorGrid)
