import React, { useState, useEffect, useRef } from 'react'
import limsApi from '../utils/limsApi'
import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { Table, Row, Col, Container, OverlayTrigger, Tooltip, ButtonGroup, Form, Button, Dropdown, DropdownButton, Modal } from 'react-bootstrap'
import DeleteConfirmation from './deleteConfirmation'
import SelectMultiLocations from './selectMultiLocations'

const LotItems = ({ parent, printAndEditButtons, setParent, reloadItem, setParentLotLocations, ...props }) => {
  const pluralize = require('pluralize')
  const [selectedItems, setSelectedItems] = useState([])
  const [showChangeLocation, setShowChangeLocation] = useState(false)
  const [newLotLocation, setNewLotLocation] = useState(null)
  const [newLotPositions, setNewLotPositions] = useState([])
  const [lotLocations, setLotLocations] = useState([])
  const ref = useRef(null)

  const selectItem = (item) => {
    setSelectedItems(prev => {
      let newSelectedItems = prev ? [...prev] : []
      const index = newSelectedItems.indexOf(item)
      if (index == -1) {
        newSelectedItems.push(item)
      }
      return newSelectedItems
    })
  }

  const deselectItem = (item) => {
    setSelectedItems(prev => {
      let newSelectedItems = prev ? [...prev] : []
      const index = newSelectedItems.indexOf(item)
      if (index > -1) {
        newSelectedItems.splice(index, 1);
      }
      return newSelectedItems
    })
  }

  const setLocationOfSelected = (location) => {
    let tempLotItems = parent.lotItems
    selectedItems.map(item=>{
      for (let i = 0; i < tempLotItems.length; i++) {
        let lotItem = tempLotItems[i]
        if (lotItem.id == item.id) {
          tempLotItems[i] = {...item, location: location}
        }
      }
    })
    setParent({...parent, lotItems: tempLotItems})
  }

  const fetchLocation = async (location) => {
    await limsApi.get(`locations/${location.id}`, (response)=>{
      const locationData = response.data.location
      setNewLotLocation(locationData)

    }, 'Error fetching location')
  }

  useEffect(()=>{
    setParentLotLocations(lotLocations)
  }, [lotLocations])

  const fetchLotLocations = () => {
    const ids = []

    parent.lotItems.map(item => {
      if (!ids.find(id=>id == item.locationId)) {
        if (item.locationId == null) {
          if (ids.indexOf(null) == -1)
            setLotLocations(prev=>[...prev, {name: "No location", id: null}].sort(function(a, b) {return a.id - b.id}))
        } else {
          limsApi.get(`locations/${item.locationId}`, (response)=>{
            const locationData = response.data.location
            setLotLocations(prev=>[...prev, locationData].sort(function(a, b) {return a.id - b.id}))
          })
        }
        ids.push(item.locationId)
      }
    })
  }

  useEffect(()=>{
    async function fetchData() {
      await setLotLocations([])
      fetchLotLocations()
    }
    fetchData()
  },[parent.lotItems])

  const handleSave = async() => {

    if (newLotLocation.limitedCapacity) {

      // Structured Location
      let pos = newLotPositions
      pos.sort(function(a, b) {
        return a - b
      })

      await parent.lotItems.map(async(item)=>{
        if (selectedItems.find(i => i.id == item.id) && pos.length > 0) {
          const newItem = {
            item: {
              locationPosition: pos[0],
              locationId: newLotLocation.id
            }
          }
          pos.splice(0, 1)
          await limsApi.patch(`items/${item.id}`, newItem, "Locations Saved")
        }
      })

    } else {
      // Unstructured Location
      await parent.lotItems.map(async(item)=>{
        if (selectedItems.find(i => i.id == item.id)) {
          const newItem = {
            item: {
              locationId: newLotLocation.id
            }
          }
          await limsApi.patch(`items/${item.id}`, newItem, "Locations Saved")
        }
      })
    }

    reloadItem()
    setSelectedItems([])
    setShowChangeLocation(false)
  }

  const deleteLotItem = (id) => {
    limsApi.delete(`items/${id}`, "Lot item removed", ()=>{
      reloadItem()
    })
  }

  const dropdownButton = (
    <DropdownButton
      variant='secondary'
      title={`Selected (${selectedItems.length})`}
      className="btn-group btn-block"
    >
      <Dropdown.Item onClick={()=>setShowChangeLocation(true)}>Change Location</Dropdown.Item>
      <Dropdown.Divider />
      <Dropdown.Item onClick={()=>parent.lotItems.map(item => selectItem(item))}>Select All</Dropdown.Item>
      <Dropdown.Item className="text-danger btn-outline-danger" onClick={() => parent.lotItems.map(item => deselectItem(item))}>Deselect All</Dropdown.Item>
    </DropdownButton>
  )

  const renderLotItem = (item) => (
    <li className="list-group-item" key={item.id}>
      <div className="row">
        <div className="col">
          <Form.Group controlId={item.id} className="ml-2 mt-1 mb-0">
            <Form.Check type="checkbox" label={`${item.code}`} checked={selectedItems.indexOf(item) >= 0} onChange={() => event.target.checked ? selectItem(item) : deselectItem(item)} />
          </Form.Group>
        </div>
        <div className="col">
          {item.locationPosition ? item.locationPosition : "None"}
          <ButtonGroup className="ml-2" style={{position: "relative", bottom: "3px"}}>
            <DeleteConfirmation delete={() => deleteLotItem(item.id)}>
              <button
                type="button"
                className="btn btn-light btn-sm"
              >
                <i className="fas fa-trash-alt"></i>
              </button>
            </DeleteConfirmation>
          </ButtonGroup>
        </div>
      </div>
    </li>
  )

  const lotItems = (location) => {
    const releventItems = parent.lotItems.filter(li=> li.locationId == location.id)
    return releventItems.map((ri, index)=>{
      return renderLotItem(ri)
    })
  }

  const locationGroups = lotLocations.map((location, index)=> (
    <div className="mb-3" key={index}>
      <div className="mb-2">
        { location.id != null ?
            <Link to={location.id != null ? `/locations/${location.id}` : '#'} className="text-primary mr-2">
              <h5 className="d-inline">{location.name}</h5>
            </Link>
          :
            <h5 className="d-inline mr-2">{location.name}</h5>
        }

        ({parent.lotItems.filter(li=> li.locationId == location.id).length} {pluralize("element", parent.lotItems.filter(li=> li.locationId == location.id).length)})
      </div>
      <ul className="list-group hover-reveal-btn-groups">
        <li className="list-group-item">
          <div className="row">
            <div className="col">
              Element
            </div>
            <div className="col">
              Position
            </div>
          </div>
        </li>
        {lotItems(location)}
      </ul>
    </div>
  ))

  return (
    <div ref={ref}>
      <Container fluid={true} style={{ paddingLeft: 0, paddingRight: 0 }}>
        <Row noGutters={true}>
          <Col xs={9}>
            {locationGroups}
          </Col>
          <Col>
            <div className="pl-3 mb-2">
              { selectedItems.length > 0 && (
                showChangeLocation ?
                  <SelectMultiLocations
                      ref={ref}
                      className="mb-2"
                      location={newLotLocation}
                      setLocation={fetchLocation}
                      locationPositions={newLotPositions}
                      setLocationPositions={setNewLotPositions}
                      excludeItems={selectedItems.map(item=>newLotLocation ? item.locationId==newLotLocation.id ? item.id : undefined : undefined)}
                      numPositions={selectedItems.length}
                      close={()=>setShowChangeLocation(false)}
                      handleSave={handleSave} >
                        <button className="btn btn-primary btn-block" onClick={()=>setShowChangeLocation(false)}>
                          Change Location {`(${selectedItems.length})`}
                        </button>
                      </SelectMultiLocations>
                :
                  <button className="btn btn-primary btn-block" onClick={()=>setShowChangeLocation(true)}>
                    Change Location {`(${selectedItems.length})`}
                  </button>
              )}
              { selectedItems.length < parent.lotItems.length &&
                <button className="btn btn-secondary btn-block" onClick={() => parent.lotItems.map(item => selectItem(item))}>
                  Select All
                </button>
              }
              { selectedItems.length > 0 &&
                <button className="btn btn-outline-secondary btn-block" onClick={() => parent.lotItems.map(item => deselectItem(item))}>
                  Deselect All
                </button>
              }

              {/* selectedItems.length == 0 ?
                <button className="btn btn-outline-secondary btn-block" onClick={() => parent.lotItems.map(item => selectItem(item))}>
                  Select All
                </button>
                :
                <div>
                { showChangeLocation &&
                  <SelectMultiLocations
                      ref={ref}
                      className=""
                      location={newLotLocation}
                      setLocation={fetchLocation}
                      locationPositions={newLotPositions}
                      setLocationPositions={setNewLotPositions}
                      excludeItems={selectedItems.map(item=>newLotLocation ? item.locationId==newLotLocation.id ? item.id : undefined : undefined)}
                      numPositions={selectedItems.length}
                      close={()=>setShowChangeLocation(false)}
                      handleSave={handleSave} >
                      {dropdownButton}
                      </SelectMultiLocations>
                }
                { !showChangeLocation &&
                  dropdownButton
              }
                </div>
            */}

            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default LotItems
