import React, { useState, useEffect } from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import ContentLoader from "react-content-loader"
import EventListItem from './eventListItem'
import firebase from '../utils/firebase'
import limsApi from '../utils/limsApi'

const EventsList = () => {
  const [isLoading, setIsLoadingInt] = useState(3)
  const [recentActivity, setRecentActivity] = useState([])
  const [numOfEvents, setNumOfEvents] = useState(7)
  const [showSeeMoreBtn, setShowSeeMoreBtn] = useState(false)
  const [showSeeLessBtn, setShowSeeLessBtn] = useState(false)
  const [users, setUsers] = useState()
  const [eventItems, setEventItems] = useState([])

  const setIsLoading = (bool) => {
    if (!bool)
      setIsLoadingInt(prev => (prev-1))
  }

  // Gets the latest events in the user's account from firebase
  const fetchRecentActivity = async (limit) => {
    const db = firebase.firestore()
    limsApi.get('accounts/me', (accountData)=>{
      const accountId = accountData.data.account.id
      db.collection(`${accountId}`).orderBy("timestamp", "desc").limit(limit+1).get()
        .then(function(querySnapshot) {
          let events = [];
          querySnapshot.forEach(doc => {
            events.push({...doc.data(), id: doc.id})
          })

      if (events.length <= limit) {
        setShowSeeMoreBtn(false)
      } else {
        setShowSeeMoreBtn(true)
        events.pop()
      }
      events.length > 7 ? setShowSeeLessBtn(true) : setShowSeeLessBtn(false)

      setRecentActivity(events)
      fetchEventItems(events)
      setIsLoading(false)
      });
    },
    'Error fetching account', undefined, setIsLoading)
  }

  // Performs a get request which returns the all users in the account
  const fetchUsers = () => {
    limsApi.get('users', (response)=>{
      setUsers(response.data.users)
    },
    'Error fetching users', undefined, setIsLoading)
  }

  const fetchEventItems = (events) => {
    const idsToFetch = getUniqueEventItemsToFetch(events)
    idsToFetch.forEach((id) => {
      limsApi.get(`items/${id}`, (response)=>{
        setEventItems(prev => [...prev, response.data.item])
      },
      '', undefined, undefined, true)
    })
  }

  const getUniqueEventItemsToFetch = (events) => {
    let itemSet = new Set()
    events.map(evt => {
      if (evt.itemIds) {
        evt.itemIds.map(itemId => {
          itemSet.add(itemId)
        })
      }
    })
    return itemSet
  }

  const increaseNumberOfEvents = () => {
    setNumOfEvents((prev) => {
      fetchRecentActivity(prev+7)
      return(prev+7)
    })
  }

  const decreaseNumberOfEvents = () => {
    setNumOfEvents((prev) => {
      fetchRecentActivity(prev-7)
      return(prev-7)
    })
  }

  useEffect(() => {
    fetchRecentActivity(numOfEvents)
    fetchUsers()
  }, [])

  let listItems = <p className="ml-2">{isLoading > 0 ? "Loading..." : "No recent activity"}</p>
  if (recentActivity.length > 0 && isLoading <= 0) {
    listItems = recentActivity.map(evt => (
        <EventListItem
          event={evt}
          key={evt.id}
          user={users.find( ({ id }) => id == evt.userId )}
          action={evt.action}
          items={eventItems}
          itemIds={evt.itemIds}
          hasDivider={evt == recentActivity[0] ? false : true}
          timestamp={new firebase.firestore.Timestamp(evt.timestamp.seconds, evt.timestamp.nanoseconds).toDate()} />
    ))
  } else if (isLoading > 0 /*|| recentActivity.length <= 0*/) {
    listItems = []
    let i = 0
    for(i = 0; i < numOfEvents; i++) {
      listItems.push(
        <div key={i}>
          <Row className={i != 0 ? "pt-2" : ""}>
            <Col className="d-flex" style={i != 0 ? {'borderTop': '1px solid rgba(0, 0, 0, 0.125)'} : {}}>
              <ContentLoader height="74" width="100%" backgroundColor={'#eee'} foregroundColor={'#ccc'} className="mt-2">
                <circle cx="20" cy="20" r="20" />
                <rect x="48" y="3" rx="3" ry="3" width="150" height="17" />
                <rect x="48" y="25" rx="3" ry="3" width="150" height="17" />
                <rect x="48" y="52" rx="3" ry="3" width="60" height="15" />
              </ContentLoader>
            </Col>
          </Row>
        </div>
      )
    }
    listItems.push(
      <div key={i+1}>
        <ContentLoader height="74" width="100%" backgroundColor={'#eee'} foregroundColor={'#ccc'} className="mt-2">
          <rect x="0" y="5" rx="3" ry="3" width="100%" height="35" />
        </ContentLoader>
      </div>
    )
  }

  return (
    <div className="px-3 pb-3 pt-1">
      <h4 className="ml-2 mt-3 mb-1" style={{fontSize: "20px"}}>Recent Activity</h4>
      <Container>
        {listItems}
      </Container>
      <div className="mt-2 ml-1">
        { showSeeMoreBtn &&
          <button
          type="button"
          className="btn btn-secondary ml-2"
          style={showSeeLessBtn ? {width: "45%"} : {width: "90%"}}
          onClick={() => increaseNumberOfEvents()}
          >
            See more
          </button>
        }
        { showSeeLessBtn &&
          <button
          type="button"
          className="btn btn-outline-secondary ml-2"
          style={showSeeMoreBtn ? {width: "45%"} : {width: "90%"}}
          onClick={() => decreaseNumberOfEvents()}
          >
            See less
          </button>
        }
      </div>
    </div>
  )
}

export default EventsList
