import React, { useState, useEffect } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

const ChecklistField = ({ field, inputData, handleChange, status }) => {
  const [checkedOptions, setCheckedOptions] = useState([])
  const [options, setOptions] = useState([])

  useEffect(() => {
    setOptions(field.fieldOptionsAttributes || [])
    setCheckedOptions(inputData[field.id] || [])
  }, [field, inputData])

  const onChange = ({ target }) => {
    let checked

    if(target.checked) {
      // add selected to checked state
      checked = checkedOptions.concat(target.value)
      setCheckedOptions(checked)
    }
    else {
      // remove selected from checked state
      checked = checkedOptions.filter(item => item !== target.value)
      setCheckedOptions(checked)
    }

    handleChange(field, checked)
  }

  let afterLabel = (
    options.map((option, index) => (
      <div className="form-check" key={option.id}>
        <label className="form-check-label">
          <input
            name={option.id}
            className="form-check-input"
            value={option.id}
            onChange={onChange}
            type="checkbox"
            checked={checkedOptions.includes(option.id.toString())}
          />
          {option.label}
        </label>
      </div>
    ))
  )

  if (Object.keys(field.viewOptions).length > 0) {
    if (field.viewOptions[status] == "hidden") {
      afterLabel = (
        <OverlayTrigger
          placement="right"
          overlay={
            <Tooltip>
              This field is hidden
            </Tooltip>
          }
        >
          <i className="fas fa-eye-slash ml-3"></i>
        </OverlayTrigger>
      )
    } else if (field.viewOptions[status] == "viewable") {
      afterLabel = (
          options.map((option, index) => (
            <div className="form-check" key={option.id}>
              <label className="form-check-label">
                <input
                  disabled
                  name={option.id}
                  className="form-check-input"
                  value={option.id}
                  onChange={onChange}
                  type="checkbox"
                  checked={checkedOptions.includes(option.id.toString())}
                />
                {option.label}
              </label>
            </div>
          ))
      )
    }
  }

  return (
    <div>
      <label>{field.name}</label>
      {afterLabel}
    </div>
  )
}

export default ChecklistField
