import React, { useState, useEffect } from 'react'
import { Link, withRouter } from 'react-router-dom'
import NavLogo from 'images/logo-login.png'
import axios from 'axios'

const Login = (props) => {
  const [email, setEmail] = React.useState("")
  const [password, setPassword] = React.useState("")

  const onLogin = async (email, password) => {
    props.setLoginIsLoading(true)

    await axios.post('/api/users/login', { user: { email: email, password: password } }, {})
    .then(async (response) => {
      let userData = response.data.user
      await localStorage.setItem('gf-token', userData.token)
      window.location.href = `/catalog/${userData.firstItemTypeId || ""}`
    })
    .catch(error => {
      props.setLoginIsLoading(false)
      props.setLoginFlashMessage({ message: 'Email or password is invalid.', alertClass: 'warning' })
    })
  }

  return (
    <div className="login-container p-5 mx-auto">
      <p className="mb-4 text-center"><img src={NavLogo} width="260" /></p>
      <h2 className="mb-3 text-center">Log In</h2>
      <form onSubmit={(e) => { e.preventDefault(); onLogin(email, password) }}>
        {props.loginFlashMessage &&
          <div className={`alert alert-${props.loginFlashMessage.alertClass}`}>{props.loginFlashMessage.message}</div>
        }
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            className="form-control"
            type="text"
            name="email"
            onChange={e => setEmail(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password</label>
          <input
            className="form-control"
            type="password"
            name="password"
            onChange={e => setPassword(e.target.value)}
          />
          <small className=""><Link to="/forgot_password">Forgot your password?</Link></small>
        </div>
        <div className="actions my-4">
          <button type="submit" className="btn btn-primary btn-block" disabled={props.isLoading}>
            { !props.isLoading ? (
              "Login"
            ) : (
              <i className="fas fa-spinner fa-spin"></i>
            )}
          </button>
        </div>
      </form>
      {/*<div className="text-center">Don't have an account yet?<br/><Link to="/signup">Sign up for free</Link></div>*/}
      <div className="text-center">Don't have an account yet?<br/><a href="https://start.genofab.com/demo">Book a demo</a>, it's fast and easy,<br/>or contact your lab manager.</div>
      {/*<div className="text-center">Don't have an account yet?<br/><a href="https://start.genofab.com/demo">Book a demo</a>, it's fast and easy!</div>*/}
    </div>
  );
}

export default Login
