import firebase from './firebase'
import axios from 'axios'

export default class events {

  static  createLinklessEvent(action) {
    let db = firebase.firestore()
    // Add a new event
    const token = localStorage.getItem('gf-token')
    axios.get('/api/user',
      { headers: {"Authorization" : `Token ${token}`} })
    .then(response => {
      const user = response.data.user
      axios.get('/api/accounts/me',
        { headers: {"Authorization" : `Token ${token}`} })
        .then(accountData => {
          const accountId = accountData.data.account.id
          db.collection(`${accountId}`).doc().set({
            userId: user.id,
            action: action,
            timestamp: firebase.firestore.FieldValue.serverTimestamp()
          })
          .then(() => {
            // console.log("Document successfully written!");
          })
          .catch(error => {
            console.error("Error writing document: ", error);
          });
        })
        .catch(error => {
          console.log(error);
        })
    })
    .catch(error => {
      console.log(error)
    })
  }

  static createLinkedEvent(action, items) {
    let db = firebase.firestore()
    // Add a new event
    const token = localStorage.getItem('gf-token')
    axios.get('/api/user',
      { headers: {"Authorization" : `Token ${token}`} })
    .then(response => {
      const user = response.data.user
      axios.get('/api/accounts/me',
        { headers: {"Authorization" : `Token ${token}`} })
        .then(accountData => {
          const accountId = accountData.data.account.id
          db.collection(`${accountId}`).doc().set({
            userId: user.id,
            action: action,
            itemIds: Array.isArray(items) ? items.map(item => item.id) : [items.id],
            timestamp: firebase.firestore.FieldValue.serverTimestamp()
          })
          .then(() => {
            // console.log("Document successfully written!");
          })
          .catch(error => {
            console.error("Error writing document: ", error);
          });
        })
        .catch(error => {
          console.log(error);
        })
    })
    .catch(error => {
      console.log(error)
    })
  }

  static createLinkedEditEvent(action, items, editedFields) {
    if (editedFields != null && Object.keys(editedFields).length > 0) {
      let db = firebase.firestore()
      // Add a new event
      const token = localStorage.getItem('gf-token')
      axios.get('/api/user',
        { headers: {"Authorization" : `Token ${token}`} })
      .then(response => {
        const user = response.data.user
        axios.get('/api/accounts/me',
          { headers: {"Authorization" : `Token ${token}`} })
          .then(accountData => {
            const accountId = accountData.data.account.id
            db.collection(`${accountId}`).doc().set({
              userId: user.id,
              action: action,
              itemIds: Array.isArray(items) ? items.map(item => item.id) : [items.id],
              editedFields: editedFields,
              timestamp: firebase.firestore.FieldValue.serverTimestamp()
            })
            .then(() => {
              // console.log("Document successfully written!");
            })
            .catch(error => {
              console.error("Error writing document: ", error);
            });
          })
          .catch(error => {
            console.log(error);
          })
      })
      .catch(error => {
        console.log(error)
      })
    }
  }

  static createEditedFieldsMap(oldItem, newItem, oldInputData, newInputData) {
    // console.log("OLD: ", oldItem);
    // console.log("NEW: ", newItem);
    let keys = Object.keys(oldItem)
    let editItemObj = {}
    let editInputData = {}
    // console.log(keys);

    const includedFields = ["status", "locationId", "locationPosition", "location"]

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i]
      if (oldItem[key] != newItem[key] && JSON.stringify(oldItem[key]) != JSON.stringify(newItem[key]) && includedFields.includes(key)) {
        
        if (("locationId" == key || "locationPosition" == key) && editItemObj["location"] == null) {
          const oldLoc = oldItem["location"] != undefined ? oldItem["location"] : null
          const newLoc = newItem["location"] != undefined ? newItem["location"] : null
          editItemObj["location"] = {old: oldLoc, new: newLoc}
        }
        editItemObj[key] = {old: oldItem[key], new: newItem[key]}
      }
    }

    if (editItemObj["locationId"] != null && editItemObj["locationPosition"] == null) {
      editItemObj["locationPosition"] = {old: oldItem["locationPosition"], new: newItem["locationPosition"]}
    }
    if (editItemObj["locationId"] == null && editItemObj["locationPosition"] != null) {
      editItemObj["locationId"] = {old: oldItem["locationId"], new: newItem["locationId"]}
    }

    let keys2 = Object.keys(newInputData)
    if (Object.keys(newInputData).length < Object.keys(oldInputData).length) {
      keys2 = Object.keys(oldInputData)
    }


    for (let i = 0; i < keys2.length; i++) {
      const key = keys2[i]
      if (oldInputData[key] != newInputData[key] && JSON.stringify(oldInputData[key]) != JSON.stringify(newInputData[key])) {
        const oldValue = oldInputData[key] ? oldInputData[key] : null
        const newValue = newInputData[key] ? newInputData[key] : null
        if (!((oldValue == null || oldValue == "") && (newValue == "" || newValue == null)))  // A funky check to exclude the first time files are uploaded
          editInputData[keys2[i]] = {old: oldValue, new: newValue}
      }
    }

    // console.log("Firebase Object: ", editItemObj);

    if (Object.keys(editInputData).length > 0) {
      editItemObj["inputData"] = editInputData
    }
    if (Object.keys(editItemObj).length == 0)
      return null

    return editItemObj
  }
}
