import React, { useState, useEffect } from 'react'
import { listToTree } from '../utils/treeUtils'
import TreeRoot from './tree/treeRoot'
import {COMPONENT_NAMES} from "../constants";

const LocationsList = (props) => {
  return (
    <div>
      {/* props.noHeading !== true &&
        <h3 className="mb-3">Manage Locations</h3>
      */}
      <TreeRoot
        treeName={props.treeName}
        children={listToTree(props.locations)}
        actions={props.actions}
        onSelect={props.onSelect}
        selectedId={props.selectedId}
        additionalClassNames={props.additionalClassNames}
      />
    </div>
  )
}

export default LocationsList
