import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import limsApi from '../utils/limsApi'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink,
  Link,
  withRouter
} from 'react-router-dom'
import { OverlayTrigger, Tooltip, InputGroup, Form, Col, Image } from 'react-bootstrap'
import NavLogo from 'images/nav-logo.png'
import HelpModal from './helpModal'
import Search from './search'
import NewItemGlobal from './newItemGlobal'

const MainNav = (props) => {
  const [showHelpModal, setShowHelpModal] = useState(false)
  const [showNewItemModal, setShowNewItemModal] = useState(false)
  const [firstItemTypeId, setFirstItemTypeId] = useState("")
  const [firstLocationId, setFirstLocationId] = useState("")

  const fetchFirstItemType = async () => {
    await limsApi.get(`item_types/first`, (response)=>{
      if(response.data.id) setFirstItemTypeId(response.data.id)
    }, '')
  }

  const fetchFirstLocation = async () => {
    await limsApi.get(`locations/first`, (response)=>{
      if(response.data.id) setFirstLocationId(response.data.id)
    }, '')
  }

  useEffect(() => {
    fetchFirstItemType()
    fetchFirstLocation()
  }, [])

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark p-0" id="MainNav">
      <div className="navbar-brand ml-4">
        <NavLink
          to={`/catalog/${firstItemTypeId}`}
          style={{marginRight: '1.75rem'}}
        >
          <img src={NavLogo} width="180" />
        </NavLink>
        <a target="_blank" href={"https://support.genofab.com/version-history"} className="version-number">
          <small>v{process.env.VERSION_NUMBER}</small>
        </a>
      </div>
      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
      { showHelpModal &&
        <HelpModal
          onHide={()=>setShowHelpModal(false)}
        />
      }
      { showNewItemModal &&
        <NewItemGlobal onHide={() => { setShowNewItemModal(false) }} />
      }
      <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
        <div className="navbar-nav">
          <NavLink
            to={`/catalog/${firstItemTypeId}`}
            className="nav-item nav-link mr-1 p-3"
            activeClassName="active border-top border-primary"
            isActive={(match, location) => {
              return location.pathname.includes('/catalog/')
            }}
          >
            Catalog
          </NavLink>
          <NavLink
            to={`/locations/${firstLocationId}`}
            className="nav-item nav-link mr-1 p-3"
            activeClassName="active border-top border-primary"
            isActive={(match, location) => {
              return location.pathname.includes('/locations/')
            }}
          >
            Locations
          </NavLink>
          <NavLink
            to="/tasks"
            className="nav-item nav-link mr-1 p-3"
            activeClassName="active border-top border-primary"
          >
            Tasks
          </NavLink>
          <NavLink
            to="/workflows"
            className="nav-item nav-link mr-1 p-3"
            activeClassName="active border-top border-primary"
            exact={true}
          >
            Workflows
          </NavLink>
          <NavLink
            to="/activity"
            className="nav-item nav-link mr-1 p-3"
            activeClassName="active border-top border-primary"
            exact={true}
          >
            Activity
          </NavLink>
        </div>
        <div className="navbar-nav ml-auto">
          <a
            href="#"
            className="btn btn-primary navbar-btn mt-3"
            style={{height: '39px'}}
            onClick={(e) => { e.preventDefault(); setShowNewItemModal(true) }}
          >
            <i className="fas fa-plus" style={{fontSize: 'inherit'}}></i>
          </a>
          <div className="mt-3 mr-2 ml-3">
            <Search />
          </div>
          {props.currentUser.userRole.id == 1 ? (
            <OverlayTrigger
              placement="left"
              overlay={
                <Tooltip>
                  Account Settings
                </Tooltip>
              }
            >
              <NavLink
                to="/manage/item-types"
                className="nav-item nav-link p-3"
                activeClassName="active border-top border-primary"
              >
                <i className="fas fa-cog"></i>
              </NavLink>
            </OverlayTrigger>
          ) : null}
          <OverlayTrigger
            placement="left"
            overlay={
              <Tooltip>
                My Settings
              </Tooltip>
            }
          >
            <NavLink
              to="/profile"
              className="nav-item nav-link p-3"
              activeClassName="active border-top border-primary"
            >

                  {props.user.avatarUrl != "/assets/fallback/default.png" ? (
                    <Image width="25" height="25" src={props.user.avatarUrl} roundedCircle style={{position: "relative", top: "-6px"}} />
                  ) : (
                    <i className="fas fa-user" style={{position: "relative", top: "-6px"}}></i>
                  )}

              <div style={{position: "relative"}}>
                <span className="text-center" style={{position: "absolute", top: "-5px", left: "-18px", width: "60px", fontSize: "10px"}}>Hi, {props.user.firstName}</span>
              </div>
            </NavLink>
          </OverlayTrigger>
          <OverlayTrigger
            placement="left"
            overlay={
              <Tooltip>
                Help
              </Tooltip>
            }
          >
            <a
              className="nav-item nav-link p-3"
              onClick={()=>{}/*(e) => { e.preventDefault(); setShowHelpModal(true) }*/}
              href="https://support.genofab.com/kb-tickets/new"
              target="_blank"
            >
              <i className="fas fa-question-circle"></i>
            </a>
          </OverlayTrigger>
          <OverlayTrigger
            placement="left"
            overlay={
              <Tooltip>
                Log Out
              </Tooltip>
            }
          >
            <a
              id="logout"
              className="nav-item nav-link mr-2 p-3"
              href="#"
              onClick={props.onLogout}
            >
              <i className="fas fa-sign-out-alt"></i>
            </a>
          </OverlayTrigger>
        </div>
      </div>
    </nav>
  )
}

const mapStateToProps = state => {
  return {
    currentUser: state.currentUser
  }
}

export default connect(mapStateToProps)(withRouter(MainNav))
