import React, { useState, useEffect } from 'react'
import { InputGroup, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { currencyTypes } from './fieldUtils'

const CurrencyField = ({ field, inputData, handleChange, status }) => {

  const currencyValue = field.fieldOptionsAttributes[0] ? field.fieldOptionsAttributes[0].value : ""

  let symbol = ""
  let step = "0.01"

  if (currencyValue) {
    const type = currencyTypes.find(ct => ct.value == currencyValue)
    symbol = type.symbol
    step = type.step
  }

  const handleCurrencyChange = (e) => {
    let numberValue = e.target.value

    if (step == "0.01" && parseFloat(numberValue).toFixed(2) != "NaN")
      numberValue = parseFloat(numberValue).toFixed(2)

    if (step == "1" && parseFloat(numberValue).toFixed(0) != "NaN")
      numberValue = parseFloat(numberValue).toFixed(0)


    const fakeEvent = {
      target: {
        value: numberValue != "" ? `${symbol} ${numberValue}` : "",
        name: e.target.name
      }
    }
    handleChange(fakeEvent)
  }

  const countDecimals = (num) => {

    if (Math.floor(num) === num) return 0;

    var str = String(num)
    if (str.indexOf(".") !== -1 && str.indexOf("-") !== -1) {
        return str.split("-")[1] || 0;
    } else if (str.indexOf(".") !== -1) {
        return str.split(".")[1].length || 0;
    }
    return str.split("-")[1] || 0;
  }

  const parseCurrency = (value) => {
    // Added for converting numbers from different field types
    if (parseFloat(value)) {
      return parseFloat(value)
    }
    return parseFloat(String(value).substring(2))
  }

  // Use effect is used to fire the handleChange when the unit is
  // changed but the value of the input field remains the same
  useEffect(()=>{
    const num = inputData[field.id]

    if (num) {
      if (!(String(num).substring(0, symbol.length) == symbol)) {
        const fakeEvent = {
          target: {
            value: `${symbol} ${parseCurrency(num)}`,
            name: field.id
          }
        }

        handleChange(fakeEvent)
      }
    }
  }, [inputData[field.id]])

  let afterLabel = (
    <InputGroup className="mb-3" style={{maxWidth: '18rem'}}>
      <InputGroup.Prepend>
        <InputGroup.Text>{symbol}</InputGroup.Text>
      </InputGroup.Prepend>
      <input
        className="form-control"
        type="number"
        step={step}
        name={field.id}
        onChange={handleCurrencyChange}
        value={parseCurrency(inputData[field.id])}
        />
    </InputGroup>
  )

  if (Object.keys(field.viewOptions).length > 0) {
    if (field.viewOptions[status] == "hidden") {
      afterLabel = (
        <OverlayTrigger
          placement="right"
          overlay={
            <Tooltip>
              This field is hidden
            </Tooltip>
          }
        >
          <i className="fas fa-eye-slash ml-3"></i>
        </OverlayTrigger>
      )
    } else if (field.viewOptions[status] == "viewable") {
      afterLabel = (
        <OverlayTrigger
          placement="bottom-start"
          overlay={
            <Tooltip>
              This field is not editable
            </Tooltip>
          }
        >
          <InputGroup className="mb-3" style={{maxWidth: '18rem'}}>
            <InputGroup.Prepend>
              <InputGroup.Text>{symbol}</InputGroup.Text>
            </InputGroup.Prepend>
            <input
              disabled
              className="form-control"
              type="number"
              step={step}
              name={field.id}
              onChange={handleCurrencyChange}
              value={parseCurrency(inputData[field.id])}
              />
          </InputGroup>
        </OverlayTrigger>
      )
    }
  }

  return (
    <div>
      <label>{field.name}</label>
      {afterLabel}
    </div>
  )
}

export default CurrencyField
