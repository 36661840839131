import React, { useState, useEffect } from 'react'
import { Image, Row, Col, Badge, Container } from 'react-bootstrap'
import moment from 'moment'
import limsApi from '../utils/limsApi'
import {Link, withRouter} from 'react-router-dom'
import ReactHtmlParser from 'react-html-parser'

const EventListItemEditHistory = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [display, setDisplay] = useState()

  const fetchItemType = async (itemTypeId) => {
    await limsApi.get(`item_types/${itemTypeId}`, (response)=>{
      const itemTypeData = response.data.itemType
      refactorEditedFieldsData(props.items[0], itemTypeData)
    },
    'Error fetching item type', undefined, setIsLoading)
  }

  const statuses = [
    { id: 1, name: "Available"},
    { id: 2, name: "Requested"},
    { id: 3, name: "Processing"},
    { id: 4, name: "Archived"},
    { id: 5, name: "Cancelled"},
  ]

  const refactorEditedFieldsData = (item, itemType) => {
    let dataToBeDisplayed = []

    if (props.event.editedFields.status) {
      const status = props.event.editedFields.status
      const oldStatus = statuses.find((s)=>s.id == status.old).name
      const newStatus = statuses.find((s)=>s.id == status.new).name
      dataToBeDisplayed.push(displayData("Status", oldStatus, newStatus))
    }
    if ((props.event.editedFields.locationId && props.event.editedFields.locationPosition) && props.event.editedFields.location) {
      const location = props.event.editedFields.location
      const oldLocation = location.old ? (
        <Link to={`/locations/${props.event.editedFields.locationId.old}`}>
          {location.old.name} {props.event.editedFields.locationPosition.old ? `(Position ${props.event.editedFields.locationPosition.old})` : ""}
        </Link>
      ) : ""

      const newLocation = location.new ? (
        <Link to={`/locations/${props.event.editedFields.locationId.new}`}>
          {location.new.name} {props.event.editedFields.locationPosition.new ? `(Position ${props.event.editedFields.locationPosition.new})` : ""}
        </Link>
      ) : ""
      dataToBeDisplayed.push(displayData("Location", oldLocation, newLocation))
    }
    if (props.event.editedFields.inputData) {
      const inputData = props.event.editedFields.inputData

      Object.keys(inputData).map((key)=> {
        const values = inputData[key]
        const field = itemType.allInstanceFields.find((aIF)=>aIF.id == key)
        const fieldName = field ? field.name : "Removed field"
        dataToBeDisplayed.push(displayData(fieldName, values.old, values.new))
      })
    }
    setDisplay(dataToBeDisplayed)
  }

  useEffect(()=>{
    if (props.items.length >= 1 && props.event.editedFields)
      fetchItemType(props.items[0].itemTypeId)

  }, [props.items, props.event])

  const createEditValueUI = (oldValue, newValue) => (
    <Container fluid>
      <Row className="compare-changes">
        <Col className={`old-value ${oldValue ? '' : 'no-value'}`}>
          {oldValue &&
            <div >
              <span>{typeof oldValue == "string" ? ReactHtmlParser(oldValue) : oldValue}</span>
            </div>
          }
        </Col>
        <Col className={`new-value ${oldValue ? '' : 'no-value'}`}>
          {newValue &&
            <div >
              <span>{typeof newValue == "string" ? ReactHtmlParser(newValue) : newValue}</span>
            </div>
          }
        </Col>
      </Row>
    </Container>
  )

  const displayData = (fieldName, oldValue, newValue) => (
    <div className="mb-1" key={fieldName}>
      <span>{fieldName}:</span>
      {createEditValueUI(oldValue, newValue)}
    </div>
  )

  return (
    <div className="ml-3">
      { !isLoading && display}
    </div>
  )
}

export default EventListItemEditHistory
