import React, { useState, useEffect, useCallback, useRef } from 'react'
import limsApi from '../utils/limsApi'
import { Modal, Form, OverlayTrigger, Tooltip, Table, Container, Row, Col, Tabs, Tab, Popover } from 'react-bootstrap'


const WorkflowResults = ({results, closeModal, ...props}) => {

  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleUndo = () => {

    let item_ids = results.new_items.map(i=>(i.id))
    let entry_ids = results.new_entries.map(e=>(e.id))

    limsApi.post(`workflows/undo/${results.workflow.id}`, { workflow: {new_items: item_ids, new_entries: entry_ids} }, `${results.workflow.name} undone`, (response)=>{
        closeModal()
      },
      'Error undoing workflow', undefined, setIsSubmitting)
  }

  return (
    <Modal backdrop="static" show={true} size="lg" onHide={() => closeModal()} enforceFocus={false}>
      <Modal.Header closeButton>
        <Modal.Title>{results.workflow.name} Results</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Num New Entries:

        <Container fluid={true} style={{ paddingLeft: 0, paddingRight: 0 }}>
          <Row noGutters={true}>
            <Col xs={9}>
              <Table bordered hover>
                <tbody>
                  { results.new_entries.length > 0 ?
                    results.new_entries.map(entry=>(
                      <tr>
                        <td>{entry.name}</td>
                        <td>{entry.id}</td>
                      </tr>
                    ))
                    :
                    <tr>
                      <td>No new entries</td>
                    </tr>
                  }
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>

        <br/>
        Num New items:
        <Container fluid={true} style={{ paddingLeft: 0, paddingRight: 0 }}>
          <Row noGutters={true}>
            <Col xs={9}>
              <Table bordered hover>
                <tbody>
                  {
                    results.new_items.map(item=>(
                      <tr>
                        <td>{item.code}</td>
                        <td>{item.created_at}</td>
                      </tr>
                    ))
                  }
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-secondary" onClick={closeModal}>Close</button>
        <button className="btn btn-danger ml-2" disabled={isSubmitting}  onClick={()=>handleUndo()}>
          { !isSubmitting ? (
            "Undo"
          ) : (
            <i className="fas fa-spinner fa-spin"></i>
          )}
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default WorkflowResults
