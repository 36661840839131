import React, { useState, useEffect } from 'react'
import { Tooltip, OverlayTrigger, Table, Popover } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import limsApi from '../utils/limsApi'
import DeleteConfirmation from './deleteConfirmation'

const PrintersList = (props) => {

  // Performs a delete request to destroy a printer (needs a printer id)
  const deletePrinter = (id) => {
    limsApi.delete(`printers/${id}`, 'Printer Removed', (() => {
        props.printerDeleted()
      }),
      'Delete Failed')
  }

  const getStatusForPrinter = (printer) => {
    if (printer == null)
      return "disconnected"
    else if (printer.computer.state == "disconnected")
      return "computer disconnected"
    else if (printer.state == "online")
      return "online"
    else if (printer.state == "disconnected")
      return "disconnected"
    return "check printer"
  }

  const getIconForStatus = (status) => {
    let color = ""
    if (status == 'online')
      color = 'green'
    else if (status.includes('disconnected'))
      color = 'red'
    return (<i className="fas fa-circle fa-xs mr-1" style={{color: color}}></i>)
  }

  // Creates a list of printers to be rendered to the screen
  let printersData = <tr><td>No printers found. Try adding a printer to your account.</td></tr>;
  if (props.printers.length > 0) {
    printersData = props.printers.map((printer) => {
      const printNode = props.printNodePrinters.find( ({ id }) => id == printer.printNodeId )
      const status = getStatusForPrinter(printNode)
      return (
      <tr key={printer.id}>
        <td>{printer.name}</td>
        <td>
          {getIconForStatus(status)}
          {status}
        </td>
        <td>
          Last printed label:
          {
            props.labelTemplates.find(temp=>temp.id == printer.lastLabelTemplateUsed) ?
              <Link to={"/manage/label_templates"} className="ml-1">
                {props.labelTemplates.find(temp=>temp.id == printer.lastLabelTemplateUsed).name}
              </Link>
              :
              " none"
          }
        </td>
        <td>
          <div className={/*'btn-group'*/''}>
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip>
                  Print Test Label
                </Tooltip>
              }
            >
              <button
                type="button"
                className="btn btn-light btn-sm"
                onClick={() => props.sendTestLabel(printer)}
              >
                <i className="fas fa-print"></i>
              </button>
            </OverlayTrigger>
            <button
              type="button"
              className="btn btn-light btn-sm"
              onClick={() => props.updatePrinter(printer)}
            >
              <i className="fas fa-edit"></i>
            </button>
            <DeleteConfirmation delete={() => deletePrinter(printer.id)}>
              <button
                type="button"
                className="btn btn-light btn-sm"
              >
                <i className="fas fa-trash-alt"></i>
              </button>
            </DeleteConfirmation>
            </div>
        </td>
      </tr>)
    });
  }

  return (
    <Table id="printers-list" bordered hover>
      <tbody>
        {printersData}
      </tbody>
    </Table>
  )
}

export default PrintersList
