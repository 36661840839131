import React, { useState, useEffect } from 'react'
import limsApi from '../utils/limsApi'
import AccountSubNav from './accountSubNav'
import UsersList from './usersList'
import UserForm from './userForm'

const ManageUsers = () => {
  const [showNewUserForm, setShowNewUserForm] = useState(false)
  const [showEditUserForm, setShowEditUserForm] = useState(null)
  const [reload, setReload] = useState(false)
  const [users, setUsers] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  // changes the value of the reload to rerender the list component
  const toggleReload = () => {
    setReload(prevReloadState => (!prevReloadState))
  }

  // Performs a get request to list all the users
  const fetchUsers = () => {
    limsApi.get('users/all', (response)=>{
        let userData = response.data.users
        setUsers(userData)
      },
      'Error fetching users', undefined, setIsLoading)
  }

  const deactivatedUser = (id) => {
    console.log("Check tasks of deactivatedUser, id: ", id)
  }

  useEffect(() => {
    fetchUsers()
  }, [reload])

  return (
    <div>
      <div className="col-fixed bg-light">
        <AccountSubNav />
      </div>
      <div className="col-fluid p-4 bg-white">
        <button className="btn btn-primary float-right" onClick={() => setShowNewUserForm(true)}>
          Invite Member
        </button>
        <h3 className="mb-3">Manage Users</h3>
        <UsersList
          users={users}
          reload={toggleReload}
          isLoading={isLoading}
          updateUser={(user) => setShowEditUserForm(user)} />
        {showNewUserForm ?
          (<UserForm
            closeModal={() => setShowNewUserForm(false)}
            reload={toggleReload}
            create />)
          : null}
        {showEditUserForm ?
          (<UserForm
            closeModal={() => setShowEditUserForm(null)}
            reload={toggleReload}
            user={showEditUserForm}
            users={users}
            deactivatedUser={(id)=>deactivatedUser(id)} />)
          : null}
      </div>
    </div>
  )
}

export default ManageUsers
