import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import limsApi from '../utils/limsApi'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  withRouter
} from 'react-router-dom'
import ContentLoader from "react-content-loader"
import LocationsList from './locationsList'
import Location from './location'
import {COMPONENT_NAMES} from "../constants";

const LocationsBrowser = ({ match, history }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [locations, setLocations] = useState([])

  const fetchLocations = () => {
    limsApi.get('locations', (response)=>{
      let locationData = response.data.locations
      setLocations(locationData)
    },
    'Error fetching locations', undefined, setIsLoading)
  }

  useEffect(() => {
    fetchLocations()
  }, [])

  const handleSelect = (location) => {
    history.push(`/locations/${location.id}`)
  }

  const urlId = window.location.href.substring(window.location.href.lastIndexOf('/') + 1)

  return (
    <div>
      {!isLoading ?
        <>
          {locations.length > 0 ? (
            <>
              <div className="col-fixed bg-light">
                <Route path={`${match.path}`}>
                  <div className="p-3">
                    <LocationsList
                      treeName={COMPONENT_NAMES.LOCATION_TREE}
                      locations={locations}
                      noHeading={true}
                      onSelect={handleSelect}
                      selectedId={urlId}
                      additionalClassNames="limited-width"
                    />
                  </div>
                </Route>
              </div>
              <div className="col-fluid p-4 bg-white">
                <Route path={`${match.path}/:id`} render={(props) => (
                  <Location {...props} refreshBrowser={()=>fetchLocations()} />
                )} />
              </div>
            </>
          ) : (
            <div className="mx-auto mt-5 py-3 px-4 text-center" style={{maxWidth: "39rem"}}>
              <h1 className="display-4 mb-4 text-success"><i className="fas fa-box"></i></h1>
              <h4 className="mb-3">It's time to describe the storage<br/>locations in your lab.</h4>
              <p className="mb-4">Having all of your lab's physical locations described in GenoFAB helps keep your team organized and in sync.</p>
              <Link to="/manage/locations" className="btn btn-primary">Describe My Locations</Link>
            </div>
          )}
        </>
      :
      <ContentLoader height="200" backgroundColor={'#eee'} foregroundColor={'#ccc'} className="p-3">
        <rect x="3" y="7" rx="3" ry="3" width="22" height="19" />
        <rect x="35" y="7" rx="3" ry="3" width="125" height="19" />
        <rect x="3" y="48" rx="3" ry="3" width="22" height="19" />
        <rect x="35" y="48" rx="3" ry="3" width="125" height="19" />
        <rect x="3" y="89" rx="3" ry="3" width="22" height="19" />
        <rect x="35" y="89" rx="3" ry="3" width="125" height="19" />
        <rect x="3" y="130" rx="3" ry="3" width="22" height="19" />
        <rect x="35" y="130" rx="3" ry="3" width="125" height="19" />
      </ContentLoader>
      }
    </div>
  )
}

export default LocationsBrowser
