import React, {useEffect, useRef, useState} from 'react'
import { Modal, Form } from 'react-bootstrap'
import ItemForm from './itemForm'

const EditItem = (props) => {
  const [modalTitle, setModalTitle] = useState("")

  const viewingFrom = () => {
    if(props.match.path.includes("catalog")) {
      return "catalog"
    } else {
      return "locations"
    }
  }

  const closeModalRedirectsTo = (viewingFrom() == "catalog") ? `/catalog/entries/${props.entry.id}` : `/locations/${props.match.params.id}`

  return (
    <Modal backdrop="static" show={true} size="lg" onHide={() => { props.history.push(closeModalRedirectsTo) }} enforceFocus={false}>
      <Modal.Header closeButton>
        <Modal.Title>{modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ItemForm
          {...props}
          create={false}
          edit={true}
          setModalTitle={(title)=>setModalTitle(title)}
          closeModalRedirectsTo={closeModalRedirectsTo} />
      </Modal.Body>
    </Modal>
  )
}

export default EditItem
