import React, { useState, useEffect } from 'react'

const DropdownField = ({ field, inputData, handleChange, status }) => {

  let afterLabel = (
    <select
      name={field.id}
      className="form-control"
      value={inputData[field.id] || ""}
      onChange={handleChange}
    >
      <option value=""></option>
      {field.fieldOptionsAttributes.map(option => (
        <option
          key={option.id}
          value={option.id}
        >
          {option.label}
        </option>
      ))}
    </select>
  )

  if (Object.keys(field.viewOptions).length > 0) {
    if (field.viewOptions[status] == "hidden") {
      afterLabel = (
        <OverlayTrigger
          placement="right"
          overlay={
            <Tooltip>
              This field is hidden
            </Tooltip>
          }
        >
          <i className="fas fa-eye-slash ml-3"></i>
        </OverlayTrigger>
      )
    } else if (field.viewOptions[status] == "viewable") {
      afterLabel = (
        <select
          disabled
          name={field.id}
          className="form-control"
          value={inputData[field.id] || ""}
          onChange={handleChange}
        >
          <option value=""></option>
          {field.fieldOptionsAttributes.map(option => (
            <option
              key={option.id}
              value={option.id}
            >
              {option.label}
            </option>
          ))}
        </select>
      )
    }
  }

  return (
    <div>
      <label>{field.name}</label>
      {afterLabel}
    </div>
  )
}

export default DropdownField
