import React, { useState, useEffect } from 'react'
import { Button, Form, Col, Row, Modal, Tooltip, OverlayTrigger, Card } from 'react-bootstrap'
import { Formik } from 'formik'
import * as Yup from 'yup'
import limsApi from '../utils/limsApi'
import events from '../utils/events'
import {
  CardElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import axios from 'axios'
import { Link } from 'react-router-dom'
import ContentLoader from "react-content-loader"

// This component acts as the form for creating a new printer and upating existing ones
const PurchaseTasksForm = (props) => {
  const [taskOption, setTaskOption] = useState("")
  const [saveToken, setSaveToken] = useState(false)
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null)
  const [taskError, setTaskError] = useState(null)
  const [processing, setProcessing] = useState('');
  const [disabled, setDisabled] = useState(true);

  const [prices, setPrices] = useState([])
  const [products, setProducts] = useState([])

  const stripe = useStripe()
  const elements = useElements()

  const getProducts = () => {
    limsApi.get('payment_intent/get_products', (response)=>{

      const productsFetched = []
      response.data.map(product => {
        if (product.active)
          productsFetched.push(product)
      })

      setProducts(sortProducts(productsFetched))
    })
  }

  const sortProducts = (products) => {
    // Hard Coded for MVP Change for future versions
    const t100 = products.find(p => p.name == "100 Task Credits") // 100 Tasks id
    const t500 = products.find(p => p.name == "500 Task Credits") // 500 Tasks id
    const t1000 = products.find(p => p.name == "1,000 Task Credits") // 1,000 Tasks id

    return [t100, t500, t1000]
  }

  const getPrices = () => {
    limsApi.get('payment_intent/get_prices', (response)=>{

      const pricesFetched = []
      response.data.map(price => {
        pricesFetched.push(price)
      })
      setPrices(pricesFetched)
    })
  }

  const handleOptionChange = (value) => {
    setTaskError(null)
    setTaskOption(value)
  }


  // const createIntent = async (productId) => {
  //   const price = prices.find(p => p.product == productId)
  //   await limsApi.post('payment_intent/create', {price_id: price.id, product_id: productId}, "", async(response)=>{
  //     return response.data.client_secret
  //   })
  // }
  //
  // const postToStripe = () => {
  //
  // }

  useEffect(()=>{
    getProducts()
    getPrices()
  }, [])

  const cardStyle = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: 'Arial, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#32325d"
        }
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a"
      }
    }
  };

  const handleChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };

  const handleStripeSubmit = async ev => {
    setProcessing(true)
    if (taskOption == "") {
      setTaskError("Please select a number of tasks")
      setProcessing(false)
      return
    }
    const price = prices.find(p => p.product == taskOption)
    await limsApi.post('payment_intent/create', {priceId: price.id, productId: taskOption}, "", async(response)=>{

      const clientSecret = response.data.client_secret

      setProcessing(true)
      const payload = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement)
        }
      });
      console.log(payload);
      if (payload.error) {
        setError(payload.error.message)
        setProcessing(false)
      } else {
        setError(null)
        setProcessing(false)
        setSucceeded(true)

        const newTasks = getNewPurchasedTaskAmount(taskOption)
        const newAvailableTaskAmount = props.account.availableTasks + newTasks

        const updatedAccountInformation = {
          available_tasks: newAvailableTaskAmount,
          tasks_previously_purchased: newAvailableTaskAmount,
        }

        saveToken ? updatedAccountInformation["payment_method_id"] = payload.paymentIntent.payment_method : null

        limsApi.put('accounts/me', { account: updatedAccountInformation }, '', (response)=>{
          props.setAccount(response.data.account)
        })

        window.flash(`${newTasks} tasks purchased`)
        props.closeModal()
      }
    })
  }

  const getNewPurchasedTaskAmount= (productId) => {
    const product = products.find(p => p.id == productId)
    if (product.name == "100 Task Credits")
      return 100
    else if (product.name == "500 Task Credits")
      return 500
    else if (product.name == "1,000 Task Credits")
      return 1000
    else
      return 0
  }

  // FOR TESTING ONLY
  const setAccountTasks = (tasks) =>{
    const updatedAccountInformation = {
      available_tasks: tasks,
      tasks_previously_purchased: 100,
    }

    limsApi.put('accounts/me', { account: updatedAccountInformation }, '', (response)=>{
      props.setAccount(response.data.account)
    })
  }

  let isLoading = false
  let taskPackagePicker =  null
  if (products.length > 0 && prices.length > 0) {
    taskPackagePicker = products.map(product => {
     if (product.active) {
       const price = prices.find(p => p.product === product.id)
       const priceStr = `$${price.unit_amount/100} ${price.currency.toUpperCase()}`
       return (
         <Col key={product.id}>
           <Card onClick={() => handleOptionChange(product.id)} border={taskOption === product.id ? 'primary':''} className={taskOption === product.id ? "shadow":""} style={{cursor: "pointer"}}>
             <Card.Body className="text-center">
               <div style={{position:'absolute', top: 5, right: 10}}>
                 <input type="radio" value={product.id} checked={taskOption === product.id} onChange={()=>handleOptionChange(product.id)} />
               </div>
               <Card.Title className="text-primary">{product.name.split(' ')[0]}</Card.Title>
               <Card.Subtitle className="text-muted pb-2">Credits</Card.Subtitle>
               <Card.Text className="pt-2" style={{'borderTop': '2px solid rgba(0, 0, 0, 0.125)'}}>
                 {priceStr}
               </Card.Text>
             </Card.Body>
           </Card>
         </Col>
       )
     }
   })
 } else {
   isLoading = true
 }

 const loadingTaskOption = (
   <Col>
    <Card>
      <Card.Body className="text-center">
        <Card.Title className="text-primary">
            <ContentLoader height="20" width="50%" backgroundColor={'#eee'} foregroundColor={'#ccc'}>
              <rect x="0" y="0" rx="3" ry="3" width="100%" height="20" />
            </ContentLoader>
        </Card.Title>
        <Card.Subtitle className="text-muted pb-2">
          <ContentLoader height="20" width="60%" backgroundColor={'#eee'} foregroundColor={'#ccc'}>
            <rect x="0" y="0" rx="3" ry="3" width="100%" height="20" />
          </ContentLoader>
        </Card.Subtitle>
        <Card.Text className="pt-2" style={{'borderTop': '2px solid rgba(0, 0, 0, 0.125)'}}>
          <ContentLoader height="20" width="80%" backgroundColor={'#eee'} foregroundColor={'#ccc'}>
            <rect x="0" y="0" rx="3" ry="3" width="100%" height="20" />
          </ContentLoader>
        </Card.Text>
      </Card.Body>
    </Card>
   </Col>
 )


  // FOR TESTING ONLY
  let testingBtns = []
  // testingBtns = [<button onClick={()=>setAccountTasks(25)}>Set Tasks TO 25</button>,
  //                 <button onClick={()=>setAccountTasks(50)}>Set Tasks TO 50</button>]

  return (
    <Form noValidate>
    <Modal show={true}>
      <Modal.Header>
        <h5 className="modal-title">Purchase Credits</h5>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={props.closeModal}>
          <span aria-hidden="true">&times;</span>
        </button>
      </Modal.Header>
      <Modal.Body>
        <div className='mb-4'>
          <Form.Label>
            Number of Credits
          </Form.Label>
          <Row className=" " style={taskError ? {borderBottom: '3px dotted #d9534f', borderRadius: "0px"} : {}}>
            {!isLoading && true ? taskPackagePicker : (
              <>
                {loadingTaskOption}
                {loadingTaskOption}
                {loadingTaskOption}
              </>
            )}
          </Row>
          { taskError ? <span className="text-danger" style={{top: "-100px"}}>Please select a number of credits.</span> : null }
        </div>
        <Form.Group>
          <span className='float-right'>
            <Link onClick={props.setShowInvoiceModal} to="#">Can't pay by card?</Link>
          </span>
          <Form.Label>
            Billing Information
          </Form.Label>
          <div className='mt-2 mb-2'>
            <div style={ error ? {border: '2px dotted #d9534f', borderRadius: "5px"} : {}}>
              <CardElement id="card-element" options={cardStyle} onChange={handleChange} />
            </div>
            <span className="text-danger">{error}</span>
          </div>
        </Form.Group>
        <Form.Group controlId="formBasicCheckbox">
          <Form.Check type="checkbox" label="Save this payment method" onChange={() => setSaveToken(prev => !prev)} checked={saveToken} />
        </Form.Group>
           <Button variant="primary" disabled={false} type="button" block onClick={handleStripeSubmit}>
             { !processing ? (
               "Purchase"
             ) : (
               <i className="fas fa-spinner fa-spin"></i>
             )}
           </Button>
           {testingBtns}
      </Modal.Body>
    </Modal>
    </Form>
  )
}

export default PurchaseTasksForm
