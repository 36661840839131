import React, { useState, useEffect } from 'react'
import limsApi from '../utils/limsApi'
import { Card, Container, Tabs, Tab, ToggleButton, ToggleButtonGroup, Form, Row, Col, OverlayTrigger, Tooltip as BSTooltip, Spinner } from 'react-bootstrap'
import moment from 'moment'
import { LineChart, BarChart, Bar, Area, Line, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer, Legend } from 'recharts'


const ActivityReport = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [snapshots, setSnapshots] = useState([])
  const [users, setUsers] = useState([])
  const [isParsing, setIsParsing] = useState(true)
  const [foundUnknownUser, setFoundUnknownUser] = useState(false)

  const [range, setRange] = useState("week")
  const [resolution, setResolution] = useState("day")

  const [allTasksData, setAllTasksData] = useState([])
  const [userCompletedTaskData, setUserCompletedTaskData] = useState([])
  const [userCreatedTaskData, setUserCreatedTaskData] = useState([])

  const metricNameKeys = ['Created', 'Completed']

  const colors = ["#007bff", "#49DC84", "#00DBF1", "#00B2FF"]
  let i = 0

  const fetchAccountSnapshots = async() => {
    limsApi.get('account_snapshots', (response)=>{
      const snapshotData = response.data.accountSnapshots
      // console.log("Snapshots: ", snapshotData)
      setSnapshots(snapshotData)
      fetchUsers()
    })
  }

  // Performs a get request which returns the all users in the account
  const fetchUsers = () => {
    limsApi.get('users', (response)=>{
      const users = response.data.users
      setUsers(users)
      // console.log("Users: ", users);
    },
    'Error fetching users', undefined, setIsLoading)
  }

  const parseAllTasksData = () => {
    const data = []
    let startDate = {}

    for (var i = 0; i < getNumEntries(); i++) {

      const releventSnaps = []
      for (let k = 0; k < snapshots.length; k++) {
        const snapshot = snapshots[k]
        const snapDate = moment(snapshot.day).toDate()
        snapDate.setHours(0,0,0,0)

        if (resolution == 'day') {
          startDate = new Date(moment().subtract(i, `${resolution}s`).format('LL'))
          startDate.setHours(0,0,0,0)
          // There should only be one snapshot so once it is found break out of the loop
          if (snapDate.getTime() == startDate.getTime()) {
            releventSnaps.push(snapshot)
            break
          }

        } else {
          startDate = new Date(moment().subtract(i, `${resolution}s`).startOf(resolution).format('LL'))
          // startDate.setHours(0,0,0,0)
          // Create and use end date
          const endDate = new Date(moment().subtract(i-1, `${resolution}s`).startOf(resolution).format('LL'))
          // endDate.setHours(0,0,0,0)

          if (snapDate.getTime() >= startDate.getTime() && snapDate.getTime() < endDate.getTime()) {
            releventSnaps.push(snapshot)
          }
        }
      }

      const createdSnapData = sumTasks(releventSnaps || {}, "tasksCreated")
      const completedSnapData = sumTasks(releventSnaps || {}, "tasksCompleted")

      data.unshift({date: startDate.getTime(), "Completed": completedSnapData, "Created": createdSnapData })
    }

    setAllTasksData(data)
  }

  const sumTasks = (snaps, metricName) => {
    let sum = 0
    const data = {}
    for (let i = 0; i < snaps.length; i++) {
      const snap = snaps[i][metricName]
      const keys = Object.keys(snap);
      for (let j = 0; j < keys.length; j++) {
        const key = keys[j]
        data[key] ? data[key] += snap[key] : data[key] = snap[key]
      }
    }
    for (const key in data) {
      sum += data[key]
    }
    return sum
  }

  const parseUserTaskData = (metricName) => {
    const data = []

    for (var i = 0; i < getNumEntries(); i++) {
      let startDate = {}

      const releventSnaps = []
      for (let k = 0; k < snapshots.length; k++) {
        const snapshot = snapshots[k]
        const snapDate = moment(snapshot.day).toDate()
        snapDate.setHours(0,0,0,0)
        if (resolution == 'day') {
          startDate = new Date(moment().subtract(i, `${resolution}s`).format('LL'))
          startDate.setHours(0,0,0,0)
          // There should only be one snapshot so once it is found break out of the loop
          if (snapDate.getTime() == startDate.getTime()) {
            releventSnaps.push(snapshot)
            break
          }
        } else {
          startDate = new Date(moment().subtract(i, `${resolution}s`).startOf(resolution).format('LL'))
          // startDate.setHours(0,0,0,0)
          // Create and use end date
          const endDate = new Date(moment().subtract(i-1, `${resolution}s`).startOf(resolution).format('LL'))
          // endDate.setHours(0,0,0,0)
          // console.log("End date: ",endDate);

          // date.getTime() <= snapDate.getTime() && snapDate.getTime() <= endDate.getTime() ? releventSnaps.push(snapshot) : null
          if (snapDate.getTime() >= startDate.getTime() && snapDate.getTime() < endDate.getTime()) {
            // console.log("Pushed! ", snapshot);
            releventSnaps.push(snapshot)
          }
        }
      }

      let newEntry = {
        date: startDate.getTime()
      }
      const usersSnapData = getSnapDataFromManySnaps(releventSnaps, metricName)
      const keys = Object.keys(usersSnapData);
      for(let j = 0; j < keys.length; j++) {
        const key = keys[j]
        const keyData = usersSnapData ? usersSnapData[key] : 0
        keyData > 0 ? newEntry[getUsersName(key)] = keyData != null ? keyData : 0 : null
      }

      data.unshift(newEntry)
    }
    return data
  }

  const getSnapDataFromManySnaps = (snaps, metricName) => {
    let data = {}

    for(let i = 0; i < snaps.length; i++) {
      const snapMetric = snaps[i][metricName]
      const userKeys = Object.keys(snapMetric);

      for (let j = 0; j < userKeys.length; j++) {
        const key = userKeys[j]
        data[key] ? data[key] += snapMetric[key] : data[key] = snapMetric[key]
      }
    }
    return data
  }

  const getNumEntries = () => {
    if (range == "week")
      return 7
    else if (range == "month")
      return (resolution == "week" ? 4 : 30)
    else if (range == "year")
      return (resolution == "month" ? 12 : (resolution == "week" ? 52 : 365))
    else {
      const days = getAllTimeDays()
      const firstDate = new Date(moment().subtract(days, `days`).format('LL'))
      const today = new Date()
      switch (resolution) {
        case "day":
          return days
        case "week":
          return days/7
        case "month":
          return days/30
        case "year":
          return (today.getFullYear() - firstDate.getFullYear()) + 1
        default:
          return 0
      }
    }
  }

  const getAllTimeDays = () => {
    const currDate = new Date()
    let oldestSnap = snapshots[0]
    let snapDate = moment(oldestSnap.day).toDate()
    snapshots.map((snapshot) => {
      if (snapDate.getTime() > moment(snapshot.day).toDate().getTime()) {
        snapDate = moment(snapshot.day).toDate()
      }
    })

    return daysBetween(currDate.getTime(), snapDate.getTime())
  }

  const daysBetween = (startDate, endDate) => {
    var millisecondsPerDay = 24 * 60 * 60 * 1000;
    return (startDate - endDate) / millisecondsPerDay;
  }

  const getUsersName = (id) => {
    try {
      return genUserNameKey(users.find(u => u.id == id))
    }
    catch {
      // console.log("Found Deleted User");
      setFoundUnknownUser(true)
      return "Deleted User(s)"
    }
  }

  const genUserNameKey = (user) => {
    return `${user.firstName} ${user.lastName.charAt(0)}.`
  }

  let dateFormat = "M/D"
  if (resolution == "month")
    dateFormat = "MMM. YY"
  else if (resolution == 'year')
    dateFormat = "YYYY"

  const chart = (data) => (
    <LineChart data={data} margin={{ top: 15, right: 20, bottom: 15, left: 5 }}>
      <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
      <XAxis dataKey="date" tickFormatter={(tick) => (moment(tick).format(dateFormat))} />
      <YAxis label={{ value: 'Tasks', angle: -90, position: 'insideLeft' }} allowDecimals={false} />
      <Tooltip labelFormatter={(tick) => (moment(tick).format(dateFormat))} />
      <Area type="monotone" dataKey="tasks" stroke="#007bff" strokeWidth="3" fillOpacity={0.3} fill="#007bff" />
    </LineChart>
  )

  const noDataChart = (data, arr) => (
    <LineChart data={data} margin={{ top: 15, right: 20, bottom: 15, left: 5 }}>
      <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
      <XAxis dataKey="date" tickFormatter={(tick) => (moment(tick).format(dateFormat))} />
      <YAxis label={{ value: 'Tasks', angle: -90, position: 'insideLeft' }} allowDecimals={false} />
      <Tooltip labelFormatter={(tick) => (moment(tick).format(dateFormat))} />
      <Legend />
      { i = 0 }
      {arr.map(key => {
        const color = colors[i%colors.length]
        i++
        return (
          <Line type='monotone' dataKey={key} name={key} key={key} stroke={color} strokeWidth="3" fill={color} fillOpacity={0.4} stackId={"a"} />
        )
      })}
    </LineChart>
  )

  const userBarChart = (data) => (
    <BarChart data={data} margin={{ top: 15, right: 20, bottom: 15, left: 5 }}>
      <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
      <XAxis dataKey="date" tickFormatter={(tick) => (moment(tick).format(dateFormat))} />
      <YAxis label={{ value: 'Tasks', angle: -90, position: 'insideLeft' }} allowDecimals={false} />
      <Tooltip labelFormatter={(tick) => (moment(tick).format(dateFormat))} />
      <Legend />
      { i = 0 }
      { foundUnknownUser ? <Bar dataKey="Deleted User(s)" name="Deleted User(s)" key="Deleted User(s)" fill={"#A8AABC"} stackId={"a"} /> : null}
      {users.map(user => {
        const u = genUserNameKey(user)
        const color = colors[i%colors.length]
        i++
        return (
          <Bar dataKey={u} name={u} key={u} fill={color} stackId={"a"} />
        )
      })}
    </BarChart>
  )

  const arrLineChart = (data, arr) => (
    <LineChart data={data} margin={{ top: 15, right: 20, bottom: 15, left: 5 }}>
      <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
      <XAxis dataKey="date" tickFormatter={(tick) => (moment(tick).format(dateFormat))} />
      <YAxis label={{ value: 'Tasks', angle: -90, position: 'insideLeft' }} allowDecimals={false} />
      <Tooltip labelFormatter={(tick) => (moment(tick).format(dateFormat))} />
      <Legend />
      { i = 0 }
      {arr.map(key => {
        const color = colors[i%colors.length]
        i++
        return (
          <Line type='monotone' dataKey={key} name={key} key={key} stroke={color} strokeWidth="3" fill={color} fillOpacity={0.4} stackId={"a"} />
        )
      })}
    </LineChart>
  )

  const shouldAllowDisplayBy = (display) => {
    if (range == "week" && (display == "day"))
      return true
    else if (range == "month" && (display == "day" || display == "week"))
      return true
    else if (range == "year" && (display != "year"))
      return true
    else if (range == "all")
      return true
    else
      return false
  }

  const loadingTab = (graph) => (
    !isParsing ? (
      graph
    ) : (
      <div style={{position: "absolute", top: "50%", left: "50%"}}><Spinner animation="border" variant="primary" /></div>
    )
  )

  useEffect(()=> {
    fetchAccountSnapshots()
  }, [])

  useEffect(() => {
    if (!isLoading) {
      setFoundUnknownUser(false)
      if (snapshots.length > 0) {
        parseAllTasksData()

        const completedData = parseUserTaskData("tasksCompleted")
        setUserCompletedTaskData(completedData)

        const createdData = parseUserTaskData("tasksCreated")
        setUserCreatedTaskData(createdData)
      }

      setIsParsing(false)
    }
  }, [range, resolution, isLoading])

  return (
    <div>
      <Card>
        <Card.Body>
          <Card.Title style={{marginBottom: "1.25rem"}}>
            Activity Report
          </Card.Title>

            <Tabs variant="pills" defaultActiveKey="allRecords" className="mb-3 no-pt ml-0">
              <Tab eventKey="allRecords" title="All Tasks">
                <ResponsiveContainer width="100%" height={500}>
                  {loadingTab(arrLineChart(allTasksData, metricNameKeys))}
                </ResponsiveContainer>
              </Tab>
              <Tab eventKey="userBarChart" title={
                <span>
                  <span>Completed Tasks By User</span>
                </span>
              }>
                <ResponsiveContainer width="100%" height={500}>
                  {loadingTab(userBarChart(userCompletedTaskData))}
                </ResponsiveContainer>
              </Tab>
              <Tab eventKey="userBarChart2" title={
                <span>
                  <span>Created Tasks By User</span>
                </span>
              }>
                <ResponsiveContainer width="100%" height={500}>
                  {loadingTab(userBarChart(userCreatedTaskData))}
                </ResponsiveContainer>
              </Tab>
              {/*
              <Tab eventKey="recordsByTypeBar" title="Tasks by Type (Line Chart)">
                <Container className="d-flex justify-content-center">
                  <ResponsiveContainer width="95%" height={500}>
                    {userLineChart(typeTaskData, taskTypes)}
                  </ResponsiveContainer>
                </Container>
              </Tab>
              */}
              {/*
              <Tab eventKey="userLineChart" title="Tasks by User (Line Chart)">
                <Container className="d-flex justify-content-center">
                  <ResponsiveContainer width="95%" height={500}>
                    {userLineChart(userTaskData, users)}
                  </ResponsiveContainer>
                </Container>
              </Tab>
              */}
            </Tabs>
          <Form.Group as={Row} className="d-flex justify-content-center">
            <Form.Label column sm="1.5" className="mr-2">From past: </Form.Label>
            <Col sm="1.5" className="mr-5">
              <Form.Control as="select"
                onChange={() => {
                  setRange(event.target.value)
                  if (event.target.value == "week")
                    setResolution("day")
                  else if (event.target.value == "month")
                    setResolution("week")
                  else if (event.target.value == "year")
                    setResolution("month")
                  else {
                    const totalDays = getAllTimeDays()
                    if (totalDays > 365)
                      setResolution("year")
                    else if (totalDays > 30)
                      setResolution("month")
                    else if (totalDays > 7)
                      setResolution("week")
                    else
                      setResolution("day")
                  }
                }}>
                <option value={"week"}>Week</option>
                <option value={"month"}>Month</option>
                <option value={"year"}>Year</option>
                <option value={"all"}>All</option>
              </Form.Control>
            </Col>
            <Form.Label column sm="1.5" className="mr-2">Display by: </Form.Label>
            <Col sm="1.5">
              <Form.Control as="select" value={resolution} onChange={() => setResolution(event.target.value)}>
                {shouldAllowDisplayBy("day") ? (<option value={"day"}>Day</option>) : null}
                {shouldAllowDisplayBy("week") ? (<option value={"week"}>Week</option>) : null}
                {shouldAllowDisplayBy("month") ? (<option value={"month"}>Month</option>) : null}
                {shouldAllowDisplayBy("year") ? (<option value={"year"}>Year</option>) : null}
              </Form.Control>
            </Col>
          </Form.Group>
        </Card.Body>
      </Card>
    </div>
  )
}

export default ActivityReport
