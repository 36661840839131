import React, {useEffect, useRef, useState} from 'react'
import { connect } from 'react-redux'
import limsApi from '../utils/limsApi'
import {Link, withRouter} from 'react-router-dom'
import { Modal, Form, Row, Col, Container, Dropdown } from 'react-bootstrap'
import SingleLineTextField from './fields/singleLineTextField'
import MultiLineTextField from './fields/multiLineTextField'
import ChecklistField from './fields/checklistField'
import DropdownField from './fields/dropdownField'
import NumberField from './fields/numberField'
import DateField from './fields/dateField'
import TeamMemberField from './fields/teamMemberField'
import RecordField from './fields/recordField'
import SelectLocation from "./selectLocation"
import FileAttachmentField from './fields/fileAttachmentField'
import UrlField from './fields/urlField'
import DurationField from './fields/durationField'
import CurrencyField from './fields/currencyField'
import events from '../utils/events'
import RichTextEditor from './richTextEditor'
import { Typeahead } from 'react-bootstrap-typeahead'

const NewItemGlobal = (props) => {
  const pluralize = require('pluralize')
  const [isLoading, setIsLoading] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [fileUploading, setFileUploading] = useState(false)
  const [item, setItem] = useState({ status: 2 }) // Default status is "Requested"
  const [inputData, setInputData] = useState({})
  const ref = useRef(null)
  const instanceName = entry?.itemType?.instanceName ? pluralize(entry.itemType.instanceName, 1) : "Item"
  const [entry, setEntry] = useState(null)
  const [itemType, setItemType] = useState(null)
  const [entries, setEntries] = useState([])
  const [location, setLocation] = useState(null)
  const [task, setTask] = useState({name: `${props.entry?.name}`})
  const [users, setUsers] = useState([])
  const [enableTask, setEnableTask] = useState(true)

  const handleSubmit = (event) => {
    event.preventDefault()
    setIsSubmitting(true)

    limsApi.post('items', { item: item, inputData: inputData }, "", (response)=>{
        const itemData = response.data.item
        const statusId = item.status
        let taskStatus = statusId == 1 || statusId == 4 ? "Done" : statusId == 2 ? "Backlog" : statusId == 3 ? "In Progress" : "Cancelled"
        limsApi.post('tasks', { name: `${entry.name}: ${itemData.code}`, status: taskStatus, itemId: itemData.id}, `New ${instanceName} Created`, (response)=>{
          // props.refreshItems()
          events.createLinkedEvent( `added a new ${entry.name} ${instanceName.toLowerCase()}: #insertlink#.`, itemData)
          props.onHide()
          props.history.push(`/catalog/entries/${item.entryId}/items/${itemData.id}`)
        })
      },
      'Error creating item', undefined, setIsSubmitting)
  }

  useEffect(() => {
    setItem({...item, itemTypeId: entry?.itemTypeId, entryId: entry?.id})
  }, [entry])

  useEffect(()=>{
    limsApi.get("users", (response)=>{
      setUsers(response.data.users)
    }, "Error fetching users")
  }, [])

  const fetchEntries = () => {
    limsApi.get(`entries?recent=true`, (response)=>{
      let entriesData = response.data.entries
      setEntries(entriesData)
    },
    'Error fetching entries')
  }

  useEffect(() => {
    fetchEntries()
  }, [])

  const fetchEntry = async () => {
    // const entryId = props.entryId != undefined ? props.entryId : props.match.params.entryId

    await limsApi.get(`entries/${item.entryId}`, (response)=>{
      const entryData = response.data.entry
      setEntry(entryData)
      fetchItemType(entryData.itemTypeId)
    },
    'Error fetching entry', undefined)
  }

  const fetchItemType = async (itemTypeId) => {
    await limsApi.get(`item_types/${itemTypeId}`, (response)=>{
      const itemTypeData = response.data.itemType
      setItemType(itemTypeData)
    },
    'Error fetching item type', undefined, setIsLoading)
  }

  useEffect(() => {
    if(item.entryId) fetchEntry()
  }, [item.entryId])

  const handleChange = (event) => {
    setItem({...item, [event.target.name]: event.target.value})
  }

  const handleTaskChange = (event) => {
    setTask({...task, [event.target.name]: event.target.value})
  }

  const handleInputDataChange = (event) => {
    setInputData({...inputData, [event.target.name]: event.target.value})
  }

  const handleFileDataChange = (key, value) => {
    setInputData({...inputData, [key]: value})
  }

  const handleChecklistChange = (field, checkedItems) => {
    setInputData({...inputData, [field.id]: checkedItems})
  }

  const fetchLocation = async (locationId, resetPosition) => {
    await limsApi.get(`locations/${locationId}`, (response)=>{
      const locationData = response.data.location
      setLocation(locationData)
      let lPosition = resetPosition == true ? null : item.locationPosition

      // Ensures that a location always has a position selected
      if (resetPosition) {
        for (let pos = 1; pos <= locationData.numPositions; pos++) {
          let position = pos

          // Makes the auto selected position compatible with vertical locations
          if (locationData.orientation == "vertical") {
            let currentRow = Math.ceil((pos)/locationData.cols)
            let currentCol = ((pos-1) % locationData.cols) + 1
            let cellVertPos = ((locationData.rows * (currentCol - 1)) + currentRow)
            position = cellVertPos
          }

          if (!locationData.children.find(child=>child.locationPosition==position) && !locationData.items.find(i=>(i.locationPosition==position && i.id != item.id))) {
            lPosition = position
            break
          }
        }
      }

      setItem({...item, locationId: locationData.id, locationPosition: lPosition})
    }, 'Error fetching item type')
  }

  const selectLocation = (location) => {
    if(!location) {
      setLocation(location) // deselect location/set to null
      setItem({...item, locationId: null, locationPosition: null})
    } else {
      fetchLocation(location.id, true)
    }

    document.body.click()
  }

  const locationPositionOptions = () => {
    let options = []
    let firstAvailablePosition
    if(!location) retur

    for (let i = 1; i <= location.numPositions; i++) {
      const childLocationInPosition = (location.children?.find(c => c.locationPosition == i) != undefined)
      const itemInPosition = (location.items?.find(item => item.locationPosition == i) != undefined)
      const positionFilled = childLocationInPosition || itemInPosition
      const optionDisabled = positionFilled && item.locationPosition != i // don't disable current position option

      if(!positionFilled) {
        if(firstAvailablePosition == undefined) firstAvailablePosition = i
      }

      options.push(
        <option
          key={i}
          value={i}
          disabled={optionDisabled}
        >
          {`${i} ${optionDisabled ? ' - Filled' : ''}`}
        </option>
      )
    }

    if(!item.locationPosition) setItem({...item, locationPosition: firstAvailablePosition})

    return options
  }

  let statuses = [
    { id: 2, name: "Requested"},
    { id: 1, name: "Available"},
    { id: 4, name: "Archived"},
  ]

  return (
    <Modal show={true} size="lg" onHide={() => { props.onHide() }} enforceFocus={false}>
      <Modal.Header closeButton>
        <Modal.Title>New {instanceName}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form ref={ref} className="mb-1" onSubmit={handleSubmit}>
          <div>
            <label>What type of item?</label>
            <Typeahead
              onChange={(selected) => {
                if(selected[0]) setItem({...item, entryId: selected[0].id})
              }}
              options={entries}
              labelKey="name"
              id="search-entries"
              placeholder="Type an entry name"
              selected={entry ? [entry] : []}
            />
            { !item.entryId &&
              <div className="mt-3">
                <label>Recent</label>
                {entries.slice(0,5).map(entryItem => (
                  <div className="recent-entries-list">
                    <a
                      href="#"
                      onClick={(e) => {e.preventDefault(); setItem({...item, entryId: entryItem.id})}}
                      key={entryItem.id}
                    >
                      {entryItem.name}
                    </a>
                  </div>
                ))}
              </div>
            }
          </div>
          { entry &&
            <div className="mt-3">
              <div className="form-row">
                <div className="form-group col-6">
                  <label>Status</label>
                  <select
                    name="status"
                    className="form-control"
                    value={item.status}
                    onChange={handleChange}
                  >
                    {statuses.map(option => (
                      <option
                        key={option.id}
                        value={option.id}
                      >
                        {option.name}
                      </option>
                    ))}
                  </select>
                </div>
                <SelectLocation
                    ref={ref}
                    className="form-group col"
                    location={location}
                    setLocation={selectLocation}
                    locationPosition={item.locationPosition}
                    setLocationPosition={(id)=>{setItem({...item, ["locationPosition"]: id})}}
                />
              </div>
              {/* entry input fields */}
              { itemType?.allTypeFields &&
                <div>
                  {itemType?.allTypeFields.filter(f => (f.fieldTypeId == 7 && entry?.inputData[f.id])).map((field, index) => (
                    <div key={field.id} className="form-group">
                      <RecordField
                        field={field}
                        inputData={inputData}
                        entry={entry}
                        handleChange={handleInputDataChange}
                        formRef={ref}
                      />
                    </div>
                  ))}
                </div>
              }
              { itemType?.allInstanceFields &&
                <div>
                  {itemType?.allInstanceFields.map((field, index) => (
                    <div key={field.id} className="form-group">
                      {
                        {
                          1: <SingleLineTextField field={field} inputData={inputData} handleChange={handleInputDataChange} />,
                          2: <MultiLineTextField field={field} inputData={inputData} handleChange={handleInputDataChange} />,
                          3: <ChecklistField field={field} inputData={inputData} handleChange={handleChecklistChange} />,
                          4: <DropdownField field={field} inputData={inputData} handleChange={handleInputDataChange} />,
                          5: <DateField field={field} inputData={inputData} handleChange={handleInputDataChange} />,
                          6: <TeamMemberField field={field} inputData={inputData} handleChange={handleInputDataChange} />,
                          7: <RecordField field={field} inputData={inputData} handleChange={handleInputDataChange} formRef={ref} />,
                          8: <NumberField field={field} inputData={inputData} handleChange={handleInputDataChange} />,
                          9: <FileAttachmentField field={field} inputData={inputData} handleChange={handleFileDataChange} setFileUploading={(bool) => setFileUploading(bool)} />,
                          10:<UrlField field={field} inputData={inputData} handleChange={handleInputDataChange} />,
                          11:<DurationField field={field} inputData={inputData} handleChange={handleInputDataChange} />,
                          12:<CurrencyField field={field} inputData={inputData} handleChange={handleInputDataChange} />
                        }[field.fieldTypeId]
                      }
                    </div>
                  ))}
                </div>
              }
              <button type="submit" className="btn btn-primary" disabled={isSubmitting || fileUploading}>
                { !isSubmitting ? (
                  "Save"
                ) : (
                  <i className="fas fa-spinner fa-spin"></i>
                )}
              </button>
              <a
                href='#'
                onClick={(e) => { e.preventDefault(); props.onHide() }}
                className="btn btn-link"
              >
                Cancel
              </a>
            </div>
          }
        </form>
      </Modal.Body>
    </Modal>
  )
}

const mapStateToProps = state => {
  return {
    currentUser: state.currentUser
  }
}

export default connect(mapStateToProps)(withRouter(NewItemGlobal))
