import React, { useState, useEffect, useRef } from 'react'
import { Form, InputGroup, Card} from 'react-bootstrap'
import { v4 as uuidv4 } from 'uuid'
import { numberTypes, currencyTypes } from './fields/fieldUtils'

const ItemTypeFieldEditor = (props) => {
  const [field, setField] = useState({
    name: "",
    fieldTypeId: "",
    fieldOptionsAttributes: []
  })

  useEffect(() => {
    let fieldProp = props.field
    fieldProp.fieldOptionsAttributes = fieldProp.fieldOptionsAttributes || [{
      id: uuidv4(),
      newRecord: true,
      label: "",
      value: props.field.fieldTypeId == "12" ? "usd" : ""
    }]
    setField(fieldProp)
  }, [props.field])

  const handleChange = (event) => {
    setField({...field, [event.target.name]: event.target.value})
  }

  const handleFieldTypeChange = (event) => {
    setupFields(event.target.value)
    setField({...field, [event.target.name]: event.target.value})
  }

  // This configures and updates field types for new selected fields
  const setupFields = (id) => {
    const fieldType = props.fieldTypes.find(ft => ft.id == id)

    // Sets the option for currency to usd as default
    if (fieldType.hasOptions && fieldType.id == 12) {
      if (field.fieldOptionsAttributes.length == 0) {
        let newField = field
        newField.fieldOptionsAttributes = [{
          id: uuidv4(),
          newRecord: true,
          label: "",
          value: "usd"
        }]

        setField(newField)
      }
      if (field.fieldOptionsAttributes.length > 0 && !(currencyTypes.find(curr => curr.value == field.fieldOptionsAttributes[0].value))) {
        const prevOptions = field.fieldOptionsAttributes
        prevOptions[0]["value"] = "usd"
        setField({...field, fieldOptionsAttributes: prevOptions})
      }
    }
  }

  useEffect(()=>{
    if (field.fieldTypeId == "9" && field.fieldOptionsAttributes.length == 0)
      addOption(null, "fileType")
  }, [field])

  const handleRadioChange = (event) => {
    setField({...field, fieldTypeId: event.target.value})
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    props.updateField(field)
    document.body.click()
  }

  // add new option with temp id
  const addOption = (event, label="") => {
    event ? event.preventDefault() : null
    let options = field.fieldOptionsAttributes.concat({
      id: uuidv4(),
      newRecord: true,
      label: label,
      value: ""
    })
    setField({...field, fieldOptionsAttributes: options})
  }

  const updateOption = (event, id) => {
    const prevOptions = field.fieldOptionsAttributes
    const foundIndex = prevOptions.findIndex(x => x.id == id)
    prevOptions[foundIndex][event.target.name] = event.target.value
    setField({...field, fieldOptionsAttributes: prevOptions})
  }

  const updateFirstOptionValue = (event) => {
    const prevOptions = field.fieldOptionsAttributes
    prevOptions[0]["value"] = event.target.value
    setField({...field, fieldOptionsAttributes: prevOptions})
  }

  const updateNumberTypeOption = (event) => {
    const prevOptions = field.fieldOptionsAttributes
    prevOptions[0]["numberType"] = event.target.value
    const type = numberTypes.find(nT=>nT.value == event.target.value)
    prevOptions[0]["value"] = type.precision[0]
    setField({...field, fieldOptionsAttributes: prevOptions})
  }

  let currNumberType = null

  if (["8"].includes(field.fieldTypeId.toString()) && field.fieldOptionsAttributes[0]) {
    currNumberType = numberTypes.find(nT=>nT.value == field.fieldOptionsAttributes[0].numberType)

    if (field.fieldOptionsAttributes[0].value != "" && field.fieldOptionsAttributes[0].numberType == null) {
      currNumberType = numberTypes.find(nT=>nT.precision.includes(field.fieldOptionsAttributes[0].value))
      const prevOptions = field.fieldOptionsAttributes
      prevOptions[0]["numberType"] = currNumberType.value
      setField({...field, fieldOptionsAttributes: prevOptions})
    }
  }

  const removeOption = (option) => {
    const prevOptions = field.fieldOptionsAttributes
    const foundIndex = prevOptions.findIndex(x => x.id == option.id)
    prevOptions[foundIndex] = {...option, _destroy: true}
    setField({...field, fieldOptionsAttributes: prevOptions})
  }

  return (
    <>
      <div className="form-group">
        <label>Name</label>
        <input
          name="name"
          className="form-control"
          value={field.name}
          onChange={handleChange}
          type="text"
        />
      </div>
      <div className="form-group mb-3">
        <label>Field Type</label>
        <select
          name="fieldTypeId"
          value={field.fieldTypeId}
          className="form-control"
          onChange={handleFieldTypeChange}
        >
          {props.fieldTypes.map((fieldType, index) => (
            <option value={fieldType.id} key={fieldType.id} className="mb-2">
              {fieldType.name}
            </option>
          ))}
        </select>
      </div>
      { ["3", "4"].includes(field.fieldTypeId.toString()) &&
        <div className="form-group mb-1">
          <button type="submit" className={`btn btn-sm btn-secondary float-right`} onClick={addOption}>
            Add Option
          </button>
          <h6 className="pt-2 mb-3">Options</h6>
          {field.fieldOptionsAttributes.filter((option) => { return !option._destroy }).map((option, index) => (
            <div key={option.id} className="form-row" style={{clear: 'both'}}>
              <div className="form-group col" style={{marginBottom: "0.5rem"}}>
                <InputGroup>
                  <input
                    name="label"
                    className="form-control"
                    type="text"
                    onChange={(e) => { updateOption(e, option.id) }}
                    value={option.label}
                  />
                  <InputGroup.Append>
                    <InputGroup.Text>
                      <a href="#" className="text-dark">
                        <i onClick={(e) => { e.preventDefault(); removeOption(option) }} className="fas fa-trash-alt"></i>
                      </a>
                    </InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
              </div>
            </div>
          ))}
        </div>
      }
      { ["8"].includes(field.fieldTypeId.toString()) &&
        <>
        <div className="form-group mb-3">
          <label>Number Type</label>
          <select
            name="numberType"
            value={field.fieldOptionsAttributes[0] ? field.fieldOptionsAttributes[0].numberType : ""}
            className="form-control"
            onChange={(e)=>updateNumberTypeOption(e)}
          >
            {numberTypes.map(numberType=> (
              <option key={numberType.value} value={numberType.value}>{numberType.name}</option>
            ))}
          </select>
        </div>
        { currNumberType && currNumberType.precision.length > 1 &&
          <div className="form-group mb-3">
            <label>Precision</label>
            <select
              name="precision"
              value={field.fieldOptionsAttributes[0] ? field.fieldOptionsAttributes[0].value : ""}
              className="form-control"
              onChange={(e)=>updateFirstOptionValue(e)}
            >
              { currNumberType.precision.map(p => (
                <option key={p} value={p}>{p}</option>
              )) }
            </select>
          </div>
        }
        </>
      }
      { ["9"].includes(field.fieldTypeId.toString()) &&
        <div className="form-group mb-3">
          <label>File Type</label>
          <select
            name="fileType"
            value={field.fieldOptionsAttributes[0] ? field.fieldOptionsAttributes[0].value : ""}
            className="form-control"
            onChange={(e)=>updateFirstOptionValue(e)}
          >
            <option value={""}>All</option>
            <option value={"image/*"}>Images (.png, .jpg)</option>
            <option value={".pdf"}>PDFs</option>
            <option value={".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"}>Spreadsheets (.csv, .xls, .xlsx)</option>
            <option value={".gb, .fa"}>Sequences (GenBank, FASTA)</option>
          </select>
        </div>
      }
      { ["12"].includes(field.fieldTypeId.toString()) &&
        <div className="form-group mb-3">
          <label>Currency</label>
          <select
            name="currency"
            value={field.fieldOptionsAttributes[0] ? field.fieldOptionsAttributes[0].value : ""}
            className="form-control"
            onChange={(e)=>updateFirstOptionValue(e)}
          >
            { currencyTypes.map(type=>(
              <option key={type.value} value={type.value}>{type.name}</option>
            ))}
          </select>
        </div>
      }
      <button type="submit" className={`btn mb-2 btn-primary`} onClick={handleSubmit}>
        Done
      </button>
    </>
  )
}

export default ItemTypeFieldEditor
