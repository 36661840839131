import React, { useState, useEffect } from 'react'
import limsApi from '../utils/limsApi'
import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { OverlayTrigger, Tooltip, Modal, Table, Container, Row, Col, Tabs, Tab, Popover } from 'react-bootstrap'
import ItemTimeline from './itemTimeline'
import RecordFieldDisplay from './fields/recordFieldDisplay'
import ContentLoader from "react-content-loader"
import { FieldDisplay } from './fields/fieldUtils'
import ReactHtmlParser from 'react-html-parser'
import ObjectsPrintForm from './objectsPrintForm'
import LotItems from './lotItems'

const Item = (props) => {
  const pluralize = require('pluralize')
  const [isLoading, setIsLoading] = useState(true)
  const [item, setItem] = useState({
    status: "",
    entry: {}
  })
  const [itemType, setItemType] = useState({
    name: "",
    instanceName: ""
  })
  const [tab, setTab] = useState('details')
  const [showLabelForm, setShowLabelForm] = useState(false)
  const [lotLocations, setLotLocations] = useState([])
  const itemId = props.itemId != undefined ? props.itemId : props.match.params.itemId
  const itemTypeId = props.itemTypeId != undefined ? props.itemTypeId : props.match.params.id
  const instanceName = props.itemType && props.itemType.instanceName ? pluralize(props.itemType.instanceName, 1) : "Item"
  let itemTypeAbbr = props.itemType && props.itemType.name ? props.itemType.name.replace(/(\w)\w*\W*/g, function (_, i) {
      return i.toUpperCase();
    }
  ): null

  const fetchItem = async () => {
    setIsLoading(true)

    await limsApi.get(`items/${itemId}`, (response)=>{
      const itemData = response.data.item
      setItem(itemData)
      fetchItemType(itemData.itemTypeId)
    },
    'Error fetching item', ()=>{setIsLoading(false)})
  }

  useEffect(() => {
    fetchItem()
  }, [props.match.params.itemId])

  const fetchItemType = async (itemTypeId) => {
    await limsApi.get(`item_types/${itemTypeId}`, (response)=>{
      const itemTypeData = response.data.itemType
      setItemType(itemTypeData)
    },
    'Error fetching item type', undefined, setIsLoading)
  }

  const viewingFrom = () => {
    if(props.match.path.includes("catalog")) {
      return "catalog"
    } else {
      return "locations"
    }
  }

  const closeModalRedirectsTo = (viewingFrom() == "catalog") ? `/catalog/entries/${props.match.params.entryId}` : `/locations/${props.match.params.id}`

  const statuses = [
    { id: 1, name: "Available"},
    { id: 2, name: "Requested"},
    { id: 3, name: "Processing"},
    { id: 4, name: "Archived"},
    { id: 5, name: "Cancelled"},
  ]

  const statusName = () => {
    const status = statuses.find(status => status.id == item.status)
    return status ? status.name : ""
  }

  const locationPositionForDisplay = () => {
    const location = item.location

    if (location.orientation == 'vertical') {
      let currentRow = Math.ceil((item.locationPosition)/location.cols)
      let currentCol = ((item.locationPosition-1) % location.cols) + 1
      let position = ((location.rows * (currentCol - 1)) + currentRow)
      return position
    }
    return item.locationPosition
  }

  // Makes a copy of the item
  const duplicateItem = () => {
    limsApi.post(`duplicate_item/${item.id}`, { }, "", (response)=>{
      const dupItem = response.data.item
      console.log(dupItem);
      props.refreshItems()
      props.history.push(`/catalog/entries/${dupItem.entryId}/items/${dupItem.id}`)
    },
    'Error duplicating item')
  }

  const printAndEditButtons = (
    <div className="pl-3 mb-2">
      <button type="button" className="btn btn-primary btn-block" onClick={()=>setShowLabelForm(true)}>Print Label</button>
      <button type="button" className="btn btn-secondary btn-block" onClick={()=>duplicateItem()}>Duplicate</button>
      { (props.currentUser.userRole.id == 1 || item.task?.assignedToId == props.currentUser.id) &&
        <Link
          className="btn btn-light btn-block"
          to={`${closeModalRedirectsTo}/items/${itemId}/edit`}
        >
          Edit
        </Link>
      }
    </div>
  )

  return (
    <Modal show={true} size="lg" onHide={() => { props.history.push(closeModalRedirectsTo) }} enforceFocus={false}>
      <Modal.Header closeButton>
        <Modal.Title>
          {!isLoading ? item.code : ""}
          <small className="d-block text-secondary">
            {!isLoading ? (
              <Link to={`/catalog/entries/${item.entryId}`}>{item.entry?.name}</Link>
            ) : (
              <ContentLoader height="64" backgroundColor={'#eee'} foregroundColor={'#ccc'}>
                <rect x="0" y="7" rx="4" ry="4" width="220" height="23" />
                <rect x="0" y="43" rx="3" ry="3" width="170" height="20" />
              </ContentLoader>
            )}
          </small>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Tabs variant="pills" defaultActiveKey="details" className="mb-3 no-pt" activeKey={tab} onSelect={(k) => setTab(k)}>
          <Tab eventKey="details" title="Details">
            { !isLoading ? (
              <Container fluid={true} style={{ paddingLeft: 0, paddingRight: 0 }}>
                <Row noGutters={true}>
                  <Col xs={9}>
                    <Table bordered hover responsive style={{width: "100%", tableLayout: 'fixed'}}>
                      <tbody>
                        { (props.currentUser.userRole.id == 1 && item.task?.id) &&
                          <tr>
                            <td style={{width: "30%"}}>Task</td>
                            <td><Link to={`/tasks/${item.task.id}`}>{item.task.name} ({item.task.status})</Link></td>
                          </tr>
                        }
                        <tr>
                          <td style={{width: "30%"}}>Status</td>
                          <td>{statusName()}</td>
                        </tr>
                        { item.entry.itemType.itemsHaveLocation &&
                          <tr>
                            <td style={{width: "30%"}}>Location</td>
                            { item.isLot ?
                                <td>
                                  { lotLocations.length > 0 ?
                                      lotLocations.map((loc, index)=>(
                                        <span className="mr-2" key={loc.id}>
                                          { loc.id != null ?
                                              <Link to={`/locations/${loc.id}`}>
                                                {loc.name}
                                              </Link>
                                            :
                                              loc.name
                                          }
                                          { index+1 == lotLocations.length ? null : "," }
                                        </span>
                                      ))
                                    :
                                      "None selected"
                                  }
                                </td>
                              :
                                <td>
                                  {item.location
                                    ? <Link to={`/locations/${item.locationId}`}>
                                        {item.location.name} {item.location.limitedCapacity ? `(Position ${locationPositionForDisplay()})` : ""}
                                      </Link>
                                    : "None selected"
                                  }
                                </td>
                            }

                          </tr>
                        }
                        { item.isLot &&
                          <tr>
                            <td style={{width: "30%"}}>Lot size</td>
                            <td>
                              <a
                                href="#"
                                onClick={(e) => { e.preventDefault(); setTab('lotItems') }}
                              >
                                {item.lotItems.length} {pluralize('element', item.lotItems.length)}
                              </a>
                            </td>
                          </tr>
                        }
                        {/* entry input fields */}
                        {itemType.allTypeFields.filter(field => field.fieldTypeId == 7).map((field, index) => (
                          <tr key={field.id}>
                            <td style={{width: "30%"}}>{field.name}</td>
                            <td>
                              <RecordFieldDisplay field={field} inputData={item.inputData} />
                            </td>
                          </tr>
                        ))}
                        {itemType.allInstanceFields.map((field, index) => {
                          if (Object.keys(field.viewOptions).length > 0) {
                            if (field.viewOptions[item.status] == "hidden")
                              return null
                          }
                          return (
                            <tr key={field.id}>
                              <td style={{width: "30%"}}>{field.name}</td>
                              <td style={{wordWrap: "break-word"}}>
                                <FieldDisplay field={field} obj={item} isEntry={false} />
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </Table>
                  </Col>
                  <Col>
                    {printAndEditButtons}
                  </Col>
                </Row>
              </Container>
            ) : (
              <ContentLoader width="600" height="215" backgroundColor={'#eee'} foregroundColor={'#ccc'}>
                <rect x="0" y="10" rx="3" ry="3" width="570" height="25" />
                <rect x="0" y="60" rx="3" ry="3" width="570" height="25" />
                <rect x="0" y="110" rx="3" ry="3" width="570" height="25" />
                <rect x="0" y="160" rx="3" ry="3" width="570" height="25" />
              </ContentLoader>
            )}
          </Tab>
          { item.isLot &&
            <Tab eventKey="lotItems" title="Elements">
              <div className="mb-2">
                <LotItems
                  parent={item}
                  setParent={setItem}
                  reloadItem={()=>fetchItem()}
                  printAndEditButtons={printAndEditButtons}
                  setParentLotLocations={(arr)=>setLotLocations(arr)}
                  />
              </div>
            </Tab>
          }
          <Tab eventKey="timeline" title="Timeline">
            <div className="mb-2">
              <ItemTimeline
                itemId={itemId}
                itemTypeId={itemTypeId} />
            </div>
          </Tab>
        </Tabs>
      </Modal.Body>
      { showLabelForm &&
        <ObjectsPrintForm
          labelsFor="items"
          closeModal={()=>setShowLabelForm(false)}
          objects={[item]} />
      }
    </Modal>
  )
}

const mapStateToProps = state => {
  return {
    currentUser: state.currentUser
  }
}

export default connect(mapStateToProps)(withRouter(Item))
