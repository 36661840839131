import React, {useEffect, useState} from 'react'
import {limsCreate, limsNew} from "../utils/limsApi";
import GDialog from "./dialogs/gDialog";
import {generateOnChange} from "../utils/defaultHandlers";

//TODO: move this file to dialogs directory. But not until its original creation is merged to master
//by mid Feb 2021, it should be safe to move this. - Jason

/**
 * Adds api functionality for creating a new thing on top of a GDialog.
 */
const NewForm = ({title, subtitle, returnPath, onSuccess, modelName, create, newQueryString, children, submitButtonText, ...props}) => {
    const pluralize = require('pluralize')
    const sModelName = pluralize.singular(modelName)
    const [isLoading, setIsLoading] = useState(true)
    const [model, setModel] = useState({})
    const onChange = generateOnChange(setModel)
    useEffect(()=>{
        if (create) {
            limsNew(modelName, (data)=>{
                setModel(data[sModelName])
                setIsLoading(false)
            }, newQueryString)
        }
    },[])

    const onSubmit = (event) => {
        limsCreate(modelName, {[sModelName]: model}, (r)=>{
            // window.flash('New Task Created', 'success')
            onSuccess()
        })
    }

    return (
        <GDialog
            title={title}
            subtitle={subtitle}
            isLoading={isLoading}
            returnPath={returnPath}
            onChange={onChange}
            onSubmit={onSubmit}
            model={model}
            submitButtonText={submitButtonText}
        >{children}</GDialog>
    )
}

export default NewForm
