// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")

import '../css/site'
import '../css/tree'
import 'jquery'
import 'popper.js'
import 'bootstrap'

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import React from "react"
import { render } from "react-dom"
import { Provider } from "react-redux";
import store from "../redux/store";
import App from '../components/app'

document.addEventListener("DOMContentLoaded", () => {
  render(
      <Provider store={store}>
        <App />
      </Provider>,
    document.body.appendChild(document.createElement("div"))
  )
})
