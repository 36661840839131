import React, { useState, useEffect } from 'react'
import axios from 'axios'
import limsApi from '../utils/limsApi'
import { Table } from 'react-bootstrap'
import ContentLoader from "react-content-loader"
import AccountSubNav from './accountSubNav'
import DeleteConfirmation from './deleteConfirmation'
import { Switch, withRouter, Link, Route } from 'react-router-dom'
import SensorForm from './sensorForm'
import NewSensor from './newSensor'
import SensorValue from './sensorValue'

const ManageSensors = () => {
  const [sensors, setSensors] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [showLinkSensorForm, setShowLinkSensorForm] = useState(false)
  const [showSensorForm, setShowSensorForm] = useState(null)

  // Performs a get request to list all the printers
  const fetchSensors = () => {
    limsApi.get('sensors', (response)=>{
      let sensorData = response.data.sensors
      setSensors(sensorData)
    },
    'Error fetching sensors', undefined, setIsLoading)
  }

  const deleteSensor = (sensor) => {
    limsApi.patch(`sensors/${sensor.id}`, { sensor: {...sensor, accountId: null} }, `${sensor.name} Removed`, (response)=>{
        fetchSensors()
      },
      'Error removing sensor')
  }

  const loadingRow = (
    <tr>
      <td>
        <ContentLoader height="20" width="100%" backgroundColor={'#eee'} foregroundColor={'#ccc'}>
          <rect x="0" y="0" rx="3" ry="3" width="150" height="20" />
        </ContentLoader>
      </td>
      <td>
        <ContentLoader height="20" width="100%" backgroundColor={'#eee'} foregroundColor={'#ccc'}>
          <rect x="0" y="0" rx="3" ry="3" width="60" height="20" />
        </ContentLoader>
      </td>
      <td>
        <ContentLoader height="25" width="100%" backgroundColor={'#eee'} foregroundColor={'#ccc'}>
          <rect x="0" y="0" rx="3" ry="3" width="100" height="25" />
        </ContentLoader>
      </td>
    </tr>
  )

  useEffect(() => {
    fetchSensors()
  }, [])

  let sensorList = sensors.map((sensor) => (
    <tr key={sensor.id}>
      <td>
        {sensor.name}
      </td>
      <td>
        <SensorValue sensor={sensor} noName />
      </td>
      <td>
        { sensor.location ?
          <Link to={`/locations/${sensor.location.id}`}>{sensor.location.name}</Link>
          :
          "None"
        }
      </td>
      <td>
        { (sensor.minValue != null && sensor.maxValue != null) &&
          <>
            {sensor.minValue + "C "}
            <i className="fas fa-arrows-alt-h"></i>
            {" " + sensor.maxValue + "C"}
          </>
        }
      </td>
      <td>
        <i className="fas fa-circle fa-xs mr-1" style={{color: sensor.active ? "green" : "red"}}></i>
        {sensor.active ? "online" : "offline"}
      </td>
      <td>
        {sensor.kind}
      </td>
      <td>
        <div className={/*'btn-group'*/''}>
          <button
            type="button"
            className="btn btn-light btn-sm"
            onClick={() => setShowSensorForm(sensor.id)}
          >
            <i className="fas fa-edit"></i>
          </button>
          <DeleteConfirmation delete={() => deleteSensor(sensor)}>
            <button type="button" className="btn btn-light btn-sm">
              <i className="fas fa-trash-alt"></i>
            </button>
          </DeleteConfirmation>
        </div>
      </td>
    </tr>
  ))

  if (sensors.length == 0)
    sensorList = <p>No sensors detected</p>

  return (
    <div>
      { showSensorForm > 0 &&
        <SensorForm sensorId={showSensorForm} closeModal={()=>setShowSensorForm(null)} reload={fetchSensors} />
      }
      { showLinkSensorForm &&
        <NewSensor closeModal={()=>setShowLinkSensorForm(false)} reload={fetchSensors} />
      }
      <div className="col-fixed bg-light">
        <AccountSubNav />
      </div>
      <div className="col-fluid p-4 bg-white">
      <button className="btn btn-primary float-right" onClick={() => setShowLinkSensorForm(true)}>
        Add Sensor
      </button>
      <h3 className="mb-3">Manage Sensors</h3>
      <Table id="sensors-list" bordered hover>
        <thead>
          <td>Name</td>
          <td>Value</td>
          <td>Location</td>
          <td>Alert Threshold</td>
          <td>Status</td>
          <td>Type</td>
          <td>Actions</td>
        </thead>
        <tbody>
        { !isLoading ?
            sensorList
          :
            loadingRow
        }
        </tbody>
      </Table>
      </div>
    </div>

  )
}

export default ManageSensors
