import React from "react";
import {withRouter} from "react-router";
import DeleteConfirmation from "../deleteConfirmation";

const typeToClassName = {
    "edit": "fa-edit",
    "new": "fa-plus",
    "delete": "fa-trash-alt"
};

/**
 * ActionButton can execute a "new", "edit", or "delete" action by going to an url, onClick event, or simply doing
 * nothing, which requires the parent to somehow handle an event, such as an overlay trigger.
 * Use this component with the props "actionUrl" and "type". Set type to one of "new", "edit" or "delete".
 * Or use the more specific versions of EditButton, NewButton, and DeleteButton below.
 * staticContext is in the arguments just to get it out of the props because React doesn't like it.
 * withRouter adds the history property, and others (we don't use any others though).
 */
export const ActionButton = withRouter(({type, actionUrl, history, onClick, staticContext, ...props}) => {
    const faClassName = typeToClassName[type] || "";
    let onClickFn = undefined;
    if (actionUrl) { onClickFn = () => {history.push(actionUrl)}; }
    if (onClick) {onClickFn = onClick;}
    return (
        <button type="button" className={`btn btn-light btn-sm ${type}-action`} onClick={onClickFn} {...props}>
            <i className={`fas ${faClassName}`}/>
        </button>
    );
});

export const EditButton = ({actionUrl, ...props}) => {
    return (<ActionButton type={"edit"} actionUrl={actionUrl} {...props}/>);
};

export const NewButton = ({actionUrl, ...props}) => {
    return (<ActionButton type={"new"} actionUrl={actionUrl} {...props}/>);
};

export const DeleteButton = ({handler, ...props}) => {
    return (
        <DeleteConfirmation delete={() => handler}>
            <ActionButton type={"delete"} {...props}/>
        </DeleteConfirmation>
    )
};

export const ActionButtonGroup = (props) => {
    return (
        <div className="btn-group dropright" role="group">
            {props.children}
        </div>
    );
};
