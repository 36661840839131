import React, { useState, useEffect } from 'react'
import axios from 'axios'
import limsApi from '../utils/limsApi'
import { Modal, Form, ProgressBar, Button, Card, InputGroup, FormControl, Table, OverlayTrigger, Tooltip } from 'react-bootstrap'

const Importer = (props) => {
  const [uploadProgress, setUploadProgress] = useState(-1)  // -1 indicates no upload started, 0-100 indicates a percentage
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [fileUploading, setFileUploading] = useState(true)
  const [files, setFiles] = useState(null)  // set file to null if no file is uploaded
  const [fileIds, setFileIds] = useState([])
  const [showConfirmation, setShowConfirmation] = useState(false)

  const fileType = null

  const uploadFiles = async(evt) => {
    const newFiles = evt.target.files
    const newFileIds = fileIds ? [...fileIds] : []
    const fileAttachments = []

    setFileUploading(true)
    setUploadProgress(0)

    for (let i = 0; i < newFiles.length; i++) {
      const file = newFiles[i]
      const uploadedFile = await uploadFile(file, newFiles.length, i)

      if(uploadedFile.id) {
        newFileIds.push(uploadedFile.id)
        fileAttachments.push(uploadedFile)
      }
    }

    setFileUploading(false)
    setUploadProgress(-1)

    if(fileAttachments.length > 0) {
      document.getElementById("fileAttachmentInput").value = "";
      setFiles(fileAttachments)
      setFileIds(newFileIds)
      // handleChange(field.id, newFileIds)
      // setReload(prev => (!prev))

      // console.log(fileAttachments)
      setShowConfirmation(true)
    } else {
      window.flash(`File can't be imported. Please check the format and try again.`, 'error')
    }
  }

  const uploadFile = async(file, numOfFiles, index) => {
    const token = localStorage.getItem('gf-token')
    const fd = new FormData()
    fd.append('file_attachment[file]', file)
    fd.append('file_attachment[name]', file?.name || "")
    fd.append('uploading_import_file', 'true')
    fd.append('item_type_id', props.itemType.id)

    let fileAttachment = {}
      await axios.post('/api/file_attachments', fd, { headers: {"Authorization" : `Token ${token}`}, onUploadProgress: (progressEvent) => options(progressEvent, numOfFiles, index)} )
        .then(response => {
          fileAttachment = response.data
        })
        .catch(error => {
          console.log(error);
        })
        return fileAttachment
  }

  const executeImport = (fileAttachmentId) => {
    limsApi.post('imports', { fileAttachmentId: fileAttachmentId, item_type_id: props.itemType.id }, `Import Complete`, (response)=>{
      const importData = response.data
      props.refreshEntries()
      props.onHide()
    }, 'Could not import. Please try again.', null, setIsSubmitting)
  }

  const options = (progressEvent, numOfFiles, index=0) => {
    let percentage = Math.floor( (progressEvent.loaded*100) / (progressEvent.total))
    setUploadProgress(prev => ((100*index + percentage)/numOfFiles)|0)
  }

  return (
    <Modal show={true} size="lg" onHide={props.onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Import {props.itemType?.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        { !showConfirmation ? (
          <div className="pb-2">
            <p>Upload a CSV file below to import data directly to your catalog. For detailed instructions, including how to format your data, see <a href="https://support.genofab.com/how-do-i-import-my-existing-data" target="_blank">How to Import Existing Data</a>.</p>
            <input
              id='fileAttachmentInput'
              type="file"
              accept=".csv"
              className="form-control"
              name="importUpload"
              onChange={uploadFiles}
              style={{height: 'auto'}}
            />
            {uploadProgress < 0 ? null :
              (<ProgressBar animated now={uploadProgress} className="mt-3" label={`${uploadProgress}%`} />)}
          </div>
        ) : (
          <div className="">
            <h5 className="mb-3">Preview</h5>
            <Table className="mb-4" bordered>
              <tbody>
                <tr>
                  <td className="heading-left">Items to Import</td>
                  <td>
                    {files[0]?.importSummary?.entries?.map((entry, index) => (
                      <div key={index}>{entry.name} <span className="text-secondary">- {entry.count} items</span></div>
                    ))}
                  </td>
                </tr>
                <tr>
                  <td className="heading-left">
                    Entry Fields
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>
                          These are the detected fields that are currently supported for automatic import.
                        </Tooltip>
                      }
                    >
                      <i className="fas fa-info-circle ml-1 text-muted"></i>
                    </OverlayTrigger>
                  </td>
                  <td>
                    {files[0]?.importSummary?.fields?.filter(f => !f.applies_to_instances).map((field, index) => (
                      <div key={index}>
                        {field.name} <span className="text-secondary">- {field.type_name}</span>
                        {field.existing &&
                          <span className="ml-2 badge badge-info">Existing</span>
                        }
                      </div>
                    ))}
                  </td>
                </tr>
                <tr>
                  <td className="heading-left">
                    Item Fields
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>
                          These are the detected fields that are currently supported for automatic import.
                        </Tooltip>
                      }
                    >
                      <i className="fas fa-info-circle ml-1 text-muted"></i>
                    </OverlayTrigger>
                  </td>
                  <td>
                    {files[0]?.importSummary?.fields?.filter(f => f.applies_to_instances).map((field, index) => (
                      <div key={index}>
                        {field.name} <span className="text-secondary">- {field.type_name}</span>
                        {field.existing &&
                          <span className="ml-2 badge badge-info">Existing</span>
                        }
                      </div>
                    ))}
                  </td>
                </tr>
              </tbody>
            </Table>
            <button className="btn btn-primary" onClick={() => executeImport(files[0]?.id)}disabled={isSubmitting}>
              { !isSubmitting ? (
                "Import"
              ) : (
                <i className="fas fa-spinner fa-spin"></i>
              )}
            </button>
            <button className="btn btn-link" onClick={() => setShowConfirmation(false)}>Cancel</button>
          </div>
        )}
      </Modal.Body>
    </Modal>
  )
}

export default Importer
