import React, {useEffect, useRef, useState} from 'react'
import { Modal, Form } from 'react-bootstrap'
import limsApi from '../utils/limsApi'
import SelectLocation from "./selectLocation"
import ItemForm from './itemForm'

const SensorForm = (props) => {
  const ref = useRef(null)
  const [sensor, setSensor] = useState({})
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const handleSubmit = (event) => {
    event.preventDefault()

    limsApi.patch(`sensors/${sensor.id}`, { sensor: {...sensor, locationId: sensor.location? sensor.location.id : null} }, `${sensor.name} Updated`, (response)=>{
        const sensorData = response.data.sensor
        props.reload()
        props.closeModal()
      },
      'Error updating sensor', undefined, setIsSubmitting)
  }

  const handleChange = (event) => {
    setSensor({...sensor, [event.target.name]: event.target.value})
  }

  const fetchSensor = () => {
    limsApi.get(`sensors/${props.sensorId}`, (response)=>{
      const sensorData = response.data.sensor
      setSensor(sensorData)
    },
    'Error fetching sensor', undefined, setIsLoading)
  }

  useEffect(()=>{
    fetchSensor()
  }, [])

  return (
    <Modal backdrop="static" show={true} size="lg" onHide={() => props.closeModal()} enforceFocus={false}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Sensor</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      { isLoading ?
        <p>Loading...</p>
        :
        <form ref={ref} onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Name</label>
            <input
              type="text"
              name="name"
              className="form-control"
              value={sensor.name}
              onChange={handleChange}
              maxLength="16"
              required
            />
          </div>
          <div className="form-group">
            <label>Type</label>
            <select
              name="kind"
              className="form-control"
              value={sensor.kind}
              onChange={handleChange}
            >
              <option
                value={"temperature"}
              >
                temperature
              </option>
            </select>
          </div>
          <div className="form-row">
            <div className="form-group col">
              <label>Min Value</label>
              <input
                type="number"
                name="minValue"
                className="form-control"
                value={sensor.minValue}
                max={sensor.maxValue}
                onChange={handleChange}
              />
            </div>
            <div className="form-group col">
              <label>Max Value</label>
              <input
                type="number"
                name="maxValue"
                className="form-control"
                value={sensor.maxValue}
                min={sensor.minValue}
                onChange={handleChange}
              />
            </div>
          </div>
          <SelectLocation
              ref={ref}
              className="form-group"
              location={sensor.location}
              setLocation={(loc)=>setSensor({...sensor, location: loc})}
          />
          <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
            { !isSubmitting ? (
              "Save"
            ) : (
              <i className="fas fa-spinner fa-spin"></i>
            )}
          </button>
          <button className="btn btn-link" onClick={()=>props.closeModal()}>
            Cancel
          </button>
        </form>
      }

      </Modal.Body>
    </Modal>
  )
}

export default SensorForm
