import axios from "axios";

const shouldLogErrors = true

const defaultSuccessMessage = "Success"
const defaultErrorMessage = "An error occured. Please try again later."

export const handleDelete = (subpath, id, successMessage, failureMessage) => {

    axios.delete(`/api/${subpath}/${id}`,
        { headers: getAuthHeader() })
        .then(response => {
            props.removeNode(id)
            window.flash(successMessage, 'success')
        })
        .catch(error => {
            console.error(error)
            window.flash(failureMessage, 'error')
        })
};

export default class limsApi {
  // EXCLUDE /api from begining of paths

  static get(path, successFunc=()=>{}, failureMessage = defaultErrorMessage, failureFunc=()=>{}, setLoadingFunc=()=>{}, isDiscrete=false) {
    failureFunc = filterParams(failureFunc)
    successFunc = filterParams(successFunc)
    setLoadingFunc = filterParams(setLoadingFunc)
    setLoadingFunc(true)
    axios.get(`/api/${path}`,
      { headers: getAuthHeader() })
      .then(response => {
        successFunc(response)
        setLoadingFunc(false)
      })
      .catch(error => {
        const errors = error.response?.data?.errors || null
        if (!isDiscrete) {
          checkErrors(error)
          flashError(errors != null ? errors[0] : failureMessage)
        }
        shouldLogErrors ? console.log(error) : null
        failureFunc(error)
        setLoadingFunc(false)
      })
  }

  static getHeaderless(path, successFunc=()=>{}, failureMessage = defaultErrorMessage, failureFunc=()=>{}, setLoadingFunc=()=>{}, isDiscrete=false) {
    failureFunc = filterParams(failureFunc)
    successFunc = filterParams(successFunc)
    setLoadingFunc = filterParams(setLoadingFunc)
    setLoadingFunc(true)
    axios.get(`/api/${path}`)
      .then(response => {
        successFunc(response)
        setLoadingFunc(false)
      })
      .catch(error => {
        const errors = error.response?.data?.errors || null
        if (!isDiscrete) {
          checkErrors(error)
          flashError(errors != null ? errors[0] : failureMessage)
        }
        shouldLogErrors ? console.log(error) : null
        failureFunc(error)
        setLoadingFunc(false)
      })
  }

  static post(path, params, successMessage, successFunc=()=>{}, failureMessage = defaultErrorMessage, failureFunc = ()=>{}, setLoadingFunc=()=>{}) {
    failureFunc = filterParams(failureFunc)
    successFunc = filterParams(successFunc)
    setLoadingFunc = filterParams(setLoadingFunc)
    setLoadingFunc(true)
    axios.post(`/api/${path}`, params,
      { headers: getAuthHeader() })
      .then(response => {
        successFunc(response)
        flashSuccess(successMessage)
        setLoadingFunc(false)
      })
      .catch(error => {
        checkErrors(error)
        shouldLogErrors ? console.log(error) : null
        let errors = error.response?.data?.errors || null
        if (Array.isArray(errors)) errors = errors.join(", ")
        failureFunc(error)
        flashError(errors != null ? errors : failureMessage)
        setLoadingFunc(false)
      })
  }

  static postFile(path, params, progressFunc, successMessage, successFunc=()=>{}, failureMessage = defaultErrorMessage, failureFunc = ()=>{}, setLoadingFunc=()=>{}) {
    failureFunc = filterParams(failureFunc)
    successFunc = filterParams(successFunc)
    setLoadingFunc = filterParams(setLoadingFunc)
    setLoadingFunc(true)
    axios.post(`/api/${path}`, params,
      { headers: getAuthHeader(), onUploadProgress: (progressEvent) => progressFunc(progressEvent) })
      .then(response => {
        successFunc(response)
        flashSuccess(successMessage)
        setLoadingFunc(false)
      })
      .catch(error => {
        checkErrors(error)
        shouldLogErrors ? console.log(error) : null
        const errors = error.response?.data?.errors || null
        failureFunc(error)
        flashError(errors != null ? errors[0] : failureMessage)
        setLoadingFunc(false)
      })
  }

  static postHeaderless(path, params, successMessage, successFunc=()=>{}, failureMessage = defaultErrorMessage, failureFunc = ()=>{}, setLoadingFunc=()=>{}) {
    failureFunc = filterParams(failureFunc)
    successFunc = filterParams(successFunc)
    setLoadingFunc = filterParams(setLoadingFunc)
    setLoadingFunc(true)
    axios.post(`/api/${path}`, params)
      .then(response => {
        successFunc(response)
        flashSuccess(successMessage)
        setLoadingFunc(false)
      })
      .catch(error => {
        checkErrors(error)
        shouldLogErrors ? console.log(error) : null
        const errors = error.response?.data?.errors || null
        failureFunc(errors)
        flashError(errors != null ? errors[0] : failureMessage)
        setLoadingFunc(false)
      })
  }

  static put(path, params, successMessage, successFunc=()=>{}, failureMessage = defaultErrorMessage, failureFunc = ()=>{}, setLoadingFunc=()=>{}) {
    failureFunc = filterParams(failureFunc)
    successFunc = filterParams(successFunc)
    setLoadingFunc = filterParams(setLoadingFunc)
    setLoadingFunc(true)
    axios.put(`/api/${path}`, params,
      { headers: getAuthHeader() })
      .then(response => {
        successFunc(response)
        flashSuccess(successMessage)
        setLoadingFunc(false)
      })
      .catch(error => {
        checkErrors(error)
        shouldLogErrors ? console.log(error) : null
        const errors = error.response?.data?.errors || null
        failureFunc(error)
        flashError(errors != null ? errors[0] : failureMessage)
        setLoadingFunc(false)
      })
  }

  static putHeaderless(path, params, successMessage, successFunc=()=>{}, failureMessage = defaultErrorMessage, failureFunc = ()=>{}, setLoadingFunc=()=>{}) {
    failureFunc = filterParams(failureFunc)
    successFunc = filterParams(successFunc)
    setLoadingFunc = filterParams(setLoadingFunc)
    setLoadingFunc(true)
    axios.put(`/api/${path}`, params)
      .then(response => {
        successFunc(response)
        flashSuccess(successMessage)
        setLoadingFunc(false)
      })
      .catch(error => {
        checkErrors(error)
        shouldLogErrors ? console.log(error) : null
        const errors = error.response?.data?.errors || null
        failureFunc(errors)
        flashError(errors != null ? errors[0] : failureMessage)
        setLoadingFunc(false)
      })
  }

  static putFile(path, params, progressFunc, successMessage, successFunc=()=>{}, failureMessage = defaultErrorMessage, failureFunc = ()=>{}, setLoadingFunc=()=>{}) {
    failureFunc = filterParams(failureFunc)
    successFunc = filterParams(successFunc)
    setLoadingFunc = filterParams(setLoadingFunc)
    setLoadingFunc(true)
    axios.put(`/api/${path}`, params,
      { headers: getAuthHeader(), onUploadProgress: (progressEvent) => progressFunc(progressEvent) })
      .then(response => {
        successFunc(response)
        flashSuccess(successMessage)
        setLoadingFunc(false)
      })
      .catch(error => {
        checkErrors(error)
        shouldLogErrors ? console.log(error) : null
        const errors = error.response?.data?.errors || null
        failureFunc(error)
        flashError(errors != null ? errors[0] : failureMessage)
        setLoadingFunc(false)
      })
  }

  static patch(path, params, successMessage, successFunc=()=>{}, failureMessage = defaultErrorMessage, failureFunc=(()=>{}), setLoadingFunc=()=>{}) {
    failureFunc = filterParams(failureFunc)
    successFunc = filterParams(successFunc)
    setLoadingFunc = filterParams(setLoadingFunc)
    setLoadingFunc(true)
    axios.patch(`/api/${path}`, params,
      { headers: getAuthHeader() })
      .then(response => {
        successFunc(response)
        flashSuccess(successMessage)
        setLoadingFunc(false)
      })
      .catch(error => {
        checkErrors(error)
        shouldLogErrors ? console.log(error) : null
        const errors = error.response?.data?.errors || null
        failureFunc(error)
        flashError(errors != null ? errors[0] : failureMessage)
        setLoadingFunc(false)

      })
  }

  static delete(path, successMessage, successFunc=()=>{}, failureMessage = defaultErrorMessage, failureFunc=()=>{}, setLoadingFunc=()=>{}) {
    failureFunc = filterParams(failureFunc)
    successFunc = filterParams(successFunc)
    setLoadingFunc = filterParams(setLoadingFunc)
    setLoadingFunc(true)
    axios.delete(`/api/${path}`,
      { headers: getAuthHeader() })
      .then(response => {
        successFunc(response)
        flashSuccess(successMessage)
        setLoadingFunc(false)
      })
      .catch(error => {
        checkErrors(error)
        shouldLogErrors ? console.log(error) : null
        const errors = error.response?.data?.errors || null
        failureFunc(error)
        flashError(errors != null ? errors[0] : failureMessage)
        setLoadingFunc(false)
      })
  }
}


const checkErrors = (error) => {
  try {
    if (error.response.data.includes('CanCan::AccessDenied')) {
      window.flash("Access to that Page was denied", 'error')
      window.location.replace('/');
    }
  }
  catch {}
}

const flashError = (message) => {
  if (message != "") {
    message && message.length > 0 ? window.flash(message, 'error') : window.flash(defaultErrorMessage, 'error')
  }
}

const flashSuccess = (message) => {
  if (message != "") {
    message && message.length > 0 ? window.flash(message, 'success') : window.flash(defaultSuccessMessage, 'success')
  }
}

const filterParams = (func) => {
  if (func == null)
    return (()=>{})
  else {
    return  (func)
  }
}

const getAuthHeader = () => {
  const token = localStorage.getItem('gf-token')
  return {"Authorization" : `Token ${token}`}
}

export const limsDelete = (subpath, id, successMessage, failureMessage, onSuccessFn) => {
    const token = localStorage.getItem('gf-token')
    axios.delete(`/api/${subpath}/${id}`,
        { headers: {"Authorization" : `Token ${token}`} })
        .then(response => {
            window.flash(successMessage, 'success')
        })
        .then(onSuccessFn)
        .catch(error => {
            console.error(error)
            window.flash(failureMessage, 'error')
        })
};

export const limsGet = (subpath, handlerFn) => {
    const token = localStorage.getItem('gf-token')
    axios.get(`/api/${subpath}`,
        { headers: {"Authorization" : `Token ${token}`} })
        .then(response => {
            return response.data
        }).then(handlerFn)
        .catch(error => {
            console.error(error)
            window.flash(`Unknown error: Failed to get data: ${subpath}.`, 'error')
        })
};

export const limsNew = (subpath, handlerFn, newQueryString='') => {
    return limsGet(`${subpath}/new?${newQueryString}`, handlerFn)
}

export const limsCreate = (subpath, model, onSuccessFn) => {
    const token = localStorage.getItem('gf-token')
    axios.post(`/api/${subpath}`, model, { headers: {"Authorization" : `Token ${token}`} })
        .then(onSuccessFn)
        .catch(error => {
            console.error(error)
            console.error(error.response.data)
            window.flash("Error creating object. Please try again later.", 'error')
        })
}

export const limsUpdate = (subpath, model, onSuccessFn) => {
    const token = localStorage.getItem('gf-token')
    const pluralize = require('pluralize')
    const modelName = pluralize.singular(subpath)
    if (model?.id == null) {
        console.error(`limsUpdate got a model with no id, or no model at all: ${JSON.stringify(model)}`)
        window.flash('Error saving object. Please try reloading the page, make the changes again, and attempt to save.' ,'error')
        return
    }
    axios.patch(`/api/${subpath}/${model.id}`, {[modelName]: model}, { headers: {"Authorization" : `Token ${token}`} })
        .then(onSuccessFn)
        .catch(error => {
            console.error(error)
            console.error(error.response.data)
            window.flash("Error saving object. Please try again later.", 'error')
        })
}
