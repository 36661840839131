import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import ReactGA from 'react-ga'

const RouteChangeTracker = () => {
  const location = useLocation()

  useEffect(() => {
    const path = location.pathname + location.search
    ReactGA.set({ page: path })
    ReactGA.pageview(path)

    if(path != '/' && path != '/login' && path != '/signup') {
      localStorage.setItem('gf-previous-path', path)
    }

    // update page title
    if(path.includes('signup')) {
      // sign up
      document.title = 'Create a GenoFAB account for your lab'
      document.querySelector('meta[name="description"]').setAttribute("content", 'Create a GenoFAB account for your lab')
    } else {
      // all other routes
      document.title = 'Laboratory Information Management System'
      document.querySelector('meta[name="description"]').setAttribute("content", 'Automate the management of your laboratory information with the GenoFAB LIMS.')
    }
  }, [location])

  return ''
}

export default RouteChangeTracker
