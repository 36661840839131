import React, { useState, useEffect } from 'react'
import { InputGroup, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap'


const NumberField = ({ field, inputData, handleChange, status }) => {

  const unit = field.fieldOptionsAttributes[0] ? field.fieldOptionsAttributes[0].value : ""

  const handleNumberChange = (e) => {
    const value = e.target.value

    const fakeEvent = {
      target: {
        value: value != "" ? `${value} ${unit}` : "",
        name: e.target.name
      }
    }

    handleChange(fakeEvent)
  }

  // Use effect is used to fire the handleChange when the unit is
  // changed but the value of the input field remains the same
  useEffect(()=>{
    const num = inputData[field.id]
    if (num != null && num != "" && num.split(" ")[1] != unit) {
        console.log("In here");
        console.log(num);
        const fakeEvent = {
          target: {
            value: `${parseFloat(num)} ${unit}`,
            name: field.id
          }
        }

        handleChange(fakeEvent)
    }

  }, [inputData[field.id]])

  let afterLabel = (
    <InputGroup className="mb-3" style={{maxWidth: '18rem'}}>
      <input
        type="number"
        step="any"
        name={field.id}
        className="form-control"
        value={inputData[field.id] != null ? inputData[field.id].split(" ")[0] : ""}
        onChange={handleNumberChange}
      />
      { unit != "" &&
        <InputGroup.Append>
          <InputGroup.Text>{unit}</InputGroup.Text>
        </InputGroup.Append>
      }
    </InputGroup>
  )

  if (Object.keys(field.viewOptions).length > 0) {
    if (field.viewOptions[status] == "hidden") {
      afterLabel = (
        <OverlayTrigger
          placement="right"
          overlay={
            <Tooltip>
              This field is hidden
            </Tooltip>
          }
        >
          <i className="fas fa-eye-slash ml-3"></i>
        </OverlayTrigger>
      )
    } else if (field.viewOptions[status] == "viewable") {
      afterLabel = (
        <OverlayTrigger
          placement="bottom-start"
          overlay={
            <Tooltip>
              This field is not editable
            </Tooltip>
          }
        >
          <InputGroup className="mb-3" style={{maxWidth: '18rem'}}>
            <input
              disabled
              type="number"
              step="any"
              name={field.id}
              className="form-control"
              value={inputData[field.id] != null ? inputData[field.id].split(" ")[0] : ""}
              onChange={handleNumberChange}
            />
            { unit != "" &&
              <InputGroup.Append>
                <InputGroup.Text>{unit}</InputGroup.Text>
              </InputGroup.Append>
            }
          </InputGroup>
        </OverlayTrigger>
      )
    }
  }

  return (
    <div>
      <label>{field.name}</label>
      {afterLabel}
    </div>
  )
}

export default NumberField
